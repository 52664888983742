import { Component } from '@angular/core';
import { LoginService } from 'src/injectables/login.service';

@Component({
    selector: 'manage',
    templateUrl: './manage.component.html',
    styleUrls: ['./manage.component.scss']
})
export class ManageComponent {
    constructor(private loginService: LoginService) { }

    isUserSuperAdmin(): boolean {
        const permissionNeededForAdmin=2;
        const user = this.loginService.user$.getValue();
        return user.permissions === permissionNeededForAdmin;
    }
    
    isUserClientAdmin(): boolean {
        const permissionNeededForClientAdmin=1;
        const user = this.loginService.user$.getValue();
        return user.permissions === permissionNeededForClientAdmin;
    }
}

