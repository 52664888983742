import { AfterViewChecked,AfterViewInit,Component,ElementRef,Input,NgZone,OnChanges,OnInit,SimpleChanges } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import * as htmlToPdf from '../../html-to-pdf';
import { ReportPaginator } from '../paginator';
import { ReportService } from '../report.service';
import { ReportPageScaler } from '../scaler';
import { DialogService } from '@app/old-ui/dialog/dialog.service';

@Component({
	selector: 'report-page-container',
	templateUrl: './report-page-container.component.html',
	styleUrls: ['./report-page-container.component.scss'],
})
export class ReportPageContainerComponent implements AfterViewChecked,AfterViewInit,OnChanges,OnInit{
	constructor(
		private readonly ngZone:NgZone,
		private readonly host:ElementRef<HTMLElement>,
		private readonly dialog:DialogService,
		private readonly report:ReportService,
	){
	}

	@Input()
	public get selectedPageSize(){
		return this._selectedPageSize;
	}
	public set selectedPageSize(v){
		this._selectedPageSize=v;
		this.paginator.reset();
		this.scaler.apply();
	}

	@Input()
	public get orientation(){
		return this.report.orientation;
	}
	public set orientation(v){
		this.report.orientation=v;
		this.paginator.reset();
		this.scaler.apply();
	}

	@Input()
	public get zoom(){
		return this.scaler.zoom;
	}
	public set zoom(v){
		if(this.scaler.zoom!==v){
			this.scaler.zoom=v;
			this.scaler.apply();
		}
	}

	private _selectedPageSize='LETTER';
	public readonly scaler=new ReportPageScaler(this.ngZone,this.host);
	private readonly paginator=new ReportPaginator(this.ngZone,this.host);

	private lastSyncedScroll=0

	public ngOnInit(){
		this.initHtmlToPdf();
	}

	public ngOnChanges(changes:SimpleChanges):void{

	}

	public ngAfterViewInit(): void {
		if(self!==top){
			//for syncing scrolling in report comparer
			window.addEventListener('message',evt=>{
				if(evt.data && typeof(evt.data)==='object' && evt.data.type==='report-scroll'){
					if(this.lastSyncedScroll!==evt.data.value){
						this.lastSyncedScroll=evt.data.value;
						this.host.nativeElement.querySelector('div.page-scroller').scrollTo({
							left: evt.data.value,
							behavior: <any>'instant',
						});
					}
				}
			});
		}
	}

	public ngAfterViewChecked(){
		this.scaler.apply(false);
		this.paginator.start();
	}

	private async initHtmlToPdf(){
		await htmlToPdf.initialize(
			'report-page-sizes',
			[
				'report-v2 .page-list.${size}.${orientation} report-page{${body}}',
				'report-v2 .page-list.${size} report-page.${orientation}.${orientation}{${body}}',
				'report .page-list.${size}.${orientation} report-page{${body}}',
				'report .page-list.${size} report-page.${orientation}.${orientation}{${body}}'
			]
		);
		this.report.htmlToPdfLoaded=true;
	}

	public async pdfBlob(progress$:BehaviorSubject<number>){
		// this.dialog.await('Generating PDF',progress$);
		// await this.scaler.disable(); should no longer be needed, should be able to render it fine scaled

		const pages:HTMLElement[]=[];
		this.host.nativeElement.querySelectorAll<HTMLElement>('report-page').forEach(page=>pages.push(page));
		const blob=await htmlToPdf.convert(pages,progress$);
		if(blob){
			return blob;
		}else{
			return null;
		}
	}

	public onScroll(evt:Event){
		if(self!==top){
			//for syncing scrolling in report comparer
			const scroller=<HTMLElement>evt.target;
			if(this.lastSyncedScroll!==scroller.scrollLeft){
				this.lastSyncedScroll===scroller.scrollLeft;
				top.postMessage({type:'report-scroll',value:scroller.scrollLeft},'*');
			}
		}
	}
	
	public scroll(direction:'next'|'prev'){
		const hostEle=this.host.nativeElement;
		if(!hostEle)
			return;

		const pages=hostEle.querySelectorAll('report-page');
		const pagesContainer=hostEle.querySelector('.page-scroller');

		let currentPage=0;
		for(let i=0; i<pages.length; ++i){
			const page=pages.item(i);
			if(!(page instanceof HTMLElement && page.style.display!=='none'))
				continue;

			const rect = page.getBoundingClientRect();
			const hostRect = pagesContainer.getBoundingClientRect();
			if(rect.left>=hostRect.left && rect.right-rect.left+hostRect.left<=hostRect.right){
				currentPage=i;
				break;
			}
		}

		const nextPage=direction==='next'?currentPage+1:currentPage-1

		if(nextPage>=0 && nextPage<pages.length){
			const page=pages.item(nextPage);
			if(page instanceof HTMLElement && page.style.display!=='none'){
				page.scrollIntoView({block:'center', inline:'center', behavior:'smooth'});
			}
		}
	}
}

