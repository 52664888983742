import {Injectable} from "@angular/core";
import {Observable,combineLatest,merge,of,op} from "@lib/rxjs";
import {Device,User} from '@models';
import {NavigationMenu,navigationMenu} from "src/navigation-menu";
import {LoginService} from "./login.service";
import {NavigationEnd, Router} from "@angular/router";

function filterMenu(input:NavigationMenu, user:User){
	const output:NavigationMenu={};
	for(const key in input){
		let item=input[key];
		if(
			item.permissionsNeeded<=(user?.permissions ?? -1)
			&& (
				!item.environmentType
				|| (item.environmentType==='cloud' && user?.localDevice==='cloud')
				|| (item.environmentType==='device' && user?.localDevice instanceof Device)
			)
		){
			item={...item};
			if(item.children)
				item.children=filterMenu(item.children,user);
			output[key]=item;
		}
	}
	return output;
}

@Injectable({
	providedIn: "root",
})
export class NavigationMenu$ extends Observable<NavigationMenu>{
	public constructor(
		loginService:LoginService,
	){
		super(
			subscriber=>loginService.user$
			.pipe(
				op.map(user=>filterMenu(navigationMenu,user)))
			.subscribe(subscriber)
		);
	}
}

function url$(router:Router){
	const urlChanged$=router.events
		.pipe(
			op.filter((v):v is NavigationEnd=>v instanceof NavigationEnd),
			op.map(v=>v.urlAfterRedirects));

	return merge(
			of(0).pipe(op.map(()=>router.url)),
			urlChanged$)
		.pipe(
			op.map(url=>url==='/'?'/dashboard':url),
			op.distinctUntilChanged());	
}

@Injectable({
	providedIn: "root",
})
export class NavigationRoute$ extends Observable<string[]>{
	public constructor(
		menu$:NavigationMenu$,
		router:Router,
	){
		super(subscriber=>
			combineLatest([
				menu$,
				url$(router)])
			.pipe(
				op.map(([menu,url])=>{
					const pieces=url.split('#')[0].split('/').slice(1);
					const selections:string[]=[];
					while(pieces.length>0){
						const piece=pieces.shift();
						const item=menu[piece];
						if(item)
							selections.push(piece);
						menu=item?.children;
						if(!menu)
							break;
					}
					if(pieces.length>0)
						return selections;
					while(menu){
						const item=Object.values(menu).find(item=>item.default) ?? null;
						if(!item)
							break;
						selections.push(item.route);
						menu=item?.children;
					}
					return selections;
				}))
			.subscribe(subscriber));
	}
}
