import { Web } from "../web";
import { math } from "../math";

export class TriangulatedPoints{
	public constructor(
		public readonly pointCount:number,
		public indices:Uint16Array|Uint32Array,
		public edge2edge:Int32Array,
	){
	}

	public readonly triangleCount=this.indices.length/3;
	private deadTriangles:Uint8Array;

	public [Symbol.iterator](){
		return this.triangleIndices();
	}

	public at(ti:number){
		ti*=3;
		return <[number,number,number]>[
			this.indices[ti+0],
			this.indices[ti+1],
			this.indices[ti+2],
		];
	}

	public atRotated(ti:number, side:number){
		ti*=3;
		return <[number,number,number]>[
			this.indices[ti+side],
			this.indices[ti+(side+1)%3],
			this.indices[ti+(side+2)%3],
		];
	}

	public kill(ti:number){
		this.deadTriangles??=new Uint8Array(this.triangleCount).fill(0);
		if(!this.deadTriangles[ti]){
			this.deadTriangles[ti]=1;
			ti*=3;
			for(let i=0;i<3;++i){
				const e=this.edge2edge[ti];
				if(e>=0)
					this.edge2edge[e]=-1;
				this.edge2edge[ti]=-1;
				++ti;
			}
		}
	}

	public triangle2At(vertices:math.Vec2[], ti:number){
		ti*=3;
		return new math.Triangle2(vertices[this.indices[ti+0]],vertices[this.indices[ti+1]],vertices[this.indices[ti+2]]);
	}

	public triangle3At(vertices:math.Vec3[], ti:number){
		ti*=3;
		return new math.Triangle3(vertices[this.indices[ti+0]],vertices[this.indices[ti+1]],vertices[this.indices[ti+2]]);
	}

	public *triangleIndices(){
		for(let ti=0;ti<this.indices.length;ti+=3){
			if(this.deadTriangles && this.deadTriangles[ti/3])
				continue;
			yield <[number,number,number]>[
				this.indices[ti+0],
				this.indices[ti+1],
				this.indices[ti+2],
			];
		}
	}

	public *triangleVertices<T>(getVertex:(vi:number)=>T){
		for(let ti=0;ti<this.indices.length;ti+=3){
			if(this.deadTriangles && this.deadTriangles[ti/3])
				continue;
			yield <[T,T,T]>[
				getVertex(this.indices[ti+0]),
				getVertex(this.indices[ti+1]),
				getVertex(this.indices[ti+2]),
			];
		}
	}

	private *_triangles2New<T extends math.Vec2>(vertices:T[]){
		for(let ti=0;ti<this.indices.length;ti+=3){
			if(this.deadTriangles && this.deadTriangles[ti/3])
				continue;
			yield new math.Triangle2<T>(
				vertices[this.indices[ti+0]],
				vertices[this.indices[ti+1]],
				vertices[this.indices[ti+2]],
			);
		}
	}

	private *_triangles2Shared<T extends math.Vec2>(vertices:T[], triangle:math.Triangle2<T>){
		for(let ti=0;ti<this.indices.length;ti+=3){
			if(this.deadTriangles && this.deadTriangles[ti/3])
				continue;
			triangle.a=vertices[this.indices[ti+0]];
			triangle.b=vertices[this.indices[ti+1]];
			triangle.c=vertices[this.indices[ti+2]];
			yield triangle;
		}
	}

	public triangles2<T extends math.Vec2>(vertices:T[], triangle:math.Triangle2<T>){
		if(triangle)
			return this._triangles2Shared(vertices,triangle);
		return this._triangles2New(vertices);
	}
	
	private *_triangles3New<T extends math.Vec3>(vertices:T[]){
		for(let ti=0;ti<this.indices.length;ti+=3){
			if(this.deadTriangles && this.deadTriangles[ti/3])
				continue;
			yield new math.Triangle3<T>(
				vertices[this.indices[ti+0]],
				vertices[this.indices[ti+1]],
				vertices[this.indices[ti+2]],
			);
		}
	}

	private *_triangles3Shared<T extends math.Vec3>(vertices:T[], triangle:math.Triangle3<T>){
		for(let ti=0;ti<this.indices.length;ti+=3){
			if(this.deadTriangles && this.deadTriangles[ti/3])
				continue;
			triangle.a=vertices[this.indices[ti+0]];
			triangle.b=vertices[this.indices[ti+1]];
			triangle.c=vertices[this.indices[ti+2]];
			yield triangle;
		}
	}

	public triangles3<T extends math.Vec3>(vertices:T[], triangle:math.Triangle3<T>){
		if(triangle)
			return this._triangles3Shared(vertices,triangle);
		return this._triangles3New(vertices);
	}

	public *edges(){
		for(let ti=0;ti<this.indices.length;ti+=3){
			if(this.deadTriangles && this.deadTriangles[ti/3])
				continue;
			for(let a=0;a<3;++a){
				const b=(a+1)%3;
				yield <[number,number]>[this.indices[ti+a],this.indices[ti+b]];
			}
		}
	}

	public *outerEdges(){
		for(let ti=0;ti<this.indices.length;ti+=3){
			if(this.deadTriangles && this.deadTriangles[ti/3])
				continue;
			for(let a=0;a<3;++a){
				if(this.edge2edge[ti+a]<0){
					const b=(a+1)%3;
					yield <[number,number]>[this.indices[ti+a],this.indices[ti+b]];
				}
			}
		}
	}

	public outerPolygon(){
		const map=new Map<number,number>();
		for(const [a,b] of this.outerEdges())
			map.set(a,b);
		const polygon:number[]=[];
		let a:number|undefined;
		let b:number|undefined;
		for([a,b] of map){
			polygon.push(a,b);
			map.delete(a);
			b=map.get(a=b);
			while(b!==undefined){
				polygon.push(b);
				map.delete(a);
				b=map.get(a=b);
			}
			break;
		}
		return polygon;
	}

	public web():Web<number>;
	public web<T>(vertices:T[]):Web<T>;
	public web<T>(vertices?:T[]):Web<number>|Web<T>{
		if(vertices){
			const web=new Web<T>();
			for(const e of this.edges())
				web.connect(vertices[e[0]],vertices[e[1]]);
			return web;
		}

		const web=new Web<number>();
		for(const e of this.edges())
			web.connect(e[0],e[1]);
		return web;
	}

	public connections():[number,number][];
	public connections<T>(vertices:T[]):[T,T][];
	public connections<T>(vertices?:T[]):([number,number][])|([T,T][]){
		const set=new Set<number>();
		for(let e of this.edges()){
			if(e[0]>e[1])
				e.reverse();
			set.add(e[0]*this.pointCount+e[1]);
		}
		if(vertices)
			return [...set].map(v=>[vertices[math.floor(v/this.pointCount)],vertices[v%this.pointCount]]);
		return [...set].map(v=>[math.floor(v/this.pointCount),v%this.pointCount]);
	}
}
