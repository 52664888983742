import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HomeFragQueryService } from 'src/injectables/home.service';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
})
export class HomeComponent {
    constructor(private readonly fragQuery: HomeFragQueryService) { }

    public readonly allDeviceSeries$ = this.fragQuery.allDeviceData$;
    public readonly devices$ = this.fragQuery.devices$;
    public readonly minutes=this.fragQuery.minutes;
    public readonly sec=this.fragQuery.sec;
    
}
