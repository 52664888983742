import {CommonModule} from '@angular/common';
import {Component,ElementRef,EventEmitter,Input,Output} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {Color} from '../../color';
import {SliderComponent} from '../slider/slider.component';

let openedComponent=new WeakSet<ColorPickerComponent>();

@Component({
	standalone: true,
	selector: 'color-picker',
	templateUrl: './color-picker.component.html',
	styleUrls: ['./color-picker.component.scss'],
	imports: [
		CommonModule,
		FormsModule,
		MatButtonModule,
		SliderComponent,
	],
})
export class ColorPickerComponent{
	constructor(
		private readonly host:ElementRef<HTMLElement>
	){
	}

	@Input() get value(){
		return this._value;
	}

	set value(v){
		this._value=v;
		this.colorStr=v.toString();
		this.hue=Math.round(v.hue());
		this.val=Math.round(v.value()*100);
		this.sat=Math.round(v.saturation()*100);
	}

	@Output() valueChange=new EventEmitter<Color>();
	
	private _value=Color.rgb(1,0,0);
	public colorStr=this._value.toString();
	public hue=0;
	public val=100;
	public sat=100;

	public popupLeft='';
	public popupRight='';
	public popupTop='';
	public popupBottom='';

	public isOpened(){
		return openedComponent.has(this);
	}

	public toggleOpened(){
		if(openedComponent.has(this)){
			openedComponent.delete(this);
		}else{
			openedComponent=new WeakSet();
			openedComponent.add(this);
			this.popupLeft='100%';
			const rect=this.host.nativeElement.getBoundingClientRect();
			if((rect.left+rect.right)/2<window.innerWidth/2){
				this.popupLeft='100%';
				this.popupRight='';
			}else{
				this.popupLeft='';
				this.popupRight='100%';
			}
			if((rect.top+rect.bottom)/2<window.innerHeight/2){
				this.popupTop='0';
				this.popupBottom='';
			}else{
				this.popupTop='';
				this.popupBottom='0';
			}
		}
	}



	public emitValue(){
		this.value=Color.hsv(this.hue,this.sat/100,this.val/100);
		this.valueChange.emit(this.value);
	}
}
