import {Injectable} from "@angular/core";
import {DialogService} from "@app/old-ui/dialog/dialog.service";
import {BehaviorSubject,firstValueFrom,op} from "@lib/rxjs";
import {storageLocal} from "@lib/storage-local";
import {Device} from "@models";
import {User$} from "./login.service";
import {WebClient} from "./web-client";
import { RegisterDeviceDialog } from "@app/shared/register-device-dialog/register-device-dialog.component";

@Injectable({
	providedIn: "root",
})
export class LocalDeviceService{
	constructor(
		private readonly webClient:WebClient,
		private readonly dialog:DialogService,
		private readonly user$:User$
	){
		this.init();
	}

	public readonly localDevice$=new BehaviorSubject<'unregistered'|'cloud'|Device>(undefined);

	private init(){
		this.user$.pipe(
			op.map((user):('unregistered'|'cloud'|Device)=>{
				if(!user)
					return undefined;
				if(user.localDevice===null)
					return 'unregistered';
				if(user.localDevice==='cloud')
					return 'cloud';
				return user.localDevice;
			}))
			.subscribe(device=>{
				this.localDevice$.next(device);
				if(device==='unregistered')
					this.register()
			});
	}

	public async register(){
		let device=await firstValueFrom(this.localDevice$);
		if(device==='unregistered'){
			const {name,type}=await this.dialog.open(RegisterDeviceDialog);
			device=await this.dialog.await(this.webClient.rest({
				route: 'register-local-device',
				method: 'post',
				body: {
					...storageLocal.get('auth'),
					name,
					type,
				},
			}).then(r=>r.to(Device)));
			device??='unregistered';
			this.localDevice$.next(device);
		}
	}	
}
