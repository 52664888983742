import { math } from "../math";
import { Color } from "./color";

export abstract class NumberToColorMap{
	public constructor(
		public positions:number[],
		public colors:Color[],
	){
		this.positions=positions.slice();
		this.colors=colors.slice();
	}

	public abstract clone():this;
	public abstract isValid():boolean;
	public abstract clean():NumberToColorMap;

	public get length(){
		return this.positions.length;
	}

	public set length(v:number){
	}

	public at(i:number){
		return <[number,Color]>[this.positions.at(i),this.colors.at(i)];
	}

	public setPosition(i:number, p:number){
		if(0<=i && i<this.positions.length){
			if(this.positions[i]!==p)
				this.positions[i]=p;
			return true;
		}
		return false;
	}

	public setColor(i:number, c:Color){
		if(0<=i && i<this.colors.length && this.colors[i]!==c){
			this.colors[i]=c;
			return true;
		}
		return false;
	}

	public add(where:'first'|'last'):void{
		if(where==='first'){
			if(this.positions.at(0)===-Infinity){
				this.colors.splice(1,0,this.colors[1]);
				this.positions.splice(1,0,this.positions[1]);
			}else{
				this.colors.unshift(this.colors[0]);
				this.positions.unshift(this.positions[0]);
			}
		}else{
			if(this.positions.at(-1)===Infinity){
				this.colors.splice(this.colors.length-1,0,this.colors.at(-2)!);
				this.positions.splice(this.positions.length-1,0,this.positions.at(-2)!);
			}else{
				this.colors.push(this.colors.at(-1)!);
				this.positions.push(this.positions.at(-1)!);
			}
		}
	}

	public equals(that:NumberToColorMap){
		return this.positions.length===that.positions.length && this.colors.length===that.colors.length && this.positions.every((v,i)=>that.positions[i]===v) && this.colors.every((v,i)=>that.colors[i].equals(v));
	}

	public copy(that:NumberToColorMap){
		this.positions=that.positions.slice();
		this.colors=that.colors.map(c=>c.clone());
		return this;
	};

	public transform(transformer:(p:number)=>number){
		this.positions=this.positions.map(transformer);
		return this;
	}

	public abstract toCssGradient(sideOrCorner:string):string;
	public abstract toJson():NumberToColorMap.JSON;

	public abstract delete(i:number):boolean;

	public abstract atPosition(v:number):Color|null;
	public abstract atPositionRange(v:number, u:number):Color|null;

	public range(){
		return math.Range.fromPoints(this.positions);
	}
}

export namespace NumberToColorMap{
	export interface JSON {
		positions:(number|'-∞'|'+∞')[];
		colors:string[];
	}

	export const fromJSONExtension:((json:NumberToColorMap.JSON)=>NumberToColorMap|null)[]=[];
	export function fromJson(json:NumberToColorMap.JSON):NumberToColorMap|null{
		if(json && typeof(json)==='object'){
			if(Array.isArray(json.positions) && Array.isArray(json.colors)){
				for(const fromJson of fromJSONExtension){
					const value=fromJson(json);
					if(value)
						return value;
				}
			}
		}
		return null;
	}
}
