import { Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DialogService } from '@app/old-ui/dialog/dialog.service';
import { FragQueryService } from 'src/injectables/frag-query.service';
import { LoginService } from 'src/injectables/login.service';

@Component({
    selector: 'api-settings',
    templateUrl: './api-settings.component.html',
    styleUrls: ['./api-settings.component.scss']
})
export class ApiSettingsComponent {
    constructor(
        private fragQuery: FragQueryService,
        private loginService: LoginService,
        private dialog:DialogService,
        private snackBar:MatSnackBar,
    ) { }
    public readonly token$ = this.fragQuery.apiToken$;
    public readonly tokenName$= this.fragQuery.tokenName$
    public newTokenName='';
    public error=false;
    public copy='Copy';

    public async getToken() {
        if(this.newTokenName!=''){
            const newtoken = await this.dialog.await(this.loginService.generateAPIToKen(this.newTokenName));
            if (newtoken) {
                this.tokenName$.next(this.newTokenName);
                this.token$.next(newtoken);
                this.newTokenName='';
                this.error=false
            }
        }
        else{
            this.error=true;
        }
    }

    public async deleteToken(){
        const isDeleted = await this.dialog.await(this.loginService.deleteAPIToKen());
        if(isDeleted){
            this.token$.next(null)
            this.snackBar.open('Api token deleted successfully', 'Close', {
                duration: 3000,
                panelClass: ['snackbar-success']
              });
        }
    }

    public copyToken(){
        navigator.clipboard.writeText(this.token$.value);
        this.copy='Copied'
        this.snackBar.open('Token Copied', 'Close', {
            duration: 3000,
            panelClass: ['snackbar-success']
          });
    }
}
