import { Component } from '@angular/core';
import { MatDialogConfig,MatDialogRef } from '@angular/material/dialog';
import { Device } from '@models';

@Component({
	selector: 'register-device-dialog',
	templateUrl: './register-device-dialog.component.html',
	styleUrls: [
		'./register-device-dialog.component.scss'
	]
})
export class RegisterDeviceDialog{
	static readonly config:MatDialogConfig<void>={
		autoFocus: false,
		disableClose: true,
	};
	static readonly output:{
		name:string;
		type:Device['type'];
	};

	constructor(
		private host:MatDialogRef<RegisterDeviceDialog,typeof RegisterDeviceDialog.output>,
	){
	}

	public readonly typeOptions=Object.entries(Device.typeOptions);
	public name='';
	public type:Device['type'];

	public valid(){
		if(!this.name)
			return false;
		return true;
	}

	public finish(){
		this.host.close({
			name: this.name,
			type: this.type,
		});
	}
}
