import { Component,ContentChild,EventEmitter,Input,Output } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { DialogService } from '@app/old-ui/dialog/dialog.service';
import { WebClient } from '@injectables';
import { ModelMeta,WhereTable } from '@lib/model-meta';
import { BehaviorSubject,combineLatest,firstValueFrom,op } from '@lib/rxjs';
import { ModelTableActionsDirective } from './model-table-actions.directive';
import { ModelTableHeaderDirective } from './model-table-header.directive';
import { ModelTableRowDirective } from './model-table-row.directive';
import { FragQueryService } from 'src/injectables/frag-query.service';

@Component({
	selector: 'model-table',
	templateUrl: './model-table.component.html',
	styleUrls: ['./model-table.component.scss']
})
export class ModelTableComponent<
	Instance extends {
		id:number;
		name?:string;
	},
	RestTypes extends WebClient.RestTypes,
>{
	constructor(
		protected readonly dialog:DialogService,
		protected readonly fragQuery:FragQueryService,
	){
	}

	@Input() title:string;
	@Input() model:{
		meta:ModelMeta;
		new ():Instance;
	};
	@Input() 
		get where(){
			return this.where$.value;
		}
		set where(v){
			this.where$.next(v);
		}

	@Input() sort:Record<string,'+'|'-'>;
	@Input() restClient:WebClient.RestModel<Instance,RestTypes>;
	@Input() editDialog:{
		new(...args:any[]):any;
		config:MatDialogConfig<{record:Instance,showTimes?:Boolean}>;
		output:boolean;
	};
	@Input() deletable=(v:Instance)=>true;
	@Input() addable=(v?:Instance)=>true;

	@Output('add') addEmitter=new EventEmitter<((confirm:boolean|PromiseLike<boolean>)=>void)>();

	@ContentChild(ModelTableHeaderDirective) headerContent:ModelTableHeaderDirective;
	@ContentChild(ModelTableRowDirective) rowContent:ModelTableRowDirective<Instance>;
	@ContentChild(ModelTableActionsDirective) actionsContent:ModelTableActionsDirective<Instance>;
	
	public readonly where$=new BehaviorSubject<WhereTable>({});
	public readonly pageIndex$=new BehaviorSubject(0);
	public readonly pageSize$=new BehaviorSubject(25);

	public readonly selected=new Set<Instance>();

	public readonly recordCount$=this.where$
		.pipe(
			op.switchMap(()=>this.restClient.count$({}).pipe(op.startWith(undefined))),
			op.startWith(undefined),
			op.shareReplay(1));

	public readonly pageRecords$=
		combineLatest([
			this.pageIndex$.pipe(op.distinctUntilChanged()),
			this.pageSize$.pipe(op.distinctUntilChanged()),
			this.where$])
		.pipe(
			op.debounceTime(250),
			op.switchMap(([pageIndex,pageSize,where])=>{
				return this.restClient.search$({
					where,
					range: [pageIndex*pageSize,pageSize],
					sort: this.sort
				}).pipe(op.startWith(undefined));
			}),
			op.startWith(undefined),
			op.shareReplay(1));

	public async selectAll(v:boolean){
		if(v){
			const records=await firstValueFrom(this.pageRecords$);
			for(const record of records)
				this.selected.add(record);
		}else{
			this.selected.clear();
		}
	}

	public onPage(evt:PageEvent){
		this.selected.clear();
		this.pageIndex$.next(evt.pageIndex);
		this.pageSize$.next(evt.pageSize);
	}

	public updateRecords(){
		this.where$.next(this.where$.value);
	}

	public async add(){
		if(this.addEmitter.observed){
			if(await new Promise<boolean>(resolve=>this.addEmitter.emit(resolve))){
				this.updateRecords();
			}
		}else{
			const record=new this.model();
			if(await this.dialog.open(this.editDialog,{record})){
				await this.dialog.await(this.restClient.save(record));
				this.updateRecords();
			}
		}
	}

	public async edit(record:Instance){
		if(await this.dialog.open(this.editDialog,{record})){
			await this.dialog.await(this.restClient.save(record));
			this.fragQuery.trigger$.next();
		}
	}

	public async _delete(record?:Instance){
		if(record){ 
			if(await this.dialog.confirm({message:'Are you sure you want to delete '+(record.name ?? 'this')})){
				await this.dialog.await(this.restClient.delete(record));
				this.updateRecords();
			}
		}else{
			if(await this.dialog.confirm({message:'Are you sure you want to delete the selected records'})){
				await this.dialog.await((async ()=>{
					for(const record of this.selected){
						if(this.deletable(record))
							await this.restClient.delete(record);
					}
				})());
				this.updateRecords();
			}
		}
		
	}
}