import {WebClient} from '@lib/web-client';
import {User as _User} from '../../../shared-models/public/user';
import {Device} from './device';

export class User extends _User{
	public password?:string;// a fake field used to send the password to the backend

	public displayName(){
		if(this.firstName && this.lastName)
			return `${this.firstName} ${this.lastName}`;
		if(this.firstName)
			return this.firstName;
		if(this.lastName)
			return this.lastName;
		return this.email;
	}

	public authentication(){
		return {
			email:this.email,
			authToken:this.authToken
		};
	}

	public get name(){
		return this.email;
	}

	public localDevice:Device|'cloud';

	public fromResponse(r:WebClient.Response){
		User.meta.copyToInstance(r.value,this);
		if(r.value.localDevice==='cloud')
			this.localDevice=r.value.localDevice;
		else if(r.value.localDevice)
			this.localDevice=WebClient.Response.getInstance(Device,r.value.localDevice.id);
		else
			this.localDevice=null;
	}
}

export namespace User{
	export import RestTypes=_User.RestTypes;
}
