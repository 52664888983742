import {math} from '../math';
import {Type,typeMeta,TypeToUnit} from './types';
import {Unit,unitMeta} from './units';
export * from './types';
export * from './units';

export function abbreviation(unit:Unit) {
	return unitMeta[unit]?.abbreviation || '';
}

export function fullUnitName(unit:Unit) {
	return unitMeta[unit]?.fullName || '';
}

interface UnitConversion{
	inBase:number;
	offsetFromBase:number;
}

function forward(value:number, unit:UnitConversion){
	return value/unit.inBase+unit.offsetFromBase;
}

function backwards(value:number, unit:UnitConversion){
	return (value-unit.offsetFromBase)*unit.inBase;
}

export function convertFromBaseUnits(value:number, toUnit:Unit){
	const meta=unitMeta[toUnit];
	return forward(value,meta);
}

export function convertToBaseUnits(value:number, fromUnit:Unit){
	const meta=unitMeta[fromUnit];
	return backwards(value,meta);
}

export function convert(value:number, toUnit:Unit):number;
export function convert(value:number, fromUnit:Unit, toUnit:Unit):number;
export function convert(value:number, fromUnit:Unit, toUnit?:Unit){
	const fromMeta=unitMeta[fromUnit];
	if(toUnit===undefined){
		return forward(value,fromMeta);
	}

	const toMeta=unitMeta[toUnit];
	value=backwards(value,fromMeta);
	value=forward(value,toMeta);
	return value;
}

export function intervalConvert(value:number, fromUnit:Unit, toUnit?:Unit){
	const fromMeta=unitMeta[fromUnit];

	if(toUnit===undefined){
		return value/fromMeta.inBase;
	}

	const toMeta=unitMeta[toUnit];
	value=value*fromMeta.inBase;
	value=value/toMeta.inBase;
	return value;
}

export function roundToFriendly(value:number, unit:Unit){
	const f=unitMeta[unit];
	value=forward(value,f);
	let step=1;
	if(unit==='meter')
		step=0.25;
	if(unit==='degrees')
		step=0.0174533;
	value=Math.round(value/step)*step;
	value=backwards(value,f);
	return value;
}

export function roundToWhole(value:number, unit:Unit){
	const f=unitMeta[unit];
	value=forward(value,f);
	value=Math.round(value);
	value=backwards(value,f);
	return value;
}

export function decimalCountFromSize(value:number){
	const absValue=math.abs(value);
	let count=0;
	for(;count<3;++count){
		if(absValue>=100/(10**count))
			break;
	}
	return count;
}

export function decimalCountFromUnit(unit:Unit){
	const um=unitMeta[unit];
	return um?.decimalCount;
}

export function decimalCountFromUnitAndSize(value:number, unit:Unit){
	let decimalCount=decimalCountFromUnit(unit);
	if(typeof(decimalCount)==='number')
		return decimalCount;

	return decimalCountFromSize(value);
}

export function toDecimalString(value:number, decimalCount:number){
	let text:string;
	if(decimalCount>=0){
		value=math.roundTo(value,1/(10**decimalCount));
		text=value.toFixed(decimalCount);
		if(decimalCount>0){
			text=text.replace(/0+$/,'');
			text=text.replace(/\.$/,'');
		}
	}else{
		decimalCount=-decimalCount;
		value=math.roundTo(value,1/(10**decimalCount));
		text=value.toFixed(decimalCount);
	}

	if(text==='-0')
		return '0';

	return text;

}

export function toMaxDecimals(value:number, decimalCount:number|Unit){
	if(typeof(decimalCount)==='string'){
		decimalCount=decimalCountFromUnitAndSize(value,decimalCount);
	}
	return toDecimalString(value,decimalCount);
}

export function addCommas(value:string|number){
	value=value.toString();
	let prefix='';  						// Adding commas to negative number
	if (value.charAt(0) === '-') {
		prefix='-';
		value=value.substring(1);
	}
	let [integer,decimal]=value.split('.');
	const pieces=[];
	while(integer.length>3){
		pieces.unshift(integer.substring(integer.length-3));
		integer=integer.substring(0,integer.length-3);
	}
	if(integer)
		pieces.unshift(integer);
	integer=pieces.join(',');
	if(decimal)
		value=integer+'.'+decimal;
	else
		value=integer;
	return prefix+value;
}

export function formatValue(
	str:string,
	value:number,
	units:Unit[],
	addCommas:boolean,
){
	// if(value===Infinity)
	// 	return '∞';
	// if(value===-Infinity)
	// 	return '-∞';
	// if(value===null || value===undefined || isNaN(value))
	// 	return '---';

	const forceSign=str[0]==='+';
	if(forceSign)
		str=str.substring(1);

	let sign='';
	if(value<0)
		sign='-';
	else if(forceSign)
		sign='+';
	value=Math.abs(value);

	let minIntegers=1;
	let minDecimals=0;
	let maxDecimals=decimalCountFromUnitAndSize(value,units[0]);

	if(str!=='v'){
		[minIntegers,minDecimals,maxDecimals]=str.split(/[\.\-]/).map(v=>+v);
	}

	let [integer,decimals]=value.toFixed(maxDecimals).split('.');
	decimals??='';
	integer=integer.padStart(minIntegers,'0');
	while(decimals.at(-1)==='0')
		decimals=decimals.substring(0,decimals.length-1);
	decimals=decimals.padEnd(minDecimals,'0');

	if(addCommas && integer.length>3){
		const pieces:string[]=[];
		while(integer.length>3){
			pieces.push(integer.substring(integer.length-3,integer.length));
			integer=integer.substring(0,integer.length-3);
		}
		pieces.push(integer);
		pieces.reverse();
		integer=pieces.join(',');
	}

	if(decimals)
		return `${sign}${integer}.${decimals}`;
	return `${sign}${integer}`;
}

export function formatByStr(
	str:string,
	value:number,
	units:Unit[],
	addCommas=false,
){
	if(value===Infinity)
		return '∞';
	if(value===-Infinity)
		return '-∞';
	if(value===null || value===undefined || isNaN(value))
		return '---';

	str=str.replace(/\$\{[^}]+\}/g,specifier=>{
		specifier=specifier.substring(2,specifier.length-1);
		if(specifier==='u')
			return units.map(u=>abbreviation(u)).join('/');
		if(specifier==='U')
			return units.map(u=>fullUnitName(u)).join('/');
		if(specifier==='v' || specifier==='+v' || specifier.match(/^\+?\d+\.\d+-\d+$/)){
			return formatValue(specifier,value,units,addCommas);
		}
		return specifier;
	});
	return str;
}

export interface FormatOptions{
	unit?:Unit;
	type?:Type;
	units?:TypeToUnit;
	commas?:boolean;
	format?:string;
}

export function format(value:number, options:FormatOptions){
	if(value===Number.POSITIVE_INFINITY)
		return '∞';
	if(value===Number.NEGATIVE_INFINITY)
		return '-∞';
	if (value === null || value === undefined || isNaN(value))
		return '---';

	const type=options.type ?? 'count';
	const meta=typeMeta[type];
	const unit=options?.unit ?? options.units?.[type] ?? meta.metricUnit;
	value=convert(value,meta.valueUnit,unit);
	let format=options.format ?? '${v}${u}';
	return formatByStr(format,value,[unit],options.commas);
}

export function formatLatLon(value:number, convertToDegrees?:boolean) {
	if (value === null || value === undefined || isNaN(value))
		return '---';
	if (convertToDegrees) {
		const absolute = Math.abs(value);
		const degrees = Math.floor(absolute);
		const minutesNotTruncated = (absolute - degrees) * 60;
		const minutes = Math.floor(minutesNotTruncated);
		const seconds = Math.floor((minutesNotTruncated - minutes) * 60);
		return `${degrees}° ${minutes}' ${seconds}"`;
	}
	const decimalsCount = 8;
	let text = value.toFixed(decimalsCount);
	//text=text.replace(/0+$/,'');
	//text=text.replace(/\.$/,'');
	return text + '°';
}

const MEMORY_SIZE_UNITS = [
	'bytes',
	'KB',
	'MB',
	'GB',
	'TB',
	'PB'
];

/**
 * Format a size in bytes for human consumption.
 */
export function formatMemorySize(sizeInBytes:number, precision:number):string {

	if (isNaN(sizeInBytes) || !isFinite(sizeInBytes)) return '---';

	let unit = 0;

	while (sizeInBytes >= 1024) {
		sizeInBytes /= 1024;
		unit += 1;
	}

	if (unit === 0) {
		// it never makes sense to have less than 1 byte
		precision = 0;
	}
	return sizeInBytes.toFixed(precision) + ' ' + MEMORY_SIZE_UNITS[unit];
}

const thresholdHumanHearingPressure=1.99948e-5;//Pa

export function volumnToAirOverPressure(volumn:number){
	return thresholdHumanHearingPressure*(10**(volumn/20));
}

export function airOverPressureToVolumn(pressure:number){
	return 20*Math.log10(pressure/thresholdHumanHearingPressure);
}
