import { Component,EventEmitter,Input,Output,TemplateRef,ViewChild } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { BehaviorSubject,filter,map } from 'rxjs';
import { FragQueryService } from 'src/injectables/frag-query.service';
import { fadeIn } from './camera-snapshot.animations';
import { transition, trigger, useAnimation } from '@angular/animations';
import { DataInterval } from '../../../../../../shared-models/public/data-interval';
import { MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'camera-snapshot',
    templateUrl: './camera-snapshot.component.html',
    styleUrls: ['./camera-snapshot.component.scss'],
    animations: [
        trigger('carouselAnimation', [
            transition('void => *', [useAnimation(fadeIn)]),
        ]),
    ],
})

export class CameraSnapshotComponent {
    [x: string]: any;
    constructor(
        private readonly fragQuery: FragQueryService,
        public dialogue: MatDialog,
    ) { }
    
    @Input() imgSrc: string = '';
    @Input() snapType: string = 'home';
    @Input() totalIntervals=-1;
    @Input() slabCount: number = 0;

    color: ThemePalette = 'primary';
    checked = false;
    disabled = false;
    defaultImageSrc: string = '../../../assets/image_not_found.jpg';
    imageLoadError: boolean = false;
    selectedImageIndex: number | null = null;

    @Input()
    get intervals() {
        return this.intervals$.value;
    }
    set intervals(v) {
        this.intervals$.next(v);
    }

    @Input()
    get currentInterval() {
        return this.currentInterval$.value;
    }
    set currentInterval(v) {
        this.currentInterval$.next(v);
    }

    @Output() nextClick: EventEmitter<void> = new EventEmitter<void>();
    @Output() previousClick: EventEmitter<void> = new EventEmitter<void>();
    @Output() refreshClick: EventEmitter<void> = new EventEmitter<void>();
    @ViewChild('fullimgtemplate', { static: false })
    fullimgtemplate!: TemplateRef<any>;

    public readonly intervals$ = new BehaviorSubject<any[]>([]);
    public readonly currentInterval$ = new BehaviorSubject(null);
    public readonly device$ = this.fragQuery.device$;

    public intervalsArray: ExtendedDataInterval[] = [];
    public imgsrc: string = '';
    public currentSlide = 0;
    public showInterval = false;
    ngOnChanges() {
        this.intervals$.subscribe((intervals) => {
            this.intervalsArray = intervals
            .filter((line) => line.name !== null)
            .flatMap((line) => line.data)
            .map((interval) => interval[2]);
        });
    }

    public readonly currentUrl = this.currentInterval$.pipe(
        filter((interval) => interval !== null),
        map((interval) => {
            this.showInterval = true;
            this.imgsrc = interval?.imageUrl;
            this.imageLoadError = false;
            return interval?.imageUrl;
        })
    );

    refresh() {
        this.showInterval = false;
        this.refreshClick.emit();
    }

    onPreviousClick() {
        const previous = this.currentSlide - 1;
        this.currentSlide = previous < 0 ? (this.totalIntervals!==-1)?this.totalIntervals-1:this.fragQuery.intervalsLength.value-1 : previous;
        this.showInterval = false;
        this.imageLoadError = false;
        this.previousClick.emit();
    }

    onImageLoadError() {
        this.imageLoadError = true;
        this.imgsrc = this.defaultImageSrc;
    }
    onNextClick() {
        const next = this.currentSlide + 1;
        this.showInterval = false;
        this.currentSlide = next === ((this.totalIntervals!==-1)?this.totalIntervals:this.fragQuery.intervalsLength.value) ? 0 : next;
        this.imageLoadError = false;
        this.nextClick.emit();
    }

    showFullImg(src?: string) {
        if (src) {
            this.imgsrc = src;
        }
        const dialogRef = this.dialogue.open(this.fullimgtemplate, {
            width: '100%',
        });
    }

    closeFullImg() {
        this.dialogue.closeAll();
    }


    downloadImage() {
        let downloadUrl:DataInterval=null
        if(this.showInterval==true){
            downloadUrl=this.currentInterval$.value
        }else{
            downloadUrl= this.intervalsArray[this.currentSlide+1];
        }
        if (downloadUrl && downloadUrl.imageUrl) {
            const anchor = document.createElement('a');
            anchor.href = downloadUrl.imageUrl;
            anchor.download = 'image';
            anchor.click();
        }
    }
}

interface ExtendedDataInterval extends DataInterval{
    slabs: number,
    thresholdCount: number
}
