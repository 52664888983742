<mat-card [ngClass]="{'card-small': size==='small', 'card-medium': size==='medium', 'card-large': size==='large', 'warn': !!warn, 'primary': !warn,'long': !!long}">
    <span>
        <mat-icon svgIcon={{icon}}></mat-icon>
        <span class="titlespan">{{title}} </span>
    </span>
    <div *ngIf="content!==''" [ngClass]="warn?'warn':'primary'" class="small-card-content">
        {{content}}
    </div>
    <div *ngIf="content===''" [ngClass]="warn?'warn':'primary'" class="small-card-content">
        <ng-content></ng-content>
    </div>
</mat-card>