import {Component,Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Color} from '@lib/color';

@Component({
	selector: 'dialog-color-picker',
	templateUrl: './dialog-color-picker.component.html',
	styleUrls: [
		'./dialog-color-picker.component.scss'
	]
})
export class DialogColorPickerComponent{
	constructor(
		@Inject(MAT_DIALOG_DATA) public input:DialogColorPickerComponent.Input,
	){
	}

	public color=this.input;
}

export namespace DialogColorPickerComponent{
	export type Input=Color;
}