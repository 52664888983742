<report-page [footer]="true" class="landscape">
	<div class="full-height full-width flex-column">
        <div class="full-width">
	    	<div class="strip bar-1"></div>
	    </div>
        <div class="flex-row justify height-10">
            <div class="full-width flex-row middle">
                <p class="font-large"><strong>Report Time Interval : </strong></p>
            </div>
            <div class="full-width flex-row middle">
                <p class="font-large"><strong>Begin: </strong>{{startDate|date:'medium'}}</p>
            </div>
            <div class="full-width flex-row middle">
                <p class="font-large"><strong>End: </strong>{{endDate|date:'medium'}}</p>
            </div>
        </div>
        <div class="container full-height">
            <chart-line class="full-height"
                [legend]="false"
                [xAxis]="{
                    name: 'Time',
                    dataType: 'time',
                }"
                [yAxis]="{
                    name: 'Particle Size (' + (particleSizeUnit$ | async) + ')',
                    dataType: 'number',
                }"
                [series]="timelineData"
              >
            </chart-line>
        </div>
        <div class="full-width">
	    	<div class="strip bar-1"></div>
            <div class="strip"></div>
            <div class="strip"></div>
	    </div>
	</div>
</report-page>
