import { BoxTree } from "./box-tree/box-tree";
import { BoxTreeBranch } from "./box-tree/box-tree-branch";
import { BoxTreeClosest } from "./box-tree/box-tree-closest";
import { BoxTreeLeaf } from "./box-tree/box-tree-leaf";
import { BoxTreeNode } from "./box-tree/box-tree-node";
import { math } from "./math";

export class OctTree<Item,Data=never> extends BoxTree<Item,math.Box3,Data>{
	public override newBox(){
		return new math.Box3();
	}

	public override newVec(){
		return new math.Vec3();
	}
}

export namespace OctTree{
	export type Node<Item,Data=never>=BoxTreeNode<Item,math.Box3,Data>;
	export const Node=BoxTreeNode;
	export type Branch<Item,Data=never>=BoxTreeBranch<Item,math.Box3,Data>;
	export const Branch=BoxTreeBranch;
	export type Leaf<Item,Data=never>=BoxTreeLeaf<Item,math.Box3,Data>;
	export const Leaf=BoxTreeLeaf;
	export type Closest<Item,Data>=BoxTreeClosest<Item,math.Box3,Data>;
}

export class Vec3OctTree<Point extends math.Vec3=math.Vec3> extends OctTree<Point>{
	public override add(
		item:Point,
		itemPos:math.Vec=item,
		itemBoxUnion:(box:math.Box3,item:Point)=>void=((box,p)=>box.expandByPoint(p))
	):BoxTreeLeaf<Point,math.Box3,never>{
		return super.add(item,itemPos,itemBoxUnion);		
	}

	public pointsInRadius(
		focus:math.Vec3,
		radius:number,
		distanceFn:(item:Point, focus:math.Vec3,)=>number,
	){
		const items:Point[]=[];
		const leafs=this.filterLeafs(node=>node.itemsBox.distanceToPoint(focus)<radius);
		for(const leaf of leafs){
			for(const item of leaf.items){
				const d=distanceFn(item,focus);
				if(d<radius){
					items.push(item);
				}
			}
		}
		return items;
	}	
}

export class Triangle3OctTree extends OctTree<math.Triangle3>{
	public override add(
		item:math.Triangle3,
		itemPos:math.Vec=item.a,
		itemBoxUnion:(box:math.Box3,item:math.Triangle3)=>void=((box,t)=>box.expandByPoints(t))
	):BoxTreeLeaf<math.Triangle3,math.Box3,never>{
		return super.add(item,itemPos,itemBoxUnion);		
	}

	public distToPoint(p:math.Vec3){
		const distSq=this.closest(p,(t,p)=>t.distToPointSq(p)).distSq;
		return math.sqrt(distSq);
	}	
}

export class IndexOctTree extends OctTree<number>{
	public constructor(
		leafDim:number,
		public readonly positions:math.Vec3[],
	){
		super(leafDim);

		for(const [i,p] of positions.entries()){
			this.add(i,p,box=>box.expandByPoint(p));
		}
	}

	public override closest<T extends math.Vec>(
		focus:T,
		distSqFn:(item:number,focus:T)=>number=((a,b)=>this.positions[a].distToSq(b)),
		maxDistSq?:number
	){
		return super.closest(focus,distSqFn,maxDistSq);
	}
}

