import {Component} from '@angular/core';
import {DialogService} from '@app/old-ui/dialog/dialog.service';
import {DataIntervalTagEditDialog} from '@app/shared/edit-dialog/data-interval-tag/data-interval-tag-edit-dialog.component';
import {DeviceEditDialog} from '@app/shared/edit-dialog/device/device-edit-dialog.component';
import {WebClient} from '@injectables';
import {op} from '@lib/rxjs';
import {Client,DataIntervalTag,Device,User} from '@models';
import { FragQueryService } from 'src/injectables/frag-query.service';

@Component({
	selector: 'app-devices',
	templateUrl: './devices.component.html',
	styleUrls: ['./devices.component.scss']
})
export class DevicesComponent {
	constructor(
		private readonly dialog:DialogService,
		private readonly webClient:WebClient,
		private readonly fragQuery:FragQueryService,
		public readonly user:User,
	){
	}

	public readonly particleSizeUnitSystem$ = this.fragQuery.particleSizeUnitSystem$;
	public readonly model=Device;
	public readonly restClient=this.webClient.model.device;
	public readonly editDialog=DeviceEditDialog;
	public readonly deletable=(v:Device)=>{
		return this.user.permissions>= 2 && !v.uuid.startsWith('STRAYOS');
	};

	public readonly addable=(v?:Device)=>{
		if(v){
			return this.user.permissions>= 2 && !v.uuid.startsWith('STRAYOS');
		}else{
			return this.user.permissions>= 2;
		}
	};

	public readonly typeOptions=<Record<any,string>>Device.typeOptions;
	public readonly clients$=this.webClient.model.client
		.search$({})
		.pipe(
			op.map(values=>new Map(values.map(v=>[v.id,v]))),
			op.startWith(new Map<number,Client>()));

	public openNotes(record:Device){
		this.dialog.message(record.notes);
	}

	public getEmails(emailCsv: string){
		return emailCsv !== null ? emailCsv.split(',') : null;
	}

	public async setLiveTag(device:Device){
		let tag=await this.webClient.model.dataIntervalTag.getLive(device.id);
		tag=tag?.clone() ?? new DataIntervalTag();
		tag.deviceId=device.id;
		tag.begin=null;
		tag.end=null;
		if(await this.dialog.open(DataIntervalTagEditDialog,{
			record: tag,
			showTimes: false,
		})){
			await this.webClient.model.dataIntervalTag.setLiveTag(tag);
		}
	}
	
}