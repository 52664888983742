import { Component, HostListener, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
	selector: 'dialog-html',
	templateUrl: './dialog-html.component.html',
	styleUrls: [
		'./dialog-html.component.scss'
	]
})
export class DialogHtmlComponent{
	constructor(
		private readonly host:MatDialogRef<any>,
		@Inject(MAT_DIALOG_DATA) public html:string,
	){
	}

	@HostListener('click') onClick(){
		this.host.close();
	}
}
