<report-page [footer]="false" class="landscape">
	<div class="full-height full-width flex-column">
		<div class="full-width">
			<div class="strip bar-1"></div>
			<div class="strip bar-2"></div>
			<div class="strip bar-3"></div>
		</div>
		<div class="flex-size-fill flex-column middle container">
			<div class="report-title">
				{{title}}
			</div>
			<div class="report-info container">
				<div style="grid-area: 1/1">
					Date
				</div>
				<div style="grid-area: 1/2">
				    <p>{{reportDate|date:'longDate'}}</p>
				</div>
				<div style="grid-area: 2/1">
					Name
				</div>
				<div style="grid-area: 2/2">
					<p>{{reporterName}}</p>
				</div>
			</div>
		</div>
		<div class="full-width">
			<div class="strip bar-1"></div>
			<div class="strip bar-2"></div>
			<div class="strip bar-3"></div>
		</div>
	</div>
</report-page>
