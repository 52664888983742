//acts like a Set except the key is the unique combination of the key (including order)

export class PairSet<A,B>{
	private readonly map=new Map<A,Set<B>>();

	public add(a:A, b:B){
		let set=this.map.get(a);
		if(!set)
			this.map.set(a,set=new Set());
		set.add(b);
	}

	public delete(a:A, b:B):boolean{
		const set=this.map.get(a);
		if(!set)
			return false;
		if(!set.delete(b))
			return false;
		if(set.size===0)
			this.map.delete(a);
		return true;
	}

	public has(a:A, b:B){
		return !!this.map.get(a)?.has(b);
	}

	public aSets(){
		return this.map.entries();
	}
}
