import { fromEvent, Observable } from "rxjs";

export class OnlineObservable extends Observable<boolean>{
	private value=window.navigator.onLine;

	public constructor(){
		super(subscriber=>{
			subscriber.next(this.value);
			const sub=fromEvent(window,'online')
				.subscribe(()=>{
					if(!this.value){
						this.value=true;
						subscriber.next(this.value);
					}
				});
			sub.add(fromEvent(window,'offline')
				.subscribe(()=>{
					if(this.value){
						this.value=false;
						subscriber.next(this.value);
					}
				}));
			return sub;
		});
	}
}
