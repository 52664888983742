import { Component, HostListener, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
	selector: 'dialog-message',
	templateUrl: './dialog-message.component.html',
	styleUrls: [
		'./dialog-message.component.scss'
	]
})
export class DialogMessageComponent {
	constructor(
		private host: MatDialogRef<any>,
		@Inject(MAT_DIALOG_DATA) public message: string,
	) {

	}

	@HostListener('click') onClick() {
		this.host.close();
	}
}