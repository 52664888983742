import * as pdfkit from './pdf';

export function renderImage(doc:pdfkit.PDFDocument, ele:HTMLImageElement, clientRect:DOMRect):boolean{
	if(ele.complete){
		ele.crossOrigin='anonymous';
		let canvas=document.createElement('canvas');
		const sourceSizeFactor=4;
		canvas.width=Math.min(ele.naturalWidth,clientRect.width*sourceSizeFactor);
		canvas.height=Math.min(ele.naturalHeight,clientRect.height*sourceSizeFactor);
		const ctx=canvas.getContext('2d');
		ctx.drawImage(ele,0,0,ele.naturalWidth,ele.naturalHeight,0,0,canvas.width,canvas.height);
		let dataUrl:string;
		try{
			dataUrl=canvas.toDataURL('image/png');
		}catch(error){
			console.error(error);
			return false;
		}
		if(dataUrl && dataUrl!=='data:,'){
			doc.image(dataUrl,clientRect.x,clientRect.y,{
				width: clientRect.width,
				height: clientRect.height,
			});
			return true;
		}
		return false;
	}
	return false
}
