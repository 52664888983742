import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
	selector: 'dialog-reflect',
	templateUrl: './dialog-reflect.component.html',
	styleUrls: [
		'./dialog-reflect.component.scss'
	]
})
export class DialogReflectComponent implements OnInit{
	constructor(
		@Inject(MAT_DIALOG_DATA) data:any,
	){
		this.json=JSON.stringify(data,undefined,'\t');
	}

	public json:string;

	public ngOnInit(): void {
			
	}
}
