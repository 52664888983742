import {Injectable} from "@angular/core";
import {DateTime} from "@lib/date-time";
import {BehaviorSubject,combineLatest,concatMap,from,mergeMap,of,op, switchMap} from "@lib/rxjs";
import {storageLocal} from "@lib/storage-local";
import {DataIntervalTag,User,fragQuery} from "@models";
import {WebClient} from "./web-client";
import { DialogService } from '@app/old-ui/dialog/dialog.service';
import { FragQueryService } from "./frag-query.service";

interface DurationOption {
	label: string;
	type: DateTime.Step | 'custom';
	key?: string;
}

const durationOptions: DurationOption[] = [
	{
		label: 'last hour',
		type: [1,'hour'],
	},
	{
		label: 'Last 3 Hours',
		type: [3,'hour'],
	},
	{
		label: 'Last 6 Hours',
		type: [6,'hour'],
	},
	{
		label: 'Last 12 Hours',
		type: [12,'hour'],
	},
	{
		label: 'Last 24 Hours',
		type: [1,'day'],
	},
	{
		label: 'Custom',
		type: 'custom',
	}
];
for (const opt of durationOptions)
	opt.key = Array.isArray(opt.type) ? opt.type.join('') : opt.type;
const durationOptionMap = new Map(durationOptions.map((v) => [v.key, v]));

function liveTimeRange(step:DateTime.Step){
	const end=new DateTime();
	const begin=end.clone().sub(step);
	return {
		begin,
		end,
	};
}

@Injectable({
	providedIn: "root",
})
export class HomeFragQueryService{
	constructor(
		private readonly webClient:WebClient,
		private readonly user: User,
		private  readonly fragQuery: FragQueryService
	) {
		this.startTimer();
		this.fragQuery.trigger$.pipe(
			op.debounceTime(100),
			op.map(()=>{
				this.refresh();
			})
		).subscribe()
	}

	private readonly refresInMinutes: number = 5;
    public readonly homeduration$ = new BehaviorSubject(durationOptionMap.get('1hour').key);
	public readonly homeDevice$ = new BehaviorSubject(null);
	public readonly allDeviceData$ = new BehaviorSubject<fragQuery.timeline.sieveSizesAtPercents.Response[]>([]);
    public readonly begin$ = new BehaviorSubject(new DateTime(storageLocal.get('frag-query-begin') ?? (DateTime.now() - (60 * 60 * 1000))));
	public readonly end$ = new BehaviorSubject(new DateTime(storageLocal.get('frag-query-end') ?? DateTime.now()));
	public readonly minutes=new BehaviorSubject(0);
    public readonly sec=new BehaviorSubject(0);
	public readonly devices$ =this.webClient.model.device.search$({
		sort: {
			clientId: '+',
			id: '+'
		}
	})

	public readonly homeTimeRange$ = this.homeduration$.pipe(
		op.map(key => durationOptionMap.get(key)),
		op.switchMap(durOpt => {
			if (durOpt.type === 'custom') {
				return combineLatest([this.begin$, this.end$])
					.pipe(op.map(([begin, end]) => ({ begin, end })));
			}
			const step = durOpt.type;
			return of(liveTimeRange(step));
		})
	)

	startTimer(){
        var secInt=setInterval(()=>{
            if(this.sec.getValue()===59){
                if(this.minutes.value===(this.refresInMinutes-1)){
                    this.refresh();
                }
				this.sec.next(0);
            }else{
                this.sec.next(this.sec.value+1);
            }
        },1000);
        var minInt=setInterval(()=>{
            if(this.minutes.value==this.refresInMinutes-1){
                this.minutes.next(0);
            }else{
                this.minutes.next(this.minutes.value+1);
            }
        },60*1000)
    }

	

	refresh() {
        this.allDeviceData$.next([]);
		this.getAllDeviceData();
    }

    getAllDeviceData() {
        this.homeTimeRange$.subscribe(
			range => {
				this.devices$.pipe(
					mergeMap(devices =>
						from(devices)
						.pipe(
							concatMap(async (device) => {
								const body: fragQuery.timeline.sieveSizesAtPercents.Body = {
									email: this.user.email,
									authToken: this.user.authToken,
									deviceId: device.id,
									begin: range.begin,
									end: range.end,
									weightType: "area",
									percents: [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]
								};
								const result = await this.webClient
									.rest({
										route: 'frag-query/timeline/sieve-sizes-at-percents',
										method: 'get',
										body,
									})
								return result;
							})
						)
					)
				)
				.subscribe((response) => {
					const transformedResponse =
					response.toRecord<fragQuery.timeline.sieveSizesAtPercents.Response>();
					const formattedResponse = {
						deviceId: transformedResponse.deviceId,
						begin:
							this.user.timezone === null || this.user.timezone === 'Default'
								? new DateTime(transformedResponse.begin)
								: new DateTime(transformedResponse.begin).replaceTimezone(
									this.user.timezoneOffset
								),
						end:
							this.user.timezone === null || this.user.timezone === 'Default'
								? new DateTime(transformedResponse.end)
								: new DateTime(transformedResponse.end).replaceTimezone(
									this.user.timezoneOffset
								),
						percents: transformedResponse.percents,
						intervals: fixDates(
							transformedResponse.intervals,
							this.user.timezone,
							this.user.timezoneOffset
						),
						tags: fixTags(transformedResponse.tags),
						summary: transformedResponse.summary,
					};
					this.allDeviceData$.value.push(formattedResponse);
				});
			});
	}
}

function getTimeOffset(offsetString: string) {
	if (offsetString === null) {
		return null;
	} else {
		const offset = offsetString.slice(3);
		const hourTime = parseInt(offset.split(':')[0].slice(1, 3)) * 60;
		const minuteTime = parseInt(offset.split(':')[1].slice(0, 2));
		const totalOffset = hourTime+minuteTime;
		const formattedNumber = offsetString.slice(3, 4) === '+' ? `+${totalOffset}` : `-${totalOffset}`;
		return parseInt(formattedNumber);
	}
}

function fixDates<
	T extends {
		begin: DateTime;
		end: DateTime;
	}
>(intervals: T[], timezone: string, offset:number) {
	for (const interval of intervals) {
		interval.begin =
			timezone === null || timezone==="Default"
				? new DateTime(interval.begin)
				: new DateTime(interval.begin).replaceTimezone(offset);
		interval.end =
			timezone === null || timezone==="Default"
				? new DateTime(interval.end)
				: new DateTime(interval.end).replaceTimezone(offset);
	}
	return intervals;
}
function fixTags(tags: DataIntervalTag.RestTypes['response']['Get'][]) {
	return tags.map((v) => {
		const tag = new DataIntervalTag();
		DataIntervalTag.meta.copyToInstance(v, tag);
		return tag;
	});
}
