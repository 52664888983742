import {Component,OnInit} from '@angular/core';
// import {ActiveDataset,ActiveSite} from '@app/app-state/app-state.service';
// import {CustomTerms} from '@app/models/custom-terms';
import {ReportService} from '../report.service';

@Component({
	selector: 'report-page-footer',
	templateUrl: './page-footer.component.html',
	styleUrls: ['./page-footer.component.scss']
})
export class ReportPageFooterComponent{
	public readonly date=new Date();

	constructor(
		public report:ReportService,
	){
	}
}
