import {Dialog,DialogModule} from '@angular/cdk/dialog';
import {Overlay} from '@angular/cdk/overlay';
import {CommonModule} from '@angular/common';
import {Component,ElementRef,EventEmitter,HostBinding,HostListener,Input,OnDestroy,Optional,Output,Self,TemplateRef,ViewChild} from '@angular/core';
import {ControlValueAccessor,FormsModule,NgControl} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatFormFieldControl,MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {Subject,firstValueFrom} from 'rxjs';
import {DateTime} from '../../../../../../lib/date-time';
import { User } from '@models';

function strValid(str:string){
	return str.match(/^\s*\d{1,2}\/\d{1,2}\/\d{4},?\s*\d{1,2}:\d{2}\s*$/);
}

@Component({
	standalone: true,
	imports: [
		CommonModule,
		DialogModule,
		FormsModule,
		MatButtonModule,
		MatDatepickerModule,
		MatFormFieldModule,
		MatIconModule,
	],
	selector: 'time-picker',
	templateUrl: './time-picker.component.html',
	styleUrls: ['./time-picker.component.scss'],
	providers: [{provide: MatFormFieldControl,useExisting: TimePickerComponent}],
})
export class TimePickerComponent implements ControlValueAccessor,MatFormFieldControl<DateTime>,OnDestroy {
	public constructor(
		@Optional() @Self() public ngControl: NgControl,
		private dialog: Dialog,
		private host: ElementRef<HTMLElement>,
		private overlay: Overlay,
		public user:User
		){
			if(ngControl)
			ngControl.valueAccessor=this;
	}
	
	@Input()
	get placeholder(){
		return this._placeholder;
	}
	set placeholder(v){
		this._placeholder=v;
		this.stateChanges.next();
	}

	dateTimeToString(v:DateTime){
		try{
			return v?.toLocaleString(undefined,{
				hourCycle: this.gettimeformat(),
				hour: 'numeric',
				minute: '2-digit',
			}) ?? '';
		}catch(e){
			return '';
		}
	}

	gettimeformat(): 'h11' | 'h12' | 'h23' | 'h24' | undefined {
		if(this.user.timeFormat==='h11'){
			return 'h11';
		}
		else if(this.user.timeFormat==='h23'){
			return 'h23'
		}
		else{
			return undefined;
		}
	}
	
	maxLengthStr=this.dateTimeToString(new DateTime('2000-12-31T23:00:00'));
	@Input()
	get required(){
		return this._required;
	}
	set required(v){
		this._required=v;
		this.stateChanges.next();
	}
	@Input()
	get disabled(){
		return this._disabled;
	}
	set disabled(v){
		this._disabled=v;
		this.stateChanges.next();
	}

	@Input() hourStep=1;
	@Input() minuteStep=1;
	@Input() secondStep=60;
	@Input() millisecondStep=1000;

	@Input()
	get value(){
		return this._value;
	}
	set value(v){
		if(this._value!==v){
			this.oldValue=v;
			this.setValue(v,true);
		}
	}

	@Output('valueChange') valueChange=new EventEmitter<DateTime>();

	@ViewChild('popup',{read: TemplateRef}) popupTemplateRef: TemplateRef<HTMLElement>;

	public stateChanges=new Subject<void>();
	public focused=false;
	public touched=false;
	private _placeholder: string;
	private _disabled=false;
	private _required=false;
	public _value:DateTime;
	static nextId=1;
	@HostBinding() id=`time-picker-${TimePickerComponent.nextId++}`;
	private popupOpen=false;
	private _valueStr='';
	private oldValue:DateTime=null;
	private openedDialog: any;

	public get empty(){
		return !this.value;
	}

	public get shouldLabelFloat(){
		return this.focused || !this.empty;
	}

	public get errorState(){
		if(!this.value){
			return this.required;
		}
		return this.value?.invalid() && this.touched;
	}

	public get valueStr(){
		return this._valueStr;
	}

	public set valueStr(v){
		this._valueStr=v;
		let value:DateTime=null;
		if(v!==''){
			value=new DateTime(this._valueStr);
		}
		this.onChange(value,false);
	}

	public get hour(){
		return this.value?.getHours().toString() ?? null;
	}

	public set hour(v){
		this.onChange(this.value.clone().set('hour',+v),true);
	}

	public get minute(){
		return this.value?.getMinutes().toString().padStart(2,'0') ?? null;
	}

	public set minute(v){
		this.onChange(this.value.clone().set('minute',+v),true);
	}

	public get second(){
		return this.value?.getSeconds().toString().padStart(2,'0') ?? null;
	}

	public set second(v){
		this.onChange(this.value.clone().set('second',+v),true);
	}

	public get millisecond(){
		return this.value?.getMilliseconds().toString().padStart(4,'0') ?? null;
	}

	public set millisecond(v){
		this.onChange(this.value.clone().set('millisecond',+v),true);
	}

	private setValue(
		v:DateTime,
		updateStr:boolean
	){
		this._value=v;
		if(v){
			if(v.valid()){
				v.setHours(
					Math.round(v.getHours()/this.hourStep)*this.hourStep,
					Math.round(v.getMinutes()/this.minuteStep)*this.minuteStep,
					Math.round(v.getSeconds()/this.secondStep)*this.secondStep,
					Math.round(v.getMilliseconds()/this.millisecondStep)*this.millisecondStep,
				);
				if(updateStr)
					this._valueStr=this.dateTimeToString(v);
			}
		}else{
			if(updateStr)
				this._valueStr='';
		}
	}

	ngOnDestroy(){
		this.stateChanges.complete();
	}

	@HostListener('focusin',['$event']) onFocusIn(event:FocusEvent){
		if(!this.focused){
			this.focused=true;
			this.stateChanges.next();
		}
	}

	@HostListener('focusout',['$event']) onFocusOut(event:FocusEvent){
		this.popupTemplateRef
		if(!this.popupOpen && !this.host.nativeElement.contains(<Element>event.relatedTarget)){
			this.focused=false;
			this.onTouched();
			this.stateChanges.next();
		}
	}

	setDescribedByIds(ids: string[]){}

	onContainerClick(event: MouseEvent){
		if((<Element>event.target).tagName.toLowerCase()!='input')
			this.host.nativeElement.querySelector('input').focus();
	}

	writeValue(v:DateTime|null):void{
		this.value=v;
		this.stateChanges.next();
	}

	private _onChange=(_:DateTime)=>{};
	private _onTouched=()=>{};

	registerOnChange(fn:(_:DateTime)=>void):void{
		this._onChange=fn;
	}

	registerOnTouched(fn:()=>{}):void{
		this._onTouched=fn;
	}

	setDisabledState(isDisabled:boolean):void{
		this.disabled=isDisabled;
	}

	public onTouched(){
		if(!this.touched){
			this.touched=true;
			this._onTouched();
			this.stateChanges.next();
		}
	}

	Close(emit:boolean){
		if(emit){
			this.valueChange.emit(this.value);
		}
		else{
			this.value=this.oldValue
		}
		if (this.openedDialog) {
			this.openedDialog.close();
			this.openedDialog = null; 
		  }
		
	}

	public onChange(
		value:DateTime,
		updateStr:boolean,
	){
		if(!DateTime.equals(this.value,value)){
			this.setValue(value,updateStr);
			if(!this.touched){
				this.touched=true;
				this._onTouched();
			}
			this._onChange(value);
			this.stateChanges.next();
		}
	}

	public open(){
		let formFieldEle=this.host.nativeElement.parentElement;
		while(formFieldEle && formFieldEle.tagName!=='MAT-FORM-FIELD')
			formFieldEle=formFieldEle.parentElement;
		formFieldEle??=this.host.nativeElement;
		const positionStrategy=this.overlay.position()
			.flexibleConnectedTo(formFieldEle)
			.withTransformOriginOn('.popup')
			.withViewportMargin(8)
			.withPositions([
				{
					originX: 'start',
					originY: 'bottom',
					overlayX: 'start',
					overlayY: 'top',
				},
				{
					originX: 'end',
					originY: 'bottom',
					overlayX: 'end',
					overlayY: 'top',
				},
				{
					originX: 'start',
					originY: 'top',
					overlayX: 'start',
					overlayY: 'bottom',
				},
				{
					originX: 'end',
					originY: 'top',
					overlayX: 'end',
					overlayY: 'bottom',
				},
			]);
		
		this.popupOpen=true;
		const popup=this.dialog.open(this.popupTemplateRef,{
			backdropClass: [
				'mat-overlay-transparent-backdrop',
			],
			closeOnNavigation: true,
			positionStrategy,
			restoreFocus: true,
		});
		this.openedDialog = popup;
		firstValueFrom(popup.closed).then(()=>this.popupOpen=false
		)
	}

	public onDateChange(_date:Date){
		const value=new DateTime(_date);
		if(this.value)
			value.setFracHours(this.value.getFracHours());
		this.onChange(value,true);
	}

	public stepHour(delta:number){
		if(this.value){
			const value=this.value.clone();
			value.setHours(value.getHours()+delta*this.hourStep);
			this.onChange(value,true);
		}
	}

	public stepMinute(delta:number){
		if(this.value){
			const value=this.value.clone();
			value.setMinutes(value.getMinutes()+delta*this.minuteStep);
			this.onChange(value,true);
		}
	}
	
	public stepSecond(delta:number){
		if(this.value){
			const value=this.value.clone();
			value.setSeconds(value.getSeconds()+delta*this.hourStep);
			this.onChange(value,true);
		}
	}

	public stepMillisecond(delta:number){
		if(this.value){
			const value=this.value.clone();
			value.setMilliseconds(value.getMilliseconds()+delta*this.minuteStep);
			this.onChange(value,true);
		}
	}
}
