import {DateTime} from '@lib/date-time';
import {DataIntervalTag as _DataIntervalTag} from '../../../shared-models/public/data-interval-tag';

export class DataIntervalTag extends _DataIntervalTag{
	public copy(that:DataIntervalTag){
		DataIntervalTag.meta.copyFields(that,this,['id']);
		return this;
	}

	public clone(){
		const that=new DataIntervalTag();
		return that.copy(this);
	}

	public equals(that:DataIntervalTag){
		return DataIntervalTag.meta.areFieldsEqual(that,this,['id']);
	}

	public get beginDateTime(){
		if(typeof(this.begin)==='number')
			return new DateTime(this.begin*1000);
		return null;
	}

	public set beginDateTime(v){
		if(v instanceof DateTime)
			this.begin=Math.floor(v.getTime()/1000);
		else
			this.begin=null;
	}

	public get endDateTime(){
		if(typeof(this.end)==='number')
			return new DateTime(this.end*1000);
		return null;
	}

	public set endDateTime(v){
		if(v instanceof DateTime)
			this.end=Math.floor(v.getTime()/1000);
		else
			this.end=null;
	}
}

export namespace DataIntervalTag{
	export import RestTypes=_DataIntervalTag.RestTypes;
}
