import {CommonModule} from '@angular/common';
import {AfterViewInit,Component,ElementRef,EventEmitter,Input,Output,ViewChild} from '@angular/core';
import {DateTime} from '../../../date-time';
import {dom} from '../../../dom';
import {math} from '../../../math';
import {MultiMap} from '../../../multi-map';
import {BehaviorSubject,Observable,combineLatest,op,taste} from '../../../rxjs';
import {CategoryAxis,LinearAxis} from '../axis';
import {toNum} from '../helpers';
import {AxisDataType,AxisOpts} from '../types';
import {BarCategory,InputBars,PlotEvent} from './chart-bar.namespace';

@Component({
	standalone: true,
	imports: [
		CommonModule,
	],
	selector: 'chart-bar',
	templateUrl: './chart-bar.component.html',
	styleUrls: ['./chart-bar.component.scss'],
})
export class ChartBarComponent implements AfterViewInit{
	constructor(
	){
	}

	@Input() legend=true;
	@Input()
		get bars(){
			return this.bars$.value;
		}
		set bars(v){
			this.bars$.next(v);
		}
	@Input()
		get xAxis(){
			return this.xAxisOpts$.value;
		}
		set xAxis(v){
			this.xAxisOpts$.next(v);
		}
	@Input()
		get yAxis(){
			return this.yAxisOpts$.value;
		}
		set yAxis(v){
			this.yAxisOpts$.next(v);
		}

	@Output('plotClick') plotClick=new EventEmitter<PlotEvent>();

	private readonly bars$=new BehaviorSubject<InputBars[]>([]);
	private readonly xAxisOpts$=new BehaviorSubject<AxisOpts>({
		name: null,
		dataType: 'number',
	});
	private readonly yAxisOpts$=new BehaviorSubject<AxisOpts>({
		name: null,
		dataType: 'number',
	});

	@ViewChild('xAxis') xAxisEle:ElementRef<HTMLElement>;
	@ViewChild('yAxis') yAxisEle:ElementRef<HTMLElement>;

	private readonly yAxisSize$=new dom.SizeObservable({resizeOnly:true});
	private readonly xAxisSize$=new dom.SizeObservable({resizeOnly:true});

	public readonly legend$:Observable<ProcessedLegend[]>=this.bars$
		.pipe(
			op.debounceTime(0),
			op.map((bars)=>{
				bars??=[];
				return bars
					.map((bars):ProcessedLegend=>({
						name: bars.name,
						color: bars.color.toString(),
					}))
					.map(v=>JSON.stringify(v))
					.unique()
					.map(v=>JSON.parse(v));
			}));

	public readonly plot$=
		combineLatest([
			this.xAxisOpts$,
			this.yAxisOpts$,
			this.bars$])
		.pipe(
			op.debounceTime(1),
			op.map(([xAxis,yAxis,bars])=>
				new Plot([xAxis.dataType,yAxis.dataType],bars)),
			op.startWith(new Plot(['number','number'],[])),
			op.shareReplay(1));

	public readonly xAxis$=
		combineLatest([
			this.plot$,
			this.xAxisOpts$,
			this.xAxisSize$])
		.pipe(
			op.debounceTime(0),
			op.map(([plot,opts])=>
				new CategoryAxis({
					...opts,
					ele: this.xAxisEle.nativeElement,
					categories: plot.barSets.map(({category})=>category),
				})),
			op.startWith(new CategoryAxis()),
			op.shareReplay(1));

	public readonly yAxis$=
		combineLatest([
			this.plot$,
			this.yAxisOpts$,
			this.yAxisSize$])
		.pipe(
			op.debounceTime(0),
			op.map(([plot,opts])=>
				new LinearAxis({
					...opts,
					ele: this.yAxisEle.nativeElement,
					dataRange: plot.dataRange,
					toData: plot.toData[1],
				})),
			op.startWith(new LinearAxis()),
			op.shareReplay(1));

	public readonly plotTransform$=
		combineLatest([
			this.xAxis$,
			this.yAxis$])
		.pipe(op.map(([xAxis,yAxis])=>{
			const xScale=xAxis.plotRange.size();
			const yScale=yAxis.plotRange.size();
			return [
				`translate(0 1)`,
				`scale(1 -1)`,
				`translate(${xAxis.plotRange.min} ${yAxis.plotRange.min})`,
				`scale(${xScale} ${yScale})`,
			].join(' ');
		}));

	ngAfterViewInit(): void {
		this.xAxisSize$.observe(this.xAxisEle.nativeElement);
		this.yAxisSize$.observe(this.yAxisEle.nativeElement);
	}

	private mouseEventToPlotEvent(evt:MouseEvent):PlotEvent{
		const plotEle=(<HTMLElement>evt.currentTarget);
		const plotTransformEle=<SVGGElement>plotEle.querySelector('g.plot-transform');
		if(plotTransformEle){
			const plot=taste(this.plot$);
			if(plot){
				let p=new DOMPoint(evt.x,evt.y);
				p=p.matrixTransform(plotTransformEle.getScreenCTM().inverse());
				const x=0;//plot.dataRanges[0].fromParameter(p.x);
				const y=plot.dataRange.fromParameter(p.y);
				return {
					dataPoint: [x,y],
				};
			}
		}
		return null;
	}

	public onPlotClick(_evt:MouseEvent){
		const evt=this.mouseEventToPlotEvent(_evt);
		if(evt){
			this.plotClick.emit(evt);
		}
	}

	public onPlotMouseOver(evt:MouseEvent){
	}
}

interface ProcessedLegend{
	name:string;
	color:string;
}

interface ProcessedBar{
	top:number;
	bottom?:number;
	height:number;
	fillColor:string;
	width:number;
	// dash:string|null;
	// strokePath:string;
	// fillPath:string;
}

interface ProcessedBarSet{
	category:BarCategory;
	bars:ProcessedBar[];
}

class Plot{
	public constructor(
		public readonly dataTypes:[AxisDataType,AxisDataType],
		inputBarSet:InputBars[]
	){
		this.init(inputBarSet ?? []);
	}

	public barSets:ProcessedBarSet[]=[];
	public dataRange:math.Range|DateTime.Range=this.dataTypes[1]==='time'?new DateTime.Range():new math.Range();

	public toData:[((x:number)=>(number|DateTime)),((x:number)=>(number|DateTime))]=[
		v=>v,
		v=>v,
	];

	private init(
		bars:InputBars[]
	){
		const categories=new MultiMap<BarCategory,ProcessedBar>();
		let dataCount=0;
		for(const input of bars){
			dataCount+=input.data.length;
			for(const datum of input.data){
				const top=toNum(datum[1]);
				categories.add(datum[0],{
					fillColor: input.color.toString(),
					width: input.width ?? 4,
					top,
					height: 0,
				});
				// outLine.points.push(p);
				if(input.includeInRange ?? true)
					this.dataRange.expandByPoint(top);
			}
			// this.lines.push(outLine);
		}

		for(const bars of categories.values()){
			bars.sort((a,b)=>a.top-b.top);
		}

		if(dataCount===0){
			this.dataRange=null;
			return;
		}
		
		for(const [category,bars] of categories){
			this.barSets.push({
				category,
				bars,
			})
		}

		const translation=this.dataRange.min;
		const scale=(this.dataRange.max-translation);
		for(const barSet of this.barSets){
			let prevPoint=0;
			for(const bar of barSet.bars){
				bar.top=(bar.top-translation)/scale;
				bar.bottom=prevPoint;
				bar.height=bar.top-prevPoint;
				prevPoint=bar.top;
			}
		}
	}
	
}

