//model-meta generated file
import {DateTime,ModelMeta,uuid} from '../../lib/model-meta';

export class FragQueryTimelineSieveSizeAtPercentBody{
	public apiKey?:string;
	public email?:string;
	public authToken?:string;
	public deviceId:number;
	public percents?:number[];
	public begin:DateTime;
	public end:DateTime;
	public weightType:string;
}

export namespace FragQueryTimelineSieveSizeAtPercentBody{
	export const meta=new ModelMeta<keyof Props>(
		"FragQueryTimelineSieveSizeAtPercentBody",
		[],
		[
			{
				name: "apiKey",
				type: "string",
				decorators: {},
				notNull: true,
				optional: true
			},{
				name: "email",
				type: "string",
				decorators: {},
				notNull: true,
				optional: true,
				comment: "needs either apiKey | {email,authToken}"
			},{
				name: "authToken",
				type: "string",
				decorators: {},
				notNull: true,
				optional: true
			},{
				name: "deviceId",
				type: "number",
				decorators: {},
				notNull: true
			},{
				name: "percents",
				type: "array",
				decorators: {},
				value: {
					type: "number"
				},
				optional: true
			},{
				name: "begin",
				type: "date",
				decorators: {},
				notNull: true
			},{
				name: "end",
				type: "date",
				decorators: {},
				notNull: true
			},{
				name: "weightType",
				type: "string",
				decorators: {},
				notNull: true
			}
		]
	);

	interface Props{
		apiKey?:string;
		email?:string;
		authToken?:string;
		deviceId:number;
		percents?:number[];
		begin:DateTime;
		end:DateTime;
		weightType:string;
	}

}
