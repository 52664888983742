import { math } from "../math";
import { Box as _Box } from '../math/box';
import { Vec } from "../math/vec";
import { isFiniteNumber } from "../number";
import { BoxTreeClosest } from "./box-tree-closest";
import { BoxTreeLeaf } from "./box-tree-leaf";
import { BoxTreeNode } from "./box-tree-node";

export class BoxTreeBranch<Item, Box extends _Box<Vec>, Data> extends BoxTreeNode<Item,Box,Data>{
	public nodes:(BoxTreeNode<Item,Box,Data>|undefined)[]=[];

	public constructor(
		itemsBox:Box,
		dim:number,
	){
		super(itemsBox,dim);
	}

	private getNode(np:Vec){
		const nodeIndex=math.floor(np.z ?? 0)*4+math.floor(np.y)*2+math.floor(np.x);
		if(nodeIndex>7 || !isFiniteNumber(nodeIndex))
			throw Error('bad');
		let node=this.nodes[nodeIndex];
		if(!node){
			const dim=this.dim/2;
			const box=this.itemsBox.clone().setEmpty();
			if(dim<1.01)
				node=new BoxTreeLeaf(box,dim);
			else
				node=new BoxTreeBranch(box,dim);
			this.nodes[nodeIndex]=node;
		}
		return node;
	}
	
	public addItem(
		nodePos:Vec,
		item:Item,
		itemBoxUnion:(box:Box,item:Item)=>void,
	):BoxTreeLeaf<Item,Box,Data>{
		nodePos.scale(2);
		const node=this.getNode(nodePos);
		nodePos.frac();
		const out=node.addItem(nodePos,item,itemBoxUnion);
		this.itemsBox.union(node.itemsBox);
		return out;
	}

	public addItems(
		nodePos:Vec,
		items:Item[],
		itemBox:Box,
	):BoxTreeLeaf<Item,Box,Data>{
		nodePos.scale(2);
		const node=this.getNode(nodePos);
		nodePos.frac();
		const out=node.addItems(nodePos,items,itemBox);
		this.itemsBox.union(node.itemsBox);
		return out;
	}

	public updateItemsBox(itemBoxUnion:((box:Box,item:Item)=>void)|undefined){
		this.itemsBox.setEmpty();
		for(const child of this.nodes){
			if(child){
				child.updateItemsBox(itemBoxUnion);
				this.itemsBox.union(child.itemsBox);
			}
		}
	}

	public *decendantNodes():Generator<BoxTreeNode<Item,Box,Data>,void,undefined>{
		yield this;
		for(const child of this.nodes){
			if(child)
				yield *child.decendantNodes();
		}
	}

	public *decendantLeafs():Generator<BoxTreeLeaf<Item,Box,Data>,void,undefined>{
		for(const child of this.nodes){
			if(child)
				yield *child.decendantLeafs();
		}
	}

	public filterLeafs(leafs:this[], condition:(node:BoxTreeNode<Item,Box,Data>)=>boolean){
		if(condition(this)){
			for(const node of this.nodes)
				node?.filterLeafs(leafs,condition);
		}
	}

	public closest<T extends Vec>(focus:T, distSqFn:(item:Item, focus:T)=>number, found:BoxTreeClosest<Item,Box,Data>){
		const nodes=this.nodes.filter((node):node is this=>{
			if(node){
				node.tmpDist=node.itemsBox.distToPointSq(focus);
				return node.tmpDist<found.distSq;
			}
			return false;
		});
		nodes.sort((a,b)=>a.tmpDist-b.tmpDist);
		for(const node of nodes){
			if(node.tmpDist<found.distSq)
				node.closest(focus,distSqFn,found);
			else
				break;
		}
	}
}
