
Object.getPropertyDescriptor=function(o,p){
	let desc=Object.getOwnPropertyDescriptor(o,p);
	while(!desc){
		o=Object.getPrototypeOf(o);
		if(!o)
			break;
		desc=Object.getOwnPropertyDescriptor(o,p);
	}
	return desc;
}
