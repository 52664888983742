<div class="flex-row center">
  <h3 class="flex-row center">Current Status:
    <span [ngClass]="deviceStatus === 'online' ? 'flex-row status-online' : 'flex-row status-offline'">
      <mat-icon>{{ deviceStatus === 'online' ? 'bolt' : 'offline_bolt' }}</mat-icon>
      <span>{{ deviceStatus === 'online' ? 'Online' : 'Offline' }}</span>
    </span>
  </h3>
</div>

<h4 class="flex-row center">{{ errorMessage }}</h4>

<div class="flex-row center video-container-wrapper">
  <mat-card id="video-container">
    <img id="video" #imageEle />
    <img id="preload-video" #preloadImageEle style="display: none;" />
    <mat-icon id="stop-button" *ngIf="isStreaming" (click)="stopStreaming()">pause_circle_outline</mat-icon>
    <mat-icon id="play-button" *ngIf="!isStreaming" (click)="startStreaming()">play_circle_outline</mat-icon>
  </mat-card>
</div>