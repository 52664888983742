import { iterate } from "../iterate";
import { math } from "../math";
import { MultiMap } from "../multi-map";
import { Color } from "./color";

export class ColorPalette{
	public constructor(
		public array:Color[]
	){
	}

	private randoms?:number[];

	public get count(){
		return this.array.length;
	}

	public at(i:number){
		return this.array.at(i);
	}

	public [Symbol.iterator](){
		return this.array.values();
	}

	public entries(){
		return this.array.entries();
	}

	public push(c:Color){
		return this.array.push(c);
	}

	public random(){
		this.randoms??=[];
		if(this.randoms.length===0){
			for(let i=0;i<this.count;++i)
				this.randoms.push(i);
		}

		let i=math.randomInt(0,this.randoms.length);
		i=this.randoms.splice(i,1)[0];
		return this.at(i);
	}
}

export namespace ColorPalette{
	export function generate(hues:number[], saturations:number[], values:number[]) {
		// if(!Array.isArray(hues)){
		// 	const count=hues;
		// 	hues=[];
		// 	for(let i=0;i<count;++i)
		// 		hues.push(i*360/count);
		// }
	
		// if(!Array.isArray(saturations)){
		// 	const count=saturations;
		// 	saturations=[];
		// 	for(let i=0;i<count;++i)
		// 		saturations.push(i/(count-1));
		// 	saturations.reverse();
		// }
	
		// if(!Array.isArray(values)){
		// 	const count=values;
		// 	values=[];
		// 	for(let i=0;i<count;++i)
		// 		values.push(i/(count-1));
		// 	values.reverse();
		// }
	
		const array:Color[]=[];
		for(const hue of hues){
			for(const sat of saturations){
				if(sat > 0) {
					for(const val of values) {
						if(0 < val && val < 1)
							array.push(Color.hsv(hue, sat, val));
					}
				}
			}
		}
		if(saturations.includes(0)){
			for(let val of values)
				array.push(Color.hsv(0, 0, val));
		}else{
			if(values.includes(0))
				array.push(Color.hsv(0, 0, 0));
			if(values.includes(1))
				array.push(Color.hsv(0, 0, 1));
		}
		return array;
	}


	type ColorArray=[number,number,number,number];

	function uniqueColors(colors:Color[]){
		const mapping=new MultiMap<string,number>();
		for(const [i,color] of colors.entries()){
			const key=JSON.stringify(color.toArray().map(v=>math.round(v*255)));
			mapping.add(key,i);
		}

		const _mapping=new MultiMap<ColorArray,number>();
		for(const [key,indices] of mapping){
			const c=JSON.parse(key);
			_mapping.set(c,indices);
		}
		return _mapping;
	}

	function colorDistSq(a:ColorArray, b:ColorArray){
		if(a===b)
			return Infinity;
		return (
			a[0]*b[0]
			+a[1]*b[1]
			+a[2]*b[2]
			+a[3]*b[3]
		);
	}

	export function bestFit(maxCount:number, colors:Color[]):ColorPalette{
		if(maxCount<=0)
			return new ColorPalette([]);
		const mapping=uniqueColors(colors);
		while(mapping.size>maxCount){
			const closestMap=new Map<ColorArray,ColorArray>();
			for(const a of mapping.keys()){
				const b=iterate(mapping.keys()).lowest(b=>colorDistSq(a,b))!;
				closestMap.set(a,b);
			}
			let pairs:{a:ColorArray,b:ColorArray,distSq:number}[]=[];
			for(const [a,b] of closestMap){
				if(a===closestMap.get(b))
					pairs.push({a,b,distSq:colorDistSq(a,b)});
			}
			pairs.sort((x,y)=>x.distSq-y.distSq);
			for(const {a,b} of pairs){
				for(let i=0;i<4;++i)
					a[0]=(a[0]+b[0])/2;
				let indices=mapping.get(a) ?? [];
				indices=indices.concat(mapping.get(b) ?? []);
				mapping.set(a,indices);
				mapping.delete(b);
				if(mapping.size<=maxCount)
					break;
			}
		}

		const palette=new ColorPalette([]);
		for(const [c,indices] of mapping){
			const color=Color.fromArray(c.map(v=>math.round(v)/255));
			palette.push(color);
			for(const i of indices)
				colors[i]=color;
		}

		return palette;
	}
}
