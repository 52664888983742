<div class="left">
	<mat-icon svgIcon="strayos_blue"></mat-icon>
	<span>Strayos,&nbsp;</span>
	<span class="created-at">Created at {{date}}</span>
</div>

<div class="report-name">
	{{report.title}}
	<ng-content></ng-content>
</div>


<div class="page-number">
	&nbsp;
</div>
