
<div class="section">
    <div class="container">
        <mat-card>
            <mat-tab-group>
                <mat-tab>
                    <ng-template mat-tab-label>
                        PSD Details
                    </ng-template>
                    <ng-container class="chart-containers">
                        <div *ngIf="(chartLines$ | async) === undefined" class="container centered-content">
                            <mat-spinner strokeWidth="3" [diameter]="50"></mat-spinner>
                        </div>
                        <div *ngIf="(quantityChartLines$ | async) === null"
                            class="container centered-content">
                            <div class="container centered-content">
                                <div>
                                    <mat-icon svgIcon="empty-data" class="not-found-svg"></mat-icon>
                                </div>
                                <div>No Data Found</div>
                            </div>
                        </div>
                        <div class="container"
                            *ngIf="(chartLines$ | async) && (quantityChartLines$ | async)">
                            <psd-chart  class="chart-containers" [index]="0" [data]="[accumulatedData$|async]"></psd-chart>
                        </div>
                    </ng-container>
                </mat-tab>
                <mat-tab>
                    <ng-template mat-tab-label>
                        PSD Timeline
                    </ng-template>
                    <ng-container>
                        <div *ngIf="(chartLines$ | async) === undefined" class="container centered-content">
                            <mat-spinner strokeWidth="3" [diameter]="50"></mat-spinner>
                        </div>
                        <div *ngIf="(this.fragQuery.addOnTimelineSieveSizesAtPercents$|async)===undefined" class="container centered-content add-on-loader">
                            <mat-spinner strokeWidth="3" [diameter]="50"></mat-spinner>
                        </div>
                        <div *ngIf="(chartLines$ | async) === null"
                            class="container centered-content">
                            <div class="container centered-content">
                                <div>
                                    <mat-icon svgIcon="empty-data" class="not-found-svg"></mat-icon>
                                </div>
                                <div>No Data Found</div>
                            </div>
                        </div>
                        <div class="container" *ngIf="(chartLines$ | async) !== null && (chartLines$ | async) !== undefined">
                            <figure *ngIf="(chartLines$ | async) !== null" class="highcharts-figure full-width">
                                <highcharts-chart class="highcharts-figure" [Highcharts]="Highcharts" [constructorType]="'stockChart'" [options]="chartLines$|async">
                                </highcharts-chart>
                            </figure>
                        </div>
                    </ng-container>
                </mat-tab>
                <mat-tab>
                    <ng-template mat-tab-label>
                        Quantity Timeline
                    </ng-template>
                    <ng-container class="chart-containers">
                        <div *ngIf="(quantityChartLines$ | async) === undefined" class="container centered-content">
                            <mat-spinner strokeWidth="3" [diameter]="50"></mat-spinner>
                        </div>
                        <div *ngIf="(this.fragQuery.addOnTimelineQuantity$|async)===undefined" class="container centered-content add-on-loader">
                            <mat-spinner strokeWidth="3" [diameter]="50"></mat-spinner>
                        </div>
                        <div *ngIf="(quantityChartLines$ | async) === null"
                            class="container centered-content">
                            <div class="container centered-content">
                                <div>
                                    <mat-icon svgIcon="empty-data" class="not-found-svg"></mat-icon>
                                </div>
                                <div>No Data Found</div>
                            </div>
                        </div>
                        <div *ngIf="(quantityChartLines$ | async)" class="container">
                            <figure class="highcharts-figure full-width">
                                <highcharts-chart class="highcharts-figure" [Highcharts]="Highcharts" [constructorType]="'stockChart'" [options]="quantityChartLines$|async">
                                </highcharts-chart>
                            </figure>
                        </div>
                    </ng-container>
                </mat-tab>
            </mat-tab-group>
        </mat-card>
    </div>
</div>
<div class="section end flex-percent" *ngIf="(accumulatedData$|async)">
    <div class="container container-33">
        <mat-card>
            <div class="container">
                <div class="flex-row justify full-width">
                    <div class="full-width">
                        <card-square-blocks [title]="'Total ' + (yAxisTitle$|async)" [warn]="false" [size]="'medium'"
                            [long]="true">
                            <span [innerHTML]="(getWeightContent()|async)"></span>
                        </card-square-blocks>
                    </div>
                    <div class="full-width" *ngIf="isConveyor()">
                        <card-square-blocks [title]="'Total Tons'" [warn]="false" [size]="'medium'"
                        [long]="true">
                        <span [innerHTML]="( getTotalTons()|async)"></span>
                    </card-square-blocks>
                    </div>
                    <div class="full-width" *ngIf="isConveyor() && slabDiameter">
                        <mat-card  class="card-large long">
                            <span>
                                Rocks >
                                <measurement class="titlespan" type="particleSize" [unitSystem]="(particleSizeUnitSystem$ | async)" [value]="slabDiameter"></measurement>
                                (Slabs)
                            </span>
                            <div class="small-card-content primary">
                                {{ slabs }}
                            </div>
                        </mat-card>
                    </div>
                    <div class="full-width" *ngIf="isTruck()">
                        <card-square-blocks [title]="'Total No. of haluers/Trucks'" [warn]="false" [size]="'medium'"
                            [long]="true">
                            {{
                            (this.fragQuery.timelineSieveSizesAtPercents$|async).intervals.length
                            }}</card-square-blocks>
                    </div>
                </div>
                <div class="flex-row wrap around card-content-scroll">
                    <div *ngFor="let keys of (newPercents$ | async).keys(); let j = index">
                        <card-square-blocks [title]="'D' + keys" [size]="'medium'" [warn]="false">
                            {{ (accumulatedData$ | async).data[j + 1] | unitConversion:  (particleSizeUnit$|async)}}
                        </card-square-blocks>
                    </div>
                </div>
            </div>
        </mat-card>
    </div>
    <div *ngIf="this.tableData && this.tableData.size>0 " class="container container-67">
        <analysis-table [showDxxTable]="false" [direction]="'vertical'"
            [dxxArr]="this.tableData.get('summary').dxxArr"
            [dataArr]="this.tableData.get('summary').dataArr"
            [percentArr]="this.tableData.get('summary').percentArr">
        </analysis-table>
    </div>
</div>