//model-meta generated file
import {DateTime,ModelMeta,uuid} from '../../lib/model-meta';
import {WhereTable,RestTypes as _RestTypes} from '../../lib/model-meta';

export class Shift{
	public id:number;
	public clientId:number;
	public deviceId:number;
	public begin:number;
	public end:number;
	public title:string;
	public emails:string|null=null;
	public timezone:string|null=null;
	public weightType:'area'|'volume'|null=null;
	public density:number|null=null;
	public timezoneOffset:number|null=null;
	public chartType:'column'|'line'|null=null;
	public createdAt:DateTime=new DateTime();
}

export namespace Shift{
	export const meta=new ModelMeta<keyof Props>(
		"Shift",
		[],
		[
			{
				name: "id",
				type: "serial",
				decorators: {},
				uniqueKey: true,
				notNull: true
			},{
				name: "clientId",
				type: "int",
				decorators: {},
				notNull: true
			},{
				name: "deviceId",
				type: "int",
				decorators: {},
				notNull: true
			},{
				name: "begin",
				type: "int",
				decorators: {},
				notNull: true
			},{
				name: "end",
				type: "int",
				decorators: {},
				notNull: true
			},{
				name: "title",
				type: "string",
				decorators: {},
				notNull: true
			},{
				name: "emails",
				type: "string",
				decorators: {},
				default: null,
				optional: true
			},{
				name: "timezone",
				type: "string",
				decorators: {},
				default: null,
				optional: true
			},{
				name: "weightType",
				type: "enum",
				members: [
					"area",
					"volume"
				],
				decorators: {},
				default: null,
				optional: true
			},{
				name: "density",
				type: "number",
				decorators: {},
				default: null,
				optional: true
			},{
				name: "timezoneOffset",
				type: "number",
				decorators: {},
				default: null,
				optional: true
			},{
				name: "chartType",
				type: "enum",
				members: [
					"column",
					"line"
				],
				decorators: {},
				default: null,
				optional: true
			},{
				name: "createdAt",
				type: "date",
				decorators: {
					"rest.readonly": true
				},
				default: "now()",
				optional: true,
				notNull: true
			}
		]
	);

	interface Props{
		id:number;
		clientId:number;
		deviceId:number;
		begin:number;
		end:number;
		title:string;
		emails?:string|null;
		timezone?:string|null;
		weightType?:'area'|'volume'|null;
		density?:number|null;
		timezoneOffset?:number|null;
		chartType?:'column'|'line'|null;
		createdAt?:DateTime;
	}


	meta.generateRest("shift",[
		{
			name: "email",
			type: "string",
			decorators: {}
		},{
			name: "authToken",
			type: "string",
			decorators: {}
		}
	]);

	export interface ReadableProps{
		id:number;
		clientId:number;
		deviceId:number;
		begin:number;
		end:number;
		title:string;
		emails?:string|null;
		timezone?:string|null;
		weightType?:'area'|'volume'|null;
		density?:number|null;
		timezoneOffset?:number|null;
		chartType?:'column'|'line'|null;
		createdAt?:DateTime;
	}

	export interface ReadablePrimitiveProps{
		id:number;
		clientId:number;
		deviceId:number;
		begin:number;
		end:number;
		title:string;
		emails?:string|null;
		timezone?:string|null;
		weightType?:'area'|'volume'|null;
		density?:number|null;
		timezoneOffset?:number|null;
		chartType?:'column'|'line'|null;
		createdAt?:DateTime;
	}

	namespace body{

		interface WritableProps{
			clientId:number;
			deviceId:number;
			begin:number;
			end:number;
			title:string;
			emails?:string|null;
			timezone?:string|null;
			weightType?:'area'|'volume'|null;
			density?:number|null;
			timezoneOffset?:number|null;
			chartType?:'column'|'line'|null;
		}

		export interface Auth{
			email?:string;
			authToken?:string;
		}

		export interface Get extends Auth{
			where:number;
			fields?:(keyof ReadableProps)[];
		}

		export interface Put extends Auth{
			where:number;
			fields:Partial<WritableProps>;
		}

		export interface Post extends Auth{
			fields:Partial<WritableProps>;
		}

		export interface Delete extends Auth{
			where:number;
		}

		export interface Count extends Auth{
			where?:WhereTable<keyof ReadablePrimitiveProps>;
		}

		export interface Search extends Auth{
			fields?:(keyof ReadablePrimitiveProps)[];
			where?:WhereTable<keyof ReadablePrimitiveProps>;
			range?:[number,number];
			sort?:Partial<Record<keyof ReadablePrimitiveProps,'+'|'-'>>;
		}
	}

	namespace _response{
		export type Get=Partial<ReadableProps>;
		export type Search=Partial<ReadablePrimitiveProps>[];
	}

	export interface RestTypes extends _RestTypes{
		body:{
			Auth:body.Auth;
			Get:body.Get;
			Put:body.Put;
			Post:body.Post;
			Delete:body.Delete;
			Count:body.Count;
			Search:body.Search;
		}

		response: {
			Get:_response.Get;
			Search:_response.Search;
		}
	}

}
