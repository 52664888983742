import { Injectable,InjectionToken } from '@angular/core';

export const InReport=new InjectionToken<boolean>('InReport');

@Injectable({
	providedIn: 'root',
})
export class ReportService{
	public constructor(){}

	public orientation:'portrait'|'landscape'='portrait';
	public title:string;
	public reportType:string;

	public htmlToPdfLoaded=false;
	
	public markupAnnotationsVisible:boolean=true;
	public isAnnotating:boolean=false;

	public init(reportType:string){
		this.reportType=reportType;
	}

}
