import { Component, Input } from '@angular/core';

export type Size = 'small'|'medium'|'large'
@Component({
    selector: 'card-square-blocks',
    templateUrl: './card-square-blocks.component.html',
    styleUrls: ['./card-square-blocks.component.scss']
})
export class CardSquareBlocksComponent {
    @Input() title: string = '';
    @Input() content: string = '';
    @Input() long: boolean = false;
    @Input() icon: string = '';
    @Input() size:Size = 'medium';
    @Input() warn: boolean = false;
    constructor() {
        this.updateIcon();
    }

    ngOnChanges() {
        this.updateIcon();
    }

    private updateIcon() {
        if (this.warn) {
            this.icon = 'warn-red';
        } else {
            this.icon = 'checked-blue';
        }
    }
}
