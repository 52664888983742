import { Component, OnInit } from '@angular/core';
import { FragQueryService } from 'src/injectables/frag-query.service';
import { MatDialogRef } from '@angular/material/dialog';
import { DataIntervalTagEditDialog } from '@app/shared/edit-dialog/data-interval-tag/data-interval-tag-edit-dialog.component';
import { User } from '@models';
import { op } from '@lib/rxjs';
import { NavigationRoute$ } from '@injectables';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: 'dialog-report-properties',
    templateUrl: './dialog-report-properties.component.html',
    styleUrls: ['./dialog-report-properties.component.scss'],
})
export class DialogReportPropertiesComponent implements OnInit {
    constructor(
        private fragQuery: FragQueryService,
        private readonly host: MatDialogRef<DataIntervalTagEditDialog, boolean>,
        public user:User,
        private readonly route$: NavigationRoute$,
        private snackBar:MatSnackBar,
    ) { }

    ngOnInit() {
        this.fragQuery.exportReport=true;
    }

    public readonly begin$ = this.fragQuery.begin$;
    public readonly end$ = this.fragQuery.end$;
    public readonly device$= this.fragQuery.device$;
    public readonly apiUrl$ = this.fragQuery.timelineSieveSizesAtPercentsUrl$;
    public readonly token$=this.fragQuery.apiToken$;
    public readonly duration$ = this.fragQuery.duration$;

    public readonly type$ = this.route$.pipe(op.map(route => route[0]));
    
    reportExport(){
        if( !this.token$.value){
            this.snackBar.open('Go to API button in the menu and generate a token first to access the reports', 'Ok', {
                duration: 3000,
                panelClass: ['snackbar-success']
              });
        } else
        this.device$.subscribe(device=>{
            if(this.fragQuery.exportReport){
                let url = '/report/data-interval#';       
                this.type$.subscribe(type=>{
                    if(type==='shift-analytics'){
                        url = '/report/time-interval#'
                    }
                })
                let fragDuration='';
                this.duration$.subscribe(duration=>fragDuration=duration)

                const duration = fragDuration === 'custom' ? [this.begin$.value, this.end$.value] : fragDuration;
                const customDuration = fragDuration === 'custom' ? [this.begin$.value, this.end$.value] : '';
                const label = fragDuration === 'custom' ? 'custom' : 'duration';
                const custom = fragDuration === 'custom' ? 'duration' : null;
                url += JSON.stringify({ device: device.id, [label]: duration, [custom]: customDuration });
                window.open(url, '_blank');
            }
        })
        this.fragQuery.exportReport=false;
        this.host.close();
    }
}