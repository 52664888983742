import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { timezonesData } from '@app/new-ui/user/user-preferences/preference-list';
import { DateTime } from '@lib/date-time';
import { op } from '@lib/rxjs';
import { Shift } from '@models';
import { Subject } from 'rxjs';
import { FragQueryService } from 'src/injectables/frag-query.service';

interface Input{
	record:Shift;
}

@Component({
  selector: 'app-shift',
  templateUrl: './shift-edit-dialog.component.html',
})
export class ShiftEditDialogComponent {
    static readonly config:MatDialogConfig<Input>={
		autoFocus: false,
		disableClose: true,
	};
	static readonly output:boolean;

	constructor(
		private readonly host:MatDialogRef<ShiftEditDialogComponent,boolean>,
		@Inject(MAT_DIALOG_DATA) private readonly input:Input,
        private fragQuery:FragQueryService
	){};

	public record=this.input.record;
	public begin=this.record.beginDateTime??new DateTime();
	public end=this.record.endDateTime??new DateTime();
    public title=this.record.title;
	public emails=this.record.emails;
	public weighType:'area'|'volume'=this.record.weightType;
	public chartType:'column'|'line'=this.record.chartType;
	public density=this.record.density

	public valid(){
		if(!this.title)
			return false;
		return true;
	}

	public cancel(){
		this.host.close(false);
	}

	public save(){
        let deviceId;
        this.fragQuery.device$.subscribe(device=> deviceId=device.id);
		this.record.emails=this.emails;
        this.record.begin=Math.floor(this.begin.valueOf()/1000);
        this.record.end=Math.floor(this.end.valueOf()/1000);
		this.record.title=this.title;
        this.record.deviceId=deviceId;
		this.record.weightType=this.weighType;
		this.record.chartType=this.chartType;
		this.record.density=this.density;
		this.host.close(true);
	}
}