import {Device as _Device} from '../../../shared-models/public/device';

export class Device extends _Device{
}

export namespace Device{
	export import RestTypes=_Device.RestTypes;

	export const typeOptions:Record<Device['type'],string>={
		conveyor: 'Conveyor belt monitor',
		truck: 'Truck unload monitor',
	};
}
