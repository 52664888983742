<div class="auth-content" *ngIf="!emailSent">
  <h4>Password Recovery</h4>
  <p>Please enter the email associated with your account<p>
  <div class="input-wrapper">
    <div>
      <label>Email</label>
      <mat-form-field appearance="outline">
        <input matInput />
      </mat-form-field>
    </div>
    <span routerlink="/forgotPawwword">I remember my password!</span>
  </div>
  <button class="button" mat-flat-button color="primary" (click)="sendMail()">Send Email</button>
</div>


<div class="auth-content" *ngIf="emailSent">
  <img src="./../../../../assets/mat-icons/mail.svg">
  <p>We have sent a password recovery option to your email</p>
  <button class="button" mat-flat-button color="primary" (click)="newPassword()">Okay</button>
  <p>Didn’t receive the email? <span>Resend Email</span> </p>
</div>