import {math} from "../math";

const epsilon=1.1102230246251565e-16;
const splitter=134217729;
const resulterrbound=(3+8*epsilon)*epsilon;

// fast_expansion_sum_zeroelim routine from oritinal code
function sum(elen:number, e:Float32Array, flen:number, f:Float32Array, h:Float32Array){
	let Q, Qnew, hh, bvirt;
	let enow=e[0];
	let fnow=f[0];
	let eindex=0;
	let findex=0;
	if((fnow>enow) ===(fnow>-enow)){
		Q=enow;
		enow=e[++eindex];
	} else {
		Q=fnow;
		fnow=f[++findex];
	}
	let hindex=0;
	if(eindex<elen && findex<flen){
		if((fnow>enow) ===(fnow>-enow)){
			Qnew=enow+Q;
			hh=Q -(Qnew-enow);
			enow=e[++eindex];
		} else {
			Qnew=fnow+Q;
			hh=Q -(Qnew-fnow);
			fnow=f[++findex];
		}
		Q=Qnew;
		if(hh!==0){
			h[hindex++]=hh;
		}
		while(eindex<elen && findex<flen){
			if((fnow>enow) ===(fnow>-enow)){
				Qnew=Q+enow;
				bvirt=Qnew-Q;
				hh=Q -(Qnew-bvirt)+(enow-bvirt);
				enow=e[++eindex];
			} else {
				Qnew=Q+fnow;
				bvirt=Qnew-Q;
				hh=Q -(Qnew-bvirt)+(fnow-bvirt);
				fnow=f[++findex];
			}
			Q=Qnew;
			if(hh!==0){
				h[hindex++]=hh;
			}
		}
	}
	while(eindex<elen){
		Qnew=Q+enow;
		bvirt=Qnew-Q;
		hh=Q -(Qnew-bvirt)+(enow-bvirt);
		enow=e[++eindex];
		Q=Qnew;
		if(hh!==0){
			h[hindex++]=hh;
		}
	}
	while(findex<flen){
		Qnew=Q+fnow;
		bvirt=Qnew-Q;
		hh=Q -(Qnew-bvirt)+(fnow-bvirt);
		fnow=f[++findex];
		Q=Qnew;
		if(hh!==0){
			h[hindex++]=hh;
		}
	}
	if(Q!==0 || hindex===0){
		h[hindex++]=Q;
	}
	return hindex;
}

function estimate(elen:number, e:ArrayLike<number>){
	let Q=e[0];
	for(let i=1;i<elen;i++) Q += e[i];
	return Q;
}

function vec(n:number){
	return new Float32Array(n);
}

const ccwerrboundA=(3+16*epsilon)*epsilon;
const ccwerrboundB=(2+12*epsilon)*epsilon;
const ccwerrboundC=(9+64*epsilon)*epsilon*epsilon;

const B=vec(4);
const C1=vec(8);
const C2=vec(12);
const D=vec(16);
const u=vec(4);

function orient2dadapt(ax:number, ay:number, bx:number, by:number, cx:number, cy:number, detsum:number){
	let acxtail, acytail, bcxtail, bcytail;
	let bvirt, c, ahi, alo, bhi, blo, _i, _j, _0, s1, s0, t1, t0, u3;

	const acx=ax-cx;
	const bcx=bx-cx;
	const acy=ay-cy;
	const bcy=by-cy;

	s1=acx*bcy;
	c=splitter*acx;
	ahi=c -(c-acx);
	alo=acx-ahi;
	c=splitter*bcy;
	bhi=c -(c-bcy);
	blo=bcy-bhi;
	s0=alo*blo -(s1-ahi*bhi-alo*bhi-ahi*blo);
	t1=acy*bcx;
	c=splitter*acy;
	ahi=c -(c-acy);
	alo=acy-ahi;
	c=splitter*bcx;
	bhi=c -(c-bcx);
	blo=bcx-bhi;
	t0=alo*blo -(t1-ahi*bhi-alo*bhi-ahi*blo);
	_i=s0-t0;
	bvirt=s0-_i;
	B[0]=s0 -(_i+bvirt)+(bvirt-t0);
	_j=s1+_i;
	bvirt=_j-s1;
	_0=s1 -(_j-bvirt)+(_i-bvirt);
	_i=_0-t1;
	bvirt=_0-_i;
	B[1]=_0 -(_i+bvirt)+(bvirt-t1);
	u3=_j+_i;
	bvirt=u3-_j;
	B[2]=_j -(u3-bvirt)+(_i-bvirt);
	B[3]=u3;

	let det=estimate(4, B);
	let errbound=ccwerrboundB*detsum;
	if(det>=errbound || -det>=errbound){
		return det;
	}

	bvirt=ax-acx;
	acxtail=ax -(acx+bvirt)+(bvirt-cx);
	bvirt=bx-bcx;
	bcxtail=bx -(bcx+bvirt)+(bvirt-cx);
	bvirt=ay-acy;
	acytail=ay -(acy+bvirt)+(bvirt-cy);
	bvirt=by-bcy;
	bcytail=by -(bcy+bvirt)+(bvirt-cy);

	if(acxtail===0 && acytail===0 && bcxtail===0 && bcytail===0){
		return det;
	}

	errbound=ccwerrboundC*detsum+resulterrbound*Math.abs(det);
	det +=(acx*bcytail+bcy*acxtail) -(acy*bcxtail+bcx*acytail);
	if(det>=errbound || -det>=errbound)
		return det;

	s1=acxtail*bcy;
	c=splitter*acxtail;
	ahi=c -(c-acxtail);
	alo=acxtail-ahi;
	c=splitter*bcy;
	bhi=c -(c-bcy);
	blo=bcy-bhi;
	s0=alo*blo -(s1-ahi*bhi-alo*bhi-ahi*blo);
	t1=acytail*bcx;
	c=splitter*acytail;
	ahi=c -(c-acytail);
	alo=acytail-ahi;
	c=splitter*bcx;
	bhi=c -(c-bcx);
	blo=bcx-bhi;
	t0=alo*blo -(t1-ahi*bhi-alo*bhi-ahi*blo);
	_i=s0-t0;
	bvirt=s0-_i;
	u[0]=s0 -(_i+bvirt)+(bvirt-t0);
	_j=s1+_i;
	bvirt=_j-s1;
	_0=s1 -(_j-bvirt)+(_i-bvirt);
	_i=_0-t1;
	bvirt=_0-_i;
	u[1]=_0 -(_i+bvirt)+(bvirt-t1);
	u3=_j+_i;
	bvirt=u3-_j;
	u[2]=_j -(u3-bvirt)+(_i-bvirt);
	u[3]=u3;
	const C1len=sum(4, B, 4, u, C1);

	s1=acx*bcytail;
	c=splitter*acx;
	ahi=c -(c-acx);
	alo=acx-ahi;
	c=splitter*bcytail;
	bhi=c -(c-bcytail);
	blo=bcytail-bhi;
	s0=alo*blo -(s1-ahi*bhi-alo*bhi-ahi*blo);
	t1=acy*bcxtail;
	c=splitter*acy;
	ahi=c -(c-acy);
	alo=acy-ahi;
	c=splitter*bcxtail;
	bhi=c -(c-bcxtail);
	blo=bcxtail-bhi;
	t0=alo*blo -(t1-ahi*bhi-alo*bhi-ahi*blo);
	_i=s0-t0;
	bvirt=s0-_i;
	u[0]=s0 -(_i+bvirt)+(bvirt-t0);
	_j=s1+_i;
	bvirt=_j-s1;
	_0=s1 -(_j-bvirt)+(_i-bvirt);
	_i=_0-t1;
	bvirt=_0-_i;
	u[1]=_0 -(_i+bvirt)+(bvirt-t1);
	u3=_j+_i;
	bvirt=u3-_j;
	u[2]=_j -(u3-bvirt)+(_i-bvirt);
	u[3]=u3;
	const C2len=sum(C1len, C1, 4, u, C2);

	s1=acxtail*bcytail;
	c=splitter*acxtail;
	ahi=c -(c-acxtail);
	alo=acxtail-ahi;
	c=splitter*bcytail;
	bhi=c -(c-bcytail);
	blo=bcytail-bhi;
	s0=alo*blo -(s1-ahi*bhi-alo*bhi-ahi*blo);
	t1=acytail*bcxtail;
	c=splitter*acytail;
	ahi=c -(c-acytail);
	alo=acytail-ahi;
	c=splitter*bcxtail;
	bhi=c -(c-bcxtail);
	blo=bcxtail-bhi;
	t0=alo*blo -(t1-ahi*bhi-alo*bhi-ahi*blo);
	_i=s0-t0;
	bvirt=s0-_i;
	u[0]=s0 -(_i+bvirt)+(bvirt-t0);
	_j=s1+_i;
	bvirt=_j-s1;
	_0=s1 -(_j-bvirt)+(_i-bvirt);
	_i=_0-t1;
	bvirt=_0-_i;
	u[1]=_0 -(_i+bvirt)+(bvirt-t1);
	u3=_j+_i;
	bvirt=u3-_j;
	u[2]=_j -(u3-bvirt)+(_i-bvirt);
	u[3]=u3;
	const Dlen=sum(C2len, C2, 4, u, D);

	return D[Dlen-1];
}

function orient2d(ax:number, ay:number, bx:number, by:number, cx:number, cy:number){
	const detleft=(ay-cy)*(bx-cx);
	const detright=(ax-cx)*(by-cy);
	const det=detleft-detright;

	if(detleft===0 || detright===0 || (detleft>0)!==(detright>0))
		return det;

	const detsum=Math.abs(detleft+detright);
	if(Math.abs(det)>=ccwerrboundA*detsum)
		return det;

	return -orient2dadapt(ax, ay, bx, by, cx, cy, detsum);
}

const EPSILON=Math.pow(2, -52);
const EDGE_STACK=new Uint32Array(512);

class HullHash{
	private readonly hashSize:number;
	private readonly hullHash:Int32Array;
	private cx:number;
	private cy:number;

	public constructor(coordCount:number){
		this.hashSize=Math.ceil(Math.sqrt(coordCount));
		this.hullHash=new Int32Array(this.hashSize).fill(-1);// angular edge hash
	}

	private _hashKey(x:number, y:number){
		return Math.floor(pseudoAngle(x-this.cx, y-this.cy)*this.hashSize)%this.hashSize;
	}

	public reset(center:{x:number,y:number}, i0:number, i0x:number, i0y:number, i1:number, i1x:number, i1y:number, i2:number, i2x:number, i2y:number){
		this.cx=center.x;
		this.cy=center.y;
		this.hullHash.fill(-1);
		this.set(i0x,i0y,i0);
		this.set(i1x,i1y,i1);
		this.set(i2x,i2y,i2);
	}

	public getStart(x:number, y:number, hullNext:Uint32Array){
		// find a visible edge on the convex hull using edge hash
		let start=0;
		for(let j=0, key=this._hashKey(x, y);j<this.hashSize;j++){
			start=this.hullHash[(key+j)%this.hashSize];
			if(start!==-1 && start!==hullNext[start])
				break;
		}
		return start;
	}

	public set(x:number, y:number, i:number){
		this.hullHash[this._hashKey(x, y)]=i;
	}
}

interface HullHash{
	[i:number]:never;
}

export class Delaunator<TrianglesType extends Uint32ArrayConstructor|Uint16ArrayConstructor>{
	static from(
		vertexCount:number,
		vertexX:(vi:number)=>number,
		vertexY:(vi:number)=>number,
	):Delaunator<Uint32ArrayConstructor|Uint16ArrayConstructor>;
	static from(
		vertexCount:number,
		vertexX:(vi:number)=>number,
		vertexY:(vi:number)=>number,
		indicesType:Uint16ArrayConstructor,
	):Delaunator<Uint16ArrayConstructor>;
	static from(
		vertexCount:number,
		vertexX:(vi:number)=>number,
		vertexY:(vi:number)=>number,
		indicesType:Uint32ArrayConstructor,
	):Delaunator<Uint32ArrayConstructor>;
	static from(
		vertexCount:number,
		vertexX: (vi:number)=>number,
		vertexY: (vi:number)=>number,
		indicesType?:Uint32ArrayConstructor|Uint16ArrayConstructor
	){
		indicesType??=vertexCount>(2**16)?Uint32Array:Uint16Array;
		return new Delaunator<Uint32ArrayConstructor|Uint16ArrayConstructor>(
			vertexCount,
			vertexX,
			vertexY,
			indicesType
		);
	}

	public hull:Uint32Array;
	public trianglesLen=0;

	public triangles:InstanceType<TrianglesType>;
	public halfedges:Int32Array;
	private readonly hullPrev:Uint32Array;
	private readonly hullNext:Uint32Array;
	private readonly hullTri:Uint32Array;
	private readonly hullHash:HullHash;
	private readonly indices:Uint32Array;
	private readonly dists:Float32Array;

	private hullStart:number;

	public constructor(
		public readonly vertexCount:number,
		public readonly vertexX: (vi:number)=>number,
		public readonly vertexY: (vi:number)=>number,
		private readonly TrianglesType:TrianglesType,
	){
		if(vertexCount===0)
			throw new Error('Expected coords to contain numbers.');

		// arrays that will store the triangulation graph
		const maxTriangles=Math.max(2*vertexCount-5, 0);
		this.triangles=<InstanceType<TrianglesType>>new TrianglesType(maxTriangles*3);
		this.halfedges=new Int32Array(maxTriangles*3);

		// temporary arrays for tracking the edges of the advancing convex hull
		this.hullHash=new HullHash(vertexCount);
		this.hullPrev=new Uint32Array(vertexCount);// edge to prev edge
		this.hullNext=new Uint32Array(vertexCount);// edge to next edge
		this.hullTri=new Uint32Array(vertexCount);// edge to adjacent triangle

		// temporary arrays for sorting points
		this.indices=new Uint32Array(vertexCount);
		this.dists=new Float32Array(vertexCount);

		this.update();
	}

	public update(){
		const {
			vertexCount,
			vertexX,
			vertexY,
			hullPrev,
			hullNext,
			hullTri,
			hullHash
		}= this;

		// populate an array of point indices;calculate input data bbox
		let minX=Infinity;
		let minY=Infinity;
		let maxX=-Infinity;
		let maxY=-Infinity;

		for(let i=0;i<vertexCount;i++){
			const x=vertexX(i);
			const y=vertexY(i);
			if(x<minX) minX=x;
			if(y<minY) minY=y;
			if(x>maxX) maxX=x;
			if(y>maxY) maxY=y;
			this.indices[i]=i;
		}
		const cx=(minX+maxX)/2;
		const cy=(minY+maxY)/2;

		let minDist=Infinity;
		let i0=0, i1:number|undefined, i2:number|undefined;

		// pick a seed point close to the center
		for(let i=0;i<vertexCount;i++){
			const d=dist(cx, cy, vertexX(i), vertexY(i));
			if(d<minDist){
				i0=i;
				minDist=d;
			}
		}
		const i0x=vertexX(i0);
		const i0y=vertexY(i0);

		minDist=Infinity;

		// find the point closest to the seed
		for(let i=0;i<vertexCount;i++){
			if(i===i0)
				continue;
			const d=dist(i0x, i0y, vertexX(i), vertexY(i));
			if(d<minDist && d>0){
				i1=i;
				minDist=d;
			}
		}
		
		if(i1===undefined){
			this.trianglesLen=0;
			this.triangles=<InstanceType<TrianglesType>>new this.TrianglesType(0);
			this.halfedges=new Int32Array(0);
			return;
		}

		let i1x=vertexX(i1);
		let i1y=vertexY(i1);

		let minRadius=Infinity;

		// find the third point which forms the smallest circumcircle with the first two
		for(let i=0;i<vertexCount;i++){
			if(i===i0 || i===i1)
				continue;
			const r=circumradius(i0x, i0y, i1x, i1y, vertexX(i), vertexY(i));
			if(r<minRadius){
				i2=i;
				minRadius=r;
			}
		}
		if(i2===undefined){
			this.trianglesLen=0;
			this.triangles=<InstanceType<TrianglesType>>new this.TrianglesType(0);
			this.halfedges=new Int32Array(0);
			return;
		}

		let i2x=vertexX(i2);
		let i2y=vertexY(i2);

		if(minRadius===Infinity){
			// order collinear points by dx(or dy if all x are identical)
			// and return the list as a hull
			for(let i=0;i<vertexCount;i++){
				this.dists[i]=(vertexX(i)-vertexX(0)) ||(vertexY(i)-vertexY(0));
			}
			quicksort(this.indices, this.dists, 0, vertexCount-1);
			const hull=new Uint32Array(vertexCount);
			let j=0;
			for(let i=0, d0=-Infinity;i<vertexCount;i++){
				const id=this.indices[i];
				if(this.dists[id]>d0){
					hull[j++]=id;
					d0=this.dists[id];
				}
			}
			this.hull=hull.subarray(0, j);
			this.trianglesLen=0;
			this.triangles=<InstanceType<TrianglesType>>new this.TrianglesType(0);
			this.halfedges=new Int32Array(0);
			return;
		}

		// swap the order of the seed points for counter-clockwise orientation
		if(orient2d(i0x, i0y, i1x, i1y, i2x, i2y)<0){
			const i=i1;
			const x=i1x;
			const y=i1y;
			i1=i2;
			i1x=i2x;
			i1y=i2y;
			i2=i;
			i2x=x;
			i2y=y;
		}

		const center=circumcenter(i0x, i0y, i1x, i1y, i2x, i2y,new math.Vec2());
		this.hullHash.reset(center,i0,i0x,i0y,i1,i1x,i1y,i2,i2x,i2y);

		for(let i=0;i<vertexCount;i++){
			this.dists[i]=dist(vertexX(i), vertexY(i), center.x, center.y);
		}

		// sort the points by distance from the seed triangle circumcenter
		quicksort(this.indices, this.dists, 0, vertexCount-1);

		// set up the seed triangle as the starting hull
		this.hullStart=i0;
		let hullSize=3;

		hullNext[i0]=hullPrev[i2]=i1;
		hullNext[i1]=hullPrev[i0]=i2;
		hullNext[i2]=hullPrev[i1]=i0;

		hullTri[i0]=0;
		hullTri[i1]=1;
		hullTri[i2]=2;

		this.trianglesLen=0;
		this._addTriangle(i0, i1, i2, -1, -1, -1);

		for(let k=0,xp=0,yp=0;k<this.indices.length;k++){
			const i=this.indices[k];
			const x=vertexX(i);
			const y=vertexY(i);

			// skip near-duplicate points
			if(k>0 && Math.abs(x-xp)<=EPSILON && Math.abs(y-yp)<=EPSILON)
				continue;
			xp=x;
			yp=y;

			// skip seed triangle points
			if(i===i0 || i===i1 || i===i2)
				continue;

			let start=hullHash.getStart(x,y,hullNext);

			start=hullPrev[start];
			let e=start;
			let q:number;
			while(q=hullNext[e], orient2d(x, y, vertexX(e), vertexY(e), vertexX(q), vertexY(q))>=0){
				e=q;
				if(e===start){
					e=-1;
					break;
				}
			}
			if(e===-1)
				continue;// likely a near-duplicate point;skip it

			// add the first triangle from the point
			let t=this._addTriangle(e, i, hullNext[e], -1, -1, hullTri[e]);

			// recursively flip triangles from the point until they satisfy the Delaunay condition
			hullTri[i]=this._legalize(t+2);
			hullTri[e]=t;// keep track of boundary triangles on the hull
			hullSize++;

			// walk forward through the hull, adding more triangles and flipping recursively
			let n=hullNext[e];
			while(q=hullNext[n], orient2d(x, y, vertexX(n), vertexY(n), vertexX(q), vertexY(q))<0){
				t=this._addTriangle(n, i, q, hullTri[i], -1, hullTri[n]);
				hullTri[i]=this._legalize(t+2);
				hullNext[n]=n;// mark as removed
				hullSize--;
				n=q;
			}

			// walk backward from the other side, adding more triangles and flipping
			if(e===start){
				while(q=hullPrev[e], orient2d(x, y, vertexX(q), vertexY(q), vertexX(e), vertexY(e))<0){
					t=this._addTriangle(q, i, e, -1, hullTri[e], hullTri[q]);
					this._legalize(t+2);
					hullTri[q]=t;
					hullNext[e]=e;// mark as removed
					hullSize--;
					e=q;
				}
			}

			// update the hull indices
			this.hullStart=hullPrev[i]=e;
			hullNext[e]=hullPrev[n]=i;
			hullNext[i]=n;

			// save the two new edges in the hash table
			hullHash.set(x,y,i);
			hullHash.set(vertexX(e),vertexY(e),e);
		}

		this.hull=new Uint32Array(hullSize);
		for(let i=0, e=this.hullStart;i<hullSize;i++){
			this.hull[i]=e;
			e=hullNext[e];
		}

		// trim typed triangle mesh arrays
		this.triangles=<InstanceType<TrianglesType>>this.triangles.subarray(0, this.trianglesLen);
		this.halfedges=this.halfedges.subarray(0, this.trianglesLen);
	}

	private _legalize(a:number){
		const {
			vertexX,
			vertexY,
			triangles,
			halfedges,
		}=this;

		let i=0;
		let ar=0;

		// recursion eliminated with a fixed-size stack
		while(true){
			const b=halfedges[a];

			/* if the pair of triangles doesn't satisfy the Delaunay condition
				*(p1 is inside the circumcircle of [p0, pl, pr]), flip them,
				* then do the same check/flip recursively for the new pair of triangles
				*
				*           pl                    pl
				*          /||\                  /  \
				*       al/ || \bl            al/    \a
				*        /  ||  \              /      \
				*       /  a||b  \    flip    /___ar___\
				*     p0\   ||   /p1   =>   p0\---bl---/p1
				*        \  ||  /              \       /
				*       ar\ || /br             b\    /br
				*          \||/                  \  /
				*           pr                    pr
				*/
			const a0=a-a%3;
			ar=a0+(a+2)%3;

			if(b===-1){ // convex hull edge
				if(i===0) break;
				a=EDGE_STACK[--i];
				continue;
			}

			const b0=b-b%3;
			const al=a0+(a+1)%3;
			const bl=b0+(b+2)%3;

			const p0=triangles[ar];
			const pr=triangles[a];
			const pl=triangles[al];
			const p1=triangles[bl];

			const illegal=inCircle(
				vertexX(p0), vertexY(p0),
				vertexX(pr), vertexY(pr),
				vertexX(pl), vertexY(pl),
				vertexX(p1), vertexY(p1));

			if(illegal){
				triangles[a]=p1;
				triangles[b]=p0;

				const hbl=halfedges[bl];

				// edge swapped on the other side of the hull(rare);fix the halfedge reference
				if(hbl===-1){
					let e=this.hullStart;
					do {
						if(this.hullTri[e]===bl){
							this.hullTri[e]=a;
							break;
						}
						e=this.hullPrev[e];
					} while(e!==this.hullStart);
				}
				this._link(a, hbl);
				this._link(b, halfedges[ar]);
				this._link(ar, bl);

				const br=b0+(b+1)%3;

				// don't worry about hitting the cap: it can only happen on extremely degenerate input
				if(i<EDGE_STACK.length){
					EDGE_STACK[i++]=br;
				}
			} else {
				if(i===0) break;
				a=EDGE_STACK[--i];
			}
		}

		return ar;
	}

	private _link(a:number, b:number){
		this.halfedges[a]=b;
		if(b!==-1)
			this.halfedges[b]=a;
	}

	// add a new triangle given vertex indices and adjacent half-edge ids
	private _addTriangle(i0:number, i1:number, i2:number, a:number, b:number, c:number){
		const t=this.trianglesLen;

		this.triangles[t]=i0;
		this.triangles[t+1]=i1;
		this.triangles[t+2]=i2;

		this._link(t, a);
		this._link(t+1, b);
		this._link(t+2, c);

		this.trianglesLen += 3;

		return t;
	}

	public edgeIndicesOfTriangle(t:number){
		return [3*t,3*t+1,3*t+2];
	}

	// public triangleOfEdge(e:number){
	// 	return Math.floor(e/3);
	// }

	public nextOnTriangle(e:number){
		return (e%3===2)?e-2:e+1;
	}

	// public prevOnTriangle(e:number){
	// 	return (e%3===0)?e+2:e-1;
	// }

	// public vertex(i:number, out=new Vec2()){
	// 	return out.set(this.vertexX(i),this.vertexY(i));
	// }

	// public forEachTriangleEdge(callback:(e:number, p:Vec2, q:Vec2)=>void) {
	// 	for (let e=0; e<this.triangles.length; e++){
	// 		if (e>this.halfedges[e]){
	// 			const p=this.vertex(this.triangles[e]);
	// 			const q=this.vertex(this.nextOnTriangle(e));
	// 			callback(e, p, q);
	// 		}
	// 	}
	// }

	// public vertexIndicesOfTriangle(t:number) {
	// 	return this.edgeIndicesOfTriangle(t)
	// 		.map(e=>this.triangles[e]);
	// }

	public *iterateTriangleIndices(){
		const triangleCount=this.triangles.length/3;
		for(let ti=0;ti<triangleCount;ti++){
			const ai=this.triangles[3*ti];
			if(ai>=this.vertexCount)
				continue;
			const bi=this.triangles[3*ti+1];
			if(bi>=this.vertexCount)
				continue;
			const ci=this.triangles[3*ti+2];
			if(ci>=this.vertexCount)
				continue;
			yield ti;
		}
	}

	// public *iterateTriangleVertexIndices(){
	// 	const triangleCount=this.triangles.length/3;
	// 	for(let ti=0;ti<triangleCount;ti++){
	// 		const ai=this.triangles[3*ti];
	// 		if(ai>=this.vertexCount)
	// 			continue;
	// 		const bi=this.triangles[3*ti+1];
	// 		if(bi>=this.vertexCount)
	// 			continue;
	// 		const ci=this.triangles[3*ti+2];
	// 		if(ci>=this.vertexCount)
	// 			continue;
	// 		yield [ai,bi,ci];
	// 	}
	// }

	public iterateTriangles<Corner extends math.Vec2>(getVertex:(vi:number)=>Corner):IterableIterator<[number,math.Triangle2<Corner>]>{
		const triangle=new math.Triangle2<Corner>();
		const triangleCount=this.triangles.length/3;
		const triangles=this.triangles;
		const vertexCount=this.vertexCount;
		
		const iterator={
			value:  <[number,math.Triangle2<Corner>]>[-1,triangle],
			done: false,
			next(){
				let ti=this.value[0]+1;
				for(;ti<triangleCount;++ti){
					const ti3=ti*3;
					const ai=triangles[ti3];
					if(ai>=vertexCount)
						continue;
					triangle.a=getVertex(ai);
					triangle.b=getVertex(triangles[ti3+1]);
					triangle.c=getVertex(triangles[ti3+2]);
					break;
				}

				this.value[0]=ti;
				this.done=ti>=triangleCount;
				return this;
			},
			[Symbol.iterator](){
				return this;
			}
		}
		iterator.next();
		return iterator;
	}

	public iterateTrianglesAndHalfedges<Corner extends math.Vec2>(getVertex:(vi:number)=>Corner):IterableIterator<[number,math.Triangle2<Corner>,[number,number,number]]>{
		const triangleCount=this.triangles.length/3;
		const triangles=this.triangles;
		const halfedges=this.halfedges;
		const vertexCount=this.vertexCount;
		
		const triangle=new math.Triangle2<Corner>();
		const halfedgesOut=<[number,number,number]>[0,0,0];
		const iterator={
			value:  <[number,math.Triangle2<Corner>,[number,number,number]]>[-1,triangle,halfedgesOut],
			done: false,
			next(){
				let ti=this.value[0]+1;
				for(;ti<triangleCount;++ti){
					const ti3=ti*3;
					const ai=triangles[ti3];
					if(ai>=vertexCount)
						continue;
					triangle.a=getVertex(ai);
					triangle.b=getVertex(triangles[ti3+1]);
					triangle.c=getVertex(triangles[ti3+2]);
					halfedgesOut[0]=halfedges[ti3];
					halfedgesOut[1]=halfedges[ti3+1];
					halfedgesOut[2]=halfedges[ti3+2];
					break;
				}

				this.value[0]=ti;
				this.done=ti>=triangleCount;
				return this;
			},
			[Symbol.iterator](){
				return this;
			}
		}
		iterator.next();
		return iterator;
	}

	// public triangleIsHull(t:number){
	// 	return this.edgeIndicesOfTriangle(t).some(i=>this.halfedges[i]<0);
	// }

	public killTriangle(t:number){
		for(const i of this.edgeIndicesOfTriangle(t)){
			this.triangles[i]=this.vertexCount;
			const he=this.halfedges[i];
			if(he>=0)
				this.halfedges[he]=-1;
			this.halfedges[i]=-1;
		}
	}

	// public trianglesAdjacentToTriangle(t:number) {
	// 	const adjacentTriangles=[];
	// 	for (const e of this.edgeIndicesOfTriangle(t)) {
	// 		const opposite=this.halfedges[e];
	// 		if (opposite >= 0) {
	// 			adjacentTriangles.push(this.triangleOfEdge(opposite));
	// 		}
	// 	}
	// 	return adjacentTriangles;
	// }	

	// public triangleCircumCenter<Point extends Vec2>(t:number, out:Point):Point{
	// 	const vertices=this.vertexIndicesOfTriangle(t);
	// 	return circumcenter(
	// 		this.vertexX(vertices[0]),
	// 		this.vertexY(vertices[0]),
	// 		this.vertexX(vertices[1]),
	// 		this.vertexY(vertices[1]),
	// 		this.vertexX(vertices[2]),
	// 		this.vertexY(vertices[2]),
	// 		out);
	// }

	// public forEachVoronoiEdge(callback:(e:number, p:Vec2, q:Vec2)=>void){
	// 	for(let e=0;e<this.triangles.length;e++){
	// 		if(e<this.halfedges[e]){
	// 			const p=this.triangleCircumCenter(this.triangleOfEdge(e),new Vec2());
	// 			const q=this.triangleCircumCenter(this.triangleOfEdge(this.halfedges[e]),new Vec2());
	// 			callback(e,p,q);
	// 		}
	// 	}
	// }

	// public edgesAroundPoint(start:number) {
	// 	const result=[];
	// 	let incoming=start;
	// 	do{
	// 		result.push(incoming);
	// 		const outgoing=this.nextOnTriangle(incoming);
	// 		incoming=this.halfedges[outgoing];
	// 	}while(incoming>=0 && incoming!==start);
	// 	return result;
	// }


	// public adjustTrianglesSplits(
	// 	cost:(viA:number,viB:number)=>number,
	// 	maxTries=32
	// ){
	// 	let fixedCounts:number[]=[];
	// 	for(let tryCount=0;tryCount<maxTries;++tryCount){
	// 		const triangles=this.triangles;
	// 		const halfedges=this.halfedges;
	// 		let reversedTriangleCount=0;
	// 		for(let tiA=0;tiA<triangles.length;tiA+=3){
	// 			for(let ciA=0;ciA<3;++ciA){
	// 				const ia=[
	// 					tiA+(ciA+0)%3,
	// 					tiA+(ciA+1)%3,
	// 					tiA+(ciA+2)%3,
	// 				];
		
	// 				let tiB=halfedges[ia[0]];
	// 				if(tiB>=0){
	// 					const ciB=tiB%3;
	// 					tiB=(tiB-ciB);
	// 					const ib=[
	// 						tiB+(ciB+0)%3,
	// 						tiB+(ciB+1)%3,
	// 						tiB+(ciB+2)%3,
	// 					];
	// 					const sharedSideLength=cost(triangles[ia[0]],triangles[ia[1]]);
	// 					const splitLength=cost(triangles[ia[2]],triangles[ib[2]]);
	// 					if(splitLength<sharedSideLength){
	// 						reversedTriangleCount+=1;
	// 						//new triangle layout
	// 						// triangles[ia[0]];
	// 						// triangles[ib[2]];
	// 						// triangles[ia[2]];
		
	// 						// triangles[ib[0]];
	// 						// triangles[ia[2]];
	// 						// triangles[ib[2]];
		
	// 						triangles[ia[1]]=triangles[ib[2]];
	// 						triangles[ib[1]]=triangles[ia[2]];
		
	// 						const outerEdges=[
	// 							halfedges[ib[1]],
	// 							halfedges[ib[2]],
	// 							halfedges[ia[1]],
	// 							halfedges[ia[2]],
	// 						];
		
	// 						halfedges[ia[1]]=ib[1];
	// 						halfedges[ib[1]]=ia[1];
		
	// 						halfedges[ia[0]]=outerEdges[0];
	// 						if(outerEdges[0]>0)
	// 							halfedges[outerEdges[0]]=ia[0];
	// 						halfedges[ib[0]]=outerEdges[2];
	// 						if(outerEdges[2]>0)
	// 							halfedges[outerEdges[2]]=ib[0];
	// 					}
	// 				}
	// 			}
	// 		}
	// 		fixedCounts.push(reversedTriangleCount);
	// 		if(reversedTriangleCount===0)
	// 			break;
	// 	}
	// 	return fixedCounts;
	// }

	// public voronoiCells<Cell extends VoronioCell>(
	// 	getCell:(vi:number)=>Cell,
	// ){
	// 	const startingEdge=new Int32Array(this.vertexCount);
	// 	startingEdge.fill(this.halfedges.length);
	// 	for (let e=0;e<this.triangles.length;e++){
	// 		const endpoint=this.triangles[this.nextHalfedge(e)];
	// 		if(endpoint<0xFFFF){
	// 			if(startingEdge[endpoint]===this.halfedges.length || this.halfedges[e]===-1){
	// 				startingEdge[endpoint]=e;
	// 			}
	// 		}
	// 	}

	// 	for(let p=0;p<this.vertexCount;++p){
	// 		const cell=getCell(p);
	// 		if(cell && cell.points.length===0){
	// 			const e=startingEdge[p];
	// 			if(e<this.halfedges.length){
	// 				const edges=this.edgesAroundPoint(e);
	// 				const triangles=edges.map(v=>this.triangleOfEdge(v)).reverse();
	// 				for(const t of triangles){
	// 					const vertices=this.vertexIndicesOfTriangle(t);
	// 					const point=cell.newPoint();
	// 					circumcenter(
	// 						this.vertexX(vertices[0]),
	// 						this.vertexY(vertices[0]),
	// 						this.vertexX(vertices[1]),
	// 						this.vertexY(vertices[1]),
	// 						this.vertexX(vertices[2]),
	// 						this.vertexY(vertices[2]),
	// 						point);
	// 					const ownIndex=vertices.indexOf(p);
	// 					point.prevNeighbor=getCell(vertices.atWrap(ownIndex+1));
	// 					point.nextNeighbor=getCell(vertices.atWrap(ownIndex+2));
	// 				}
	// 			}
	// 		}
	// 	}
	// }	
}

// monotonically increases with real angle, but doesn't need expensive trigonometry
function pseudoAngle(dx:number, dy:number){
	const p=dx /(Math.abs(dx)+Math.abs(dy));
	return(dy>0 ? 3-p : 1+p)/4;// [0..1]
}

function dist(ax:number, ay:number, bx:number, by:number){
	const dx=ax-bx;
	const dy=ay-by;
	return dx*dx+dy*dy;
}

function inCircle(ax:number, ay:number, bx:number, by:number, cx:number, cy:number, px:number, py:number){
	const dx=ax-px;
	const dy=ay-py;
	const ex=bx-px;
	const ey=by-py;
	const fx=cx-px;
	const fy=cy-py;

	const ap=dx*dx+dy*dy;
	const bp=ex*ex+ey*ey;
	const cp=fx*fx+fy*fy;

	return dx*(ey*cp-bp*fy) -
			dy*(ex*cp-bp*fx) +
			ap*(ex*fy-ey*fx)<0;
}

function circumradius(ax:number, ay:number, bx:number, by:number, cx:number, cy:number){
	const dx=bx-ax;
	const dy=by-ay;
	const ex=cx-ax;
	const ey=cy-ay;

	const bl=dx*dx+dy*dy;
	const cl=ex*ex+ey*ey;
	const d=0.5 /(dx*ey-dy*ex);

	const x=(ey*bl-dy*cl)*d;
	const y=(dx*cl-ex*bl)*d;

	return x*x+y*y;
}

function circumcenter<Point extends math.Vec2>(ax:number, ay:number, bx:number, by:number, cx:number, cy:number, out:Point){
	const dx=bx-ax;
	const dy=by-ay;
	const ex=cx-ax;
	const ey=cy-ay;

	const bl=dx*dx+dy*dy;
	const cl=ex*ex+ey*ey;
	const d=0.5 /(dx*ey-dy*ex);

	const x=ax+(ey*bl-dy*cl)*d;
	const y=ay+(dx*cl-ex*bl)*d;

	return out.set(x,y);
}

export function quicksort(ids:Uint32Array, dists:Float32Array, left:number, right:number){
	if(right-left<=20){
		for(let i=left+1;i<=right;i++){
			const temp=ids[i];
			const tempDist=dists[temp];
			let j=i-1;
			while(j>=left && dists[ids[j]]>tempDist) ids[j+1]=ids[j--];
			ids[j+1]=temp;
		}
	} else {
		const median=(left+right) >> 1;
		let i=left+1;
		let j=right;
		swap(ids, median, i);
		if(dists[ids[left]]>dists[ids[right]]) swap(ids, left, right);
		if(dists[ids[i]]>dists[ids[right]]) swap(ids, i, right);
		if(dists[ids[left]]>dists[ids[i]]) swap(ids, left, i);

		const temp=ids[i];
		const tempDist=dists[temp];
		while(true){
			do i++;while(dists[ids[i]]<tempDist);
			do j--;while(dists[ids[j]]>tempDist);
			if(j<i) break;
			swap(ids, i, j);
		}
		ids[left+1]=ids[j];
		ids[j]=temp;

		if(right-i+1>=j-left){
			quicksort(ids, dists, i, right);
			quicksort(ids, dists, left, j-1);
		} else {
			quicksort(ids, dists, left, j-1);
			quicksort(ids, dists, i, right);
		}
	}
}

function swap(arr:Uint32Array, i:number, j:number){
	const tmp=arr[i];
	arr[i]=arr[j];
	arr[j]=tmp;
}
