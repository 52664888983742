<div class="flex-row center container">
  <div *ngIf="(allDeviceSeries$ | async) !== undefined && (allDeviceSeries$ | async)===null">
    No Devices
  </div>
  <mat-spinner *ngIf="(allDeviceSeries$ | async) !== undefined && (allDeviceSeries$ | async)!==null && (devices$|async)!==null && (allDeviceSeries$ | async).length<(devices$|async).length" strokeWidth="8" [diameter]="60"></mat-spinner>
</div>
<div class="section end grid-2xn">
  <div class="container" *ngFor="let device of (devices$|async),let i=index">
    <mat-card class="card" *ngIf="(allDeviceSeries$|async) && ((allDeviceSeries$|async).length)>i">
      <snapshot-card-home *ngIf="device && (allDeviceSeries$|async)" [timer]="(minutes|async)+' min '+(sec|async)+' sec'" [deviceName]="device.name" [series]="(allDeviceSeries$|async)[i]">
      </snapshot-card-home>
    </mat-card>
  </div>
</div>