import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'unitConversion',
})
export class UnitConversionPipe implements PipeTransform {
    transform(value: number, targetUnit: string): string {
        switch (targetUnit) {
            case 'millimeter':
                return `${Number(value.toFixed(0))} mm`;
            case 'centimeter':
                return `${value.toFixed(2) } cm`;
            case 'meter':
                return `${value.toFixed(3)} m`;
            case 'inch':
                return `${value.toFixed(3)} in`;
            default:
                return `${value.toFixed(3)} ${targetUnit}`;
        }
    }
}
