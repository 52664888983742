import { CommonModule } from '@angular/common';
import { AfterViewInit, AfterViewChecked, Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, Output, SimpleChanges, ViewChild, ViewContainerRef } from '@angular/core';
import { DateTime } from '../../../date-time';
import { dom } from '../../../dom';
import { math } from '../../../math';
import { BehaviorSubject, Observable, combineLatest, op, taste } from '../../../rxjs';
import { LinearAxis } from '../axis';
import { AxisOpts } from '../types';
import { PlotClickEvent, PlotEvent, Series } from './chart-line.namespace';
import { Plot, ProcessedLine, ProcessedOccurance } from './plot';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { storageLocal } from '../../../storage-local';

interface ProcessedLegend {
	name: string;
	color: string;
}

@Component({
	standalone: true,
	imports: [
		CommonModule,
		MatProgressSpinnerModule,
	],
	selector: 'chart-line',
	templateUrl: './chart-line.component.html',
	styleUrls: ['./chart-line.component.scss'],
})
export class ChartLineComponent implements AfterViewInit, AfterViewChecked {
	constructor(private elRef: ElementRef) { }
	@Input() legend:boolean = true;
	@Input() tooltip:boolean=false;
	@Input() showLoaders:boolean=false;
	@Input() scroll: boolean = false;
	@Input() scrollTrackWidth: number;
	@Input() scrollBarWidth: number;


	@Input()
	get series() {
		return this.series$.value;
	}
	set series(v) {
		this.series$.next(v);
	}
	@Input()
	get xAxis() {
		return this.xAxisOpts$.value;
	}
	set xAxis(v) {
		this.xAxisOpts$.next(v);
	}
	@Input()
	get yAxis() {
		return this.yAxisOpts$.value;
	}
	set yAxis(v) {
		this.yAxisOpts$.next(v);
	}
	@Input()
	get xHighlight() {
		return this.xHighlight$.value;
	}
	set xHighlight(v) {
		this.xHighlight$.next(v);
	}

	@Output('plotClick') plotClick = new EventEmitter<PlotClickEvent>();
	@Output('plotMouseMove') plotMouseMove = new EventEmitter<PlotEvent>();
	@Output('plotMouseOver') plotMouseOver = new EventEmitter<PlotEvent>();
	@Output() addData = new EventEmitter<string>();

	private xHighlight$ = new BehaviorSubject<math.Range | DateTime.Range>(null);
	private addDataAt:string=null;
	public showBeginLoader: boolean = false;
	public showEndLoader: boolean = false;
	private plotlength:number=0;
	private plotScrollPosition:number=0;
	public tooltipImageUrl:string='';
	public tooltipDisplay:string='';

	private readonly series$ = new BehaviorSubject<Series[]>([]);
	private readonly xAxisOpts$ = new BehaviorSubject<AxisOpts>({
		name: null,
		dataType: 'number',
	});
	private readonly yAxisOpts$ = new BehaviorSubject<AxisOpts>({
		name: null,
		dataType: 'number',
	});

	@ViewChild('xAxis') xAxisEle: ElementRef<HTMLElement>;
	@ViewChild('yAxis') yAxisEle: ElementRef<HTMLElement>;
	@ViewChild('plot') plotEle: ElementRef<HTMLElement>;
	@ViewChild('tooltip') tooltipEle: ElementRef<HTMLElement>;

	private readonly yAxisSize$ = new dom.SizeObservable({ resizeOnly: true });
	private readonly xAxisSize$ = new dom.SizeObservable({ resizeOnly: true });

	@HostListener('scroll', ['$event'])
	onPlotScroll(event: Event): void {
		if (this.scroll) {
			const element = event.target as HTMLElement;
			this.plotScrollPosition=element.scrollLeft;
			storageLocal.set('scrollLeft',element.scrollLeft);
			if (element.scrollLeft === 0) {
				this.plotlength=this.plotEle.nativeElement.clientWidth;
				this.addData.emit('begin');
				this.addDataAt='begin';
				this.showBeginLoader = true;
			}
			if (element.scrollLeft + this.elRef.nativeElement.clientWidth >= element.scrollWidth-10) {
				this.addData.emit('end');
				this.addDataAt='end';
				this.showEndLoader = true;
			}
		}
	}

	public readonly legend$: Observable<ProcessedLegend[]> = this.series$
		.pipe(
			op.debounceTime(0),
			op.map((lines) => {
				lines ??= [];
				return lines
					.filter((line) => line.name)
					.map((line): ProcessedLegend => ({
						name: line.name,
						color: line.color.toString(),
					}))
					.map((v) => JSON.stringify(v))
					.unique()
					.map((v) => JSON.parse(v));
			})
		);

	public readonly plot$ = combineLatest([this.xAxisOpts$, this.yAxisOpts$, this.series$]).pipe(
		op.debounceTime(1),
		op.tap(([xAxis, yAxis, lines]) => {
			// to hide loaders
			if(this.scroll && this.showLoaders){
				this.showBeginLoader = false;
				this.showEndLoader = false;
				if(this.addDataAt==='begin'){
					this.elRef.nativeElement.scrollLeft=((this.plotEle.nativeElement.clientWidth-this.plotlength)/parseInt(storageLocal.get('elementLength'))*100*parseInt(storageLocal.get('elementLength'))/100);
				}
			}
		}),
		op.map(([xAxis, yAxis, lines]) => new Plot([xAxis.dataType, yAxis.dataType], lines)),
		op.startWith(new Plot(['number', 'number'], [])),
		op.shareReplay(1)
	);

	public readonly xAxis$ = combineLatest([this.plot$, this.xAxisOpts$, this.xAxisSize$]).pipe(
		op.debounceTime(0),
		op.map(([plot, opts]) =>
			new LinearAxis({
				...opts,
				ele: this.xAxisEle.nativeElement,
				dataRange: plot.dataRanges[0],
				toData: plot.toData[0],
			})
		),
		op.startWith(new LinearAxis()),
		op.shareReplay(1)
	);

	public readonly yAxis$ = combineLatest([this.plot$, this.yAxisOpts$, this.yAxisSize$]).pipe(
		op.debounceTime(0),
		op.map(([plot, opts]) =>
			new LinearAxis({
				...opts,
				ele: this.yAxisEle.nativeElement,
				dataRange: plot.dataRanges[1],
				toData: plot.toData[1],
			})
		),
		op.startWith(new LinearAxis()),
		op.shareReplay(1)
	);

	public readonly plotTransform$ = combineLatest([this.xAxis$, this.yAxis$]).pipe(
		op.map(([xAxis, yAxis]) => {
			const xScale = xAxis.plotRange.size();
			const yScale = yAxis.plotRange.size();
			return [
				`translate(0 1)`,
				`scale(1 -1)`,
				`translate(${xAxis.plotRange.min} ${yAxis.plotRange.min})`,
				`scale(${xScale} ${yScale})`,
			].join(' ');
		})
	);


	public readonly xHighlightBox$ = combineLatest([this.xHighlight$, this.xAxis$]).pipe(
		op.map(([range, xAxis]) => {
			if (!(range && xAxis.axisRange)) return null;
			const min = xAxis.axisRange.getParameter(range.min);
			const max = xAxis.axisRange.getParameter(range.max);
			return {
					left: min * 100 + '%',
					right: (1 - max) * 100 + '%',
					width: (max - min) * 100 + '%',
			};
		})
	);

	ngAfterViewInit(): void {
		this.xAxisSize$.observe(this.xAxisEle.nativeElement);
		this.yAxisSize$.observe(this.yAxisEle.nativeElement);
	}

	ngAfterViewChecked(){
		if(this.scroll){
			// to retain scrollbar length and position
			if(this.elRef.nativeElement.clientWidth!=0){
				storageLocal.set('elementLength',this.elRef.nativeElement.clientWidth);
			}
			
			if (this.elRef && this.plotEle && this.xAxisEle) {
				if(this.scrollBarWidth && this.scrollTrackWidth){
					var width = ((this.scrollTrackWidth / this.scrollBarWidth) * storageLocal.get('elementLength')).toString() + 'px';
					this.plotEle.nativeElement.style.width = width;
					this.xAxisEle.nativeElement.style.width = width;
				}
			}
	
			if(this.elRef.nativeElement.scrollLeft===0){
				if(this.plotScrollPosition){
					this.elRef.nativeElement.scrollLeft=this.plotScrollPosition;
				}else{
					this.elRef.nativeElement.scrollLeft=storageLocal.get('scrollLeft');
				}
			}
		}
	}

	private mouseEventToPlotEvent(evt: MouseEvent, target: ProcessedLine | ProcessedOccurance): PlotEvent {
		const plotEle = this.plotEle?.nativeElement;
		if (plotEle) {
			const plotTransformEle = <SVGGElement>plotEle.querySelector('g.plot-transform');
			if (plotTransformEle) {
				const plot = taste(this.plot$);
				if (plot) {
					let p = new DOMPoint(evt.x, evt.y);
					const m = plotTransformEle.getScreenCTM().inverse();
					p = p.matrixTransform(m);
					return plot.mouseEventToPlotEvent(p, target);
				}
			}
		}
		return null;
	}

	public onPlotClick(_evt: MouseEvent, target: ProcessedLine | ProcessedOccurance) {
		_evt.stopPropagation();
		const evt = <PlotClickEvent>this.mouseEventToPlotEvent(_evt, target);
		if (evt) {
			evt.button = _evt.button;
			this.plotClick.emit(evt);
		}
	}

	public onPlotMouseOver(_evt: MouseEvent, target: ProcessedLine | ProcessedOccurance) {
		_evt.stopPropagation();
		const evt = this.mouseEventToPlotEvent(_evt, target);
		if (evt) {
			this.plotMouseOver.emit(evt);
		}
	}

	public onPlotMouseMove(_evt: MouseEvent, target: ProcessedLine | ProcessedOccurance) {
		_evt.stopPropagation();
		
		if(this.tooltip && this.tooltipEle){
			if((_evt.clientX+(1.2*this.tooltipEle.nativeElement.clientWidth))>=this.elRef.nativeElement.clientWidth+this.elRef.nativeElement.getBoundingClientRect().x){

				this.tooltipEle.nativeElement.style.left='-'+this.tooltipEle.nativeElement.clientWidth+'px'
			}else{
				this.tooltipEle.nativeElement.style.left='20px'
			}
		}
		
		const evt = this.mouseEventToPlotEvent(_evt, target);
		if (evt) {
			this.plotMouseMove.emit(evt);
			let dataPoint;
			if(evt.series){
				dataPoint =evt.series.data[Math.floor(evt.dataIndex)+1];
			}
            if (dataPoint) {
                const interval = dataPoint[2];
                if (interval) {
                    this.tooltipDisplay='block';
                    this.tooltipImageUrl=interval.imageUrl;
                    this.xHighlight = new DateTime.Range(interval.begin, interval.end);
                }
            }
		}
	}

	public onPlotMouseOut(_evt: MouseEvent, target: ProcessedLine | ProcessedOccurance) {
		_evt.stopPropagation();
		const evt = this.mouseEventToPlotEvent(_evt, target);
		if (evt) {
			this.tooltipDisplay='none';
			this.plotMouseOver.emit(evt);
		}
	}
}

interface ProcessedLegend {
	name: string;
	color: string;
}

