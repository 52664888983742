import {math} from "../math";
import {Color} from "./color";
import {isValidNumber} from "../number";
import {NumberToColorMap} from "./number-to-color-map";

const tmpV2A=new math.Vec2();
const tmpV2B=new math.Vec2();

export class NumberToColorMapStepped extends NumberToColorMap{
	public constructor(
		positions:number[],
		colors:Color[],
	){
		super(positions,colors);
		if(this.positions.at(0)===null)
			this.positions[0]=-Infinity;
		if(this.positions.at(-1)===null)
			this.positions[this.positions.length-1]=Infinity;
		const length=Math.min(this.positions.length,this.colors.length+1);
		this.positions.length=length;
		this.colors.length=length-1;
	}

	public clone(){
		return <this>new NumberToColorMapStepped(this.positions.slice(),this.colors.map(c=>c.clone()));
	}

	public isValid(){
		if(!Array.isArray(this.positions))
			return false;
		if(!Array.isArray(this.colors))
			return false;
		if(this.positions.length!==this.colors.length+1)
			return false;
		for(const p of this.positions){
			if(!isValidNumber(p))
				return false;
		}
		for(const c of this.colors){
			if(!(c instanceof Color))
				return false;
		}
		return true;
	}

	public clean(){
		const map=new Map<number,Color>();
		let q:number|undefined;
		for(const [i,p] of this.positions.entries()){
			if(isValidNumber(p) && (q===undefined || q<=p)){
				map.set(p,this.colors[i] ?? null);
				if(!this.colors[i])
					break;
			}
			q=p;
		}

		const positions=[...map.keys()];
		const colors=[...map.values()].filter(v=>!!v);
		return new NumberToColorMapStepped(positions,colors);
	}

	public get length(){
		return this.positions.length;
	}

	public set length(v:number){
		while(this.positions.length<v)
			this.positions.push(this.positions.at(-1)!);
		this.positions.length=v;
		v=Math.min(v-1);
		while(this.colors.length<v)
			this.colors.push(this.colors.at(-1)!);
		this.colors.length=v;
	}

	public *entries(){
		for(let i=1;i<this.positions.length;++i)
			yield <[number,number,Color]>[this.positions[i-1],this.positions[i],this.colors[i-1]];
	}

	public [Symbol.iterator](){
		return this.entries();
	}

	public delete(index:number){
		if(0<=index && index<this.positions.length){
			this.positions.splice(index,1);
			this.colors.splice(index,1);
			this.colors.length=this.positions.length-1;
			return true;
		}
		return false;
	}

	public positionToIndex(v:number){
		for(let i=0;i<this.positions.length;++i){
			if(v<this.positions[i])
				return i-1;
		}
		return this.positions.length;
	}

	public atPosition(v:number){
		const i=this.positionToIndex(v);
		return this.colors[i] ?? null;
	}

	public atPositionRange(v:number, u:number){
		if(v>u)
			[v,u]=[u,v];

		const vi=this.positionToIndex(v);
		const ui=this.positionToIndex(u);
		if(vi===ui)
			return this.colors[vi];

		let weight=this.positions[vi+1]-v;
		let c=this.colors[vi];
		let weightSum=weight;
		let hueVector=math.azimuth.toVector(c.hue()*math.D2R,tmpV2A).multiplyScalar(weight);
		let satSum=c.saturation()*weight;
		let valSum=c.value()*weight;
		let opacSum=c.alpha()*weight;

		weight=u-this.positions[ui];
		c=this.colors[ui],weight;
		weightSum+=weight;
		hueVector.addScaledVector(math.azimuth.toVector(c.hue()*math.D2R,tmpV2B),weight);
		satSum+=c.saturation()*weight;
		valSum+=c.value()*weight;
		opacSum+=c.alpha()*weight;

		for(let i=vi+1;i<ui;++i){
			weight=this.positions[i+1]-this.positions[i];
			c=this.colors[i],weight;
			weightSum+=weight;
			hueVector.addScaledVector(math.azimuth.toVector(c.hue()*math.D2R,tmpV2B),weight);
			satSum+=c.saturation()*weight;
			valSum+=c.value()*weight;
			opacSum+=c.alpha()*weight;
		}
		return Color.hsv(math.azimuth.from(hueVector)*math.R2D,satSum/weightSum,valSum/weightSum,opacSum/weightSum);
	}

	public slice(begin:number, end:number){
		const positions:number[]=[];
		const colors:Color[]=[];
		for(let [a,b,color] of this){
			a=math.max(begin,a);
			b=math.min(end,b);
			if(a<b){
				if(positions.at(-1)!==a)
					positions.push(a);
				positions.push(b);
				colors.push(color.clone());
			}
		}
		return new NumberToColorMapStepped(
			positions,
			colors,
		);
	}

	public toCssGradient(sideOrCorner:string):string{
		if(this.colors.length===0)
			return 'none';

		const steps:string[]=[];
		for(let [a,b,color] of this){
			if(a===-Infinity){
				steps.push(`${color.toString()} ${b*100}%`);
			}else if(b===+Infinity){
				steps.push(`${color.toString()} ${a*100}%`);
			}else{
				steps.push(`${color.toString()} ${a*100}% ${b*100}%`);
			}
		}
		return `linear-gradient(${sideOrCorner},${steps.join(',')})`;
	}

	public toJson(){
		return {
			positions: this.positions.map(v=>{
				if(v===-Infinity)
					return '-∞';
				if(v===+Infinity)
					return '+∞';
				return v;
			}),
			colors: this.colors.map(c=>c.html())
		}
	}

	public extendToInfinity(
		colorA:Color,
		colorB:Color,
	){
		if(this.positions.at(0)!=-Infinity){
			this.positions.unshift(-Infinity);
			this.colors.unshift(colorA);
		}
		if(this.positions.at(-1)!=Infinity){
			this.positions.push(Infinity);
			this.colors.push(colorB);
		}
		return this;
	}

	public retractFromInfinity(){
		if(this.positions.at(0)===-Infinity){
			this.positions.shift();
			this.colors.shift();
		}
		if(this.positions.at(-1)===+Infinity){
			this.positions.pop();
			this.colors.pop();
		}
		return this;
	}

	public insideRange(v:number){
		return this.positions[0]<=v && v<=this.positions.at(-1)!;
	}
}

export namespace NumberToColorMapStepped{
	export function fromJson(json:NumberToColorMap.JSON){
		const positions=json.positions.map(v=>{
			if(v==='-∞')
				return -Infinity;
			if(v==='+∞')
				return +Infinity;
			return v;
		});
		return new NumberToColorMapStepped(positions,json.colors.map(c=>Color.html(c)));
	}
	NumberToColorMap.fromJSONExtension.push(fromJson);
}
