import { Component, HostBinding, Input, OnChanges, OnInit } from '@angular/core';
import { User } from '@models';
import { ReportService } from '../report.service';

@Component({
	selector: 'report-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss']
})
export class ReportHeaderComponent implements OnChanges,OnInit {
	@Input() title='';
	@Input() subTitle='';
	@Input() includeProjectName=false;
	@Input() companyLogoHeight='1em';

	// public get company$(){return this.user.company$;}
	public readonly projectName:string;


	constructor(
		private readonly report:ReportService,

	){
		this.projectName='projectName'
	}

	ngOnInit(): void {
		if(!this.title)
			this.title=this.report.title;
	}

	ngOnChanges(){
		if(!this.title)
			this.title=this.report.title;
	}

	@HostBinding('class.has-sub-title') get hasSubTitle(){
		return !!this.subTitle;
	}
}
