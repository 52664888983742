import { ClipboardModule } from '@angular/cdk/clipboard';
import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS,MatFormFieldDefaultOptions,MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatChipsModule } from '@angular/material/chips';
import { MatTreeModule } from '@angular/material/tree';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { userProvider } from '@injectables';
import { ChartBarComponent } from '@lib/angular/chart/bar/chart-bar.component';
import { ChartLineComponent } from '@lib/angular/chart/line/chart-line.component';
import { ColorPickerComponent } from '@lib/angular/color-picker/color-picker.component';
import { DateTimePickerComponent } from '@lib/angular/date-time-picker/date-time-picker.component';
import { TimePickerComponent } from './new-ui/shared/time-picker/time-picker.component' 
import { DatePickerComponent } from './new-ui/shared/date-picker/date-picker.component';
import { MeasurementInputComponent } from '@lib/angular/measurement/measurement-input.component';
import { MeasurementComponent } from '@lib/angular/measurement/measurement.component';
import { PowerBIEmbedModule } from 'powerbi-client-angular';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DashboardComponent } from './old-ui/dashboard/dashboard.component';
import { DashboardLiveFeedComponent } from './old-ui/dashboard/live-feed/dashboard-live-feed.component';
import { DashboardPassingPercentSelectorComponent } from './old-ui/dashboard/passing-percent-selector/dashboard-passing-percent-selector.component';
import { DashboardPsdIntervalDialog } from './old-ui/dashboard/psd-interval-dialog/dashboard-psd-interval-dialog.component';
import { DashboardPsdTimelineComponent } from './old-ui/dashboard/psd-timeline/dashboard-psd-timeline.component';
import { DashboardQuantityTimelineComponent } from './old-ui/dashboard/quantity-timeline/dashboard-quantity-timeline.component';
import { DashboardSieveSizeAtPercentComponent } from './old-ui/dashboard/sieve-size-at-percent/dashboard-sieve-size-at-percent.component';
import { DashboardTagsDisplayComponent } from './old-ui/dashboard/tags-display/dashboard-tags-display.component';
import { DashboardTagsComponent } from './old-ui/dashboard/tags/dashboard-tags.component';
import { DatabaseComponent } from './old-ui/database/database.component';
import { DatabaseFieldComponent } from './old-ui/database/prop/database-field.component';
import { DialogColorPickerComponent } from './old-ui/dialog/dialog-color-picker/dialog-color-picker.component';
import { DialogConfirmComponent } from './old-ui/dialog/dialog-confirm/dialog-confirm.component';
import { DialogHtmlComponent } from './old-ui/dialog/dialog-html/dialog-html.component';
import { DialogInputComponent } from './old-ui/dialog/dialog-input/dialog-input.component';
import { DialogMessageComponent } from './old-ui/dialog/dialog-message/dialog-message.component';
import { DialogReflectComponent } from './old-ui/dialog/dialog-reflect/dialog-reflect.component';
import { DialogSpinnerComponent } from './old-ui/dialog/dialog-spinner/dialog-spinner.component';
import { GuiTestComponent } from './old-ui/gui-test/gui-test.component';
import { LoginComponent } from './new-ui/auth/login/login.component';
import { ManageClientsComponent } from './old-ui/manage/clients/manage-clients.component';
import { ManageDevicesComponent } from './old-ui/manage/devices/manage-devices.component';
import { ManageComponent } from './new-ui/manage/manage.component';
import { ManageUsersComponent } from './old-ui/manage/users/manage-users.component';
import { PageNotFoundComponent } from './old-ui/page-not-found/page-not-found.component';
import { QueriesComponent } from './old-ui/queries/queries.component';
import { BannerComponent } from './shared/banner/banner.component';
import { ClientEditDialog } from './shared/edit-dialog/client/client-edit-dialog.component';
import { DataIntervalTagEditDialog } from './shared/edit-dialog/data-interval-tag/data-interval-tag-edit-dialog.component';
import { DeviceEditDialog } from './shared/edit-dialog/device/device-edit-dialog.component';
import { UserEditDialog } from './shared/edit-dialog/user/user-edit-dialog.component';
import { ModelTableActionsDirective } from './shared/model-table/model-table-actions.directive';
import { ModelTableHeaderDirective } from './shared/model-table/model-table-header.directive';
import { ModelTableRowDirective } from './shared/model-table/model-table-row.directive';
import { ModelTableComponent } from './shared/model-table/model-table.component';
import { NavigationComponent } from './shared/navigation/navigation.component';
import { RegisterDeviceDialog } from './shared/register-device-dialog/register-device-dialog.component';
import { TimelineQueryControlsComponent } from './shared/timeline-query-controls/timeline-query-controls.component';
import { NewUiComponent } from './new-ui/new-ui.component';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCardModule } from '@angular/material/card';
import { HomeComponent } from './new-ui/home/home.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { HighchartsChartModule } from 'highcharts-angular';
import { ScatterPlotComponent } from './new-ui/shared/scatter-plot/scatter-plot.component';
import { SidebarComponent } from './new-ui/shared/sidebar/sidebar.component';
import { LiveTagSettingsComponent } from './new-ui/manage/live-tag-settings/live-tag-settings.component';
import { SnapshotCardHomeComponent } from './new-ui/shared/snapshot-card-home/snapshot-card-home.component';
import { CardSquareBlocksComponent } from './new-ui/shared/card-square-blocks/card-square-blocks.component';
import { ColorPaletteComponent } from './new-ui/shared/color-palette/color-palette.component';
import { CameraSnapshotComponent } from './new-ui/shared/camera-snapshot/camera-snapshot.component';
import { SnapshotCardAnalysisComponent } from './new-ui/shared/snapshot-card-analysis/snapshot-card-analysis.component';
import { PSDChartComponent } from './new-ui/shared/analysis-chart/psd-chart.component';
import { detailsTableComponent } from './new-ui/shared/table/table.component';
import { UserPreferencesComponent } from './new-ui/user/user-preferences/user-preferences.component';
import { MatTabsModule } from '@angular/material/tabs';
import { ReactiveFormsModule } from '@angular/forms';
import { DialogAddLivetagComponent } from './old-ui/dialog/dialog-add-livetag/dialog-add-livetag.component';
import { DialogReportPropertiesComponent } from './old-ui/dialog/dialog-report-properties/dialog-report-properties.component';
import { AuthComponent } from './new-ui/auth/auth.component';
import { PasswordRecoveryComponent } from './new-ui/auth/password-recovery/password-recovery.component';
import { NewPasswordComponent } from './new-ui/auth/new-password/new-password.component';
import { NavbarComponent } from './new-ui/shared/navbar/navbar.component';
import { PercentSelectorComponent } from './new-ui/shared/percent-selector/percent-selector.component';
import { LogDetailsComponent } from './new-ui/log-details/log-details.component';
import { AnalysisComponent } from './new-ui/analysis/analysis.component';
import { LiveTagDetailsComponent } from './new-ui/shared/live-tag-details/live-tag-details.component';
import { ClientsComponent } from './new-ui/manage/clients/clients.component';
import { DevicesComponent } from './new-ui/manage/devices/devices.component';
import { UsersComponent } from './new-ui/manage/users/users.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { UserComponent } from './new-ui/user/user.component';
import { ApiSettingsComponent } from './new-ui/user/api-settings/api-settings.component';
import { UnitConversionPipe } from './shared/pipes/unit-converter.pipe';
import { ReportComponent } from './utilities/report/report.component';
import { ReportPageTitleComponent } from './utilities/report/page-title/report-page-title.component';
import { ReportPageComponent } from './utilities/report/page/page.component';
import { ReportPageFooterComponent } from './utilities/report/page-footer/page-footer.component';
import { ReportPageContainerComponent } from './utilities/report/page-container/report-page-container.component';
import { ImageContainerComponent } from './utilities/report/image-container/image-container.component';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { ReportHeaderComponent } from './utilities/report/header/header.component';
import { ReportTimelinePageComponent } from './utilities/report/timeline-page/timeline-page.component';
import { FragCameraReportComponent } from './new-ui/reports/frag-camera-report/frag-camera-report.component';
import { environment } from '@environment';
import * as Sentry from "@sentry/angular-ivy";
import { Router } from '@angular/router';
import { ShiftAnalyticsComponent } from './new-ui/shift-analytics/shift-analytics.component';
import { ShiftEditDialogComponent } from './shared/edit-dialog/shift/shift-edit-dialog.component';
import { ShiftsComponent } from './new-ui/manage/shifts/shifts.component';
import { AggregatedDataReportComponent } from './new-ui/reports/aggregated-data-report/aggregated-data-report.component';
import { ReportsComponent } from './new-ui/reports/reports.component';
import { TargetIntervalsComponent } from './new-ui/manage/target-intervals/target-intervals.component';
import { FiltersComponent } from './new-ui/shared/filters/filters.component';
import { ChunkPipe } from './shared/pipes/chunk.pipe';
import {ScrollingModule} from '@angular/cdk/scrolling';
import { VideoStreamingComponent } from './new-ui/video-streaming/video-streaming.component';
import { DialogProgressComponent } from './old-ui/dialog/dialog-progress/dialog-progress.component';

const matFormFieldDefaultOptions: MatFormFieldDefaultOptions={
	appearance: 'outline'
}

export let errorHandlerFactory = () => {
	return environment["sentryUrl"] ? Sentry.createErrorHandler({
		showDialog: false,
	  }) : new ErrorHandler();
}

@NgModule({
	declarations: [
		AppComponent,
		BannerComponent,
		ClientEditDialog,
		DashboardComponent,
		DataIntervalTagEditDialog,
		DashboardLiveFeedComponent,
		DashboardPassingPercentSelectorComponent,
		DashboardPsdIntervalDialog,
		DashboardPsdTimelineComponent,
		DashboardQuantityTimelineComponent,
		DashboardSieveSizeAtPercentComponent,
		DashboardTagsComponent,
		DashboardTagsDisplayComponent,
		DatabaseComponent,
		DatabaseFieldComponent,
		DeviceEditDialog,
		DialogColorPickerComponent,
		DialogConfirmComponent,
		DialogInputComponent,
		DialogHtmlComponent,
		DialogProgressComponent,
		DialogMessageComponent,
		DialogReflectComponent,
		DialogSpinnerComponent,
		DialogAddLivetagComponent,
		DialogReportPropertiesComponent,
		GuiTestComponent,
		LoginComponent,
		DeviceEditDialog,
		ManageDevicesComponent,
		ManageClientsComponent,
		ManageComponent,
		ManageUsersComponent,
		ModelTableComponent,
		ModelTableActionsDirective,
		ModelTableHeaderDirective,
		ModelTableRowDirective,
		NavigationComponent,
		PageNotFoundComponent,
		QueriesComponent,
		RegisterDeviceDialog,
		TimelineQueryControlsComponent,
		NewUiComponent,
		UserEditDialog,
        HomeComponent,
		ScatterPlotComponent,
		SidebarComponent,
		NavbarComponent,
		LogDetailsComponent,
		LiveTagSettingsComponent,
		SnapshotCardHomeComponent,
		CardSquareBlocksComponent,
		ColorPaletteComponent,
		CameraSnapshotComponent,
		SnapshotCardAnalysisComponent,
		AnalysisComponent,
		PSDChartComponent,
  		detailsTableComponent,
		UserComponent,
		ApiSettingsComponent,
        UserPreferencesComponent,
		AuthComponent,
		LoginComponent,
		DatePickerComponent,
		PasswordRecoveryComponent,
		NewPasswordComponent,
		PercentSelectorComponent,
        LiveTagDetailsComponent,
        ManageComponent,
        ClientsComponent,
        DevicesComponent,
        UsersComponent,
	    UnitConversionPipe,
		ReportComponent,
		ReportPageTitleComponent,
		ReportPageComponent,
		ReportHeaderComponent,
		ReportPageFooterComponent,
		ReportPageContainerComponent,
		ReportTimelinePageComponent,
		ImageContainerComponent,
		FragCameraReportComponent,
		ShiftAnalyticsComponent,
		ShiftEditDialogComponent,
		ShiftsComponent,
		AggregatedDataReportComponent,
		ReportsComponent,
		TargetIntervalsComponent,
		FiltersComponent,
		ChunkPipe,
		VideoStreamingComponent
	],
	imports: [
		AppRoutingModule,
		BrowserAnimationsModule,
		BrowserModule,
		ChartBarComponent,
		ChartLineComponent,
		ClipboardModule,
		ColorPickerComponent,
		DateTimePickerComponent,
		TimePickerComponent,
		FormsModule,
		HttpClientModule,
		MatAutocompleteModule,
		MatButtonModule,
		MatCheckboxModule,
		MatDatepickerModule,
		MatDialogModule,
		MatFormFieldModule,
		MatListModule,
		MatIconModule,
		MatInputModule,
		MatMenuModule,
		MatNativeDateModule,
		MatPaginatorModule,
		MatProgressBarModule,
		MatProgressSpinnerModule,
		MatSelectModule,
		MatSnackBarModule,
		MatTableModule,
		MatTooltipModule,
		MatChipsModule,
		MatTreeModule,
		MeasurementComponent,
		MeasurementInputComponent,
		PowerBIEmbedModule,
		MatGridListModule,
		MatCardModule,
		MatSlideToggleModule,
		MatSliderModule,
		MatSidenavModule,
		MatToolbarModule,
		HighchartsChartModule,
		MatTabsModule,
		ReactiveFormsModule,	
		MatMenuModule,
		MatSnackBarModule,
		MatButtonToggleModule,
		MatBottomSheetModule,
		ScrollingModule
	],
	providers: [
		{
			provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
			useValue: matFormFieldDefaultOptions
		},
		{ provide: ErrorHandler, useFactory: errorHandlerFactory },
		{
			provide: Sentry.TraceService,
			deps: [Router],
		},
		{
			provide: APP_INITIALIZER,
			useFactory: () => () => {},
			deps: [Sentry.TraceService],
			multi: true,
		},
		userProvider,
	],
	bootstrap: [AppComponent]
})
export class AppModule { }
