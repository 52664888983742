<div>
    <mat-nav-list class="nav-list">
        <mat-list-item class="matlist" [routerLinkActive]="['matlist-active']" [routerLink]="['home']">
            <mat-icon svgIcon="home" class="maticon" [routerLinkActive]="['matlist-icon-active']"></mat-icon>
            <mat-icon svgIcon="home" class="maticon" [routerLink]="['details']"
                [routerLinkActive]="['matlist-icon-active']"></mat-icon>
        </mat-list-item>
        <mat-list-item class="matlist" [routerLink]="['analysis']" [routerLinkActive]="['matlist-active']">
            <mat-icon svgIcon="analysis" class="maticon" [routerLinkActive]="['matlist-icon-active']"></mat-icon>
        </mat-list-item>
        <mat-list-item class="matlist" [routerLink]="['settings']" [routerLinkActive]="['matlist-active']">
            <mat-icon svgIcon="settings" class="maticon" [routerLinkActive]="['matlist-icon-active']"></mat-icon>
        </mat-list-item>
    </mat-nav-list>
</div>