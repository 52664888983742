import {Directive,TemplateRef} from '@angular/core';

@Directive({
	selector: 'ng-template[model-table-header]',
})
export class ModelTableHeaderDirective{
	public constructor(
		public templateRef:TemplateRef<void>
	){
	}
}
