export namespace async{
	export function timeout(delay:number){
		return new Promise<void>(resolve=>setTimeout(resolve,delay));
	}

	export function _yield(){
		return new Promise<void>(resolve=>setTimeout(resolve,0));
	}

	export function animationFrame(){
		if(requestAnimationFrame)
			return new Promise<void>(resolve=>requestAnimationFrame(()=>{resolve();}));
		throw Error('animationFrame only works in browser');
	}
}
