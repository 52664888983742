export function downloadFromUrl(
	url:string,
	filename?:string
){
	const link=document.createElement('a');
	if(filename!==undefined)
		link.download=filename;
	if(url.endsWith('.jpg') || url.endsWith('.png'))
		link.target='_blank';
	link.href=url;
	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);
}

export function cleanFilename(filename:string, ext:string='', spaceSub='-'){
	filename=filename.trim();
	filename=filename.replace(new RegExp(`[\\s\\${spaceSub}]+`,'g'),spaceSub);
	filename=filename.replace(/[^a-zA-Z0-9_-]+/g,'');
	if(ext!=='' && ext[0]!=='.')
		ext='.'+ext;
	return filename+ext;
}

export function downloadContent(filename:string, contents:string|Blob, contentType='text/plain') {
	const link=document.createElement('a');
	link.download=filename;

	const blob=(contents instanceof Blob)?contents:new Blob([contents], { type: contentType });
	link.href=window.URL.createObjectURL(blob);
	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);
}
