import {DateTime} from "@lib/date-time";
import {op} from "@lib/rxjs";
import {WebClient} from "@lib/web-client";
import {DataIntervalTag} from "@models";
import {RestModel} from "./rest-model";

export class RestModelDataIntervalTag extends RestModel<DataIntervalTag,DataIntervalTag.RestTypes>{
	public constructor(webClient:WebClient){
		super(DataIntervalTag,webClient);
	}

	public async getLive(deviceId:number){
		const rows=await this.search({
			where: {
				deviceId: deviceId,
				end: null,
			},
			sort: {
				begin: '-',
			}
		});
		return rows?.[0] ?? null;
	}

	public getLive$(deviceId:number){
		return this.search$({
			where: {
				deviceId: deviceId,
				end: null,
			},
			sort: {
				begin: '-',
			}
		})
		.pipe(
			op.map(rows=>rows?.[0] ?? null));
	}

	public async setLiveTag(
		tag:DataIntervalTag,
	){
		tag.id=null;
		tag.begin=Math.floor(DateTime.now()/1000);
		tag.end=null;
		return await this.save(tag);
	}	
}
