
Number.prototype.toDecimal=function(this:number, decimalCount:number){
	let s=this.toFixed(decimalCount);
	if(decimalCount>0){
		while(s.endsWith('0'))
			s=s.substring(0,s.length-1);
		while(s.endsWith('.'))
			s=s.substring(0,s.length-1);
	}
	return s;
}
