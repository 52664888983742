import { Component } from '@angular/core';

@Component({
  selector: 'dialog-spinner',
  templateUrl: './dialog-spinner.component.html',
  styleUrls: ['./dialog-spinner.component.scss']
})
export class DialogSpinnerComponent {

  constructor() { }

}
