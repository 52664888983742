import {measurement} from "../../measurement";
import {math} from '../../math';

function convertValue(
	value:number,
	offset:number,
	valueUnits:measurement.Unit[],
	units:measurement.Unit[],
){
	value=value+offset;
	for(let i=0;i<units.length;++i){
		const valueUnit=valueUnits[i];
		const unit=units[i];
		if(i===0){
			value=measurement.convert(value,valueUnit,unit);
		}else{
			value=1/measurement.convert(1/value,valueUnit,unit);
		}
	}
	return value;
}

export function formatStr(
	value:number|number[],
	type:string,
	typeToUnit:measurement.TypeToUnit,
	offset:number,
	format:string,
	allowSummarize:boolean,
){
	const types=(type ?? 'count').split('/');
	const valueUnits=types.map(type=>(measurement.typeMeta[<measurement.Type>type] ?? measurement.typeMeta.count).valueUnit);
	const units=types.map(type=>(typeToUnit?.[<measurement.Type>type]) ?? 'none');
	
	if(typeof(value)==='number'){
		value=convertValue(value,offset,valueUnits,units);
		return measurement.formatByStr(format,value,units);
	}
	
	if(Array.isArray(value) && value.length>0){
		const numFormat=format.replace('${u}','').replace('${U}','');
		const values=value.map(v=>convertValue(v,offset,valueUnits,units));
		const stats=math.stats(values);
		if(!allowSummarize || (values.length>2 && values.length<12 && stats.min<stats.mean-stats.standardDeviation*3 && stats.mean+stats.standardDeviation*3<stats.max)){
			return values
				.map(v=>measurement.formatByStr(numFormat,v,units))
				.join(', ');
		}else{
			const min=measurement.formatByStr(numFormat,stats.min,units);
			const max=measurement.formatByStr(numFormat,stats.max,units);
			if(min===max)
				return min;
			return min+' - '+max;
		}
	}
	return '---';
}