import { ElementRef,NgZone } from '@angular/core';
import { async } from '@lib/async';
import { _iterate } from '@lib/iterate';

export class ReportPageScaler{
	public constructor(
		private readonly ngZone:NgZone,
		private readonly host:ElementRef<HTMLElement>,
	){
	}

	public zoom:'fit'|'rows2'|number='fit';
	private scalingEnabled=true;
	private prevHeightToFill=0;
	private prevPageHeight=0;
	private animationFrameHandle=0;
	private styleBlock:HTMLStyleElement;

	public apply(force=true){
		if(this.zoom==='fit' || this.zoom==='rows2'){
			this.toWindow(force);
		}else{
			this.set(this.zoom);
		}
	}

	private pages(){
		const out:HTMLElement[]=[];
		const pages=this.host.nativeElement.querySelectorAll<HTMLElement>('report-page');
		pages.forEach(v=>out.push(v));
		return out;
	}

	private set(scale:number){
		if(!this.styleBlock){
			this.styleBlock=document.createElement('style');
			document.head.append(this.styleBlock);
		}

		this.styleBlock.textContent=
`.page-list report-page{
	transform: scale(${scale});
	margin-right: calc(var(--page-width)*${scale-1});
	margin-bottom: calc(var(--page-height)*${scale-1});
}`;
	}

	private toWindow(force:boolean){
		if(!this.scalingEnabled)
			return;
		if(this.animationFrameHandle)
			return;

		this.ngZone.runOutsideAngular(()=>{
			requestAnimationFrame(()=>{
				this.animationFrameHandle=0;
				if(!this.scalingEnabled)
					return;
				if(!(this.zoom==='fit' || this.zoom==='rows2'))
					return;

				const pageScroller=this.host.nativeElement.querySelector<HTMLElement>('.page-scroller-inner');
				if(!(pageScroller))
					return;

				const pages=this.pages();
				if(pages.length>0){
					const heightToFill=pageScroller.offsetHeight;
					const pageHeight=_iterate.highest(pages,page=>page.offsetHeight).offsetHeight;
					if(!isNaN(heightToFill) && (force || this.prevHeightToFill!==heightToFill || this.prevPageHeight!==pageHeight)){
						this.prevHeightToFill=heightToFill;
						this.prevPageHeight=pageHeight;
						if(pageHeight>0){
							let scale=heightToFill/pageHeight;
							if(this.zoom==='rows2')
								scale/=2;
							this.set(scale);
						}
					}
				}
			});
		});
	}

	public async disable(){
		this.scalingEnabled=false;
		this.set(1);
		await async.animationFrame();
	}

	public enable(){
		this.scalingEnabled=true;
		this.apply(true);
	}
}