import {DateTime} from "../../date-time";
import {iterate} from "../../iterate";
import {math} from "../../math";
import {toNum} from "./helpers";

export class Axis{
	public labels:{
		value:string|number;
		position:number;
		positionLength:number;
	}[]=[];

	public plotRange=new math.Range(0,1);
	public plotLength=1;
}

export class LinearAxis extends Axis{
	public constructor(
		args?:{
			ele:HTMLElement;
			name:string,
			dataType:'number'|'time';
			dataRange:math.Range|DateTime.Range;
			toData:(v:number)=>number|DateTime,
		},
	){
		super();
		if(args){
			this.ele=args.ele;
			this.name=args.name;
			if(args.dataRange){
				if(args.dataRange instanceof DateTime.Range)
					this.initTimeAxis(args.dataRange);
				else
					this.initNumAxis(args.dataRange);
			}
		}
	}

	private readonly ele:HTMLElement;
	public name:string=null;
	public axisRange:math.Range|DateTime.Range;

	private initNumAxis(
		dataRange:math.Range,
	){

		const fontSize=+getComputedStyle(this.ele).fontSize.replace('px','');
		const labelHeight=fontSize*2;
		const steps=calcNumAxisStep(labelHeight,this.ele.clientHeight,dataRange);
		this.axisRange=new math.Range(toNum(steps.at(0)),toNum(steps.at(-1)));

		this.plotRange=new math.Range(this.axisRange.getParameter(dataRange.min),this.axisRange.getParameter(dataRange.max));
		this.plotLength=this.plotRange.size();
		
		let prevPosition=this.axisRange.getParameter(steps.at(0));
		this.labels=steps.map(value=>{
			const position=this.axisRange.getParameter(value);
			const positionLength=position-prevPosition;
			prevPosition=position;
			return {
				value,
				position,
				positionLength,
			};
		});
	}

	private initTimeAxis(
		dataRange:DateTime.Range,
	){
		const fontSize=+getComputedStyle(this.ele).fontSize.replace('px','');
		const labelWidth=fontSize*3;
		const steps=calcTimeAxisSteps(labelWidth,this.ele.clientWidth,dataRange,false);
		this.axisRange=dataRange.clone();

		this.plotRange=new math.Range(0,1);
	
		let prevValue:DateTime;
		let prevPosition=dataRange.getParameter(toNum(dataRange.min));
		const labelText=formatLabelText(steps);
		this.labels=steps.map((value,i)=>{
			const position=dataRange.getParameter(toNum(value));
			const positionLength=position-prevPosition;
			prevValue=value;
			prevPosition=position;
			return {
				value: labelText[i],
				position,
				positionLength,
			};
		});
	}
}

function calcNumAxisStep(
	labelSize:number,
	axisSize:number,
	dataRange:math.Range,
){
	const maxLabelCount=Math.max(1,Math.floor(axisSize/labelSize));
	const log10=Math.log10(dataRange.size());
	const stepLengths=[Math.floor(log10)-1,Math.floor(log10),Math.ceil(log10)]
		.map(log10=>10**log10)
		.flatMap(pow10=>[
			1*pow10,
			2*pow10,
			2.5*pow10,
			5*pow10,
		]);

	const stepPossiblities=stepLengths.map(length=>{
		const begin=Math.floor(dataRange.min/length);
		const end=Math.ceil(dataRange.max/length);
		return {
			length,
			begin,
			end,
			count: begin<=end?end-begin+1:0,
		};
	});

	const step=stepPossiblities.find(v=>0<v.count && v.count<=maxLabelCount);
	if(step){
		step.begin*=step.length;
		step.end*=step.length;
		const steps=[...iterate.numbers(step.begin,step.end,step.length,true)];
		return steps;
	}

	return [dataRange.min,dataRange.max];
}

function calcTimeAxisSteps(
	labelSize:number,
	axisSize:number,
	dataRange:DateTime.Range,
	inclusive:boolean,
){
	//const [valueMin,valueMax]=dataRange;
	const maxLabelCount=Math.max(1,Math.floor(axisSize/labelSize));

	const stepLengths:DateTime.Step[]=[
		[1,'millisecond'],
		[2,'millisecond'],
		[2.5,'millisecond'],
		[5,'millisecond'],
		[10,'millisecond'],
		[20,'millisecond'],
		[25,'millisecond'],
		[50,'millisecond'],
		[100,'millisecond'],
		[200,'millisecond'],
		[250,'millisecond'],
		[500,'millisecond'],
		[1,'second'],
		[5,'second'],
		[15,'second'],
		[30,'second'],
		[1,'minute'],
		[5,'minute'],
		[15,'minute'],
		[30,'minute'],
		[1,'hour'],
		[3,'hour'],
		[6,'hour'],
		[12,'hour'],
		[1,'day'],
		[2,'day'],
		[4,'day'],
		[7,'day'],
		// [1,'month'],
		// [1,'year'],
	];
	const stepPossiblities=stepLengths.map(length=>{
		const interval=dataRange.clone();
		if(inclusive){
			interval.begin.floor(length);
			interval.end.ceil(length);
		}else{
			interval.begin.ceil(length);
			interval.end.floor(length);
		}
		return {
			length,
			begin: interval.begin,
			end: interval.end,
			count: Math.ceil(interval.div(length)),
		};
	});

	let step=stepPossiblities.find(v=>0<v.count && v.count<=maxLabelCount);
	if(step){
		const steps=[...iterate.numbers(0,step.count,1,true)].map(i=>step.begin.clone().add([step.length[0]*i,step.length[1]]));
		return steps;
	}

	return [dataRange.begin.clone(),dataRange.end.clone()];
}

function formatLabelText(values:(number|string|DateTime)[]){
	const out:(number|string)[]=[];
	let prevTime:DateTime;
	for(let value of values){
		if(value instanceof DateTime){
			const opts:Intl.DateTimeFormatOptions={
				hourCycle: 'h23',
				hour:'2-digit',
				minute:'2-digit',
			}
			if(prevTime?.getDate()!==value.getDate()){
				opts.year='2-digit';
				opts.month='numeric';
				opts.day='numeric';
			}
			prevTime=value;
			value=value.toLocaleTimeString(undefined,opts).replace(',','');
		}
		out.push(value);
	}
	return out;
}

export class CategoryAxis extends Axis{
	public constructor(
		args?:{
			ele:HTMLElement;
			name:string,
			categories:(number|string|DateTime)[],
		},
	){
		super();
		if(args){
			this.ele=args.ele;
			this.name=args.name;
			this.init(args.categories);
		}

	}

	private readonly ele:HTMLElement;
	public name:string=null;

	private init(
		categories:(number|string|DateTime)[],
	){

		// const fontSize=+getComputedStyle(this.ele).fontSize.replace('px','');
		// const labelHeight=fontSize*2;
		const labelText=formatLabelText(categories);
		this.labels=categories.map((cat,i)=>{
			return {
				value: labelText[i],
				position: i/categories.length,
				positionLength: 1/categories.length,
			}
		});
	}
}
