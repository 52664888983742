<div #contentWindow class="content-window">
	<div class="content">
		<ng-content></ng-content>
		<report-page-footer *ngIf="footer"></report-page-footer>
	</div>
	<svg
		class="markup-annotations"
		[class.annotate]="report.isAnnotating"
		[style.display]="report.markupAnnotationsVisible?'block':'none'"
		[style.pointerEvents]="report.isAnnotating?'all':'none'"
	>
	</svg>
</div>
<button mat-mini-fab class="no-pdf page-close small-padding" (click)="closed=true">
	<mat-icon>close</mat-icon>
</button>
