
JSON.isPrimitive=function(value):value is number|string|boolean|null{
	return value===null || (typeof(value)!=='object' && typeof(value)!=='function');
};

JSON.isJsonable=function(value:any){
	if(value===null)
		return true;
	if(Array.isArray(value) && value.every(v=>JSON.isJsonable(v)))
		return true;
	if(value instanceof Date)
		return true;
	if(typeof(value)==='object')
		return false;
	if(typeof(value)==='function')
		return false;
	return true;
};

JSON.toJsonable=function(value:any){
	if(JSON.isJsonable(value))
		return JSON.clone(value);
	if(value===undefined)
		return undefined;
	if(value===null)
		return null;
	if(typeof(value)!=='object' && typeof(value)!=='function')
		return value;

	const keys=Object.getOwnPropertyNames(value);
	const src=value;
	const dst:{[key:string]:any}={};
	for(const key of keys){
		const value=src[key];
		if(JSON.isJsonable(value))
			dst[key]=value;
	}
	return dst;
};

JSON.clone=function(src){
	if(src==undefined)
		return undefined;
	const json=JSON.stringify(src);
	return JSON.parse(json);
};
