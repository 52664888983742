import {Component} from '@angular/core';
import {UserEditDialog} from '@app/shared/edit-dialog/user/user-edit-dialog.component';
import {WebClient} from '@injectables';
import {op} from '@lib/rxjs';
import {Client,User} from '@models';

@Component({
	selector: 'app-users',
	templateUrl: './users.component.html',
	styleUrls: ['./users.component.scss']
})
export class UsersComponent{
	constructor(
		private readonly webClient:WebClient,
	){}

	public readonly model=User;
	public readonly restClient=this.webClient.model.user;
	public readonly editDialog=UserEditDialog;
	public readonly deletable=(v:User)=>v.email!=='admin@strayos.com';

	public readonly clients$=this.webClient.model.client
		.search$({})
		.pipe(
			op.map(values=>new Map(values.map(v=>[v.id,v]))),
			op.startWith(new Map<number,Client>()));
}
