import { Component,Input,OnDestroy,OnInit } from "@angular/core";
// import { User } from "@app/user/user.model";
// import { Subscriptions,op } from "rxjs";
import { ReportService } from "../report.service";
import { User } from "@models";

@Component({
	selector: 'report-page-title',
	templateUrl: './report-page-title.component.html',
	styleUrls: ['./report-page-title.component.scss'],
})
export class ReportPageTitleComponent implements OnDestroy, OnInit{
	@Input() title:string=null;
	
	// private readonly subs=new Subscriptions();
	// public customerName=this.user.customerName;
	// public designerName=this.user.designerName;
	// public customerLogoUrl=this.user.customerLogoUrl;
	// public designerLogoUrl=this.user.designerLogoUrl;
	public reporterName:string;
	public reportDate:Date;

	public constructor(
		private readonly user:User,
		private readonly report:ReportService,
	){
	}

	ngOnInit(){
		if(this.user){
			this.reporterName=this.user.firstName+' '+this.user.lastName;
			this.reportDate=new Date();
		}
		// There is one site based report which would not work based of dataset properties.
		// While using report components, check vibration monitoring result report or have a check on active dataset being available.
		// if(this.dataset){
			// this.subs.add(this.report.reportProps$
			// 	.pipe(op.filter(v=>!!v))
			// 	.subscribe(reportProps=>{
			// 		this.title= this.title;
			// 		// this.customerName=reportProps.customerName ?? this.customerName;
			// 		// this.designerName=reportProps.designerName ?? this.designerName;
			// 		// this.customerLogoUrl=reportProps.customerLogoUrl ?? this.customerLogoUrl;
			// 		// this.designerLogoUrl=reportProps.designerLogoUrl ?? this.designerLogoUrl;
			// 		this.reporterSignUrl=reportProps.reporterSignUrl ?? this.reporterSignUrl;
			// 		this.reporterName=reportProps.reporterName ?? this.reporterName;
			// 		this.reporterTitle=reportProps.reporterTitle ?? this.reporterTitle;
			// 		this.reportDate=new Date(reportProps.reportDate ?? this.reportDate ?? new Date());
			// 	}));
		// }
	}

	ngOnDestroy(){
		// this.subs.unsubscribe();
	}
}
