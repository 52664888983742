<div *ngIf="device" class="flex-column middle justify section container">
    <mat-form-field class="mat-pickers fields">
        <mat-label> Device </mat-label>
        <mat-select [value]="device$ | async" (valueChange)="device$.next($event)">
            <mat-option *ngFor="let device of devices$ | async" [value]="device">
                {{ device.name }}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="center">
        <mat-tab label="Regular Intervals">
            <div class="form-content">
                <div class="slide-toggle-box">
                    <mat-slide-toggle
                        [(ngModel)]="preferCustomSizes"
                        class="example-margin"
                        color="primary"
                        [checked]="preferCustomSizes">
                        Apply Custom Sizes Over Regular Target Intervals
                    </mat-slide-toggle>
                </div>
                <mat-form-field class="fields">
                    <mat-label>Minimum Target Size</mat-label>
                    <measurement-input type="particleSize" [unitSystem]="(particleSizeUnitSystem$|async)" [(ngModel)]="minSize"></measurement-input>
                </mat-form-field>
                <mat-form-field class="fields">
                    <mat-label>Maximum Target Size</mat-label>
                    <measurement-input type="particleSize" [unitSystem]="(particleSizeUnitSystem$|async)" [(ngModel)]="maxSize"></measurement-input>
                </mat-form-field>
                <mat-form-field class="fields">
                    <mat-label>No. of Target Intervals</mat-label>
                    <input matInput type="number" [(ngModel)]="intervals" />
                </mat-form-field>
            </div>
        </mat-tab>
        <mat-tab label="Custom Sizes ">
            <div class="form-content">
                <div class="slide-toggle-box">
                    <mat-slide-toggle
                        [(ngModel)]="preferCustomSizes"
                        class="example-margin"
                        color="primary"
                        [checked]="preferCustomSizes">
                        Apply Custom Sizes Over Regular Target Intervals
                    </mat-slide-toggle>
                </div>
                <table mat-table [dataSource]="sizeOptions" class="mat-elevation-z8">
                    <ng-container matColumnDef="minSize">
                        <th mat-header-cell *matHeaderCellDef>Starting Target Size</th>
                        <td mat-cell *matCellDef="let element">
                            <mat-form-field>
                                <measurement-input type="particleSize" [unitSystem]="(particleSizeUnitSystem$|async)" [(ngModel)]="element.minSize"
                                    [value]="element.minSize"></measurement-input>
                            </mat-form-field>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="maxSize">
                        <th mat-header-cell *matHeaderCellDef>Ending Target Size</th>
                        <td mat-cell *matCellDef="let element">
                            <mat-form-field>
                                <measurement-input type="particleSize" [unitSystem]="(particleSizeUnitSystem$|async)" [(ngModel)]="element.maxSize"
                                    [value]="element.maxSize"></measurement-input>
                            </mat-form-field>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="interval">
                        <th mat-header-cell *matHeaderCellDef>Target Intervals</th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.intervals }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="action">
                        <th mat-header-cell *matHeaderCellDef>Action</th>
                        <td mat-cell *matCellDef="let element">
                            <button mat-raised-button color="primary" (click)="removeSizeRange(element)">Remove</button>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="['minSize', 'maxSize','interval', 'action']"></tr>
                    <tr mat-row *matRowDef="let row; columns: ['minSize', 'maxSize','interval', 'action']"></tr>
                </table>
            </div>
            <div style="text-align: center; margin-top: 16px;">
                <button mat-flat-button color="primary" (click)=" toggleAddSizeRange()">
                    Add New
                </button>
            </div> <br />
            <div *ngIf="showAddSizeRange" style="width: 100%; overflow: hidden;" class="">
                <mat-form-field class="min-13 container">
                    <mat-label>Starting Target Size</mat-label>
                    <measurement-input type="particleSize" [unitSystem]="(particleSizeUnitSystem$|async)"
                        [(ngModel)]="newMinSize"></measurement-input>
                </mat-form-field>
                <mat-form-field class="min-13 container">
                    <mat-label>Ending Target Size</mat-label>
                    <measurement-input type="particleSize" [unitSystem]="(particleSizeUnitSystem$|async)"
                        [(ngModel)]="newMaxSize"></measurement-input>
                </mat-form-field>
                <mat-form-field class="min-13 container">
                    <mat-label>No. of Target intervals</mat-label>
                    <input matInput [(ngModel)]="newTargetInterval" type="number" placeholder="Enter interval">
                </mat-form-field>
                <button mat-mini-fab color="primary" class="add-icon" (click)="addCustomSizeRange()">
                    <mat-icon>add_circle_outline</mat-icon>
                </button>
                <br/>
            </div>
        </mat-tab>
    </mat-tab-group>
    <button mat-raised-button color="primary" class="section container" (click)="saveSettings()">
        Save Interval Settings
    </button>
</div>