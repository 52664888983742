<div class="auth-circles top-right">
</div>
<div class="auth-circles bottom-left">
</div>
<div class="flex-column center container card">
  <div>
    <div>
      <div class="flex-row even">
        <img height="60px" src="./../../../assets/mat-icons/strayos.svg">
        <h1 class="title">Strayos Netra</h1>
      </div>
    <div>
    <login></login>
  </div>
</div>