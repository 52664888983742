import {Component} from '@angular/core';
import {ActivatedRoute} from '@angular/router';


@Component({
	selector: 'page-not-found',
	templateUrl: './page-not-found.component.html',
	styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent{
	constructor(
		public readonly route:ActivatedRoute,
	){}

}
