import { Injectable } from "@angular/core";
import { measurement } from "../../measurement";
import { BehaviorSubject, combineLatest, map } from "rxjs";
import { combineLatestInit } from "rxjs/internal/observable/combineLatest";
import { ExtendedTypeMeta, UnitSystem } from "../../measurement/types";

@Injectable({
  providedIn: "root",
}) export class MeasurementTypeToUnit{

  constructor(){
    this.system$.subscribe(system => {
      this.units$.next(this.getUnitsForSystem(system));
    });
  }

  public system$ = new BehaviorSubject<UnitSystem>('metricUnit');
	public readonly units$= new BehaviorSubject(this.getUnitsForSystem(this.system$.value));

  public getUnitsForSystem(system: UnitSystem): measurement.TypeToUnit {
    return Object.fromEntries(
        Object.entries(measurement.typeMeta).map(([key, typeMeta]) => {
            if (system in typeMeta) {
                return [
                    key as keyof measurement.TypeToUnit,
                    (typeMeta as ExtendedTypeMeta)[system]
                ];
            } else {
                // Default to metricUnit or imperialUnit if the system isn't found
                return [
                    key as keyof measurement.TypeToUnit,
                    system.includes('Metric') ? typeMeta.metricUnit : typeMeta.imperialUnit
                ];
            }
        })
    ) as measurement.TypeToUnit;
}


  // new BehaviorSubject(
	// 	<measurement.TypeToUnit>Object.fromEntries(Object.entries(measurement.typeMeta).map(([key,{metricUnit}])=>[key,metricUnit]))
	// );
}
