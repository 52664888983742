import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { DataIntervalTagEditDialog } from '@app/shared/edit-dialog/data-interval-tag/data-interval-tag-edit-dialog.component';
import { WebClient } from '@injectables';
import { FragQueryService } from 'src/injectables/frag-query.service';
import { DataIntervalTag } from '../../../../../../shared-models/public/data-interval-tag';
import { op } from '@lib/rxjs';
import { DateTime } from '@lib/date-time';

@Component({
    selector: 'live-tag-settings',
    templateUrl: './live-tag-settings.component.html',
    styleUrls: ['./live-tag-settings.component.scss'],
})
export class LiveTagSettingsComponent {
    constructor(
        private readonly webClient: WebClient,
        private readonly fragQuery: FragQueryService,
        private readonly router: Router
    ) { }

    public readonly devices$ = this.fragQuery.devices$;
    public readonly device$ = this.fragQuery.device$;
    public readonly particleSizeUnitSystem$ = this.fragQuery.particleSizeUnitSystem$;

    public readonly model = DataIntervalTag;
    public readonly restClient = this.webClient.model.dataIntervalTag;
    public readonly editDialog = DataIntervalTagEditDialog;
    public readonly where$ = this.device$.pipe(
        op.map((device) => ({
            deviceId: device.id,
        }))
    );

    public openLiveTagDialog() {
        this.fragQuery.openLiveTagDialog();
    }

    public openInDTimeline(tag: DataIntervalTag) {
        let url = '/analytics#';
        const timeRange = [new DateTime(1000 * tag.begin)];
        if (tag.end) {
            timeRange.push(new DateTime(1000 * tag.end));
        }
        url += JSON.stringify({ custom: timeRange });
        this.router.navigateByUrl(url);
    }

    public onAdd(resolve:(confirm:Promise<boolean>)=>void){
        resolve(this.fragQuery.openLiveTagDialog(true));
    }
}
