import {Component} from '@angular/core';
import {ClientEditDialog} from '@app/shared/edit-dialog/client/client-edit-dialog.component';
import {WebClient} from '@injectables';
import {Client} from '@models';

@Component({
	selector: 'app-clients',
	templateUrl: './clients.component.html',
	styleUrls: ['./clients.component.scss']
})
export class ClientsComponent {

	constructor(
		private readonly webClient: WebClient,
	) { }

	public readonly model = Client;
	public readonly restClient = this.webClient.model.client;
	public readonly editDialog = ClientEditDialog;
	public readonly deletable = (v: Client) => v.name !== 'Strayos';
}

