import { ElementRef, NgZone } from '@angular/core';
import { dom } from '@lib/dom';
import { math } from '@lib/math';

export class ReportPaginator{
	private paginationHTML=new WeakMap<HTMLElement,string>();
	private pageCount=0;
	private pageNumbers=new WeakMap<Element,number>();
	private readonly pagesElesNeedingUpdating=new Set<HTMLElement>();
	private animationFrame=0;

	constructor(
		private ngZone:NgZone,
		private host:ElementRef<HTMLElement>,
	){
	}

	public reset(){
		this.paginationHTML=new WeakMap<HTMLElement,string>();
		this.pagesElesNeedingUpdating.clear();
	}

	public start(){
		if(!this.host.nativeElement)
			return;

		this.ngZone.runOutsideAngular(()=>{
			const reportPageses=this.host.nativeElement.querySelectorAll('report-pages');
			if(reportPageses.length>0){
				reportPageses.forEach(reportPagesEle=>{
					if(!(reportPagesEle instanceof HTMLElement))
						return;
					if(this.pagesElesNeedingUpdating.has(reportPagesEle))
						return;

					const masterPage=reportPagesEle.querySelector('report-page');
					const wrapper=masterPage.querySelector('.content-window');
					const content=masterPage.querySelector('.content');
					if(!(masterPage instanceof HTMLElement && wrapper instanceof HTMLElement && content instanceof HTMLElement))
						return;
					const html=content.innerHTML;
					if(this.paginationHTML.get(content)===html){
					}else{
						this.paginationHTML.set(content,html);
						wrapper.style.height='100%';
						this.pagesElesNeedingUpdating.add(reportPagesEle);

						const pages=reportPagesEle.querySelectorAll('report-page');
						pages.forEach(page=>{
							if(page!==masterPage)
								page.remove();
						});
					}
				});
				//console.timeEnd('startPagination');
			}
			if(this.pagesElesNeedingUpdating.size>0){
				if(!this.animationFrame)
					requestAnimationFrame(()=>this.finish());
			}else{
				this.updatePageNumbers();
			}
			
		});
	}

	private finish(){
		this.animationFrame=0
		const hostEle=this.host.nativeElement;
		if(!hostEle)
			return;
		//console.time('finishPagination');

		for(const reportPagesEle of this.pagesElesNeedingUpdating){
			const masterPage=reportPagesEle.querySelector('report-page');
			if(!(masterPage instanceof HTMLElement)){
				console.warn('no master');
				continue;
			}

			const transform=dom.getTransform(masterPage);
			const scale=transform.a;

			const pages=reportPagesEle.querySelectorAll('report-page');
			pages.forEach(page=>{
				if(page!==masterPage)
					page.remove();
			});

			const wrapper=masterPage.querySelector('div.content-window');
			if(!(wrapper instanceof HTMLElement)){
				console.warn('no wrapper');
				continue;
			}
			const content=wrapper.querySelector('div.content');
			if(!(content instanceof HTMLElement)){
				console.warn('no content');
				continue;
			}
			
			const wrapperBounds=wrapper.getBoundingClientRect();
			const contentBounds=content.getBoundingClientRect();
			if(contentBounds.height<=wrapperBounds.height)
				continue;

			let avoidBreakRanges:{top:number, bottom:number}[]=[];
			content.querySelectorAll('*')
				.forEach(ele=>{
					if(ele instanceof HTMLElement){
						const styles=window.getComputedStyle(ele);
						if(styles.breakInside==='avoid' || styles.breakInside==='avoid-page'){
							const bounds=ele.getBoundingClientRect();
							if(bounds.width<=wrapperBounds.width)
								avoidBreakRanges.push({top: bounds.top, bottom: bounds.bottom});
						}
					}
				});/**/
			avoidBreakRanges.sort((a,b)=>a.top-b.top);

			content.style.top='0px';
			let prevWrapperTop=contentBounds.top;
			let prevWrapper=wrapper;
			for(let y=wrapperBounds.bottom,insure=0;y<contentBounds.bottom && insure<32;y+=wrapperBounds.height,++insure){
				const clonedPage=<HTMLElement>masterPage.cloneNode(true);
				clonedPage.querySelector('button.page-close')?.remove();
				const clonedWrapper=clonedPage.querySelector('div.content-window');
				if(!(clonedWrapper instanceof HTMLElement))
					continue;
				const cloneContent=clonedPage.querySelector('div.content');
				if(!(cloneContent instanceof HTMLElement))
					continue;

				//avoidBreakRanges=avoidBreakRanges.filter(range=>range.bottom<y);
				for(let i=0;i<avoidBreakRanges.length;++i){
					const range=avoidBreakRanges[i];
					if(range.top>y){
						avoidBreakRanges.splice(0,i);
						break;
					}
					if(range.top<y && y<range.bottom){
						const _y=math.max(prevWrapperTop,range.top);
						y=_y;
						prevWrapper.style.height=(y-prevWrapperTop)/scale+'px';
						avoidBreakRanges.splice(0,i+1);
						break;
					}
				}

				cloneContent.style.top=-(y-wrapperBounds.top)/scale+'px';
				clonedPage.classList.add('cloned-page-'+insure);
	
				masterPage.parentNode.appendChild(clonedPage);
				prevWrapper=clonedWrapper;
				prevWrapperTop=y;
			}
		}

		this.updatePageNumbers();
		//console.timeEnd('finishPagination');

	}

	private updatePageNumbers(){
		const hostEle=this.host.nativeElement;
		if(!hostEle)
			return;
		let pageNumber=1;
		const pages=hostEle.querySelectorAll('report-page');

		let pageCount=0;
		pages.forEach(page=>{
			if(!(page instanceof HTMLElement && page.style.display!=='none'))
				return;
			pageCount+=1;
		});

		if(pageCount!==this.pageCount){
			this.pageCount=pageCount;
			this.pageNumbers=new WeakMap();
		}
		pages.forEach(page=>{
			if(!(page instanceof HTMLElement && page.style.display!=='none'))
				return;
			if(this.pageNumbers.get(page)!==pageNumber){
				this.pageNumbers.set(page,pageNumber);
				page.querySelectorAll('.page-number')
					.forEach(ele=>ele.innerHTML=`${pageNumber} of ${pageCount}`);
			}
			pageNumber+=1;
		});

		this.pagesElesNeedingUpdating.clear();		
	}
}
