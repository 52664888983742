import {CommonModule} from '@angular/common';
import {ChangeDetectionStrategy,ChangeDetectorRef,Component,Input,OnDestroy,Optional} from '@angular/core';
import {BehaviorSubject,combineLatest,op} from '../../rxjs';
import {formatStr} from './format-str';
import {MeasurementTypeToUnit} from './measurement-type-to-unit';
import { UnitSystem } from '../../measurement/types';

@Component({
	standalone: true,
	imports: [
		CommonModule,
	],
	selector: 'measurement',
	templateUrl: './measurement.component.html',
	styleUrls: ['./measurement.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MeasurementComponent implements OnDestroy {
	constructor(
		@Optional() private readonly measurementTypeToUnit:MeasurementTypeToUnit,
		private readonly cdr:ChangeDetectorRef,
	){
	}

	@Input() 
		get type(){
			return this.type$.value;
		}
		set type(v){
			this.type$.next(v);
		}
	@Input() 
		get value(){
			return this.value$.value;
		}
		set value(v){
			this.value$.next(v);
		}
	@Input() 
		get offset(){
			return this.offset$.value;
		}
		set offset(v){
			this.offset$.next(v);
		}
	@Input() 
		get format(){
			return this.format$.value;
		}
		set format(v){
			this.format$.next(v);
		}
	@Input() 
		get allowSummarize(){
			return this.allowSummarize$.value;
		}
		set allowSummarize(v){
			this.allowSummarize$.next(v);
		}
	
	@Input()
		get unitSystem(){
			return this.unitSystem$.value;
		}
		set unitSystem(v){
			this.unitSystem$.next(v);
		}

	private readonly type$=new BehaviorSubject<string>('count');
	private readonly value$=new BehaviorSubject<number|number[]>(undefined);
	private readonly format$=new BehaviorSubject('${v}${u}');
	private readonly offset$=new BehaviorSubject(0);
	private readonly allowSummarize$=new BehaviorSubject(true);
	private readonly unitSystem$ = new BehaviorSubject<UnitSystem>('metricUnit');

	public readonly contents$=
		combineLatest([
			this.type$,
			this.value$,
			this.offset$,
			this.format$,
			this.allowSummarize$])
		.pipe(
			op.debounceTime(0),
			op.map(([type,value,offset,format,allowSummarize])=>{
				const typeToUnit = this.measurementTypeToUnit.getUnitsForSystem(this.unitSystem$.value);
				return formatStr(value,type,typeToUnit,offset,format,allowSummarize);
				//this.cdr.detectChanges();
			}));

	ngOnDestroy(){
		this.type$.complete();
		this.value$.complete();
		this.format$.complete();
		this.offset$.complete();
		this.allowSummarize$.complete();
	}
}
