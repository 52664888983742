import { Component, Input } from '@angular/core';

@Component({
    selector: 'color-palette',
    templateUrl: './color-palette.component.html',
    styleUrls: ['./color-palette.component.scss']
})
export class ColorPaletteComponent {
    @Input() blurred: boolean = true;
    @Input() text: string = '';
    @Input() color: string = '';
    @Input() horizontal: boolean = true;
    @Input() small: boolean = false;
}
