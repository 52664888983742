<div class="section start">
    <div class="container">
        <h3>API Settings</h3>
        <p class="translucent">You need an API access token to access and get data from Strayos Netra's API's. Read more
            about Strayos API's in our documentation</p>
        <hr>
        <div class="api-token container" *ngIf="token$|async">
            <strong>{{tokenName$|async}} : </strong>{{token$|async}}
            <mat-icon class="list-icons" (click)="copyToken()" matTooltip="copy"
                [matTooltipPosition]="'above'">copy</mat-icon>
            <mat-icon class="list-icons" (click)="deleteToken()" matTooltip="remove"
                [matTooltipPosition]="'above'">delete</mat-icon>
        </div>
        <div class="container">
            <mat-form-field>
                <input matInput type="text" [(ngModel)]="newTokenName" />
                <mat-label>New Token's Name</mat-label>
            </mat-form-field>
            <p *ngIf="error" class="error">please enter name for new token
            <p>
        </div>
        <button mat-button color="primary" (click)="getToken()">Generate new Token</button>
    </div>
</div>