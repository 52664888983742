
import { Box as _Box } from '../math/box';
import { Vec } from '../math/vec';
import { Vec3 } from '../math/vec3';
import { BoxTreeBranch } from './box-tree-branch';
import { BoxTreeClosest } from './box-tree-closest';
import { BoxTreeLeaf } from './box-tree-leaf';
import { BoxTreeNode } from './box-tree-node';

const oneVec=new Vec3(1,1,1);

export abstract class BoxTree<Item, Box extends _Box<Vec>, Data=never>{
	private extent?:Box;
	public root?:BoxTreeNode<Item,Box,Data>;

	public get itemBox(){
		return this.root?.itemsBox;
	}

	public constructor(
		public readonly leafDim:number,
	){
		if(!(leafDim>0))
			throw new Error('bad leafDim');
	}

	protected abstract newBox():Box;
	protected abstract newVec():Vec;

	public itemCount(){
		let count=0;
		for(const leaf of this.leafs())
			count+=leaf.items.length;
		return count;
	}

	private cover(lp:Vec){
		if(!this.extent || !this.root){
			this.extent=this.newBox();
			this.extent.min.copy(lp).floor()
			this.extent.max.copy(this.extent.min).add(oneVec);
			this.root=new BoxTreeLeaf<Item,Box,Data>(this.newBox(),1);
		}else{
			const {extent}=this;
			let {root}=this;
			let dim=root.dim;
			while(!extent.containsPoint(lp)){
				let nodeIndex=0;
				if(lp.x<extent.min.x){
					nodeIndex+=1;
					extent.min.x-=dim;
				}else
					extent.max.x+=dim;
				if(lp.y<extent.min.y){
					nodeIndex+=2;
					extent.min.y-=dim;
				}else
					extent.max.y+=dim;
				if(lp.z!==undefined){
					if(lp.z<extent.min.z!){
						nodeIndex+=4;
						extent.min.z!-=dim;
					}else
						extent.max.z!+=dim;
				}
				dim*=2;
				if(dim>2**16)
					throw new Error('root dim too big');
				const _root=new BoxTreeBranch<Item,Box,Data>(root.itemsBox.clone(),dim);
				_root.nodes[nodeIndex]=root;
				root=_root;
			}
			this.root=root;
		}
		return <const>[this.extent,this.root];
	}

	public add(
		item:Item,
		itemPos:Vec,
		itemBoxUnion:(box:Box,item:Item)=>void,
	){
		const lp=this.newVec().copy(itemPos).invScale(this.leafDim);
		const [extent,root]=this.cover(lp);
		const np=lp.sub(extent.min).invScale(root.dim);
		return root.addItem(np,item,itemBoxUnion);
	}

	// public addItems(
	// 	item:Item,
	// ){
	// 	const itemPos=this.itemToPos(item);
	// 	const lp=itemPos.clone().invScale(this.leafDim);
	// 	const [extent,root]=this.cover(lp);
	// 	const np=lp.sub(extent.min).invScale(root.dim);
	// 	return root.addItem(np,item,this.itemBoxUnion);
	// }

	public delete(item:Item, itemPos:Box['min']){
		const leafs=this.filterLeafs(node=>node.itemsBox.containsPoint(itemPos));
		for(const leaf of leafs){
			if(leaf.deleteItem(item))
				return true;
		}
		return false;
	}

	public updateBoxes(itemBoxUnion?:((box:Box,item:Item)=>void)){
		this.root?.updateItemsBox(itemBoxUnion);
	}

	public nodes():Iterable<BoxTreeNode<Item,Box,Data>>{
		return this.root?.decendantNodes() ?? [];
	}
	
	public leafs():Iterable<BoxTreeLeaf<Item,Box,Data>>{
		return this.root?.decendantLeafs() ?? [];
	}
	
	public filterLeafs(condition:(node:BoxTreeNode<Item,Box,Data>)=>boolean){
		const leafs:BoxTreeLeaf<Item,Box,Data>[]=[];
		this.root?.filterLeafs(leafs,condition);
		return leafs;
	}

	public filterItems(
		nodeCondition:(node:BoxTreeNode<Item,Box,Data>)=>boolean,
		itemCondition:(item:Item)=>boolean,
	){
		const leafs:BoxTreeLeaf<Item,Box,Data>[]=[];
		this.root?.filterLeafs(leafs,nodeCondition);
		let items:Item[]=[];
		for(const leaf of leafs)
			items=items.concat(leaf.items.filter(itemCondition));
		return items;
	}

	public closest<T extends Vec>(
		focus:T,
		distSqFn:(item:Item, focus:T)=>number,
		maxDistSq=Infinity,
	){
		const found:BoxTreeClosest<Item,Box,Data>={
			item: <Item|null>null,
			node: null,
			distSq: maxDistSq,
		};
		this.root?.closest(focus,distSqFn,found);
		return found;
	}
}
