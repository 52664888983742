<div class="container">
    <div class="container">
        <form [formGroup]="formGroup" class="formgroup" >
        <mat-tab-group>
            <mat-tab>
                <ng-template mat-tab-label>
                    <mat-icon>timer</mat-icon>
                    Time Zone Settings
                </ng-template>
                <div class="form-content">
                    <div>
                        <div>
                            <label>Time Zone</label>
                            <mat-form-field class="fill-available-width min-20" appearance="outline">
                                <mat-select formControlName="timezone">
                                    <mat-option *ngFor="let timezone of timezonesData.tz2" [value]=timezone.value>
                                        {{ timezone.value }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div>
                            <label>Date Format</label>
                            <mat-form-field class="fill-available-width min-20" appearance="outline">
                                <mat-select formControlName="dateFormat">
                                    <mat-option *ngFor="let format of dateFormats" [value]="format">{{ format }}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div>
                            <label>Time Format</label>
                            <mat-form-field class="fill-available-width min-20" appearance="outline">
                                <mat-select formControlName="timeFormat">
                                    <mat-option value="h23">24 Hour Clock</mat-option>
                                    <mat-option value="h11">12-hour clock</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label>
                    <mat-icon>straighten</mat-icon>
                    Measurement Units
                </ng-template>
                <div class="form-content">
                    <div>
                        <div>
                            <label>Particle Size Unit</label>
                            <mat-form-field class="fill-available-width min-20" appearance="outline">
                                <mat-select formControlName="particleSizeUnit">
                                    <mat-option value="meter">meter</mat-option>
                                    <mat-option value="centimeter">centimeter</mat-option>
                                    <mat-option value="millimeter">millimeter</mat-option>
                                    <mat-option value="inch">inch</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div>
                            <label>Quantity Unit</label>
                            <mat-form-field class="fill-available-width min-20" appearance="outline">
                                <mat-select formControlName="quantityUnit">
                                    <mat-option value="meter">meter</mat-option>
                                    <mat-option value="yard">yard</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div>
                            <label>Sieve Size</label>
                            <mat-form-field class="fill-available-width min-20" appearance="outline">
                                <mat-select formControlName="sieveSize">
                                    <mat-option value="minimum sieve size">minimum sieve size</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div>
                            <label>Particle Size Distribution Model</label>
                            <mat-form-field class="fill-available-width min-20" appearance="outline">
                                <mat-select formControlName="particleSizeDistributionModel">
                                    <mat-option value="Swebrec">Swebrec</mat-option>
                                    <mat-option value="Kuz-Ram">Kuz-Ram</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label>
                    <mat-icon>mail</mat-icon>
                    Email Settings
                </ng-template>
                <div class="form-content">
                    <div class="slide-toggle-box">
                        <mat-slide-toggle
                            formControlName="dailySummary"
                            class="example-margin"
                            color="primary"
                            [checked]="user.dailySummaryMail">
                            Send Daily summary Report
                        </mat-slide-toggle>
                    </div>
                </div>
            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label>
                    Target Interval Settings (User)
                </ng-template>
                <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="center">
                    <mat-tab label="Regular Intervals">
                        <div class="form-content">
                            <div class="slide-toggle-box">
                                <mat-slide-toggle
                                    formControlName="preferCustomSizes"
                                    class="example-margin"
                                    color="primary"
                                    [checked]="user.preferCustomSizes">
                                    Apply Custom Sizes Over Regular Target Intervals
                                </mat-slide-toggle>
                            </div>
                            <mat-form-field class="min-20">
                                <mat-label>Minimum Target Size</mat-label>
                                <measurement-input type="particleSize" [unitSystem]="(particleSizeUnitSystem$|async)" formControlName="minTargetSize"></measurement-input>
                            </mat-form-field>
                            <mat-form-field class="min-20">
                                <mat-label>Maximum Target Size</mat-label>
                                <measurement-input type="particleSize" [unitSystem]="(particleSizeUnitSystem$|async)" formControlName="maxTargetSize"></measurement-input>
                            </mat-form-field>
                            <mat-form-field class="min-20">
                                <mat-label>No. of Target intervals</mat-label>
                                <input matInput type="number" formControlName="targetIntervals" />
                            </mat-form-field>
                        </div>
                    </mat-tab>
                    <mat-tab label="Custom Sizes ">
                        <div class="form-content">
                            <div class="slide-toggle-box">
                                <mat-slide-toggle
                                    formControlName="preferCustomSizes"
                                    class="example-margin"
                                    color="primary"
                                    [checked]="user.preferCustomSizes">
                                    Apply Custom Sizes Over Regular Target Intervals
                                </mat-slide-toggle>
                            </div>
                            <table mat-table [dataSource]="sizeOptions" class="mat-elevation-z8">
                                <ng-container matColumnDef="minSize">
                                    <th mat-header-cell *matHeaderCellDef>Starting Target Size</th>
                                    <td mat-cell *matCellDef="let element">
                                        <mat-form-field>
                                            <measurement-input type="particleSize" [unitSystem]="(particleSizeUnitSystem$|async)" [(ngModel)]="element.minSize"
                                                [value]="element.minSize"></measurement-input>
                                        </mat-form-field>
                                    </td>
                                </ng-container>
            
                                <ng-container matColumnDef="maxSize">
                                    <th mat-header-cell *matHeaderCellDef>Ending Target Size</th>
                                    <td mat-cell *matCellDef="let element">
                                        <mat-form-field>
                                            <measurement-input type="particleSize" [unitSystem]="(particleSizeUnitSystem$|async)" [(ngModel)]="element.maxSize"
                                                [value]="element.maxSize"></measurement-input>
                                        </mat-form-field>
                                    </td>
                                </ng-container>
            
                                <ng-container matColumnDef="interval">
                                    <th mat-header-cell *matHeaderCellDef>Target Intervals</th>
                                    <td mat-cell *matCellDef="let element">
                                        {{ element.intervals }}
                                    </td>
                                </ng-container>
        
                                <ng-container matColumnDef="action">
                                    <th mat-header-cell *matHeaderCellDef>Action</th>
                                    <td mat-cell *matCellDef="let element">
                                        <button mat-raised-button color="primary" (click)="removeSizeRange(element)">Remove</button>
                                    </td>
                                </ng-container>
                                <tr mat-header-row *matHeaderRowDef="['minSize', 'maxSize','interval', 'action']"></tr>
                                <tr mat-row *matRowDef="let row; columns: ['minSize', 'maxSize','interval', 'action']"></tr>
                            </table>
                        </div>
                        <div style="text-align: center; margin-top: 16px;">
                            <button mat-flat-button color="primary" (click)="toggleAddSizeRange()">
                                Add New
                            </button>
                        </div>
                        <br />
                        <div *ngIf="showAddSizeRange" style="width: 100%; overflow: hidden;" class="">
                            <mat-form-field class="min-13">
                                <mat-label>Starting Target Size</mat-label>
                                <measurement-input type="particleSize" [unitSystem]="(particleSizeUnitSystem$|async)"
                                    formControlName="newMinSize"></measurement-input>
                            </mat-form-field> &nbsp;
                            <mat-form-field class="min-13">
                                <mat-label>Ending Target Size</mat-label>
                                <measurement-input type="particleSize" [unitSystem]="(particleSizeUnitSystem$|async)"
                                    formControlName="newMaxSize"></measurement-input>
                            </mat-form-field>&nbsp;
                            <mat-form-field class="min-13">
                                <mat-label>No. of Target intervals</mat-label>
                                <input matInput formControlName="newTargetInterval" type="number" placeholder="Enter interval">
                            </mat-form-field>
                                <button mat-mini-fab color="primary" class="add-icon" (click)="addCustomSizeRange()">
                                    <mat-icon>add_circle_outline</mat-icon>
                                </button>
                            <br/>
                        </div>
                    </mat-tab>
                </mat-tab-group>
            </mat-tab>
            </mat-tab-group>
            <div class="flex-row center">
                <div class="container">
                    <button mat-raised-button color="primary" (click)="saveSettings()" type="submit">
                        Save Settings
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>