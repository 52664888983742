import {CommonModule} from '@angular/common';
import {ChangeDetectionStrategy,ChangeDetectorRef,Component,ElementRef,EventEmitter,HostBinding,HostListener,Input,OnDestroy,OnInit,Optional,Output,Self,forwardRef} from '@angular/core';
import {ControlValueAccessor,FormsModule,NG_VALUE_ACCESSOR,NgControl,ReactiveFormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatFormFieldControl,MatFormFieldModule} from '@angular/material/form-field';
import {BehaviorSubject, Subject,combineLatest} from 'rxjs';
import {measurement} from '../../measurement';
import {op} from '../../rxjs';
import {formatStr} from './format-str';
import {MeasurementTypeToUnit} from './measurement-type-to-unit';
import { UnitSystem } from '../../measurement/types';

@Component({
	standalone: true,
	imports: [
		CommonModule,
		FormsModule,
		MatButtonModule,
		MatFormFieldModule,
		ReactiveFormsModule,
	],
	selector: 'measurement-input',
	templateUrl: './measurement-input.component.html',
	styleUrls: ['./measurement-input.component.scss'],
	providers: [
		{
			provide: MatFormFieldControl,
			useExisting: forwardRef(()=>MeasurementInputComponent),
		},
		// {
		// 	provide: NG_VALUE_ACCESSOR,
		// 	useExisting: forwardRef(()=>MeasurementInputComponent),
		// 	multi: true
		// },
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MeasurementInputComponent implements ControlValueAccessor,MatFormFieldControl<number>,OnInit,OnDestroy {
	public constructor(
		@Optional() @Self() public ngControl:NgControl,
		private readonly host:ElementRef<HTMLElement>,
		@Optional() private readonly measurementTypeToUnit:MeasurementTypeToUnit,
		private readonly cdr:ChangeDetectorRef,
	){
		if(ngControl)
			ngControl.valueAccessor=this;
	}

	@Input()
		get unitSystem(){
			return this.unitSystem$.value;
		}
		set unitSystem(v){
			this.unitSystem$.next(v);
		}

	@Input()
		get placeholder(){
			return this._placeholder;
		}
		set placeholder(v){
			this._placeholder=v;
			this.stateChanges.next();
		}

	@Input()
		get required(){
			return this._required;
		}
		set required(v){
			this._required=v;
			this.stateChanges.next();
		}

	@Input()
		get disabled(){
			return this._disabled;
		}
		set disabled(v){
			this._disabled=v;
			this.stateChanges.next();
		}

	@Input()
		get type(){
			return this._type;
		}
		set type(v){
			this._type=v;
			this.inputType$.next(v);
		}
	@Input()
		get value(){
			return this._value;
		}
		set value(v){
			if(this._value!==v){
				this._value=v;
				this.inputValue$.next(v);
			}
		}
	@Input()
		get offset(){
			return this._offset;
		}
		set offset(v){
			this._offset=v;
			this.inputOffset$.next(v);
		}

	@Input() step=1;

	@Output('valueChange') valueChange=new EventEmitter<number>();

	private readonly inputType$=new Subject<measurement.Type>();
	private readonly inputValue$=new Subject<number>();
	private readonly inputOffset$=new Subject<number>();

	public stateChanges=new Subject<void>();
	public focused=false;
	public touched=false;
	private _placeholder: string;
	private _disabled=false;
	private _required=false;
	static nextId=1;
	@HostBinding() id=`date-time-picker-${MeasurementInputComponent.nextId++}`;
	public maxLengthStr='0000.00';
	private _valueStr='';
	public unitStr='';

	public _type:measurement.Type='count';
	public _value:number;
	public _offset=0;
	private readonly unitSystem$ = new BehaviorSubject<UnitSystem>('metricUnit');

	public get empty(){
		return typeof(this._value)!=='number';
	}

	public get shouldLabelFloat(){
		return this.focused || !this.empty;
	}

	public get errorState(){
		if(!this.required && this.valueStr==='')
			return false;
		return isNaN(+this._value) && this.touched;
	}

	public get valueStr(){
		return this._valueStr;
	}

	public set valueStr(v){
		this._valueStr=v;
		if(v!==''){
			const unit=this.measurementTypeToUnit.getUnitsForSystem(this.unitSystem)[this._type] ?? 'none';
			const value=measurement.convertToBaseUnits(+v,unit)
			this.onChange(value,false);
		}else
			this.onChange(null,false);
	}
	ngOnInit(){
		let typeToUnit = this.measurementTypeToUnit.getUnitsForSystem(this.unitSystem);
		combineLatest([
			this.inputType$.pipe(op.startWith(this._type)),
			this.inputValue$.pipe(op.startWith(this._value)),
			this.inputOffset$.pipe(op.startWith(this._offset))])
		.pipe(op.debounceTime(0))
		.subscribe(([type,value,offset])=>{
			this.unitStr=measurement.abbreviation(typeToUnit[type]);
			if(value===null || isNaN(+value))
				this._valueStr='';
			else
				this._valueStr=formatStr(+value,type,typeToUnit,offset,'${v}',false);
			this.stateChanges.next();
			this.cdr.markForCheck();
		});

	}

	ngOnDestroy(){
		this.inputType$.complete();
		this.inputValue$.complete();
		this.inputOffset$.complete();
		this.stateChanges.complete();
	}

	@HostListener('focusin',['$event']) onFocusIn(event:FocusEvent){
		if(!this.focused){
			this.focused=true;
			this.stateChanges.next();
		}
	}

	@HostListener('focusout',['$event']) onFocusOut(event:FocusEvent){
		this.focused=false;
		this.onTouched();
	}

	setDescribedByIds(ids: string[]){
		// const controlElement=this._elementRef.nativeElement
		// .querySelector('.example-tel-input-container')!;
		// controlElement.setAttribute('aria-describedby', ids.join(' '));
	}

	onContainerClick(event: MouseEvent){
		if((<Element>event.target).tagName.toLowerCase()!='input')
			this.host.nativeElement.querySelector('input').focus();
	}

	writeValue(v:number):void{
		this.value=v;
	}

	private _onChange=(_:number)=>{};
	private _onTouched=()=>{};

	registerOnChange(fn:(_:number)=>void):void{
		this._onChange=fn;
	}

	registerOnTouched(fn:()=>{}):void{
		this._onTouched=fn;
	}

	setDisabledState(isDisabled:boolean):void{
		this.disabled=isDisabled;
	}

	public onTouched(){
		if(!this.touched){
			this.touched=true;
			this._onTouched();
			this.stateChanges.next();
		}
	}


	public onChange(value:number, updateStr:boolean){
		if(this._value!==value){
			this._value=value;
			if(updateStr)
				this.inputValue$.next(value);
			
			if(!this.touched){
				this.touched=true;
				this._onTouched();
			}
			this._onChange(value);
			this.stateChanges.next();
			this.valueChange.emit(value);
		}
	}

	public doStep(delta:number){
		this.onChange(this._value+delta*this.step,true);
		// if(this.value){
		// 	const value=this.value.clone();
		// 	value.setMinutes(value.getMinutes()+delta*this.minuteStep);
		// 	this.onChange(value);
		// }
	}

}
