<div *ngIf="(chartLines$ | async) === undefined" class="container flex-column center middle full-width full-height">
    <mat-spinner strokeWidth="3" [diameter]="50"></mat-spinner>
</div>

<report class="full-height report" *ngIf="(chartLines$|async) && (chartLines$|async)!==(null||undefined)" [orientation]="'landscape'"
    title="Fragmentation Camera Aggregated Report">
    <div controls>
        <div>
            <mat-form-field class="full-width">
                <mat-label>Duration</mat-label>
                <mat-select [value]="duration$ | async" [disabled]="(chartLines$ | async) === undefined"
                    (valueChange)="duration$.next($event)">
                    <mat-option *ngFor="let opt of durationOptions|async; let index = index" [value]="opt.key">
                        {{ opt.label }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <ng-container *ngIf="(duration$ | async) === 'shifts'">
            <mat-form-field class="full-width">
                <mat-label> Shift </mat-label>
                <mat-select [disabled]="(chartLines$ | async) === undefined" [value]="shift$ | async" (valueChange)="shift$.next($event)" >
                    <mat-option *ngFor="let shift of shifts$ | async" [value]="shift">
                        {{ shift.title }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </ng-container>
        <ng-container *ngIf="(duration$ | async) === 'custom'">
            <div>
                <mat-form-field class="full-width">
                    <mat-label>Start Date</mat-label>
                    <date-picker [disabled]="(chartLines$|async)===undefined" [value]="begin$ | async"
                        (valueChange)="begin$.next($event)">
                    </date-picker>
                </mat-form-field>
            </div>
            <div>
                <mat-form-field class="full-width">
                    <mat-label>End Date</mat-label>
                    <date-picker [disabled]="(chartLines$|async)===undefined" [value]="end$ | async"
                        (valueChange)="end$.next($event)">
                    </date-picker>
                </mat-form-field>
            </div>
            <div>
                <mat-form-field class="full-width">
                    <mat-label>Start Time</mat-label>
                    <time-picker [disabled]="(chartLines$|async)===undefined" [value]="begin$|async"
                        (valueChange)="begin$.next($event)">
                    </time-picker>
                </mat-form-field>
            </div>
            <div>
                <mat-form-field class="full-width">
                    <mat-label>End Time</mat-label>
                    <time-picker [disabled]="(chartLines$|async)===undefined" [value]="end$|async"
                        (valueChange)="end$.next($event)">
                    </time-picker>
                </mat-form-field>
            </div>
        </ng-container>
    </div>
    <ng-container pages>
        <report-page-title ngProjectAs="report-page" title="Fragmentation Camera Aggregated Report">
        </report-page-title>
        <report-page-timeline ngProjectAs="report-page" [timelineData]="chartLines$|async" [startDate]="(timeRange$|async).begin"
        [endDate]="(timeRange$|async).end"></report-page-timeline>
        <ng-container ngProjectAs="report-page">
            <report-page [footer]="true" class="landscape">
                <div class="full-height full-width flex-column">
                    <div class="full-width">
                        <div class="strip bar-1"></div>
                    </div>
                    <div class="container full-height">
                        <chart-line class="full-height" #chartLine
                            [legend]="false"
                            [xAxis]="{
                                name: 'Time',
                                dataType: 'time',
                            }"
                            [yAxis]="{
                                name: (yAxisTitle$|async),
                                dataType: 'number',
                            }" 
                            [series]="quantityChartLines$ |async" >
                        </chart-line>
                    </div>
                    <div class="full-width">
                        <div class="strip bar-1"></div>
                        <div class="strip"></div>
                        <div class="strip"></div>
                    </div>
                </div>
            </report-page>
        </ng-container>
        <ng-container ngProjectAs="report-page" *ngIf="(this.fragQuery.timelineSieveSizesAtPercents$|async)">
            <report-page [footer]="true" class="landscape">
                <div class="full-height full-width flex-column">
                    <div class="full-width">
                        <div class="strip bar-1"></div>
                    </div>
                    <div class="container psd-chart-container">
                        <div class="container">
                            <div class="flex-row justify full-width">
                                <div class="full-width">
                                    <card-square-blocks [title]="'Total ' + (yAxisTitle$|async)" [warn]="false" [size]="'medium'" [long]="true">
                                    <span [innerHTML]="(getWeightContent()|async)"></span>
                                </card-square-blocks>
                                </div>
                                <div class="full-width" *ngIf="isTruck()">
                                    <card-square-blocks [title]="'Total No. of haulers/Trucks'" [warn]="false" [size]="'medium'" [long]="true">
                                        <span>{{(this.fragQuery.timelineSieveSizesAtPercents$|async).intervals.length}}</span>
                                    </card-square-blocks>
                                </div>
                                <div class="full-width" *ngIf="isConveyor()">
                                    <card-square-blocks [title]="'Total Tons'" [warn]="false" [size]="'medium'"
                                    [long]="true">
                                    <span [innerHTML]="( getTotalTons()|async)"></span>
                                </card-square-blocks>
                                </div>
                                <div class="full-width" *ngIf="isConveyor()">
                                    <mat-card  class="card-medium long">
                                        <span>
                                            Rocks >
                                            <measurement class="titlespan" type="particleSize" [unitSystem]="(particleSizeUnitSystem$ | async)" [value]="slabDiameter"></measurement>
                                            (Slabs)
                                        </span>
                                        <div class="small-card-content primary">
                                            {{ slabs }}
                                        </div>
                                    </mat-card>
                                    </div>
                            </div>
                            <div class="flex-row wrap around card-content-scroll">
                                <div *ngFor="let keys of (newPercents$ | async).keys(); let j = index">
                                    <card-square-blocks [title]="'D' + keys" [size]="'small'" [warn]="false">
                                        <span>{{ (accumulatedData$ | async).data[j + 1] | unitConversion:  (particleSizeUnit$|async)}}</span>
                                    </card-square-blocks>
                                </div>
                            </div>
                        </div>
                        <div>
                            <analysis-table [showDxxTable]="false" [size]="'small'" [direction]="'vertical'" [height]="'normal'"
                            [dxxArr]="this.tableDataMap.get('summary').dxxArr"
                            [dataArr]="this.tableDataMap.get('summary').dataArr"
                            [percentArr]="this.tableDataMap.get('summary').percentArr"
                            [primaryTableRows]="20"
                            [secondaryTableRows]="35"
                            (secondaryTables)="onTablesReceived($event)"
                            >
                            </analysis-table>
                        </div>
                    </div>
                    <div class="full-width">
                        <div class="strip bar-1"></div>
                        <div class="strip"></div>
                        <div class="strip"></div>
                    </div>
                </div>
            </report-page>
        </ng-container>
        <ng-container ngProjectAs="report-page" *ngFor="let chunk of this.cardTables |chunk: 4">
            <report-page [footer]="true" class="landscape">
                <div class="full-height full-width flex-column">
                    <div class="full-width">
                        <div class="strip bar-1"></div>
                    </div>
                    <div class="flex-row container psd-chart-container">
                        <div class="table-container" *ngFor="let table of chunk">
                            <ng-container *ngTemplateOutlet="table"></ng-container>
                        </div>
                    </div>
                    <div class="full-width">
                        <div class="strip bar-1"></div>
                        <div class="strip"></div>
                        <div class="strip"></div>
                    </div>
                </div>
            </report-page>
        </ng-container>
        <ng-container ngProjectAs="report-page">
            <report-page [footer]="true" class="landscape">
                <div class="full-height full-width flex-column">
                    <div class="full-width">
                        <div class="strip bar-1"></div>
                    </div>
                    <div class="container psd-chart-container">
                        <psd-chart [index]="0" [data]="[accumulatedData$|async]" [dxxLabels]="false"></psd-chart>
                    </div>
                    <div class="full-width">
                        <div class="strip bar-1"></div>
                        <div class="strip"></div>
                        <div class="strip"></div>
                    </div>
                </div>
            </report-page>
        </ng-container>
    </ng-container>
</report>