import {PDFDocument} from "./pdf";

function pathRect(doc:PDFDocument, ele:SVGRectElement){
	doc.lineWidth
	const strokeWidth=doc._lineWidth;
	const cornerRadius=ele.rx.baseVal.value;		
	const func:'roundedRect'|'rect'=cornerRadius>0?'roundedRect':'rect';
	doc[func](
		ele.x.baseVal.value+strokeWidth*0.5,
		ele.y.baseVal.value+strokeWidth*0.5,
		ele.width.baseVal.value-strokeWidth*0.5,
		ele.height.baseVal.value-strokeWidth*0.5,
		cornerRadius
	);
	return true;
}

function pathCircle(doc:PDFDocument, ele:SVGCircleElement){
	doc.circle(ele.cx.baseVal.value,ele.cy.baseVal.value,ele.r.animVal.value);
	return true;
}

function pathEllipse(doc:PDFDocument, ele:SVGEllipseElement){
	doc.ellipse(ele.cx.baseVal.value,ele.cy.baseVal.value,ele.rx.baseVal.value,ele.ry.baseVal.value);
	return true;
}

function pathLine(doc:PDFDocument, ele:SVGLineElement){
	doc
		.moveTo(ele.x1.baseVal.value,ele.y1.baseVal.value)
		.lineTo(ele.x2.baseVal.value,ele.y2.baseVal.value);
	return false;
}

function pathPolyline(doc:PDFDocument, ele:SVGPolylineElement){
	doc.moveTo(ele.points[0].x,ele.points[0].y);
	for(let i=1;i<ele.points.length;++i)
		doc.lineTo(ele.points[i].x,ele.points[i].y);
	return ele.points.length>2
}

function pathPath(doc:PDFDocument, ele:SVGPathElement){
	const d=ele.getAttribute('d');
	if(d){
		doc.path(d);
		return true;
	}
	return false;
}

export function pathSvgElement(doc:PDFDocument, ele:SVGElement):boolean{
	//return true if fill applies
	if(ele instanceof SVGRectElement)
		return pathRect(doc,ele);
	if(ele instanceof SVGCircleElement)
		return pathCircle(doc,ele);
	if(ele instanceof SVGEllipseElement)
		return pathEllipse(doc,ele);
	if(ele instanceof SVGLineElement)
		return pathLine(doc,ele);
	if(ele instanceof SVGPolylineElement)
		return pathPolyline(doc,ele);
	if(ele instanceof SVGPathElement)
		return pathPath(doc,ele);
	return false;
}
