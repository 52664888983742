import { _iterate } from "../iterate";

Array.asArray=<typeof Array.asArray>function(v){
	if(Array.isArray(v))
		return v;
	return [v];
}

Array.prototype.atWrap=function(index:number){
	if(this.length===0)
		return undefined;
	index=index%this.length;
	return this.at(index);
}

Array.prototype.atClamp=function(index:number){
	if(index<0)
		return this[0];
	if(index>=this.length)
		return this.at(-1);
	return this[index];
}

Array.prototype.groupsOf=function(groupSize:number){
	const out=[];
	for(let i=0;i<this.length;i+=groupSize)
		out.push(this.slice(i,i+groupSize));
	return out;
}

Array.prototype.highest=function(worth?:(v:any, i:number)=>number){
	worth??=(v=>+v);
	return _iterate.highest(this,worth);
}

Array.prototype.highestIndex=function(worth?:(v:any, i:number)=>number){
	worth??=(v=>+v);
	return _iterate.highestIndex(this,worth);
}

Array.prototype.lowest=function(worth?:(v:any, i:number)=>number){
	worth??=(v=>+v);
	return _iterate.lowest(this,worth);
}

Array.prototype.lowestIndex=function(worth?:(v:any, i:number)=>number){
	worth??=(v=>+v);
	return _iterate.lowestIndex(this,worth);
}

Array.prototype.pairs=function(loop:boolean=false){
	if(loop)
		return _iterate.pairsLooped(this);
	return _iterate.pairsNonLooped(this);
}

Array.prototype.triplets=function(loop:boolean=false):Generator<[any,any,any,number],void,undefined>{
	if(loop)
		return _iterate.tripletsLooped(this);
	return _iterate.tripletsNonLooped(this);
}

Array.prototype.unique=function(){
	return [...new Set(this)];
}
