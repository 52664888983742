import {Component,Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
	selector: 'dialog-input',
	templateUrl: './dialog-input.component.html',
	styleUrls: [
		'./dialog-input.component.scss'
	]
})
export class DialogInputComponent{
	constructor(
		@Inject(MAT_DIALOG_DATA) public input:DialogInputComponent.Input,
	){
	}

	public value=this.input.default ?? '';
}

export namespace DialogInputComponent{
	export interface Input{
		label:string;
		title?:string;
		hint?:string;
		default?:string;
	}
}