export namespace _iterate{
	export function *filter<T>(iterable:Iterable<T>, fn:(v:T)=>boolean){
		for(const v of iterable){
			if(fn(v))
				yield v;
		}
	}

	export function *map<T,U>(iterable:Iterable<T>, fn:(v:T)=>U){
		for(const v of iterable)
			yield fn(v);
	}

	export function *pairsNonLooped<T>(iterable:Iterable<T>){
		let i=-1;
		let a:T;
		let b:T;
		for(b of iterable){
			if(i>=0)
				yield <[T,T,number]>[a!,b,i];
			a=b;
			++i;
		}
		if(i<0)
			yield *[];
	}

	export function *pairsLooped<T>(iterable:Iterable<T>){
		let i=-1;
		let a:T;
		let b:T;
		let first:T;
		for(b of iterable){
			if(i<0)
				first=b;
			else
				yield <[T,T,number]>[a!,b,i];
			a=b;
			++i;
		}
		if(i>0)
			yield <[T,T,number]>[b!,first!,i];
		else
			yield *[];
	}

	export function *tripletsNonLooped<T>(iterable:Iterable<T>){
		let i=-2;
		let a:T;
		let b:T;
		let c:T;
		for(c of iterable){
			if(i>=0)
				yield <[T,T,T,number]>[a!,b!,c,i];
			a=b!;
			b=c;
			++i;
		}
		if(i<0)
			yield *[];
	}

	export function *tripletsLooped<T>(iterable:Iterable<T>){
		let i=-2;
		let a:T;
		let b:T;
		let c:T;
		let initial:T[]=[];
		for(c of iterable){
			if(i<0)
				initial[i+2]=c;
			else
				yield <[T,T,T,number]>[a!,b!,c,i];
			a=b!;
			b=c;
			++i;
		}
		if(i>0){
			yield <[T,T,T,number]>[a!,b!,initial[0],i];
			++i;
			yield <[T,T,T,number]>[b!,initial[0],initial[1],i];
		}else
			yield *[];
	}

	export function *segments<T>(iterable:Iterable<T>, same:(a:T,b:T)=>boolean){
		let segment:T[]=[];
		for(const v of iterable){
			if(segment.length>0 && !same(segment.at(-1)!,v)){
				yield segment;
				segment=[];
			}
			segment.push(v);
		}
		if(segment.length>0)
			yield segment;
	}

	export function lowest<T>(iterable:Iterable<T>, worth:(v:T, i:number)=>number){
		let found:T|undefined=undefined;
		let foundWorth=Infinity;
		let i=0;
		for(const v of iterable){
			const w=worth(v,i);
			if(w<foundWorth){
				foundWorth=w;
				found=v;
			}
			++i;
		}
		return found;
	}

	export function highest<T>(iterable:Iterable<T>, worth:(v:T, i:number)=>number){
		let found:T|undefined=undefined;
		let foundWorth=-Infinity;
		let i=0;
		for(const v of iterable){
			const w=worth(v,i);
			if(w>foundWorth){
				foundWorth=w;
				found=v;
			}
			++i;
		}
		return found;
	}
	
	export function lowestIndex<T>(iterable:Iterable<T>, worth:(v:T, i:number)=>number){
		let found=-1;
		let foundWorth=Infinity;
		let i=0;
		for(const v of iterable){
			const w=worth(v,i);
			if(w<foundWorth){
				foundWorth=w;
				found=i;
			}
			++i;
		}
		return found;
	}

	export function highestIndex<T>(iterable:Iterable<T>, worth:(v:T, i:number)=>number){
		let found=-1;
		let foundWorth=-Infinity;
		let i=0;
		for(const v of iterable){
			const w=worth(v,i);
			if(w>foundWorth){
				foundWorth=w;
				found=i;
			}
			++i;
		}
		return found;
	}
}

class IterateIterator<T> implements Iterable<T>{
	public constructor(
		private iterable:Iterable<T>,
	){
	}

	public [Symbol.iterator](){
		return this.iterable[Symbol.iterator]();
	}

	public filter(fn:(v:T)=>boolean){
		return new IterateIterator(_iterate.filter(this,fn));
	}

	public map<U>(fn:(v:T)=>U):IterateIterator<U>{
		return new IterateIterator(_iterate.map(this,fn));
	}

	public pairs(loop=false){
		if(loop)
			return new IterateIterator(_iterate.pairsLooped(this));
		return new IterateIterator(_iterate.pairsNonLooped(this));
	}

	public triplets(loop=false){
		if(loop)
			return new IterateIterator(_iterate.tripletsLooped(this));
		return new IterateIterator(_iterate.tripletsNonLooped(this));
	}

	public segments(same:(a:T,b:T)=>boolean){
		return new IterateIterator(_iterate.segments(this,same));
	}

	//Resolution Methods (they don't return a new iterator)

	public at(index:number){
		let i=0;
		for(const v of this){
			if(i===index)
				return v;
			++i;
		}
		return undefined;
	}

	public every(condition:(v:T, i:number)=>boolean){
		let i=0;
		for(const v of this){
			if(!condition(v,++i))
				return false;
		}
		return true;
	}

	public some(condition:(value:T, i:number)=>boolean){
		let i=0;
		for(const v of this){
			if(condition(v,++i))
				return true;
		}
		return false;
	}

	public lowest():T|undefined;
	public lowest(worth:(v:T, i:number)=>number):T|undefined;
	public lowest(worth?:(v:T, i:number)=>number):T|undefined{
		worth??=(v=>+v);
		return _iterate.lowest(this,worth);
	}

	public highest():T|undefined;
	public highest(worth:(v:T, i:number)=>number):T|undefined;
	public highest(worth?:(v:T, i:number)=>number):T|undefined{
		worth??=(v=>+v);
		return _iterate.highest(this,worth);
	}

	public sum():number;
	public sum(worth:(v:T, i:number)=>number):number;
	public sum(worth?:(v:T, i:number)=>number):number{
		worth??=(v=>+v);
		let sum=0;
		let i=0;
		for(const v of this){
			sum+=worth(v,i);
			++i;
		}
		return sum;
	}

	public mostCommon(){
		const map=new Map<T,number>();
		for(const v of this)
			map.set(v,(map.get(v) ?? 0)+1);

		let found:[T|undefined,number]=[undefined,0];
		for(const e of map){
			if(found[1]<e[1])
				found=e;
		}
		return found[0];
	}

	public toSet(){
		return new Set(this.iterable);
	}
}

export function iterate<T>(it:Iterable<T>){
	return new IterateIterator(it);
}

export namespace iterate{
	function *accendingNumber(begin:number, end:number, step=1, inclusive=false){
		if(inclusive)
			end+=step/2;
		for(let i=begin;i<end;i+=step)
			yield i;
	}

	function *decendingNumber(begin:number, end:number, step=1, inclusive=false){
		if(inclusive)
			end-=step/2;
		for(let i=begin;i>=end;i-=step)
			yield i;
	}


	export function numbers(begin:number, end:number, step=1, inclusive=false){
		if(step<0)
			step=-step;
		if(begin<=end)
			return new IterateIterator(accendingNumber(begin,end,step,inclusive));
		return new IterateIterator(decendingNumber(begin,end,step,inclusive));
	}

	export function toSet<T>(iterable:Iterable<T>){
		if(iterable instanceof Set)
			return iterable;
		return new Set(iterable);
	}

	export function intersection<T>(iterableA:Iterable<T>, iterableB:Iterable<T>){
		const setA=toSet(iterableA);
		const setB=toSet(iterableB);
		const out=new Set<string>();
		for(const v of setA){
			if(setB.has(v)){
				out.add(v);
			}
		}
		return out;
	}
}
