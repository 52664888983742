import { math } from '@lib/math';
import { dom } from '@lib/dom';
import * as pdfkit from './pdf';
import { BehaviorSubject } from 'rxjs';
import * as Async from '@lib/async';
import * as blobStream from '../blob-stream';
import { loadFonts } from './fonts';
import { renderElement } from './render-element';
import { ElementMeta,getDOMRectStr,PageInfo } from './shared';

//page size in printer points, 1/72 inch, 0.352777778mm
export const pageSizes:Record<string,[number,number]>={
	A0: [2383.94, 3370.39],
	A1: [1683.78, 2383.94],
	A2: [1190.55, 1683.78],
	A3: [841.89, 1190.55],
	A4: [595.28, 841.89],
	A5: [419.53, 595.28],
	A6: [297.64, 419.53],
	A7: [209.76, 297.64],
	A8: [147.40, 209.76],
	A9: [104.88, 147.40],
	A10: [73.70, 104.88],
	B0: [2834.65, 4008.19],
	B1: [2004.09, 2834.65],
	B2: [1417.32, 2004.09],
	B3: [1000.63, 1417.32],
	B4: [708.66, 1000.63],
	B5: [498.90, 708.66],
	B6: [354.33, 498.90],
	B7: [249.45, 354.33],
	B8: [175.75, 249.45],
	B9: [124.72, 175.75],
	B10: [87.87, 124.72],
	C0: [2599.37, 3676.54],
	C1: [1836.85, 2599.37],
	C2: [1298.27, 1836.85],
	C3: [918.43, 1298.27],
	C4: [649.13, 918.43],
	C5: [459.21, 649.13],
	C6: [323.15, 459.21],
	C7: [229.61, 323.15],
	C8: [161.57, 229.61],
	C9: [113.39, 161.57],
	C10: [79.37, 113.39],
	RA0: [2437.80, 3458.27],
	RA1: [1729.13, 2437.80],
	RA2: [1218.90, 1729.13],
	RA3: [864.57, 1218.90],
	RA4: [609.45, 864.57],
	SRA0: [2551.18, 3628.35],
	SRA1: [1814.17, 2551.18],
	SRA2: [1275.59, 1814.17],
	SRA3: [907.09, 1275.59],
	SRA4: [637.80, 907.09],
	EXECUTIVE: [521.86, 756.00],
	LEGAL: [612.00, 1008.00],
	LETTER: [612.00, 792.00],
	TABLOID: [792.00, 1224.00],
};

function sizeCss(css:Set<string>, selector:string, size:string, orientation:string|null, w:string, h:string){
	if(selector.includes('${size}')){
		const body=
`width: ${w};
min-width: ${w};
max-width: ${w};
height: ${h};
min-height: ${h};
max-height: ${h};
--page-width: ${w};
--page-height: ${h};`;
		
		selector=selector.replace(/\$\{size\}/g,size);
		selector=selector.replace(/\$\{orientation\}/g,orientation);
		selector=selector.replace(/\$\{body\}/g,body);
		css.add(selector);
	}
}

export async function initialize(id:string, selectors:string[]){
	const libPromise=Promise.all([blobStream.load(),pdfkit.load()]);

	if(!document.getElementById(id)){
		const css=new Set<string>();
		for(const key in pageSizes){
			const sizes=(<number[]>pageSizes[key]).map(v=>{
				const inches=v/72;
				const mm=v*0.352777778;
				const best=[[inches,'in'],[v,'pt'],[mm,'mm']].lowest(v=>Math.abs((<number>v[0])%1));
				return best.join('');
			});
			for(const selector of selectors){
				sizeCss(css,selector,key,'portrait',sizes[0],sizes[1]);
				sizeCss(css,selector,key,'landscape',sizes[1],sizes[0]);
			}
		}
		const style=document.createElement('style');
		style.id=id;
		style.setAttribute('id',id);
		style.innerHTML=[...css].join("\n");
		document.head.appendChild(style);
	}
	return libPromise;
}

function ancesterWithClass<T extends string>(element:HTMLElement, classes:T[]){
	let checkElement=element;
	while(checkElement){
		for(const _class of classes){
			if(checkElement.classList.contains(_class))
				return _class;
		}
		if(checkElement.parentNode instanceof HTMLElement)
			checkElement=checkElement.parentNode;
	}
	return null;
}

class Converter{
	private doc:pdfkit.PDFDocument=null;
	private pages:PageInfo[]=[];
	//private pageNumber=1;

	public async convert(
		pageElements:HTMLElement[],
		progress$:BehaviorSubject<number>
	){
		this.buildPageInfo(pageElements);

  
		await document.fonts.ready;

		let stream:blobStream.BlobStream=null;
		let top=0;
		if(this.pages.length>0){
			top=this.pages[0].rect.top;
		}
		let pageNumber=1;
		for(const page of this.pages){
			if(!this.doc){
				this.doc=pdfkit.newPDFDocument({
					size: page.size,
					margin: 0,
					layout: page.layout,
				});
				await loadFonts(this.doc);
				stream=this.doc.pipe(blobStream.blobStream());
			}else{
				this.doc.addPage({
					size: page.size,
					layout: page.layout,
				});
			}
			if(!this.convertPage(page)){
				return null
			};
			progress$.next((pageNumber++)/this.pages.length);
			await Async.async.animationFrame()
		}
		progress$.next(1);

		this.doc?.end();
		return stream;
	}

	public buildPageInfo(elements:HTMLElement[]){
		this.pages.length=0;
		for(const element of elements){
			const size=ancesterWithClass(element,Object.keys(pageSizes)) || 'LETTER';
			const layout=ancesterWithClass(element,['portrait','landscape']) || 'portrait';
			const rect=element.getBoundingClientRect();
			const dimensions=math.Vec2.fromArray(pageSizes[size]);
			if(layout==='landscape')
				[dimensions.x,dimensions.y]=[dimensions.y,dimensions.x];
			const ptsPerPx=dimensions.x/element.clientWidth;
			const meta=this.getPageMeta(element);
			const transform=dom.getTransform(element);
			const inverseScale=1/transform.a;
			this.removeTransforms(meta);
			this.getElementRects(rect,inverseScale,meta);
			const canvasList=new Map<string,Array<HTMLCanvasElement>>();
			this.getCanvasesFromElements(meta,canvasList);
			this.restoreTransforms(meta);
			rect.x=0;
			rect.y=0;
			rect.width*=inverseScale;
			rect.height*=inverseScale;
			const page:PageInfo={
				element,
				rect,
				size,
				layout,
				dimensions,
				ptsPerPx,
				meta,
				canvasList,
			};
			this.pages.push(page);
		}
		return this.pages;
	}

	private getPageMeta(ele:HTMLElement){
		const meta=new Map<HTMLElement|SVGSVGElement,ElementMeta>()
		for(let i=0;i<ele.children.length;++i){
			const child=ele.children.item(i);
			if(child instanceof HTMLElement || child instanceof SVGElement){
				this.getElementMeta(child,0,meta);
			}
		}

		return meta;
	}

	private getElementMeta(ele:HTMLElement|SVGElement, zIndex:number, meta:Map<HTMLElement|SVGSVGElement,ElementMeta>){
		if(ele.classList.contains('no-pdf'))
			return null;

		if(ele instanceof HTMLElement || ele instanceof SVGSVGElement){
			const styles=getComputedStyle(ele);
			if(styles.visibility==='hidden')
				return null;

			if(styles.zIndex!=='auto')
				zIndex=zIndex+(+styles.zIndex);

			meta.set(ele,{
				styles: styles,
				rect: null,
				zIndex,
			});
		}

		for(let i=0;i<ele.children.length;++i){
			const child=ele.children.item(i);
			if(child instanceof HTMLElement || child instanceof SVGElement){
				this.getElementMeta(child,zIndex,meta);
			}
		}

		return meta;
	}

	private removeTransforms(elementMeta:Map<HTMLElement|SVGSVGElement,ElementMeta>){
		for(const [element,meta] of elementMeta){
			if(meta.styles.transform!=='none'){
				if(element.style.transform)
					meta.originalTransform=element.style.transform;
				else
					meta.originalTransform='';
				element.style.transform='none';
			}
		}
	}

	private getElementRects(
		pageRect:DOMRect,
		inverseScale:number,
		elementMeta:Map<HTMLElement|SVGSVGElement,ElementMeta>
	){
		for(const [element,meta] of elementMeta){
			meta.rect=element.getBoundingClientRect();
			meta.rect.x-=pageRect.x;
			meta.rect.y-=pageRect.y;
			meta.rect.x*=inverseScale;
			meta.rect.y*=inverseScale;
			meta.rect.width*=inverseScale;
			meta.rect.height*=inverseScale;
		}
	}

	private getCanvasesFromElements(elementMeta:Map<HTMLElement|SVGSVGElement,ElementMeta>,canvasList:Map<string,Array<HTMLCanvasElement>>) {
		let canvasElements:HTMLCanvasElement[]=[];
		for(const element of elementMeta.keys()){
			if(element instanceof HTMLCanvasElement) {
				canvasElements.push(element);
			}
		}
		canvasElements.forEach(element=>{
			const rect=elementMeta.get(element)?.rect;
			const rectStr=getDOMRectStr(rect);
			if(!!rect) {
				canvasList.getOrAdd(rectStr, ()=>[]);
				canvasList.get(rectStr).push(element);
			}
		});
	}

	private restoreTransforms(elementMeta:Map<HTMLElement|SVGSVGElement,ElementMeta>){
		for(const [element,meta] of elementMeta){
			if(meta.originalTransform!==undefined){
				element.style.transform=meta.originalTransform;
			}
		}
	}

	private convertPage(page:PageInfo):boolean{
		const doc=this.doc;
		doc.save();
		doc.scale(page.ptsPerPx);
		for(let i=0;i<page.element.children.length;i++){
			const child=page.element.children.item(i);
			if((child instanceof HTMLElement || child instanceof SVGSVGElement)){
				return renderElement(doc,page,page.rect,child,0)
			}
		}
		doc.restore();
		return true;
	}
};

export async function convert(pages:HTMLElement|HTMLElement[], progress$:BehaviorSubject<number>){
	if(!Array.isArray(pages))
		pages=[pages];

	pages=pages.filter(page=>{
		const style=getComputedStyle(page);	
		return style.visibility==='visible' && style.display!=='none';
	});

	if(pages.length===0)
		return null;

	const converter=new Converter();
	const stream=await converter.convert(pages,progress$)
	const blob=await new Promise<Blob>(resolve=>{
		stream.on('finish',()=>{
			const blob=stream.toBlob('application/pdf');
			resolve(blob);
		});
	});
	return blob;
}

export function pages(pages:HTMLElement|HTMLElement[]){
	if(!Array.isArray(pages))
		pages=[pages];

	pages=pages.filter(page=>{
		const style=getComputedStyle(page);
		return style.visibility==='visible' && style.display!=='none';
	});
	
	if(pages.length===0)
		return null;

	const converter=new Converter();
	return converter.buildPageInfo(pages);
}

export function ready(){
	return !!(blobStream.blobStream && pdfkit.PDFDocument);
}
