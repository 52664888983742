//model-meta generated file
import {DateTime,ModelMeta,uuid} from '../../lib/model-meta';
import {WhereTable,RestTypes as _RestTypes} from '../../lib/model-meta';

export class User{
	public id:number;
	public clientId:number;
	public email:string;
	public authToken:string|null=null;
	public permissions:number=0;
	public firstName:string|null=null;
	public lastName:string|null=null;
	public createdAt:DateTime=new DateTime();
	public lastSignInAt:DateTime|null=null;
	public lastSignInIp:string|null=null;
	public tokenName:string|null=null;
	public apiToken:string|null=null;
	public timezone:string|null=null;
	public timezoneOffset:number|null=null;
	public timeFormat:string="h23";
	public sieveSize:string="minimum sieve size";
	public particleSizeDistributionModel:string="Swebrec";
	public unitSystem:string="m Kg s";
	public particleUnit:string="millimeter";
	public quantityUnit:string="meter";
	public dateFormat:string="Month DD YYYY";
	public cameraSpeed:number=0;
	public dailySummaryMail:boolean=false;
	public minTargetSize:number|null=null;
	public maxTargetSize:number|null=null;
	public targetIntervals:number|null=null;
	public customSizeRanges:string="";
	public preferCustomSizes:boolean=false;
}

export namespace User{
	export const meta=new ModelMeta<keyof Props>(
		"User",
		[],
		[
			{
				name: "id",
				type: "serial",
				decorators: {},
				uniqueKey: true,
				notNull: true
			},{
				name: "clientId",
				type: "int",
				decorators: {},
				notNull: true
			},{
				name: "email",
				type: "string",
				typeLength: [0,128],
				decorators: {
					uniqueKey: true
				},
				uniqueKey: true,
				notNull: true
			},{
				name: "authToken",
				type: "string",
				typeLength: [32,32],
				decorators: {
					uniqueKey: true,
					"rest.readonly": true
				},
				uniqueKey: true,
				default: null,
				optional: true
			},{
				name: "permissions",
				type: "int",
				decorators: {},
				default: 0,
				optional: true,
				notNull: true
			},{
				name: "firstName",
				type: "string",
				typeLength: [0,128],
				decorators: {},
				default: null,
				optional: true
			},{
				name: "lastName",
				type: "string",
				typeLength: [0,128],
				decorators: {},
				default: null,
				optional: true
			},{
				name: "createdAt",
				type: "date",
				decorators: {
					"rest.readonly": true
				},
				default: "now()",
				optional: true,
				notNull: true
			},{
				name: "lastSignInAt",
				type: "date",
				decorators: {
					"rest.readonly": true
				},
				default: null,
				optional: true
			},{
				name: "lastSignInIp",
				type: "inet",
				decorators: {
					"rest.readonly": true
				},
				default: null,
				optional: true
			},{
				name: "tokenName",
				type: "string",
				decorators: {},
				default: null,
				optional: true
			},{
				name: "apiToken",
				type: "string",
				decorators: {},
				default: null,
				optional: true
			},{
				name: "timezone",
				type: "string",
				decorators: {},
				default: null,
				optional: true
			},{
				name: "timezoneOffset",
				type: "number",
				decorators: {},
				default: null,
				optional: true
			},{
				name: "timeFormat",
				type: "string",
				decorators: {},
				default: "h23",
				optional: true,
				notNull: true
			},{
				name: "sieveSize",
				type: "string",
				decorators: {},
				default: "minimum sieve size",
				optional: true,
				notNull: true
			},{
				name: "particleSizeDistributionModel",
				type: "string",
				decorators: {},
				default: "Swebrec",
				optional: true,
				notNull: true
			},{
				name: "unitSystem",
				type: "string",
				decorators: {},
				default: "m Kg s",
				optional: true,
				notNull: true
			},{
				name: "particleUnit",
				type: "string",
				decorators: {},
				default: "millimeter",
				optional: true,
				notNull: true
			},{
				name: "quantityUnit",
				type: "string",
				decorators: {},
				default: "meter",
				optional: true,
				notNull: true
			},{
				name: "dateFormat",
				type: "string",
				decorators: {},
				default: "Month DD YYYY",
				optional: true,
				notNull: true
			},{
				name: "cameraSpeed",
				type: "int",
				decorators: {},
				default: 0,
				optional: true,
				notNull: true
			},{
				name: "dailySummaryMail",
				type: "boolean",
				decorators: {},
				default: false,
				optional: true,
				notNull: true
			},{
				name: "minTargetSize",
				type: "number",
				decorators: {},
				default: null,
				optional: true
			},{
				name: "maxTargetSize",
				type: "number",
				decorators: {},
				default: null,
				optional: true
			},{
				name: "targetIntervals",
				type: "int",
				decorators: {},
				default: null,
				optional: true
			},{
				name: "customSizeRanges",
				type: "string",
				decorators: {},
				default: "",
				optional: true,
				notNull: true
			},{
				name: "preferCustomSizes",
				type: "boolean",
				decorators: {},
				default: false,
				optional: true,
				notNull: true
			},{
				name: "password",
				type: "string",
				decorators: {
					"rest.writeonly": true
				},
				notNull: true,
				comment: "not a real field in the db used for setting the password"
			}
		]
	);

	interface Props{
		id:number;
		clientId:number;
		email:string;
		authToken?:string|null;
		permissions?:number;
		firstName?:string|null;
		lastName?:string|null;
		createdAt?:DateTime;
		lastSignInAt?:DateTime|null;
		lastSignInIp?:string|null;
		tokenName?:string|null;
		apiToken?:string|null;
		timezone?:string|null;
		timezoneOffset?:number|null;
		timeFormat?:string;
		sieveSize?:string;
		particleSizeDistributionModel?:string;
		unitSystem?:string;
		particleUnit?:string;
		quantityUnit?:string;
		dateFormat?:string;
		cameraSpeed?:number;
		dailySummaryMail?:boolean;
		minTargetSize?:number|null;
		maxTargetSize?:number|null;
		targetIntervals?:number|null;
		customSizeRanges?:string;
		preferCustomSizes?:boolean;
		password:string;
	}


	meta.generateRest("user",[
		{
			name: "email",
			type: "string",
			decorators: {}
		},{
			name: "authToken",
			type: "string",
			decorators: {}
		}
	]);

	export interface ReadableProps{
		id:number;
		clientId:number;
		email:string;
		authToken?:string|null;
		permissions?:number;
		firstName?:string|null;
		lastName?:string|null;
		createdAt?:DateTime;
		lastSignInAt?:DateTime|null;
		lastSignInIp?:string|null;
		tokenName?:string|null;
		apiToken?:string|null;
		timezone?:string|null;
		timezoneOffset?:number|null;
		timeFormat?:string;
		sieveSize?:string;
		particleSizeDistributionModel?:string;
		unitSystem?:string;
		particleUnit?:string;
		quantityUnit?:string;
		dateFormat?:string;
		cameraSpeed?:number;
		dailySummaryMail?:boolean;
		minTargetSize?:number|null;
		maxTargetSize?:number|null;
		targetIntervals?:number|null;
		customSizeRanges?:string;
		preferCustomSizes?:boolean;
		password:string;
	}

	export interface ReadablePrimitiveProps{
		id:number;
		clientId:number;
		email:string;
		authToken?:string|null;
		permissions?:number;
		firstName?:string|null;
		lastName?:string|null;
		createdAt?:DateTime;
		lastSignInAt?:DateTime|null;
		lastSignInIp?:string|null;
		tokenName?:string|null;
		apiToken?:string|null;
		timezone?:string|null;
		timezoneOffset?:number|null;
		timeFormat?:string;
		sieveSize?:string;
		particleSizeDistributionModel?:string;
		unitSystem?:string;
		particleUnit?:string;
		quantityUnit?:string;
		dateFormat?:string;
		cameraSpeed?:number;
		dailySummaryMail?:boolean;
		minTargetSize?:number|null;
		maxTargetSize?:number|null;
		targetIntervals?:number|null;
		customSizeRanges?:string;
		preferCustomSizes?:boolean;
		password:string;
	}

	namespace body{

		interface WritableProps{
			clientId:number;
			email:string;
			permissions?:number;
			firstName?:string|null;
			lastName?:string|null;
			tokenName?:string|null;
			apiToken?:string|null;
			timezone?:string|null;
			timezoneOffset?:number|null;
			timeFormat?:string;
			sieveSize?:string;
			particleSizeDistributionModel?:string;
			unitSystem?:string;
			particleUnit?:string;
			quantityUnit?:string;
			dateFormat?:string;
			cameraSpeed?:number;
			dailySummaryMail?:boolean;
			minTargetSize?:number|null;
			maxTargetSize?:number|null;
			targetIntervals?:number|null;
			customSizeRanges?:string;
			preferCustomSizes?:boolean;
			password:string;
		}

		export interface Auth{
			email?:string;
			authToken?:string;
		}

		export interface Get extends Auth{
			where:number;
			fields?:(keyof ReadableProps)[];
		}

		export interface Put extends Auth{
			where:number;
			fields:Partial<WritableProps>;
		}

		export interface Post extends Auth{
			fields:Partial<WritableProps>;
		}

		export interface Delete extends Auth{
			where:number;
		}

		export interface Count extends Auth{
			where?:WhereTable<keyof ReadablePrimitiveProps>;
		}

		export interface Search extends Auth{
			fields?:(keyof ReadablePrimitiveProps)[];
			where?:WhereTable<keyof ReadablePrimitiveProps>;
			range?:[number,number];
			sort?:Partial<Record<keyof ReadablePrimitiveProps,'+'|'-'>>;
		}
	}

	namespace _response{
		export type Get=Partial<ReadableProps>;
		export type Search=Partial<ReadablePrimitiveProps>[];
	}

	export interface RestTypes extends _RestTypes{
		body:{
			Auth:body.Auth;
			Get:body.Get;
			Put:body.Put;
			Post:body.Post;
			Delete:body.Delete;
			Count:body.Count;
			Search:body.Search;
		}

		response: {
			Get:_response.Get;
			Search:_response.Search;
		}
	}

}
