
export function dynamicScriptLoad(src:string){
	const selector=`script[src="${src}"]`;
	if(document.querySelector(selector))
		return Promise.resolve(true);

	const script=document.createElement('script');
	return new Promise<void>(resolve=>{
		script.setAttribute('src',src);
		document.head.appendChild(script);
		script.addEventListener('load',()=>resolve());
	});
}
