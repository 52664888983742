import * as pdfkit from './pdf';
import {math} from '@lib/math';
import {getFont} from './fonts';
import {pathSvgElement} from './path-svg-element';
import {renderElement} from './render-element';
import {fromColor,fromPx,PageInfo} from './shared';

interface Fill{
	rgb:[number,number,number];
	opacity:number;
};

interface Stroke{
	rgb:[number,number,number];
	opacity:number;
	width?:number;
	dash?:number[];
	lineCap?:string;
};

interface RenderProps{
	fillPattern:string;
	fill:Fill;
	stroke:Stroke;
	paintOrder:('fill'|'stroke')[];
	clipPath:string;
}

function applyFill(doc:pdfkit.PDFDocument, fill:Fill){
	doc.fillColor(fill.rgb,fill.opacity);
}

function applyStroke(doc:pdfkit.PDFDocument, stroke:Stroke){
	if(stroke.dash && stroke.dash[0]>0)
		doc.dash(stroke.dash[0],{space: stroke.dash[1]});
	doc.lineCap(stroke.lineCap);
	doc.lineWidth(stroke.width);
	doc.strokeColor(stroke.rgb,stroke.opacity);
}

const alignmentBaselineToBaseline:Record<string,pdfkit.TextOptions['baseline']>={
	auto: 'alphabetic',
	baseline: 'alphabetic',
	'before-edge': undefined,
	'text-bottom': undefined,
	'text-before-edge': undefined,
	middle: 'svg-middle',
	central: 'middle',
	'after-edge': undefined,
	'text-top': undefined,
	'text-after-edge': undefined,
	'ideographic': undefined,
	'alphabetic': 'alphabetic',
	'mathematical': undefined,
	hanging: 'hanging',
	//top: 'top',
	center: 'middle',
	//bottom: 'bottom',
}

class SVGRenderer{
	private readonly opacity=[1];

	public constructor(
		private readonly doc:pdfkit.PDFDocument,
		private readonly page:PageInfo,
		private readonly svg:SVGSVGElement,
		private readonly width:number,
		private readonly height:number,
	){

	}

	public pushViewBoxTransform(
		svgWidth:number,
		svgHeight:number,
		viewBox:DOMRect,
		preAspRat:SVGPreserveAspectRatio
	){
		const doc=this.doc;
		doc.save();
		if(viewBox.width>0 && viewBox.height>0 && (preAspRat.meetOrSlice===preAspRat.SVG_MEETORSLICE_MEET || preAspRat.meetOrSlice===preAspRat.SVG_MEETORSLICE_SLICE)){
			const translate=new math.Vec2(0,0);
			const scale=new math.Vec2(svgWidth/viewBox.width,svgHeight/viewBox.height);
			if(preAspRat.align!==preAspRat.SVG_PRESERVEASPECTRATIO_NONE){
				if(preAspRat.meetOrSlice===preAspRat.SVG_MEETORSLICE_MEET){
					scale.x=math.min(scale.x,scale.y);
				}else{// if(preAspRat.meetOrSlice===preAspRat.SVG_MEETORSLICE_SLICE){
					scale.x=math.max(scale.x,scale.y);
				}
				scale.y=scale.x;
				const w=viewBox.width*scale.x;
				const h=viewBox.height*scale.x;
				if(preAspRat.align===preAspRat.SVG_PRESERVEASPECTRATIO_XMIDYMIN || preAspRat.align===preAspRat.SVG_PRESERVEASPECTRATIO_XMIDYMID || preAspRat.align===preAspRat.SVG_PRESERVEASPECTRATIO_XMIDYMAX)
					translate.x=(svgWidth-w)*0.5;
				else if(preAspRat.align===preAspRat.SVG_PRESERVEASPECTRATIO_XMAXYMIN || preAspRat.align===preAspRat.SVG_PRESERVEASPECTRATIO_XMAXYMID || preAspRat.align===preAspRat.SVG_PRESERVEASPECTRATIO_XMAXYMAX)
					translate.x=svgWidth-w;
				if(preAspRat.align===preAspRat.SVG_PRESERVEASPECTRATIO_XMINYMID || preAspRat.align===preAspRat.SVG_PRESERVEASPECTRATIO_XMIDYMID || preAspRat.align===preAspRat.SVG_PRESERVEASPECTRATIO_XMAXYMID)
					translate.y=(svgHeight-h)*0.5;
				else if(preAspRat.align===preAspRat.SVG_PRESERVEASPECTRATIO_XMINYMAX || preAspRat.align===preAspRat.SVG_PRESERVEASPECTRATIO_XMIDYMAX || preAspRat.align===preAspRat.SVG_PRESERVEASPECTRATIO_XMAXYMAX)
					translate.y=svgHeight-h;
			}

			doc.translate(translate.x,translate.y);
			doc.scale(scale.x,scale.y);
			doc.translate(-viewBox.x, -viewBox.y);
		}
	}

	public popViewBoxTransform(){
		this.doc.restore();
	}

	public renderElements(ele:SVGSVGElement|SVGMarkerElement){
		for(let i=0;i<ele.children.length;++i){
			const child=ele.children.item(i);
			if(child instanceof SVGElement)
				this.renderElement(child);
		}
	}

	public renderForeignObjectElements(ele:SVGSVGElement|SVGMarkerElement){
		for(let i=0;i<ele.children.length;++i){
			const child=ele.children.item(i);
			if(child instanceof SVGElement)
				this.renderForeignObjectElement(child);
		}
	}

	private getRenderProps(
		ele:SVGGraphicsElement,
		styles:CSSStyleDeclaration
	):RenderProps{
		let fill:Fill=null;
		let fillPattern:string=null;
		if(styles.fill.startsWith('url("#') && styles.fill.endsWith('")')){
			fillPattern=styles.fill.substring(6,styles.fill.length-2);
		}else{
			fill=fromColor(styles.fill);
			fill.opacity*=this.opacity.at(-1);
			if(fill.opacity<=0)
				fill=null;
		}

		let stroke=<Stroke>fromColor(styles.stroke);
		stroke.opacity*=this.opacity.at(-1);
		if(stroke.opacity>0){
			stroke.width=fromPx(styles.strokeWidth);
			if(stroke.width>0){
				stroke.dash=styles.strokeDasharray!=='none'?styles.strokeDasharray.split(' ').map(v=>fromPx(v)):null;
				if(styles[<keyof CSSStyleDeclaration>'vectorEffect']==='non-scaling-stroke'){
					const m=ele.getCTM();
					const scale=math.max(math.abs(m.a),math.abs(m.d));//this doesn't work properply if the scales are different not sure what to do --John Lockwood
					stroke.width/=scale;
					if(stroke.dash)
						stroke.dash=stroke.dash.map(v=>v/scale);
				}
				stroke.lineCap=styles.strokeLinecap;
			}else
				stroke=null;
		}else
			stroke=null;

		if(!(fill || fillPattern || stroke))
				return null;

		let clipPath:string=null;
		if(styles.clipPath.startsWith('url("#') && styles.clipPath.endsWith('")'))
			clipPath=styles.clipPath;

		const paintOrder:RenderProps['paintOrder']=[];
		if(fill || fillPattern)
			paintOrder.push('fill');
		if(stroke)
			paintOrder.push('stroke');
		if(styles.paintOrder==='stroke')
			paintOrder.reverse();
		
		return {
			fill,
			fillPattern,
			stroke,
			paintOrder,
			clipPath,
		};
	}

	private applyClipPath(clipPath:string){
		if(clipPath){
			const clipPathEle=this.svg.getElementById(clipPath);
			if(clipPathEle instanceof SVGClipPathElement){
				const pathEle=clipPathEle.firstChild;
				if(pathEle instanceof SVGPathElement){
					let d=pathEle.getAttribute('d');
					this.doc.path(d).clip();
					return true;
				}
			}
		}
		return false;
	}



	private *iteratePattern(patternEle:SVGPatternElement){
		yield *[];
		const pw=patternEle.width.baseVal.value;
		const ph=patternEle.height.baseVal.value;
		for(let y=0;y<this.height;y+=ph){
			for(let x=0;x<this.width;x+=pw){
				yield [x,y];
			}
		}
	}

	private renderFillPattern(ele:SVGElement, fillPattern:string){
		const patternEle=this.svg.getElementById(fillPattern);
		if(patternEle instanceof SVGPatternElement){
			const {doc}=this;
			doc.save();
			// this.applyClipPath(renderProps.clipPath);
			pathSvgElement(doc,ele);
			doc.clip();
			for(const [x,y] of this.iteratePattern(patternEle)){
				doc.save();
				doc.translate(x,y);
				for(let i=0;i<patternEle.children.length;++i){
					const child=patternEle.children.item(i);
					if(child instanceof SVGElement)
						this.renderElement(child);
				}
				doc.restore();
			}
			doc.restore();
		}
	}

	private renderFillAndStroke(ele:SVGGraphicsElement){
		const styles=getComputedStyle(ele);
		const renderProps=this.getRenderProps(ele,styles);
		if(!renderProps)
			return {styles,renderProps};

		const {doc}=this;
		const saveState=!!renderProps.clipPath;
		if(saveState)
			doc.save();
			
		this.applyClipPath(renderProps.clipPath);

		let {fill,fillPattern,stroke,paintOrder}=renderProps;
		if(fillPattern){
			for(const step of paintOrder){
				if(step==='fill'){
					this.renderFillPattern(ele,fillPattern);
				}else if(step==='stroke'){
					applyStroke(doc,stroke);
					pathSvgElement(doc,ele);
					doc.stroke();
				}
			}
		}else{
			if(!pathSvgElement(doc,ele))
				paintOrder=paintOrder.filter(v=>v!=='fill');

			if(paintOrder[0]==='fill' && paintOrder[1]==='stroke'){
				applyFill(doc,fill);
				applyStroke(doc,stroke);
				doc.fillAndStroke();
			}else{
				for(const step of paintOrder){
					if(step==='fill'){
						applyFill(doc,fill);
						doc.fill();
					}else if(step==='stroke'){
						applyStroke(doc,stroke);
						doc.stroke();
					}
				}
			}
		}
		return {styles,renderProps};		
	}
	
	private renderElement(ele:SVGElement){
		if(ele instanceof SVGGElement){
			this.renderGroupElement(ele);
		}else if(ele instanceof SVGTextElement){
			this.renderTextElement(ele);
		}else if(ele instanceof SVGForeignObjectElement){
			//we don't handle this here, handled in html element code
		}else if(ele instanceof SVGRectElement){
			this.renderFillAndStroke(ele);
		}else if(ele instanceof SVGCircleElement){
			this.renderFillAndStroke(ele);
		}else if(ele instanceof SVGEllipseElement){
			this.renderFillAndStroke(ele);
		}else if(ele instanceof SVGLineElement){
			this.renderFillAndStroke(ele);
		}else if(ele instanceof SVGPolylineElement){
			if(ele.points.length<2)
				return;
			this.renderFillAndStroke(ele);
		}else if(ele instanceof SVGPathElement){
			const {styles,renderProps}=this.renderFillAndStroke(ele);
			const strokeWidth=renderProps?.stroke?.width ?? 0;
			if(strokeWidth>0){
				let marker=this.getMarkerFromUrlAttr(styles.markerStart);
				if(marker){
					const pathLength=ele.getTotalLength();
					const a=math.Vec2.from(ele.getPointAtLength(0));
					const b=math.Vec2.from(ele.getPointAtLength(pathLength*0.5));
					const v=b.clone().sub(a).normalize();
					this.renderMarker(marker,strokeWidth,a,v);
				}

				marker=this.getMarkerFromUrlAttr(styles.markerEnd);
				if(marker){
					const pathLength=ele.getTotalLength();
					const a=math.Vec2.from(ele.getPointAtLength(pathLength*0.95));
					const b=math.Vec2.from(ele.getPointAtLength(pathLength));
					const v=b.clone().sub(a).normalize();
					this.renderMarker(marker,strokeWidth,b,v);
				}
			}
		}
	}

	private renderGroupElement(ele:SVGGElement){
		const {doc}=this;
		const styles=getComputedStyle(ele);
		doc.save();
		this.opacity.push(+styles.opacity);
		setSVGTransform(doc,ele);
		for(let i=0;i<ele.children.length;++i){
			const child=ele.children.item(i);
			if(child instanceof SVGElement)
				this.renderElement(child);
		}
		this.opacity.pop();
		doc.restore();		
	}

	private renderTextElement(ele:SVGTextElement){
		const {doc}=this;
		const styles=getComputedStyle(ele);
		const renderProps=this.getRenderProps(ele,styles);
		const text=ele.textContent;
		if(text!=='' && renderProps){
			if(ele.x.baseVal.length===0 || ele.y.baseVal.length===0)
				return;
			let x=ele.x.baseVal.getItem(0).value;
			let y=ele.y.baseVal.getItem(0).value;

			doc.save();
			setSVGTransform(doc,ele);

			const {fill,stroke,paintOrder}=renderProps;
			let baseline=alignmentBaselineToBaseline[styles.alignmentBaseline];
			const options:pdfkit.TextOptions={
				fill: true,
				lineBreak: false,
				align: 'left',//this only aligns the text inside the width you give it but that doesn't work, so alignment must be done below manually
				baseline,
			};
			const font=getFont(styles);
			doc.font(font.family);
			doc.fontSize(font.size);
			let width=doc.widthOfString(text,options);
			if(styles.textAnchor==='middle')
				x-=width*0.5;
			else if(styles.textAnchor==='end')
				x-=width;

			if(paintOrder[0]==='fill' && paintOrder[1]==='stroke'){
				applyFill(doc,fill);
				applyStroke(doc,stroke);
				options.fill=true;
				options.stroke=true;
				doc.text(text,x,y,options);
			}else{
				for(const step of paintOrder){
					if(step==='fill'){
						applyFill(doc,fill);
						options.fill=true;
						options.stroke=false;
						doc.text(text,x,y,options);
					}else if(step==='stroke'){
						applyStroke(doc,stroke);
						options.fill=false;
						options.stroke=true;
						doc.text(text,x,y,options);
					}
				}
			}
			doc.restore();
		}
	}

	private getEleFromUrl(url:string){
		if(url.startsWith('url("#') && url.endsWith('")')){
			const id=url.substring(6,url.length-2);
			const ele=this.svg.getElementById(id);
			return ele ?? null;
		}
		return null;
	}

	private getMarkerFromUrlAttr(url:string){
		const ele=this.getEleFromUrl(url);
		if(ele instanceof SVGMarkerElement)
			return ele;
		return null;
	}

	private renderMarker(marker:SVGMarkerElement, strokeWidth:number, p:math.Vec2, v:math.Vec2){
		const markerWidth=marker.markerWidth.baseVal.value*strokeWidth;
		const markerHeight=marker.markerHeight.baseVal.value*strokeWidth;
		const markerUnits=marker.markerUnits.baseVal;//should be 2 for stroke width
		const refX=marker.refX.baseVal.value;
		const refY=marker.refY.baseVal.value;

		const doc=this.doc;
		doc.save();
		doc.translate(p.x,p.y);
		doc.rotate(math.R2D*v.angle());

		this.pushViewBoxTransform(markerWidth,markerHeight,marker.viewBox.baseVal,marker.preserveAspectRatio.baseVal);
		doc.translate(-refX,-refY);
		this.renderElements(marker);
		this.popViewBoxTransform();

		doc.restore();

	}

	private renderForeignObjectElement(ele:SVGElement){
		if(ele instanceof SVGForeignObjectElement){
			for(let i=0;i<ele.children.length;++i){
				const child=ele.children.item(i);
				if(child instanceof HTMLElement){
					renderElement(this.doc,this.page,null,child,0);
				}
			}
		}else if(ele instanceof SVGGElement){
			for(let i=0;i<ele.children.length;++i){
				const child=ele.children.item(i);
				if(child instanceof SVGElement)
					this.renderForeignObjectElement(child);
			}
		}
	}

}

function setSVGTransform(doc:pdfkit.PDFDocument, ele:SVGGraphicsElement){
	for(let i=0;i<ele.transform.baseVal.numberOfItems;++i){
		let transform=ele.transform.baseVal.getItem(i);
		doc.transform(transform.matrix.a,transform.matrix.b,transform.matrix.c,transform.matrix.d,transform.matrix.e,transform.matrix.f);
	}
}

export function renderSVG(doc:pdfkit.PDFDocument, page:PageInfo, ele:SVGSVGElement, styles:CSSStyleDeclaration){
	const meta=page.meta.get(ele);
	if(!meta)
		return;

	const svgRect=meta.rect;
	svgRect.x+=ele.clientLeft;
	svgRect.y+=ele.clientTop;
	doc.save();
	doc.translate(svgRect.x,svgRect.y);
	if(styles.overflow==='hidden')
		doc.rect(0,0,svgRect.width,svgRect.height).clip();

	const renderer=new SVGRenderer(doc,page,ele,svgRect.width,svgRect.height);
	renderer.pushViewBoxTransform(svgRect.width,svgRect.height,ele.viewBox.baseVal,ele.preserveAspectRatio.baseVal);
	renderer.renderElements(ele);
	renderer.popViewBoxTransform();
	doc.restore();
	renderer.renderForeignObjectElements(ele);
}
