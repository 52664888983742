import { Box as _Box } from '../math/box';
import { Vec } from "../math/vec";
import { BoxTreeClosest } from './box-tree-closest';
import { BoxTreeNode } from "./box-tree-node";

export class BoxTreeLeaf<Item, Box extends _Box<Vec>, Data> extends BoxTreeNode<Item,Box,Data>{
	public items:Item[]=[];
	public data:Data;

	public constructor(
		itemsBox:Box,
		dim:number,
	){
		super(itemsBox,dim);
	}

	public addItem(
		nodePos:Vec,
		item:Item,
		itemBoxUnion:(box:Box,item:Item)=>void,
	){
		if(item!==null)
			this.items.push(item);
		itemBoxUnion(this.itemsBox,item);
		return this;
	}

	public addItems(
		nodePos:Vec,
		items:Item[],
		itemBox:Box,
	){
		this.items=this.items.concat(items);
		this.itemsBox.union(itemBox);
		return this;
	}

	public deleteItem(item:Item){
		const len=this.items.length;
		this.items=this.items.filter(v=>v!==item);
		return len!==this.items.length;
	}

	public filterDuplicateItems(){
		this.items=this.items.unique();
	}

	public updateItemsBox(itemBoxUnion:((box:Box,item:Item)=>void)|undefined){
		if(itemBoxUnion){
			this.itemsBox.setEmpty();
			for(const item of this.items)
				itemBoxUnion(this.itemsBox,item);
		}
	}

	public *decendantNodes():Generator<BoxTreeNode<Item,Box,Data>,void,undefined>{
		yield this;
	}

	public *decendantLeafs():Generator<BoxTreeLeaf<Item,Box,Data>,void,undefined>{
		yield this;
	}

	public filterLeafs(leafs:this[], condition:(node:BoxTreeNode<Item,Box,Data>)=>boolean){
		if(condition(this)){
			leafs.push(this);
		}
	}

	public closest<T extends Vec>(focus:T, distSqFn:(item:Item, focus:T)=>number, found:BoxTreeClosest<Item,Box,Data>){
		for(const item of this.items){
			const distSq=distSqFn(item,focus);
			if(distSq<found.distSq){
				found.node=this;
				found.item=item;
				found.distSq=distSq;
			}
		}
	}
}
