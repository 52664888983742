import { _keysEqual } from "./is-key-diff";

Set.prototype.addAll=function(items){
	for(const item of items)
		this.add(item);
}

Set.prototype.array=function(){
	return Array.from(this);
}

Set.prototype.deleteIf=function(condition){
	for(const v of this){
		if(condition(v))
			this.delete(v);
	}
}

Set.prototype.equals=function(that){
	if(that.size!=that.size)
		return false;

	for(let v of this.values()){
		if(!that.has(v))
			return false;
	}
	return true;
}

Set.prototype.every=function(predicate:(value:any)=>unknown){
	for(const v of this){
		if(!predicate(v))
			return false;
	}
	return true;
}

Set.prototype.filter=function(passes:(v:any)=>boolean){
	const that=new Set<any>();
	this.forEach(v=>{
		if(passes(v))
			that.add(v);
	});
	return that;
}

Set.prototype.keyDiff=function(that){
	const added=new Set<any>();
	for(const v of that.keys()){
		if(!this.has(v)){
			added.add(v);
		}
	}
	const deleted=new Set<any>();
	for(const v of this){
		if(!that.has(v)){
			deleted.add(v);
		}
	}
	return {added,deleted};
}

Set.prototype.keysEqual=_keysEqual;

Set.prototype.map=function(change:(v:any)=>any){
	const that=new Set<any>();
	for(const v of this.values())
		that.add(change(v));
	return that;
}

Set.prototype.some=function(predicate){
	for(const v of this){
		if(predicate(<any>v))
			return true;
	}
	return false;
}
