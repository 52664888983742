
	<div class="y-axis" #yAxis >
		<div
			*ngIf="(yAxis$|async).name"
			class="title"
		>
			<div>
				{{(yAxis$|async).name}}
			</div>
		</div>
		<div class="labels">
			<div
				*ngFor="let label of (yAxis$|async).labels"
				class="label"
				[style.height]="label.positionLength*100+'%'"
			>
				<div class="text">{{label.value| number : '1.2-2'}}</div>
			</div>
		</div>
		<svg
			class="lines"
			viewBox="0 0 1 1"
			preserveAspectRatio="none"
		>
			<g>
				<line
					*ngFor="let label of (yAxis$|async).labels"
					[attr.y1]="label.position"
					x1="0"
					[attr.y2]="label.position"
					x2="1"
					stroke-width="1"
					vector-effect="non-scaling-stroke"
				>
				</line>
			</g>
		</svg>
	</div>
	<div class="x-axis" #xAxis [ngClass]="{'scroll': scroll}">
		<svg
			class="lines"
			viewBox="0 0 1 1"
			preserveAspectRatio="none"
		>
			<g>
				<line
					*ngFor="let label of (xAxis$|async).labels"
					[attr.x1]="label.position"
					y1="0"
					[attr.x2]="label.position"
					y2="1"
					stroke-width="1"
					vector-effect="non-scaling-stroke"
				>
				</line>
			</g>
		</svg>
	
		<div class="labels">
			<div
				*ngFor="let label of (xAxis$|async).labels"
				class="label"
				[style.width]="label.positionLength*100+'%'"
			>
				<div class="spacer">{{label.value}}</div>
				<div class="text">{{label.value}}</div>
			</div>
		</div>
		<div
			*ngIf="(xAxis$|async).name"
			class="title"
		>
			<div>
				{{(xAxis$|async).name}}
			</div>
		</div>
	</div>

	<div *ngIf="showLoaders && showBeginLoader" class="loader begin">
		<div class="container centered-content">
			<mat-spinner strokeWidth="3" [diameter]="50"></mat-spinner>
		</div>
	</div>

	<div
		#plot
		class="plot"
		[ngClass]="{'scroll': scroll}"
		(click)="onPlotClick($event,null)"
		(mouseover)="onPlotMouseOver($event,null)"
		(mousemove)="onPlotMouseMove($event,null)"
		(mouseout)="onPlotMouseOut($event,null)"
	>
		<div class="guide-lines">
			<div
				*ngFor="let line of (yAxis$|async).labels"
				class="line"
				[style.height]="line.positionLength*100+'%'"
			></div>
		</div>
	
		<svg
			viewBox="0 0 1 1"
			preserveAspectRatio="none"
		>
			<g 
				class="plot-transform"
				[attr.transform]="plotTransform$|async"
			>
				<g opacity="0.1">
					<path
						*ngFor="let line of (plot$|async).lines"
						[attr.d]="line.fillPath"
						[attr.fill]="line.fillColor"
						stroke="none"
						(click)="onPlotClick($event,line)"
						(mousemove)="onPlotMouseMove($event,line)"
					>
					</path>
				</g>
				<g opacity="1">
					<path
						*ngFor="let line of (plot$|async).lines"
						[attr.d]="line.strokePath"
						[attr.stroke]="line.fillColor"
						[attr.stroke-width]="line.width"
						[attr.stroke-dasharray]="line.dash"
						fill="none"
						stroke-linejoin="bevel"
						vector-effect="non-scaling-stroke"
					>
					</path>
				</g>
				<g *ngFor="let set of (plot$|async).occuranceSets">
					<rect
						*ngFor="let occurance of set.occurances"
						[attr.x]="set.left"
						[attr.width]="set.width" 
						[attr.y]="occurance.bottom"
						[attr.height]="occurance.top-occurance.bottom"
						[attr.fill]="occurance.fillColor"
						[attr.stroke]="occurance.strokeColor"
						[attr.stroke-width]="occurance.strokeWidth"
						vector-effect="non-scaling-stroke"
						paint-order="stroke"
						(click)="onPlotClick($event,occurance)"
						(mousemove)="onPlotMouseMove($event,occurance)"
					></rect>
				</g>
			</g>
		</svg>

		<div
			*ngIf="xHighlightBox$|async"
			class="xHighlight"
		>
			<div
				[style.left]="(xHighlightBox$|async).left"
				[style.width]="(xHighlightBox$|async).width"
			>
			<div class="edge"></div>
			<div class="mid">
				<div
					*ngIf="tooltip" 
					#tooltip
					[style.display]="tooltipDisplay"
					class="tooltip">
					<img 
						[src]="tooltipImageUrl"
						onerror="this.src='../../../assets/image_not_found.jpg'"
						alt="tooltip image"
						height="100%"
						width="100%">
				</div>
			</div>
				<div class="edge"></div>
			</div>
		</div>	
	</div>

	<div #loader class="loader end" *ngIf="showLoaders && showEndLoader">
		<div class="container centered-content">
			<mat-spinner strokeWidth="3" [diameter]="50"></mat-spinner>
		</div>
	</div>
	
	<div
		*ngIf="legend && (legend$|async)"
		class="legend"
	>
		<div
			*ngFor="let item of (legend$|async)"
			class="item"
		>
			<div class="text">{{item.name}}</div>
			<div class="color" [style.color]="item.color"></div>
		</div>
	</div>

