<button
	mat-button
	(click)="toggleOpened()"
>
	<span
		class="color"
		[style.backgroundColor]="colorStr"
	>
	&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
	</span>
</button>

<div
	#popup
	*ngIf="isOpened()"
	class="popup"
	[style.left]="popupLeft"
	[style.right]="popupRight"
	[style.top]="popupTop"
	[style.bottom]="popupBottom"
>
	<button mat-button class="close" (click)="toggleOpened()">✖</button>
	
	<slider
		class="hue"
		min="0"
		max="360"
		step="1"
		[(value)]="hue"
		(valueChange)="emitValue()"
		[thumbColor]="'hsl('+hue+', 100%, 50%)'"
	>
	</slider>
	<slider
		class="val"
		min="0"
		max="100"
		step="1"
		[(value)]="val"
		(valueChange)="emitValue()"
		[thumbColor]="'hsl('+hue+', 100%, '+(val*0.5)+'%)'"
		[trackBackground]="'linear-gradient(90deg,hsl(0,0%,0%) 0, hsl('+hue+',100%,50%))'"
	>
	</slider>
	<slider
		class="sat"
		min="0"
		max="100"
		step="1"
		[(value)]="sat"
		(valueChange)="emitValue()"
		[thumbColor]="'hsl('+hue+', '+sat+'%, '+(val*0.5)+'%)'"
		[trackBackground]="'linear-gradient(90deg,hsl('+hue+', 0%, '+(val)+'%) 0, hsl('+hue+', 100%, '+(val*0.5)+'%))'"
	>
	</slider>
</div>