import { Component, OnInit, } from '@angular/core';
import { chart } from '@lib/angular/chart';
import { Observable, combineLatest, firstValueFrom, map } from 'rxjs';
import { FragQueryService } from 'src/injectables/frag-query.service';
import { combineInput, combineQunatityInput, getSummaryData } from '../analysis/analysis.component';
import { op } from '@lib/rxjs';
import { generateChartLines, generateQuantityChartLines, generateTimelineChartLines, generateTimelineQualtityChartLines } from '../chart-transforms';
import { fragQuery } from '../../../../../shared-models/frag-query';
import { DialogService } from '@app/old-ui/dialog/dialog.service';
import { DXXData, Device, RockSizePassingPercent, User } from '@models';
import * as Highcharts from 'highcharts/highstock';
import { math } from '@lib/math';
import { fragmentationChartOptions } from '../reports/frag-camera-report/frag-camera-report.component';
import { highcharts } from '@lib/highcharts';

@Component({
    selector: 'app-shift-analytics',
    templateUrl: './shift-analytics.component.html',
    styleUrls:['./shift-analytics.component.scss']
})

export class ShiftAnalyticsComponent implements OnInit{
    constructor(
        public readonly fragQuery: FragQueryService,
        private readonly dialog:DialogService,
        public readonly user: User,
    ) { }

    public selectedDevice: Device;
    public density:number;
    public slabCount: number = 0;
    public slabs:number;
    public slabDiameter:number;

    public readonly durationOptions = this.fragQuery.durationOptions;
    public readonly duration$ = this.fragQuery.duration$;
    public readonly newPercents$ = this.fragQuery.newPercents$;
    public readonly devices$ = this.fragQuery.devices$;
    public readonly device$ = this.fragQuery.device$;
    public readonly begin$ = this.fragQuery.begin$;
    public readonly end$ = this.fragQuery.end$;
    public readonly shifts$ = this.fragQuery.shifts$;
    public readonly shift$ = this.fragQuery.shift$;
    public readonly chartType$ = this.fragQuery.chartType$;
    public readonly weightType$ = this.fragQuery.weightType$;
    public readonly particleSizeUnit$ = this.fragQuery.particleSizeUnit$;
    public readonly yAxisTitle$=this.fragQuery.QuantityTitle$;
    public readonly particleSizeUnitSystem$ = this.fragQuery.particleSizeUnitSystem$;
    

    public Highcharts: typeof Highcharts = Highcharts;
    public chartOptions:highcharts.Options;
    tableData: Map<string,  {
        dataArr: math.Vec2Like[];
        percentArr: math.Vec2Like[];
        dxxArr: DXXData[];
    }> = new Map();

    ngOnInit(): void {
        this.device$.subscribe(device => {
            this.selectedDevice = device;
            this.slabDiameter=device.slabDiameter
        });

        this.shift$.subscribe((shift)=>{
        this.density=shift.density
        })

          this.fragQuery.timelineSieveSizesAtPercents$.subscribe(data => {
            let totalSlabs = 0;
          
            data.intervals.forEach(interval => {
              if (interval.slabs !== undefined && interval.slabs !== null) {
                totalSlabs += interval.slabs; 
              }
            });
            this.slabs=totalSlabs
          });
    }

    public accumulatedData$:Observable<fragQuery.timeline.sieveSizesAtPercents.Interval>=this.fragQuery.timelineSieveSizesAtPercents$.pipe(
        op.map((input)=>getSummaryData(input))
    )

    isTruck(): boolean {
        return this.selectedDevice && this.selectedDevice.type === 'truck';
    }
    isConveyor(): boolean {
        return this.selectedDevice && this.selectedDevice.type === 'conveyor';
    }

    countSlabs(input: fragQuery.timeline.sieveSizesAtPercents.Response, threshold: number = 12): number {
        if (!input || !input.summary || !input.summary.psd) {
            return 0;
        }
        return input.summary.psd.filter(rock => rock.size > threshold).length;
    }
    
    getTotalTons(): Observable<string> {
        return combineLatest([
            this.fragQuery.timelineSieveSizesAtPercents$,
            this.getWeightContent()
        ]).pipe(
            map(([input, totalWeight]) => {
                if (input && input.summary && totalWeight) {
                    const totalVolume = parseFloat(totalWeight);
                    const totalTons = totalVolume * this.density;
                    return totalTons.toFixed(2);                    
                }
                return null;
            })
        );
    }

    addData(type:string){
        this.fragQuery.addDataAt.next(type);
    };

    getWeightContent(): Observable<string> {
        return this.fragQuery.timelineSieveSizesAtPercents$.pipe(
            op.map(input => (input && input.summary && input.summary.totalWeight) ? input.summary.totalWeight.toFixed(2) : null)
        );
    }

    public readonly chartLines$:Observable<Highcharts.Options>= combineLatest([
        this.fragQuery.newPercents$,
        this.fragQuery.timelineSieveSizesAtPercents$,
        this.fragQuery.addOnTimelineSieveSizesAtPercents$,
        this.fragQuery.particleSizeUnit$
    ]).pipe(
        op.tap(([percents,input,addOn])=>combineInput(input,addOn,this.fragQuery.addDataAt.value)),
        op.tap(input=>{
            if (input[1] && input[1].intervals) {
                this.fragQuery.totalIntervalLength.next((input[1].end.valueOf() - input[1].begin.valueOf()))
                this.fragQuery.intervalsLength.next(input[1].intervals.length)
            }
        }),
        op.map(([percents, input,input2,unit]) => {
            this.generateTablesData(input,percents);
            return generateTimelineChartLines('line',percents, input, unit,this.addData.bind(this));
        })
    )

    public readonly quantityChartLines$:Observable<Highcharts.Options>= combineLatest([
        this.fragQuery.timelineQuantity$,
        this.fragQuery.addOnTimelineQuantity$,
        this.yAxisTitle$
    ]).pipe(
        op.tap(([input,addOn,title])=>combineQunatityInput(input,addOn,this.fragQuery.addDataAt.value)),
        op.map(([input,addOn,title])=>generateTimelineQualtityChartLines('line',input,this.addData.bind(this),title)))
    
    generateTablesData(input: fragQuery.timeline.sieveSizesAtPercents.Response, percents: Map<number, string>): { input: fragQuery.timeline.sieveSizesAtPercents.Response, percents: Map<number, string> } {
        if (input === undefined) {
            return undefined;
        }
        let obj = transformSummary(input, percents);
        if (obj) {
            for (const entry of obj.entries()) {
                const [key, value] = entry;
                this.tableData.set(key,fragmentationChartOptions(value.rockSizes, value.rockSizeDetails, value.dxxValues))
            }
        }
        return { input, percents };
    }
}

export function transformSummary(data: fragQuery.timeline.sieveSizesAtPercents.Response | undefined, percents: Map<number, string>): Map<string, { rockSizes: Array<number>, rockSizeDetails: Array<RockSizePassingPercent>, dxxValues: Array<DXXData>}>{
    if (data) {
        const datamap: Map<string, { rockSizes: Array<number>, rockSizeDetails: Array<RockSizePassingPercent>, dxxValues: Array<DXXData> }> = new Map();
            const sievesizes: Array<number> = [];
            data.summary.psd.forEach(psd => {
                sievesizes.push(psd.size);
            });
            const dxxvalues: Array<DXXData> = [];
            let counter = 1;
            percents.forEach((percent, index) => {
                dxxvalues.push({
                    label: `D${index}`,
                    value: data.summary.dvalues[counter],
                    index: index,
                    color: percent,
                    canDelete: false
                });
                counter++;
            });
            let result = { rockSizes: sievesizes, rockSizeDetails: data.summary.psd, dxxValues: dxxvalues };
            datamap.set('summary', result);
        return datamap;
    }
    return null;
}
