import { Component, ElementRef, EventEmitter, Input, Output, QueryList, TemplateRef, ViewChildren, ViewEncapsulation } from '@angular/core';
import { math } from '@lib/math';
import { DXXData } from '@models';
import { BehaviorSubject } from 'rxjs';
import { FragQueryService } from 'src/injectables/frag-query.service';

interface sieveSizeValues{
    targetSize:number,
    targetRange:number,
    cumulative:number
}

interface dxxValues{
    percentPassing:string,
    value:number
}

@Component({
    selector: 'analysis-table',
    templateUrl: './table.component.html',
    styleUrls: ['./table.component.scss']
})

export class detailsTableComponent {
    constructor(
        private readonly fragQuery:FragQueryService
    ){}

    displayedColumns: string[] = ['percentPassing', 'value', 'targetSize', 'targetRange', 'cumulative'];
    @Input() showDxxTable:boolean = true;
    @Input() dataArr: math.Vec2Like[] = [];
    @Input() percentArr: math.Vec2Like[] = [];
    @Input() dxxArr:DXXData[];
    @Input() size: 'small'|'normal' = 'normal';
    @Input() height: 'small'|'normal' = 'small';
    @Input() direction: 'vertical' | 'horizontal' = 'horizontal';
    @Input() primaryTableRows:number;
    @Input() secondaryTableRows:number;
    @ViewChildren('cardTemplate') cardTemplates!: QueryList<TemplateRef<HTMLElement>>;
    @Output() secondaryTables = new EventEmitter<TemplateRef<HTMLElement>[]>();

    dxxDataTable:dxxValues[]=[];
    primarySieveSizeDataTable:sieveSizeValues[]=[];
    secondarySieveSizeDataTables:sieveSizeValues[][]=[];

    public readonly particleSizeUnit$ = this.fragQuery.particleSizeUnit$;

    ngOnChanges(){
        this.dxxDataTable=[];
        this.primarySieveSizeDataTable=[];
        if(this.percentArr!=undefined){
            let count=0;
            let singleSecondaryTable:sieveSizeValues[]=[];
            this.percentArr.forEach((percent,index)=>{
                let targetSize = percent.x.toFixed(1) 
                let cumulativeValue = Math.round(this.dataArr[index].y * 100) / 100;
                let cumulative = parseFloat(cumulativeValue.toFixed(1))
                let data = {
                    targetSize: Number(targetSize),
                    targetRange: percent.y,
                    cumulative: cumulative
                };
                if(this.primaryTableRows && this.secondaryTableRows){
                    if(index+1<=this.primaryTableRows){
                        this.primarySieveSizeDataTable=[...this.primarySieveSizeDataTable,data];
                    }else{
                        count++;
                            if(count>this.secondaryTableRows){
                                this.secondarySieveSizeDataTables=[...this.secondarySieveSizeDataTables,singleSecondaryTable]
                                singleSecondaryTable=[];
                                count=1;
                            }
                            singleSecondaryTable.push(data);
                    }

                    if(index+1===this.percentArr.length && singleSecondaryTable.length){
                        this.secondarySieveSizeDataTables=[...this.secondarySieveSizeDataTables,singleSecondaryTable]
                    }
                }else{
                    this.primarySieveSizeDataTable=[...this.primarySieveSizeDataTable,data]
                }
            })
        }
        if(this.dataArr!=undefined){
            this.dxxArr.forEach((dxxValue,index)=>{
                let data:dxxValues={
                    percentPassing:dxxValue.label,
                    value:dxxValue.value
                }
                this.dxxDataTable=[...this.dxxDataTable,data]
            })
        }
    }

    ngAfterViewInit(){
        if(this.secondarySieveSizeDataTables.length){
            this.secondaryTables.emit(this.cardTemplates.toArray());
        }
    }
}
