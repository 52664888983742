import { DateTime } from "@lib/date-time";
import { math } from "@lib/math";

export function getChartLineSeperationThreshold(intervals:{end:DateTime}[]){
	const times=intervals.map(v=>v.end.getTime());
	const timeRange=math.Range.fromPoints(times);
	// const seperationThreshold=timeRange.length()/32;
	const seperationThreshold=timeRange.size()*math.sqrt(math.mean(math.differences(times.map(v=>timeRange.getParameter(v)))));
	return seperationThreshold;
}

