import { Vec as _Vec } from "../math/vec";

type MostImportantFn<T>=(string:Readonly<T[]>,ia:number,ic:number)=>number;

function _recursiveStringFilter<T>(
	string:Readonly<T[]>,
	mostImportantFn:MostImportantFn<T>,
	ia:number,
	ic:number,
	out:T[],
){
	if(ia+1>=ic)
		return;

	const mostImportant=mostImportantFn(string,ia,ic);
	if(mostImportant<0)
		return;
	
	_recursiveStringFilter<T>(string,mostImportantFn,ia,mostImportant,out);
	out.push(string[mostImportant]);
	_recursiveStringFilter<T>(string,mostImportantFn,mostImportant,ic,out);
}

export function recursiveStringFilter<T>(
	string:Readonly<T[]>, 
	mostImportantFn:MostImportantFn<T>,
){
	if(string.length<=2)
		return string.slice();

	const out=[string[0]];
	_recursiveStringFilter<T>(string,mostImportantFn,0,string.length-1,out);
	out.push(string.at(-1)!);
	return out;
}

export function douglasPeuckerMostImportant<T,Vec extends _Vec>(
	toPoint:(p:T)=>Vec,
	threshold:number,
	string:Readonly<T[]>,
	ia:number,
	ic:number,
){
	const a=toPoint(string[ia]);
	const c=toPoint(string[ic]);
	const dir=c.clone().sub(a).nrm();
	const dirDotA=dir.dot(a);
	const dirDotC=dir.dot(c);
	let farthestIndex=-1;
	let farthestDistSq=0;
	for(let ib=ia+1;ib<ic;++ib){
		const b=toPoint(string[ib]);
		const dirDotB=dir.dot(b);
		let distSq:number;
		//should we check for sharp bends??
		if(dirDotB<=dirDotA){
			distSq=b.distToSq(a);	
		}else if(dirDotC<=dirDotB){
			distSq=c.distToSq(b);
		}else{
			const abLen=dirDotB-dirDotA;
			const hSq=b.distToSq(a);
			distSq=hSq-abLen**2;
		}
		if(farthestDistSq<distSq){
			farthestIndex=ib;
			farthestDistSq=distSq;
		}
	}
	if(farthestDistSq<=threshold**2)
		return -1;
	return farthestIndex;
}

export function douglasPeuckerFilter<T,Vec extends _Vec>(string:Readonly<T[]>, toPoint:(p:T)=>Vec, threshold:number){
	return recursiveStringFilter(
		string,
		(string,ia,ic)=>douglasPeuckerMostImportant(toPoint,threshold,string,ia,ic),
	);
}
