import {Component,Inject,OnInit} from '@angular/core';
import {MAT_DIALOG_DATA,MatDialogConfig,MatDialogRef} from '@angular/material/dialog';
import {WebClient} from '@injectables';
import {op} from '@lib/rxjs';
import {Client,User} from '@models';

interface Input{
	record:User;
}

@Component({
	selector: 'user-edit-dialog',
	templateUrl: './user-edit-dialog.component.html',
	styleUrls: [
		'./user-edit-dialog.component.scss'
	]
})
export class UserEditDialog implements OnInit{
	static readonly config:MatDialogConfig<Input>={
		autoFocus: false,
		disableClose: true,
	};
	static readonly output:boolean;

	constructor(
		public readonly user:User,
		private readonly webClient:WebClient,
		private host:MatDialogRef<boolean>,
		@Inject(MAT_DIALOG_DATA) private readonly input:Input,
	){
	}

	public readonly clients$=this.webClient.model.client.search$({}).pipe(op.startWith([]));
	public readonly initialClient$=this.clients$
		.pipe(op.map(clients=>clients.find(client=>client.id===this.record.clientId)));

	public record=this.input.record;
	public client:Client;
	public email=this.record.email ?? '';
	public permissions=this.record.permissions;
	public firstName=this.record.firstName ?? '';
	public lastName=this.record.lastName ?? '';
	public password='';

	public ngOnInit(){
		this.initialClient$
			.subscribe(client=>{
				this.client=client;
			});
	}

	public clientDisplayWith(client:Client){
		return client?.name ?? '';
	}

	public valid(){
		if(!this.client)
			return false;
		if(!this.email.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/))
			return false;
		if(!(this.user.id || this.password))
			return false;
		return true;
	}

	public cancel(){
		this.host.close(false);
	}

	public save(){
		this.record.clientId=this.client.id;
		this.record.email=this.email.toLowerCase();
		this.record.permissions=this.permissions;
		this.record.firstName=this.firstName;
		this.record.lastName=this.lastName;
		if(this.password)
			this.record.password=this.password;
		this.host.close(true);
	}
}
