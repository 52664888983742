import { algorithms } from "./algorithms";

export class Web<T>{
	private readonly map=new Map<T,Set<T>>();

	public get size(){
		let size=0;
		for(const set of this.map.values()){
			size+=set.size;
		}
		return size;
	}

	public clear(){
		this.map.clear();
	}

	public has(v:T){
		return this.map.has(v);
	}

	public isConnected(a:T, b:T){
		if(a===b)
			return false;
		return !!this.map.get(a)?.has(b);
	}

	public connect(a:T, b:T){
		if(a===b)
			return;//throw Error('connect to self');
		this.map.getOrAdd(a,()=>new Set()).add(b);
		this.map.getOrAdd(b,()=>new Set()).add(a);
	}

	private halfDelete(a:T, b:T){
		const set=this.map.get(a);
		if(set && set.delete(b)){
			if(set.size===0)
				this.map.delete(a);
			return true;
		}
		return false;
	}

	public disconnect(a:T, b:T){
		if(a===b)
			return;//throw Error('disconnect from self');
		this.halfDelete(a,b);
		this.halfDelete(b,a);
	}

	public getConnections(a:T){
		return this.map.get(a);
	}

	public iterateConnections(a:T):Iterable<T>{
		return this.map.get(a) ?? [];
	}

	public connectionCount(a:T){
		return this.map.get(a)?.size ?? 0
	}

	public sharedConnections(a:T, b:T){
		if(a==b)
			return [];
		const aConnections=this.map.get(a);
		const bConnections=this.map.get(b);
		if(!(aConnections && bConnections))
			return [];
		return [...aConnections].filter(c=>bConnections.has(c));
	}

	public delete(a:T){
		const set=this.map.get(a);
		if(set){
			this.map.delete(a);
			for(const b of set)
				this.halfDelete(b,a);
		}
	}

	public merge(a:T, b:T){
		const set=this.map.get(b);
		if(set){
			this.map.delete(b);
			for(const c of set){
				this.halfDelete(c,b);
				this.connect(a,c);
			}
		}
	}

	public [Symbol.iterator](){
		return this.entriesTwoWay();
	}

	private *entriesTwoWay():Generator<[T,T],void>{
		for(const [a,set] of this.map){
			for(const b of set){
				yield [a,b];
			}
		}
	}
	
	private *entriesOneWay():Generator<[T,T],void>{
		const map=new Map<T,Set<T>>();
		for(const [a,set] of this.map){
			for(const b of set){
				if(!map.get(b)?.has(a)){
					map.getOrAdd(a,()=>new Set()).add(b);
					yield [a,b];
				}
			}
		}
	}
	
	public entries(twoWay=true){
		if(twoWay)
			return this.entriesTwoWay();
		return this.entriesOneWay();
	}

	public keys(){
		return this.map.keys();
	}

	public sets(){
		return this.map.entries();
	}

	public islands(){
		return algorithms.islands(this.keys(),item=>this.iterateConnections(item));
	}

	public paths(
		costFunc?:algorithms.PathCostFunc<T>,
	){
		return algorithms.paths(this.keys(),item=>this.iterateConnections(item),costFunc);
	}

	public pathsNonBranching(){
		let paths=algorithms.paths(this.keys(),a=>this.iterateConnections(a));
		//break paths up in to single line pieces
		const _paths=paths.flatMap(path=>{
			const divides=[0];
			for(let i=1;i<path.length-1;++i){
				if(this.connectionCount(path[i])>=3)
					divides.push(i);
			}
			divides.push(path.length-1);
			const paths:T[][]=[];
			for(const [a,b] of divides.pairs())
				paths.push(path.slice(a,b+1));
			return paths;
		});
		return _paths;
	}
	
	// public pathTo(
	// 	begin:T,
	// 	end:T|Set<T>,
	// 	maxStepCount:number,
	// 	cost:(a:T, b:T)=>number,
	// ){
	// 	return algorithms.pathTo<T>(
	// 		item=>this.iterateConnections(item),
	// 		begin,
	// 		end,
	// 		maxStepCount,
	// 		cost,
	// 	);
	// }
}
