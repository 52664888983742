<div class="input-wrapper">
	<input
		type="number"
		class="mat-mdc-input-element mat-mdc-form-field-input-control mdc-text-field__input"
		[disabled]="disabled"
		[(ngModel)]="valueStr"
		[step]="step"
	/>
	<div class="input-sizer">
		{{maxLengthStr}}
	</div>
</div>
<div>
	{{unitStr}}
</div>
<div class="steppers">
	<button mat-button (click)="doStep(+1)">
		<span class="chevron">
			›
		</span>
	</button>
	<button mat-button (click)="doStep(-1)">
		<span class="chevron">
			›
		</span>
	</button>
</div>
