import { Injectable } from '@angular/core';
import { WebsocketService } from './websocket.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WebrtcService {
  private imageElement: HTMLImageElement | null = null;
  private preloadImageElement: HTMLImageElement | null = null;
  private peerConnection: RTCPeerConnection | null = null;
  private dataChannel: RTCDataChannel | null = null;
  public errorMessage$: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
  public deviceStatus$: BehaviorSubject<string | null> = new BehaviorSubject<string | null>('');
  public chunks: { [imageId: number]: string[] } = {};

  constructor(private websocketService: WebsocketService) { }

  setupPeerConnection(deviceId: string): Promise<void> {
    return new Promise((resolve, reject) => {
      console.log('Setting up peer connection for device:', deviceId);

      var pcConfig: RTCConfiguration = {
        iceServers: [
          {
            urls: 'stun:stun.l.google.com:19302'
          },
          {
            urls: "turn:68.154.96.255:3478",
            username: "strayos",
            credential: "Strayos@2024",
          },
          {
            urls: "turn:68.154.96.255:3478?transport=tcp",
            username: "strayos",
            credential: "Strayos@2024",
          },
        ],
        iceTransportPolicy: 'all'
      };

      this.peerConnection = new RTCPeerConnection(pcConfig);

      this.peerConnection.onicecandidate = event => {
        if (event.candidate) {
          console.log('Sending ICE candidate:', event.candidate);
          this.websocketService.sendMessage({ id: deviceId, type: 'candidate', candidate: event.candidate });
        }
      };

      this.dataChannel = this.peerConnection.createDataChannel('imagesChannel');

      this.dataChannel.onopen = () => {
        console.log('Data channel opened');
        this.errorMessage$.next(null);
        resolve();
      };

      this.dataChannel.onmessage = event => {
        console.log('Message received on data channel:', event.data);
        const message = JSON.parse(event.data);
        const { imageId, chunkIndex, totalChunks, data } = message;

        if (!this.chunks[imageId]) {
          this.chunks[imageId] = [];
        }
        this.chunks[imageId][chunkIndex] = data;

        if (this.chunks[imageId].length === totalChunks && this.chunks[imageId].every(chunk => chunk !== undefined)) {
          const completeBase64Image = this.chunks[imageId].join('');
          this.preloadAndSwapImage(`data:image/png;base64,${completeBase64Image}`);
          delete this.chunks[imageId];
        }
      };

      this.dataChannel.onerror = error => {
        console.error('Data channel error:', error);
        reject('Data channel error');
      };

      this.dataChannel.onclose = () => {
        console.log('Data channel closed');
        this.imageElement.src = '';
        this.errorMessage$.next('Data channel closed, Video streaming not available for device');
      };

      this.peerConnection
        .createOffer()
        .then(offer => this.peerConnection?.setLocalDescription(offer))
        .then(() => {
          console.log('Sending local description:', this.peerConnection?.localDescription);
          this.websocketService.sendMessage({ id: deviceId, type: this.peerConnection?.localDescription?.type, sdp: this.peerConnection?.localDescription?.sdp });
        })
        .catch(error => {
          console.error('Error creating offer:', error);
          reject('Error creating offer');
        });
    });
  }

  async handleSignalingMessage(message: any, peerConnection: RTCPeerConnection | null) {
    console.log('Received signaling message:', message);
    if (!peerConnection) {
      console.error('Peer connection is null. Cannot handle signaling message.');
      return;
    }
    if (message.type === 'answer') {
      await peerConnection?.setRemoteDescription(new RTCSessionDescription({ sdp: message.description, type: message.type }));
    } else if (message.type === 'candidate') {
      try {
        await peerConnection?.addIceCandidate(new RTCIceCandidate({ ...message }));
        console.log('successful in setting ice candidates')
      } catch {
        console.log('error in adding remote candidates')
      }
    } else if (message.type === 'requestStream') {
      const offer = await peerConnection?.createOffer();
      await peerConnection?.setLocalDescription(offer);
      this.websocketService.sendMessage({ type: 'sdp', sdp: offer });
    }

    peerConnection.onicecandidateerror = event => {
      console.error('ICE candidates error::', event)
    }

    peerConnection.oniceconnectionstatechange = event => {
      console.log('ICE connection state change:', peerConnection.iceConnectionState);
    };
  }

  onlineDevice(deviceId:string){
    if(deviceId){
      this.errorMessage$.next(null);
      this.deviceStatus$.next('offline')
      this.websocketService.sendMessage({ id: deviceId, type:'ping'})
      this.websocketService.getMessages().subscribe(
        message => {
          if(message.type==='ping-answer' && message.deviceId===deviceId)
          this.errorMessage$.next(null);
          this.deviceStatus$.next('online')
          this.handleSignalingMessage(message, this.peerConnection)
        },
        error => {
          console.error('WebSocket error:', error);
        }
      );
    }
  }

  initializeImageElement(imageElement: HTMLImageElement, preloadImageElement: HTMLImageElement) {
    this.imageElement = imageElement;
    this.preloadImageElement = preloadImageElement;
  }

  preloadAndSwapImage(newImageUrl: string) {
    if (this.preloadImageElement && this.imageElement) {
      this.preloadImageElement.src = newImageUrl;
      this.preloadImageElement.onload = () => {
        this.imageElement.src = newImageUrl;
      };
    }
  }

  startStream(deviceId: string): Promise<void> {
    console.log('Starting stream for device ID:', deviceId);
    this.cleanup();
    if (this.imageElement && this.imageElement.src === '') {
      this.errorMessage$.next('Video streaming not available for device');
      return Promise.resolve();
    }
    return this.setupPeerConnection(deviceId);
  }

  stopStream() {
    console.log('Stopping stream');
    this.cleanup();
    this.imageElement!.src = '';
  }

  private cleanup() {
    if (this.peerConnection) {
      this.peerConnection.close();
      this.peerConnection = null;
    }
    if (this.dataChannel) {
      this.dataChannel.close();
      this.dataChannel = null;
    }
    if (this.imageElement) {
      this.imageElement.src = '';
      if (this.imageElement.src === '') {
        this.errorMessage$.next('Video streaming not available for device');
      }
    }
  }
}
