<model-table [model]="model" [sort]="{ begin: '-' }" [restClient]="restClient" [editDialog]="editDialog"
    [where]="where$ | async">
    <div class="settings">
        <mat-form-field class="device mat-pickers">
            <mat-label> Device </mat-label>
            <mat-select [value]="device$ | async" (valueChange)="device$.next($event)">
                <mat-option *ngFor="let device of devices$ | async" [value]="device">
                    {{ device.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <ng-template model-table-header>
        <th>Title</th>
        <th>Begin Time</th>
        <th>End Time</th>
        <th matTooltip="Note: This quantity is used by auto generated summary emails only" [matTooltipPosition]="'above'">Quantity Type</th>
        <th>Chart Type</th>
        <th matTooltip="Note: Please change density according to your selected unit" [matTooltipPosition]="'above'">Density</th>
        <th>Emails</th>
    </ng-template>
    <ng-template model-table-row [type]="model" let-record="record">
        <td class="comments">
            {{ record.title }}
        </td>
        <td>
            <div>
                {{ record.begin * 1000 | date : "HH:mm" }}
            </div>
        </td>
        <td>
            <div *ngIf="record.end">
                {{ record.end * 1000 | date : "HH:mm" }}
            </div>
        </td>
        <td>
            <div *ngIf="record.weightType">
                {{ record.weightType }}
            </div>
        </td>
        <td>
            <div *ngIf="record.chartType">
                {{ record.chartType }}
            </div>
        </td>
        <td>
            <div *ngIf="record.density">
                {{ record.density }}
            </div>
        </td>
        <td>
			<mat-chip-set *ngIf="record.emails" class="mat-chip-list-stacked">
				<mat-chip *ngFor="let chip of getEmails(record.emails)" class="chip" >
					{{chip}}
				</mat-chip>
			</mat-chip-set>
		</td>
    </ng-template>
</model-table>