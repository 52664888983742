import { Box as _Box } from '../math/box';
import { Vec } from "../math/vec";
import { BoxTreeClosest } from "./box-tree-closest";
import { BoxTreeLeaf } from './box-tree-leaf';

export abstract class BoxTreeNode<Item, Box extends _Box<Vec>, Data>{
	public tmpDist=0;

	public constructor(
		public readonly itemsBox:Box,
		public readonly dim:number,
	){
	}

	public abstract addItem(
		nodePos:Vec,
		item:Item,
		itemBoxUnion:(box:Box,item:Item)=>void,
	):BoxTreeLeaf<Item,Box,Data>;

	public abstract addItems(
		nodePos:Vec,
		items:Item[],
		itemBox:Box,
	):BoxTreeLeaf<Item,Box,Data>;

	public abstract updateItemsBox(itemBoxUnion:((box:Box,item:Item)=>void)|undefined):void;
	public abstract decendantNodes():Generator<BoxTreeNode<Item,Box,Data>,void,undefined>;
	public abstract decendantLeafs():Generator<BoxTreeLeaf<Item,Box,Data>,void,undefined>;
	public abstract filterLeafs(leafs:this[], condition:(node:BoxTreeNode<Item,Box,Data>)=>boolean):void;
	public abstract closest<T extends Vec>(focus:T, distFn:(item:Item, focus:T)=>number, found:BoxTreeClosest<Item,Box,Data>):void;
}
