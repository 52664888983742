import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@environment";
import { OnlineObservable } from "@lib/dom/online-observable";
import { RestTypes as _RestTypes } from "@lib/model-meta";
import { of,op } from "@lib/rxjs";
import { WebClient as _WebClient } from "@lib/web-client";
import { Client,DataInterval,Device,RockCount,Shift,User } from "@models";
import { RestModel,RestModel as _RestModel } from "./rest-model";
import { RestModelDataIntervalTag } from "./rest-model-data-interval-tag";

@Injectable({
	providedIn: 'root',
})
export class WebClient extends _WebClient{
	constructor(
		httpClient:HttpClient,
	){
		super(httpClient,environment.backendUrl);
	}

	public readonly model={
		client: new RestModel<Client,Client.RestTypes>(Client,this),
		dataInterval: new RestModel<DataInterval,DataInterval.RestTypes>(DataInterval,this),
		dataIntervalTag: new RestModelDataIntervalTag(this),
		device: new RestModel<Device,Device.RestTypes>(Device,this),
		rockCount: new RestModel<RockCount,RockCount.RestTypes>(RockCount,this),
		user: new RestModel<User,User.RestTypes>(User,this),
		shift: new RestModel<Shift,Shift.RestTypes>(Shift,this)
	};

	public readonly serverReachable$=new OnlineObservable()
		.pipe(
			op.switchMap(online=>{
				if(!online)
					return of(false);
				return this.rest$({
					route: 'up',
					method: 'post',
					body: {},
					pollInveral: 5000,
				}).pipe(op.map(r=>r.toBool()));
			}));	
}

export namespace WebClient{
	export type RestModel<
		Instance extends {id:number},	
		RestTypes extends _RestTypes,
	>=_RestModel<
		Instance,
		RestTypes
	>;

	export type RestTypes=_RestTypes;
}
