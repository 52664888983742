<div class="auth-content">
  <p>Welcome, kindly input your details to sign in<p>
  <div class="input-wrapper">
    <div>
      <label>Email</label>
      <mat-form-field appearance="outline">
        <input matInput type="text" [(ngModel)]="email" (keyup)="loginOnEnter($event)"/>
      </mat-form-field>
    </div>
    <div>    
      <label>Password</label>
      <mat-form-field appearance="outline">
        <input matInput type="password" [(ngModel)]="password" (keyup)="loginOnEnter($event)"/>
      </mat-form-field>
    </div>
  </div>
  <div *ngIf="errors"class='error'>
		<div *ngFor="let error of errors">
		{{error}}
		</div>
	</div>
  <button class="button" mat-flat-button color="primary" (click)="login()">Login</button>
</div>
