import { Component, Input } from '@angular/core';
import { chart } from '@lib/angular/chart';
import { DateTime } from '@lib/date-time';
import { FragQueryService } from 'src/injectables/frag-query.service';

@Component({
  selector: 'report-page-timeline',
  templateUrl: './timeline-page.component.html',
  styleUrls:['./timeline-page.component.scss']
})

export class ReportTimelinePageComponent {
  constructor(
    public fragQuery:FragQueryService
  ){}

  @Input() timelineData:chart.line.Series[];
  @Input() startDate:DateTime;
  @Input() endDate:DateTime;
  
  public readonly particleSizeUnit$ = this.fragQuery.particleSizeUnit$;
}
