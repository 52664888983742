import { Component, ElementRef, HostBinding, Input, ViewChild } from '@angular/core';
import { ReportService } from '../report.service';

@Component({
	selector: 'report-page',
	templateUrl: './page.component.html',
	styleUrls: ['./page.component.scss'],
})
export class ReportPageComponent{
	constructor(
		public readonly report:ReportService,
	){
	}

	@ViewChild('contentWindow') contentWindow:ElementRef<HTMLElement>;

	@Input() footer=true;

	@HostBinding('style.display') get styleDisplay(){
		return this.closed?'none':'';
	}

	public closed=false;

	// public onPointerDown(evt:PointerEvent){
	// 	if(this.report.markupEditor){
	// 		this.report.markupEditor.onMarkupAnnotationStart(evt);
	// 	}
	// }

	// public onPointerMove(evt:PointerEvent){
	// 	if(this.report.markupEditor){
	// 		this.report.markupEditor.onMarkupAnnotationDraw(evt);
	// 	}
	// }

	// public onLeave(evt:PointerEvent|MouseEvent){
	// 	if(this.report.markupEditor){
	// 		this.report.markupEditor.onMarkupAnnotationEnd(evt);
	// 	}
	// }
}
