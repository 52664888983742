import { Component } from '@angular/core';
import { WebClient } from '@injectables';
import { FragQueryService } from 'src/injectables/frag-query.service';
import { Shift } from '../../../../../../shared-models/public/shift';
import { ShiftEditDialogComponent } from '@app/shared/edit-dialog/shift/shift-edit-dialog.component';
import { op } from '@lib/rxjs';

@Component({
    selector: 'shifts',
    templateUrl: './shifts.component.html',
    styleUrls: ['./shifts.component.scss']
})
export class ShiftsComponent {
    constructor(
        private readonly webClient: WebClient,
        private readonly fragQuery: FragQueryService,
    ) { };

    public readonly devices$ = this.fragQuery.devices$;
    public readonly device$ = this.fragQuery.device$;

    public readonly model = Shift;
    public readonly restClient = this.webClient.model.shift;
    public readonly editDialog = ShiftEditDialogComponent;
    public readonly where$ = this.device$.pipe(
        op.map((device) => ({
            deviceId: device.id,
        }))
    );

    public getEmails(emailCsv: string){
		return emailCsv !== null ? emailCsv.split(',') : null;
	}
}
