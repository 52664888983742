import * as pdfkit from './pdf';
import {math} from '@lib/math';
import {fontAscents,getFont} from './fonts';
import {ElementMeta,fromColor,fromPx,PageInfo} from './shared';

function trimLeftSpacesOnly(s:string){
	for(let i=0;i<s.length;++i){
		if(s[i]!==' ')
			return s.substring(i);
	}
	return s;
}

function trimRightSpacesOnly(s:string){
	for(let i=s.length-1;i>=0;--i){
		if(s[i]!==' ')
			return s.substr(0,i+1);
	}
	return s;
}

function getLineHeight(styles:CSSStyleDeclaration){
	if(styles.lineHeight==='normal'){
		let x=fromPx(styles.fontSize);
		x*=1.2;
		return x;
	}

	return fromPx(styles.lineHeight);
}

function getFontColor(styles:CSSStyleDeclaration){
	const color=fromColor(styles.color);
	color.opacity*=+styles.opacity;
	return color;
}

class TextChild{
	public x=0;
	public y=0;
	public trimmedWidth=this.width;

	public constructor(
		public readonly ele:Text|HTMLElement,
		public text:string,
		public fontFamily:string,
		public fontSize:number,
		public lineHeight:number,
		public verticalAlign:string,
		public color:ReturnType<typeof getFontColor>,
		public width?:number,
		public height?:number,
	){
	}

	public mergeable(that:TextChild){
		return (
			this.text
			&& that.text
			&& that.fontFamily===this.fontFamily
			&& that.fontSize===this.fontSize
			&& that.lineHeight===this.lineHeight
			&& that.verticalAlign===this.verticalAlign
			&& that.color===this.color
		);
	}

	public calcWidth(doc:pdfkit.PDFDocument){
		if(this.text){
			doc.font(this.fontFamily).fontSize(this.fontSize);
			//this.spaceWidth=math.round(doc.widthOfString('aa aa')-doc.widthOfString('aaaa'));
			this.width=doc.widthOfString(this.text,{lineBreak:false});
			this.height=doc.heightOfString(this.text,{lineBreak:false});
			this.trimmedWidth=doc.widthOfString(this.text.trim(),{lineBreak:false});
		}
	}

	public applyStyles(doc:pdfkit.PDFDocument){
		// let stroke=false;
		let lineWidth=0;
		let strokeColor:[number,number,number]=[0,0,0];
		// if(styles.textShadow!=='none'){
		// 	const matches=styles.textShadow.match(/(rgb\([^,]+,[^,]+,[^)]+\)) [^\s]+ [^\s]+ ([0-9]+px)/);
		// 	if(matches){
		// 		stroke=true;
		// 		strokeColor=fromColor(matches[1]).rgb;
		// 		lineWidth=fromPx(matches[2])*3;
		// 	}
		// }
		doc.font
		doc
		.lineWidth(lineWidth)
		.lineJoin('round')
		.fillOpacity(this.color.opacity)
		.fill(this.color.rgb)
		.stroke(strokeColor)
		.font(this.fontFamily)
		.fontSize(math.floor(this.fontSize));
	}
}

function flattenNodes(out:Node[], page:PageInfo, node:Node){
	node.childNodes.forEach(node=>{
		if(node instanceof HTMLElement){
			const meta=page.meta.get(node);
			if(!meta)
				return;
			if(meta.styles.display==='contents'){
				flattenNodes(out,page,node);
				return;
			}
		}
		out.push(node)
	});
	return out;
}

function isPartOfTextFlow(meta:ElementMeta, ele:HTMLElement){
	const {styles}=meta;
	return (styles.display==='inline' && (styles.position==='static' || styles.position==='relative') && ele.childElementCount===0)
}

function getTextLines(doc:pdfkit.PDFDocument, page:PageInfo, container:HTMLElement, styles:CSSStyleDeclaration, containerRect:{width:number,height:number}){
	// if(styles.writingMode==='vertical-rl')
	// 	containerRect={width:containerRect.height,height:containerRect.width};

	let children:TextChild[]=[];

	const font=getFont(styles);
	const lineHeight=math.max(font.size,getLineHeight(styles));
	const verticalAlign=styles.verticalAlign;
	const color=getFontColor(styles);

	const nodes=flattenNodes([],page,container);
	for(const ele of nodes){
		if(ele instanceof Text){
			let text=trimLeftSpacesOnly(ele.textContent);
			if(text.length===0)
				continue;
			if(styles.textTransform==='uppercase')
				text=text.toUpperCase();

			const words=text.match(/[^\s]+\s*/g);
			if(words){
				for(const word of words){
					children.push(new TextChild(
						ele,
						word,
						font.family,
						font.size,
						lineHeight,
						verticalAlign,
						color,
					));
				}
			}
			continue;
		}

		if(!(ele instanceof HTMLElement))
			continue;

		const meta=page.meta.get(ele);
		if(!meta)
			continue;

		if(meta.styles.display==='inline-block' || ele instanceof HTMLImageElement){
			children.push(new TextChild(
				ele,
				null,
				font.family,
				font.size,
				lineHeight,
				verticalAlign,
				color,
				meta.rect.width,
				meta.rect.height,
			));
			continue;
		}

		if(isPartOfTextFlow(meta,ele)){
			let text=trimLeftSpacesOnly(ele.textContent);
			if(text.length===0)
				continue;
			if(meta.styles.textTransform==='uppercase')
				text=text.toUpperCase();

			const font=getFont(meta.styles);
			const lineHeight=math.max(font.size,getLineHeight(meta.styles));
			const verticalAlign=meta.styles.verticalAlign;
			const color=getFontColor(meta.styles);
			const words=text.match(/[^\s]+\s*/g);
			if(words){
				for(const word of words){
					children.push(new TextChild(
						ele,
						word,
						font.family,
						font.size,
						lineHeight,
						verticalAlign,
						color,
					));
				}
			}
			continue;
		}
	}

	if(children.length===0)
		return [];

	if(children.at(-1).text)
		children.at(-1).text=trimRightSpacesOnly(children.at(-1).text);

	for(const child of children)
		child.calcWidth(doc);
	
	const containerWidth=math.ceil(containerRect.width);
	let containerHeight=containerRect.height;
	const lines:TextChild[][]=[[]];
	let lastLine=lines.at(-1);
	let x=0,y=0;
	for(const child of children){
		if(x+child.trimmedWidth>containerWidth){
			let lineHeight=math.max(0,...lastLine.map(v=>v.lineHeight));
			if(x===0){
				//this piece is bigger than the line, just leave it there on its own line
				child.x=x;
				lastLine.push(child);
				lineHeight=math.max(child.lineHeight,lineHeight);
				y+=lineHeight;
				lines.push(lastLine=[]);
				continue;
			}

			if(containerHeight-y<1.25*lineHeight){
				//this piece should mathematically go to the next line but it seems like there are no more lines
				//browser sometimes cheats it seems when telling size to just tack it to the end of it anyways
				child.x=x;
				const last=lastLine.at(-1);
				if(last?.mergeable(child)){
					last.text+=child.text;
					last.calcWidth(doc);
				}else
					lastLine.push(child);
				lineHeight=math.max(child.lineHeight,lineHeight);
				y+=lineHeight;
				lines.push(lastLine=[]);
				continue;
			}

			x=0;
			y+=lineHeight;
			lines.push(lastLine=[]);
		}

		child.x=x;
		const last=lastLine.at(-1);
		if(last?.mergeable(child)){
			last.text+=child.text;
			last.calcWidth(doc);
		}else
			lastLine.push(child);
		x+=child.width;
	}

	if(lastLine.length===0)
		lines.pop();
	return lines;
}

export function renderText(doc:pdfkit.PDFDocument, page:PageInfo, ele:HTMLElement, styles:CSSStyleDeclaration, paddingRect:DOMRect){
	//const inTableCell=ele instanceof HTMLTableCellElement;

	const shouldDebug=false;//styles.writingMode==='vertical-rl';
	if(shouldDebug)
		debugger;

	const isVertical=styles.writingMode==='vertical-rl';
	if(isVertical){
		doc.save();
		doc.translate(paddingRect.right,paddingRect.y);
		doc.rotate(90);
		paddingRect=new DOMRect(0,0,paddingRect.height,paddingRect.width);
	}
	const lines=getTextLines(doc,page,ele,styles,paddingRect);
	if(lines.length===0)
		return;
	

	const lineHeight=getLineHeight(styles);
	if(shouldDebug){
	}

	lines.reverse();
	let y=paddingRect.height;
	for(const children of lines){
		const last=children.at(-1);
		const lineWidth=last?last.x+last.trimmedWidth:9;
		let x=paddingRect.x;
		if(styles.textAlign==='center')
			x=paddingRect.x+(paddingRect.width-lineWidth)/2;
		else if(styles.textAlign==='right')
			x=paddingRect.x+paddingRect.width-lineWidth;
		for(const child of children){
			if(!child.text)
				continue;

			child.applyStyles(doc);
			const ascent=fontAscents[child.fontFamily];
			let baseline=lineHeight-child.fontSize;
			if(child.verticalAlign==='middle')
				baseline=(lineHeight-child.fontSize*ascent)/2;
			else if(child.verticalAlign==='top')
				baseline=(lineHeight-child.fontSize*ascent)/2;
			else if(child.verticalAlign==='bottom')
				baseline=(lineHeight-child.fontSize*ascent)/2;
			doc.text(
				child.text,
				x+child.x,
				paddingRect.y+y,
				{
					lineBreak: false,
					fill: true,
					stroke: false,
					//align: styles.textAlign,
					baseline,
					lineGap: 0,
					//wordSpacing: child.spaceWidth,
					width: 10000,
					height: 10000,
					continued: false,
				},
			);

		}
		y-=lineHeight;
	}

	if(isVertical)
		doc.restore();
}
