import {Component,Inject,OnInit} from '@angular/core';
import {MAT_DIALOG_DATA,MatDialogConfig,MatDialogRef} from '@angular/material/dialog';
import {WebClient} from '@injectables';
import {op} from '@lib/rxjs';
import {Client,Device,User} from '@models';
import { FragQueryService } from 'src/injectables/frag-query.service';

interface Input{
	record:Device;
}

@Component({
	selector: 'device-edit-dialog',
	templateUrl: './device-edit-dialog.component.html',
	styleUrls: [
		'./device-edit-dialog.component.scss'
	]
})
export class DeviceEditDialog implements OnInit{
	static readonly config:MatDialogConfig<Input>={
		autoFocus: false,
		disableClose: true,
	};
	static readonly output:boolean;

	constructor(
		public readonly user:User,
		private readonly fragQuery:FragQueryService,
		private readonly webClient:WebClient,
		private host:MatDialogRef<boolean>,
		@Inject(MAT_DIALOG_DATA) private readonly input:Input,
	){
	}

	public readonly particleSizeUnitSystem$ = this.fragQuery.particleSizeUnitSystem$;
	public readonly clients$=this.webClient.model.client.search$({}).pipe(op.startWith([]));
	public readonly initialClient$=this.clients$
		.pipe(op.map(clients=>clients.find(client=>client.id===this.record.clientId)));

	public record=this.input.record;
	public client:Client;
	public name=this.record.name ?? '';
	public type=this.record.type;
	public notes=this.record.notes ?? '';
	public threshold=this.record.threshold ?? 0;
	public slabDiameter=this.record.slabDiameter ?? 0;
	public alertEmail=this.record.alertEmail ?? '';

	public readonly typeOptions=Object.entries(Device.typeOptions);

	public ngOnInit(){
		this.initialClient$
			.subscribe(client=>{
				this.client=client;
			});
	}

	public clientDisplayWith(client:Client){
		return client?.name ?? '';
	}

	public valid(){
		if(!this.client)
			return false;
		if(!this.name)
			return false;
		return true;
	}

	public cancel(){
		this.host.close(false);
	}

	public save(){
		this.record.clientId=this.client.id;
		this.record.name=this.name;
		this.record.type=this.type;
		this.record.notes=this.notes;
		this.record.threshold=this.threshold;
		this.record.slabDiameter=this.slabDiameter;
		this.record.alertEmail=this.alertEmail;
		this.host.close(true);
	}
}
