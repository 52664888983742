import {Component,Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
	selector: 'dialog-confirm',
	templateUrl: './dialog-confirm.component.html',
	styleUrls: ['./dialog-confirm.component.scss'],
})
export class DialogConfirmComponent{
	constructor(
		@Inject(MAT_DIALOG_DATA) public input:DialogConfirmComponent.Input,
	){
		input.confirm??='Confirm';
	}
}

export namespace DialogConfirmComponent{
	export interface Input{
		message:string;
		title?:string;
		confirm?:string;
	}
}