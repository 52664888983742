export const timezonesData = {
    "tz1": [
        { "value": "Default", "display": "Default" },
        { "value": "UTC-05:00", "display": "America/New_York Eastern Standard Time" },
        { "value": "UTC-12:00", "display": "International Date Line West" },
        { "value": "UTC-11:00", "display": "Midway Island, Samoa" },
        { "value": "UTC-10:00", "display": "Hawaii" },
        { "value": "UTC-09:00", "display": "Alaska" },
        { "value": "UTC-08:00", "display": "Pacific Time (US & Canada)" },
        { "value": "UTC-08:00", "display": "Tijuana, Baja California" },
        { "value": "UTC-07:00", "display": "Arizona" },
        { "value": "UTC-07:00", "display": "Chihuahua, La Paz, Mazatlan" },
        { "value": "UTC-07:00", "display": "Mountain Time (US & Canada)" },
        { "value": "UTC-06:00", "display": "Central America" },
        { "value": "UTC-06:00", "display": "Central Time (US & Canada)" },
        { "value": "UTC-06:00", "display": "Guadalajara, Mexico City, Monterrey" },
        { "value": "UTC-06:00", "display": "Saskatchewan" },
        { "value": "UTC-05:00", "display": "Bogota, Lima, Quito, Rio Branco" },
        { "value": "UTC-05:00", "display": "Eastern Time (US & Canada)" },
        { "value": "UTC-05:00", "display": "Indiana (East)" },
        { "value": "UTC-04:00", "display": "Atlantic Time (Canada)" },
        { "value": "UTC-04:00", "display": "Caracas, La Paz" },
        { "value": "UTC-04:00", "display": "Manaus" },
        { "value": "UTC-04:00", "display": "Santiago" },
        { "value": "UTC-03:30", "display": "Newfoundland" },
        { "value": "UTC-03:00", "display": "Brasilia" },
        { "value": "UTC-03:00", "display": "Buenos Aires, Georgetown" },
        { "value": "UTC-03:00", "display": "Greenland" },
        { "value": "UTC-03:00", "display": "Montevideo" },
        { "value": "UTC-02:00", "display": "Mid-Atlantic" },
        { "value": "UTC-01:00", "display": "Cape Verde Is." },
        { "value": "UTC-01:00", "display": "Azores" },
        { "value": "UTC+00:00", "display": "Casablanca, Monrovia, Reykjavik" },
        { "value": "UTC+00:00", "display": "Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London" },
        { "value": "UTC+01:00", "display": "Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna" },
        { "value": "UTC+01:00", "display": "Belgrade, Bratislava, Budapest, Ljubljana, Prague" },
        { "value": "UTC+01:00", "display": "Brussels, Copenhagen, Madrid, Paris" },
        { "value": "UTC+01:00", "display": "Sarajevo, Skopje, Warsaw, Zagreb" },
        { "value": "UTC+01:00", "display": "West Central Africa" },
        { "value": "UTC+02:00", "display": "Amman" },
        { "value": "UTC+02:00", "display": "Athens, Bucharest, Istanbul" },
        { "value": "UTC+02:00", "display": "Beirut" },
        { "value": "UTC+02:00", "display": "Cairo" },
        { "value": "UTC+02:00", "display": "Harare, Pretoria" },
        { "value": "UTC+02:00", "display": "Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius" },
        { "value": "UTC+02:00", "display": "Jerusalem" },
        { "value": "UTC+02:00", "display": "Minsk" },
        { "value": "UTC+02:00", "display": "Windhoek" },
        { "value": "UTC+03:00", "display": "Kuwait, Riyadh, Baghdad" },
        { "value": "UTC+03:00", "display": "Moscow, St. Petersburg, Volgograd" },
        { "value": "UTC+03:00", "display": "Nairobi" },
        { "value": "UTC+03:00", "display": "Tbilisi" },
        { "value": "UTC+03:30", "display": "Tehran" },
        { "value": "UTC+04:00", "display": "Abu Dhabi, Muscat" },
        { "value": "UTC+04:00", "display": "Baku" },
        { "value": "UTC+04:00", "display": "Yerevan" },
        { "value": "UTC+04:30", "display": "Kabul" },
        { "value": "UTC+05:00", "display": "Yekaterinburg" },
        { "value": "UTC+05:00", "display": "Islamabad, Karachi, Tashkent" },
        { "value": "UTC+05:30", "display": "Chennai, Kolkata, Mumbai, New Delhi" },
        { "value": "UTC+05:30", "display": "Sri Jayawardenapura" },
        { "value": "UTC+05:45", "display": "Kathmandu" },
        { "value": "UTC+06:00", "display": "Almaty, Novosibirsk" },
        { "value": "UTC+06:00", "display": "Astana, Dhaka" },
        { "value": "UTC+06:30", "display": "Yangon (Rangoon)" },
        { "value": "UTC+07:00", "display": "Bangkok, Hanoi, Jakarta" },
        { "value": "UTC+07:00", "display": "Krasnoyarsk" },
        { "value": "UTC+08:00", "display": "Beijing, Chongqing, Hong Kong, Urumqi" },
        { "value": "UTC+08:00", "display": "Kuala Lumpur, Singapore" },
        { "value": "UTC+08:00", "display": "Irkutsk, Ulaan Bataar" },
        { "value": "UTC+08:00", "display": "Perth" },
        { "value": "UTC+08:00", "display": "Taipei" },
        { "value": "UTC+09:00", "display": "Osaka, Sapporo, Tokyo" },
        { "value": "UTC+09:00", "display": "Seoul" },
        { "value": "UTC+09:00", "display": "Yakutsk" },
        { "value": "UTC+09:30", "display": "Adelaide" },
        { "value": "UTC+09:30", "display": "Darwin" },
        { "value": "UTC+10:00", "display": "Brisbane" },
        { "value": "UTC+10:00", "display": "Canberra, Melbourne, Sydney" },
        { "value": "UTC+10:00", "display": "Hobart" },
        { "value": "UTC+10:00", "display": "Guam, Port Moresby" },
        { "value": "UTC+10:00", "display": "Vladivostok" },
        { "value": "UTC+11:00", "display": "Magadan, Solomon Is., New Caledonia" },
        { "value": "UTC+12:00", "display": "Auckland, Wellington" },
        { "value": "UTC+12:00", "display": "Fiji, Kamchatka, Marshall Is." },
        { "value": "UTC+13:00", "display": "Nuku'alofa" }
    ],
    "tz2": [
        { "value": "Default", "text": "Default"},
        { "value": "Afghanistan Time", "abbr": "AFT", "offset": 4.5, "isdst": false, "text": "UTC+04:30", "utc": ["Asia/Kabul"] },
        { "value": "Alaskan Standard Time", "abbr": "AKDT", "offset": -8, "isdst": true, "text": "UTC-09:00", "utc": ["America/Anchorage", "America/Juneau", "America/Nome", "America/Sitka", "America/Yakutat"] },
        { "value": "Argentina Standard Time", "abbr": "ART", "offset": -3, "isdst": false, "text": "UTC-03:00", "utc": ["America/Argentina/Buenos_Aires", "America/Argentina/Catamarca", "America/Argentina/ComodRivadavia", "America/Argentina/Cordoba", "America/Argentina/Jujuy", "America/Argentina/La_Rioja", "America/Argentina/Mendoza", "America/Argentina/Rio_Gallegos", "America/Argentina/Salta", "America/Argentina/San_Juan", "America/Argentina/San_Luis", "America/Argentina/Tucuman", "America/Argentina/Ushuaia", "America/Buenos_Aires"] },
        { "value": "Atlantic Standard Time", "abbr": "ADT", "offset": -3, "isdst": true, "text": "UTC-03:00", "utc": ["America/Glace_Bay", "America/Goose_Bay", "America/Halifax", "America/Moncton"] },
        { "value": "Aus Central W. Standard Time", "abbr": "ACWST", "offset": 8, "isdst": false, "text": "UTC+08:45", "utc": ["Australia/Eucla"] },
        { "value": "AUS Central Standard Time", "abbr": "ACST", "offset": 9, "isdst": false, "text": "UTC+09:30", "utc": ["Australia/Darwin"] },
        { "value": "AUS Eastern Standard Time", "abbr": "AEST", "offset": 10, "isdst": false, "text": "UTC+10:00", "utc": ["Australia/Brisbane", "Australia/Lindeman"] },
        { "value": "Azores Standard Time", "abbr": "AZOST", "offset": 0, "isdst": true, "text": "UTC+00:00", "utc": ["America/Scoresbysund", "Atlantic/Azores"] },
        { "value": "Bangladesh Standard Time", "abbr": "BST", "offset": 6, "isdst": false, "text": "UTC+06:00", "utc": ["Asia/Dhaka"] },
        { "value": "Cape Verde Standard Time", "abbr": "CVST", "offset": -1, "isdst": true, "text": "UTC-01:00 ", "utc": ["Atlantic/Cape_Verde"] },
        { "value": "Caucasus Standard Time", "abbr": "CAST", "offset": 4, "isdst": false, "text": "UTC+04:00", "utc": ["Asia/Yerevan"] },
        { "value": "Central Asia Standard Time", "abbr": "CAST", "offset": 6, "isdst": false, "text": "UTC+06:00", "utc": ["Asia/Almaty", "Asia/Aqtau", "Asia/Aqtobe", "Asia/Atyrau", "Asia/Oral", "Asia/Qyzylorda"] },
        { "value": "Central Europe Standard Time", "abbr": "CEST", "offset": 2, "isdst": true, "text": "UTC+02:00", "utc": ["Europe/Belgrade", "Europe/Bratislava", "Europe/Budapest", "Europe/Ljubljana", "Europe/Podgorica", "Europe/Prague"] },
        { "value": "Central Pacific Standard Time", "abbr": "CPST", "offset": 11, "isdst": false, "text": "UTC+11:00", "utc": ["Antarctica/McMurdo", "Pacific/Efate", "Pacific/Guadalcanal", "Pacific/Kosrae", "Pacific/Noumea", "Pacific/Ponape"] },
        { "value": "Central Standard Time", "abbr": "CDT", "offset": -5, "isdst": true, "text": "UTC-06:00", "utc": ["America/Belize", "America/Costa_Rica", "America/El_Salvador", "America/Guatemala"] },
        { "value": "China Standard Time", "abbr": "CST", "offset": 8, "isdst": false, "text": "UTC+08:00", "utc": ["Asia/Hong_Kong", "Asia/Macau", "Asia/Shanghai"] },
        { "value": "Coordinated Universal Time", "abbr": "UTC", "offset": 0, "isdst": false, "text": "UTC+00:00", "utc": ["UCT", "UTC", "Etc/UCT", "Etc/UTC"] },
        { "value": "Dateline Standard Time", "abbr": "DST", "offset": -12, "isdst": false, "text": "UTC-12:00", "utc": ["Etc/GMT+12"] },
        { "value": "E. Australia Standard Time", "abbr": "EAST", "offset": 10, "isdst": false, "text": "UTC+10:00", "utc": ["Australia/Brisbane", "Australia/Lindeman"] },
        { "value": "E. Europe Standard Time", "abbr": "EEDT", "offset": 3, "isdst": true, "text": "UTC+03:00", "utc": ["Europe/Kaliningrad", "Europe/Minsk"] },
        { "value": "E. South America Standard Time", "abbr": "ESAT", "offset": -3, "isdst": false, "text": "UTC-03:00", "utc": ["America/Sao_Paulo"] },
        { "value": "Eastern Standard Time", "abbr": "EST", "offset": -5, "isdst": false, "text": "UTC-05:00", "utc": ["America/Detroit", "America/Indiana/Indianapolis", "America/Indiana/Marengo", "America/Indiana/Petersburg", "America/Indiana/Vevay", "America/Indiana/Vincennes", "America/Indiana/Winamac", "America/Ithaca", "America/Kentucky/Louisville", "America/Kentucky/Monticello", "America/New_York"] },
        { "value": "FLE Standard Time", "abbr": "FDT", "offset": 3, "isdst": true, "text": "UTC+03:00", "utc": ["Europe/Helsinki", "Europe/Kiev", "Europe/Mariehamn", "Europe/Riga", "Europe/Sofia", "Europe/Tallinn", "Europe/Uzhgorod", "Europe/Vilnius"] },
        { "value": "Fiji Standard Time", "abbr": "FST", "offset": 12, "isdst": false, "text": "UTC+12:00", "utc": ["Pacific/Fiji"] },
        { "value": "Georgian Standard Time", "abbr": "GDT", "offset": 4, "isdst": true, "text": "UTC+04:00", "utc": ["Asia/Tbilisi"] },
        { "value": "GMT Standard Time", "abbr": "GDT", "offset": 1, "isdst": true, "text": "UTC+01:00", "utc": ["Europe/Lisbon", "Europe/London"] },
        { "value": "Greenland Standard Time", "abbr": "WGT", "offset": -3, "isdst": false, "text": "UTC-03:00", "utc": ["America/Godthab"] },
        { "value": "Greenwich Mean Time", "abbr": "GMT", "offset": 0, "isdst": false, "text": "UTC+00:00", "utc": ["Africa/Abidjan", "Africa/Accra", "Africa/Bamako", "Africa/Banjul", "Africa/Bissau", "Africa/Conakry", "Africa/Dakar", "Africa/Freetown", "Africa/Lome", "Africa/Monrovia", "Africa/Nouakchott", "Africa/Ouagadougou", "Africa/Sao_Tome", "Africa/Timbuktu", "Atlantic/Reykjavik", "GB", "Iceland"] },
        { "value": "Greenwich Mean Time", "abbr": "GMT", "offset": 0, "isdst": false, "text": "UTC+00:00", "utc": ["Europe/Lisbon", "Europe/London"] },
        { "value": "Greenwich Mean Time", "abbr": "GMT", "offset": 0, "isdst": false, "text": "UTC+00:00", "utc": ["Africa/Abidjan", "Africa/Accra", "Africa/Bamako", "Africa/Banjul", "Africa/Bissau", "Africa/Conakry", "Africa/Dakar", "Africa/Freetown", "Africa/Lome", "Africa/Monrovia", "Africa/Nouakchott", "Africa/Ouagadougou", "Africa/Sao_Tome", "Africa/Timbuktu"] },
        { "value": "GTB Standard Time", "abbr": "GDT", "offset": 3, "isdst": true, "text": "UTC+03:00", "utc": ["Europe/Athens", "Europe/Bucharest"] },
        { "value": "Hawaiian Standard Time", "abbr": "HST", "offset": -10, "isdst": false, "text": "UTC-10:00", "utc": ["Etc/GMT+10", "Pacific/Honolulu", "Pacific/Johnston", "Pacific/Rarotonga", "Pacific/Tahiti"] },
        { "value": "India Standard Time", "abbr": "IST", "offset": 5.5, "isdst": false, "text": "UTC+05:30, New Delhi", "utc": ["Asia/Kolkata"] },
        { "value": "Israel Standard Time", "abbr": "IST", "offset": 3, "isdst": false, "text": "UTC+03:00", "utc": ["Asia/Jerusalem"] },
        { "value": "Jordan Standard Time", "abbr": "JST", "offset": 2, "isdst": false, "text": "UTC+02:00", "utc": ["Asia/Amman"] },
        { "value": "Korea Standard Time", "abbr": "KST", "offset": 9, "isdst": false, "text": "UTC+09:00", "utc": ["Asia/Pyongyang", "Asia/Seoul"] },
        { "value": "Magadan Standard Time", "abbr": "MST", "offset": 12, "isdst": false, "text": "UTC+12:00", "utc": ["Asia/Anadyr", "Asia/Kamchatka", "Asia/Magadan", "Asia/Srednekolymsk"] },
        { "value": "Mauritius Standard Time", "abbr": "MST", "offset": 4, "isdst": false, "text": "UTC+04:00", "utc": ["Indian/Mahe", "Indian/Mauritius", "Indian/Reunion"] },
        { "value": "Middle East Standard Time", "abbr": "MEDT", "offset": 3, "isdst": true, "text": "UTC+03:00", "utc": ["Asia/Baghdad"] },
        { "value": "Morocco Standard Time", "abbr": "MDT", "offset": 1, "isdst": true, "text": "UTC+01:00", "utc": ["Africa/Casablanca"] },
        { "value": "Mountain Standard Time", "abbr": "MDT", "offset": -6, "isdst": true, "text": "UTC-07:00 ", "utc": ["America/Denver"] },
        { "value": "Mountain Standard Time (Mexico)", "abbr": "MDT", "offset": -6, "isdst": true, "text": "UTC-07:00", "utc": ["America/Chihuahua", "America/Mazatlan"] },
        { "value": "Myanmar Standard Time", "abbr": "MST", "offset": 6.5, "isdst": false, "text": "UTC+06:30", "utc": ["Asia/Yangon"] },
        { "value": "Namibia Standard Time", "abbr": "NST", "offset": 1, "isdst": false, "text": "UTC+01:00", "utc": ["Africa/Windhoek"] },
        { "value": "Nepal Time", "abbr": "NPT", "offset": 5.75, "isdst": false, "text": "UTC+05:45", "utc": ["Asia/Kathmandu"] },
        { "value": "New Zealand Standard Time", "abbr": "NZST", "offset": 12, "isdst": false, "text": "UTC+12:00", "utc": ["Pacific/Auckland", "Pacific/Fiji", "Pacific/Tongatapu"] },
        { "value": "Newfoundland Standard Time", "abbr": "NDT", "offset": -2.5, "isdst": true, "text": "UTC-03:30", "utc": ["America/St_Johns"] },
        { "value": "North Central Asia Standard Time", "abbr": "NCAST", "offset": 7, "isdst": false, "text": "UTC+07:00", "utc": ["Asia/Barnaul", "Asia/Novokuznetsk", "Asia/Novosibirsk", "Asia/Omsk", "Asia/Tomsk"] },
        { "value": "North Central Asia Standard Time", "abbr": "NCAST", "offset": 7, "isdst": false, "text": "UTC+07:00", "utc": ["Asia/Novokuznetsk", "Asia/Novosibirsk", "Asia/Omsk"] },
        { "value": "Pacific SA Standard Time", "abbr": "PSST", "offset": -4, "isdst": false, "text": "UTC-05:00", "utc": ["America/Santiago"] },
        { "value": "Pacific Standard Time", "abbr": "PST", "offset": -8, "isdst": false, "text": "UTC-08:00", "utc": ["America/Los_Angeles", "America/Tijuana", "America/Vancouver", "America/Whitehorse", "PST8PDT"] },
        { "value": "Pacific Standard Time (Mexico)", "abbr": "PST", "offset": -8, "isdst": false, "text": "UTC-08:00", "utc": ["America/Santa_Isabel", "America/Tijuana"] },
        { "value": "Pakistan Standard Time", "abbr": "PST", "offset": 5, "isdst": false, "text": "UTC+05:00", "utc": ["Asia/Karachi"] },
        { "value": "Paraguay Standard Time", "abbr": "PYT", "offset": -4, "isdst": false, "text": "UTC-04:00", "utc": ["America/Asuncion"] },
        { "value": "Romance Standard Time", "abbr": "RDT", "offset": 2, "isdst": true, "text": "UTC+02:00", "utc": ["Europe/Brussels", "Europe/Copenhagen", "Europe/Madrid", "Europe/Paris"] },
        { "value": "Russian Standard Time", "abbr": "RDT", "offset": 3, "isdst": true, "text": "UTC+03:00", "utc": ["Europe/Moscow", "Europe/Simferopol", "Europe/Volgograd"] },
        { "value": "SA Eastern Standard Time", "abbr": "EDT", "offset": -3, "isdst": true, "text": "UTC-04:00", "utc": ["America/Argentina/San_Juan", "America/Argentina/San_Luis"] },
        { "value": "SA Pacific Standard Time", "abbr": "SPST", "offset": -5, "isdst": false, "text": "UTC-05:00", "utc": ["America/Bogota", "America/Jamaica", "America/Lima", "America/Panama"] },
        { "value": "SA Western Standard Time", "abbr": "SWT", "offset": -4, "isdst": false, "text": "UTC-04:00", "utc": ["America/Anguilla", "America/Antigua", "America/Aruba", "America/Barbados", "America/Blanc-Sablon", "America/Boa_Vista", "America/Curacao", "America/Dominica", "America/Grand_Turk", "America/Grenada", "America/Guadeloupe", "America/Guyana", "America/Kralendijk", "America/La_Paz", "America/Lower_Princes", "America/Manaus", "America/Marigot", "America/Martinique", "America/Montserrat", "America/Port_of_Spain", "America/Porto_Velho", "America/Puerto_Rico", "America/Santo_Domingo", "America/St_Barthelemy", "America/St_Kitts", "America/St_Lucia", "America/St_Thomas", "America/St_Vincent", "America/Tortola", "America/Virgin"] },
        { "value": "Samoa Standard Time", "abbr": "SST", "offset": 13, "isdst": false, "text": "UTC+13:00", "utc": ["Pacific/Apia"] },
        { "value": "SE Asia Standard Time", "abbr": "SEAST", "offset": 7, "isdst": false, "text": "UTC+07:00", "utc": ["Asia/Bangkok", "Asia/Ho_Chi_Minh", "Asia/Jakarta"] },
        { "value": "Singapore Standard Time", "abbr": "SST", "offset": 8, "isdst": false, "text": "UTC+08:00", "utc": ["Asia/Brunei", "Asia/Kuala_Lumpur", "Asia/Kuching", "Asia/Makassar", "Asia/Manila", "Asia/Singapore", "Etc/GMT-8"] },
        { "value": "South Africa Standard Time", "abbr": "SAST", "offset": 2, "isdst": false, "text": "UTC+02:00", "utc": ["Africa/Blantyre", "Africa/Bujumbura", "Africa/Gaborone", "Africa/Harare", "Africa/Johannesburg", "Africa/Kigali", "Africa/Lubumbashi", "Africa/Lusaka", "Africa/Maputo", "Africa/Maseru", "Africa/Mbabane"] },
        { "value": "Sri Lanka Standard Time", "abbr": "SLST", "offset": 5.5, "isdst": false, "text": "UTC+05:30", "utc": ["Asia/Colombo"] },
        { "value": "Syria Standard Time", "abbr": "SDT", "offset": 3, "isdst": true, "text": "UTC+03:00", "utc": ["Asia/Damascus"] },
        { "value": "Taipei Standard Time", "abbr": "TST", "offset": 8, "isdst": false, "text": "UTC+08:00", "utc": ["Asia/Taipei"] },
        { "value": "Tasmania Standard Time", "abbr": "TST", "offset": 10, "isdst": false, "text": "UTC+10:00", "utc": ["Australia/Hobart"] },
        { "value": "Tokyo Standard Time", "abbr": "TST", "offset": 9, "isdst": false, "text": "UTC+09:00", "utc": ["Asia/Tokyo"] },
        { "value": "Tomsk Standard Time", "abbr": "ToST", "offset": 7, "isdst": false, "text": "UTC+07:00", "utc": ["Asia/Tomsk"] },
        { "value": "Tonga Standard Time", "abbr": "TST", "offset": 13, "isdst": false, "text": "UTC+13:00", "utc": ["Pacific/Enderbury", "Pacific/Fakaofo", "Pacific/Tongatapu"] },
        { "value": "Turkey Standard Time", "abbr": "TDT", "offset": 3, "isdst": true, "text": "UTC+03:00", "utc": ["Europe/Istanbul"] },
        { "value": "Turks And Caicos Standard Time", "abbr": "TCST", "offset": -4, "isdst": false, "text": "UTC-05:00", "utc": ["America/Grand_Turk"] },
        { "value": "Ulaanbaatar Standard Time", "abbr": "UST", "offset": 8, "isdst": false, "text": "UTC+08:00", "utc": ["Asia/Choibalsan", "Asia/Ulaanbaatar"] },
        { "value": "US Eastern Standard Time", "abbr": "UEDT", "offset": -4, "isdst": true, "text": "UTC-05:00", "utc": ["America/Detroit", "America/Grand_Turk", "America/Indiana/Indianapolis", "America/Indiana/Marengo", "America/Indiana/Petersburg", "America/Indiana/Vevay", "America/Indiana/Vincennes", "America/Indiana/Winamac", "America/Ithaca", "America/Kentucky/Louisville", "America/Kentucky/Monticello", "America/New_York", "America/Nipigon", "America/Pangnirtung", "America/Port_Huron", "America/Thunder_Bay"] },
        { "value": "US Mountain Standard Time", "abbr": "UMST", "offset": -7, "isdst": false, "text": "UTC-07:00", "utc": ["America/Campo_Grande", "America/Denver", "America/Edmonton", "America/Mazatlan"] },
        { "value": "W. Australia Standard Time", "abbr": "WAST", "offset": 8, "isdst": false, "text": "UTC+08:00", "utc": ["Australia/Perth"] }, { "value": "W. Central Africa Standard Time", "abbr": "WCAST", "offset": 1, "isdst": false, "text": "UTC+01:00", "utc": ["Africa/Algiers", "Africa/Bangui", "Africa/Brazzaville", "Africa/Douala", "Africa/Kinshasa", "Africa/Lagos", "Africa/Libreville", "Africa/Luanda", "Africa/Malabo", "Africa/Ndjamena", "Africa/Niamey", "Africa/Porto-Novo", "Africa/Tunis", "Etc/GMT-1"] },
        { "value": "W. Europe Standard Time", "abbr": "WEDT", "offset": 1, "isdst": true, "text": "UTC+01:00", "utc": ["Europe/Amsterdam", "Europe/Andorra", "Europe/Belgrade", "Europe/Berlin", "Europe/Brussels", "Europe/Budapest", "Europe/Copenhagen", "Europe/Gibraltar", "Europe/Ljubljana", "Europe/Luxembourg", "Europe/Madrid", "Europe/Malta", "Europe/Monaco", "Europe/Oslo", "Europe/Paris", "Europe/Podgorica", "Europe/Prague", "Europe/Rome", "Europe/San_Marino", "Europe/Sarajevo", "Europe/Skopje", "Europe/Stockholm", "Europe/Tirane", "Europe/Vaduz", "Europe/Vatican", "Europe/Vienna", "Europe/Warsaw", "Europe/Zagreb", "Europe/Zurich"] },
        { "value": "West Asia Standard Time", "abbr": "WAST", "offset": 5, "isdst": false, "text": "UTC+05:00", "utc": ["Asia/Aqtau", "Asia/Aqtobe", "Asia/Ashgabat", "Asia/Dushanbe", "Asia/Oral", "Asia/Samarkand", "Etc/GMT-5"] }, { "value": "West Pacific Standard Time", "abbr": "WPST", "offset": 10, "isdst": false, "text": "UTC+10:00", "utc": ["Pacific/Guam", "Pacific/Port_Moresby", "Pacific/Saipan", "Pacific/Truk"] }, { "value": "Yakutsk Standard Time", "abbr": "YST", "offset": 9, "isdst": false, "text": "UTC+09:00", "utc": ["Asia/Duluth", "Asia/Khandyga", "Asia/Yakutsk"] },
        { "value": "Yukon Standard Time", "abbr": "YST", "offset": -9, "isdst": false, "text": "UTC-09:00", "utc": ["America/Whitehorse"] }
    ]
};

export const dateFormats = [
    'Month DD YYYY',
    'MM/DD/YY',
    'YY/MM/DD',
    'DD/MM/YY',
    'DD/MM/YYYY'
]