import { Component } from '@angular/core';

@Component({
  selector: 'new-password',
  templateUrl: './new-password.component.html',
})
export class NewPasswordComponent {
  resetSuccessful:boolean=true;
  reset(){
    this.resetSuccessful=true;
  }
}
