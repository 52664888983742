<div class="auth-content" *ngIf="!resetSuccessful">
  <p>Create a New Password<p>
  <div class="input-wrapper">
    <div>
      <label>Password</label>
      <mat-form-field appearance="outline">
        <input matInput />
      </mat-form-field>
    </div>
    <div>    
      <label>Password</label>
      <mat-form-field appearance="outline">
        <input matInput />
      </mat-form-field>
    </div>
  </div>
  <button class="button" mat-flat-button color="primary">Reset Password</button>
</div>

<div class="auth-content" *ngIf="resetSuccessful">
  <img src="./../../../../assets/mat-icons/reset-successful.svg">
  <p>Password Reset was successful</p>
  <button class="button" mat-flat-button color="primary">Login</button>
</div>