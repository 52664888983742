import { Component } from '@angular/core';
import { environment } from '@environment';
import { User$ } from '@injectables';


@Component({
    selector: 'sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent {

    public showVideoTab: boolean = environment.showVideoTab;
    constructor(public readonly user$: User$,) { }


}