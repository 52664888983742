<div>
    <div [ngClass]="small? 'small-plaette':''">
        <span [ngClass]="horizontal? 'span-flex':'span-block'">
            <button mat-fab extended disabled class="button-background" *ngIf="blurred===false">
                {{text}}
            </button>
            <button mat-fab extended disabled class="blured-button-background" *ngIf="blurred===true">
                {{text}}
            </button>
            <button [ngClass]="horizontal?'horizontal-button':'vertical-button'" *ngIf="blurred===true" mat-fab extended
                class="color-button-background" [ngStyle]="{'background-color':'#F5F5F5'}">
            </button>
            <button [ngClass]="horizontal?'horizontal-button':'vertical-button'" *ngIf="blurred===false" mat-fab
                extended class="color-button-background" [ngStyle]="{'background-color':color}">
            </button>
        </span>
    </div>
</div>