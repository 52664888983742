<model-table
	[model]="model"
	[restClient]="restClient"
	[editDialog]="editDialog"
	[deletable]="deletable"
>
	<ng-template model-table-header>
		<th>
			Client
		</th>
		<th>
			Email
		</th>
		<th>
			Permissions
		</th>
		<th>
			First Name
		</th>
		<th>
			Last Name
		</th>
		<th>
			Last Sign In At
		</th>
		<th>
			Last Sign In Ip
		</th>
	</ng-template>

	<ng-template
		model-table-row
		[type]="model"
		let-record="record"
	>
		<td>
			{{(clients$|async).get(record.clientId)?.name ?? '\<uknown\>'}}
		</td>
		<td>
			{{record.email}}
		</td>
		<td>
			<span *ngIf="record.permissions===0"></span>
			<span *ngIf="record.permissions===1">Client Admin</span>
			<span *ngIf="record.permissions===2">Super Admin</span>
		</td>
		<td>
			{{record.firstName}}
		</td>
		<td>
			{{record.lastName}}
		</td>
		<td>
			{{record.lastSignInAt|date:'yyyy-MM-dd hh:mm:ss'}}
		</td>
		<td>
			{{record.lastSignInIp}}
		</td>
	</ng-template>
</model-table>
