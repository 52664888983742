
<div mat-dialog-content>
	<div class="title">
	  Live Tag Settings
	  </div>
	  <div class="flex-column">
		<mat-form-field *ngIf="showTimes">
			<mat-label>Begin</mat-label>
			<date-time-picker [(value)]="begin"></date-time-picker>
		</mat-form-field>
		<mat-form-field *ngIf="showTimes">
			<mat-label>End</mat-label>
			<date-time-picker [(ngModel)]="end"></date-time-picker>
		</mat-form-field>
		<mat-form-field>
			<mat-label>Tolerance Spec</mat-label>
			<measurement-input type="particleSize" [unitSystem]="(particleSizeUnitSystem$|async)" [(ngModel)]="toleranceSpecSize"></measurement-input>
		</mat-form-field>
		<mat-form-field>
			<mat-label>Bench Seam</mat-label>
			<measurement-input type="distance" [unitSystem]="(particleSizeUnitSystem$|async)" [(ngModel)]="benchSeamSize"></measurement-input>
		</mat-form-field>
		<mat-form-field>
			<mat-label>Material Type</mat-label>
			<input matInput type="text" [(ngModel)]="materialType"/>
		</mat-form-field>
		<mat-form-field>
			<mat-label>Truck Load Count</mat-label>
			<measurement-input type="count" [(ngModel)]="truckLoadCount"></measurement-input>
		</mat-form-field>
		<mat-form-field>
			<mat-label>Comments</mat-label>
			<textarea matInput [(ngModel)]="comments"></textarea>
		</mat-form-field>
	  </div>
  </div>
  <div mat-dialog-actions>
	  <button mat-button (click)="cancel()">Cancel</button>
	  <button mat-button class="confirm" [disabled]="!valid()" (click)="save()">Save</button>
  </div>
