<div class="snap-card"[ngClass]="snapType === ('analysis'||'details') ? 'analysis' : 'home'" >
    <div class="container img-container">
        <div *ngIf="imgSrc === ''">
            <button mat-mini-fab color="primary" *ngIf="showInterval" (click)="refresh()" class="refresh">
                <mat-icon svgIcon="refresh-white"></mat-icon>
            </button>
            <button *ngIf="intervalsArray && intervalsArray.length > 0 && !showInterval" mat-mini-fab color="primary" (click)="onPreviousClick()" class="arrow left">
                <mat-icon svgIcon="arrow-left-white"></mat-icon>
            </button>
            <div [ngClass]="snapType === ('analysis' || 'details') ? 'image-analysis' : 'image-home'">
                <div *ngIf="(currentUrl | async) && showInterval" class="image-wrapper full-width">
                    <img (click)="showFullImg()" (error)="onImageLoadError()"
                        loading="eager"
                        onerror="this.src='../../../assets/image_not_found.jpg'"
                        [src]="currentUrl | async" />    
                </div>
                <div *ngIf="!showInterval" class="full-width image-wrapper">
                        <div class="full-width">
                            <img
                                [ngClass]="{
                                'red-border': intervalsArray[currentSlide]?.thresholdCount,
                                'yellow-border': intervalsArray[currentSlide]?.slabs,
                                }"
                                (click)="showFullImg(intervalsArray[currentSlide]?.imageUrl)" (error)="onImageLoadError()"
                                loading="eager"
                                onerror="this.src='../../../assets/image_not_found.jpg'"
                                [src]="intervalsArray[currentSlide]?.imageUrl"
                            /> 
                        </div>
                        <div class="count-container" *ngIf="intervalsArray[currentSlide]?.slabs || intervalsArray[currentSlide]?.thresholdCount">
                            <div class="count-box">
                                <mat-icon svgIcon="warn-yellow"></mat-icon>&nbsp;
                                <span>Slabs: {{intervalsArray[currentSlide].slabs}}</span>&nbsp;&nbsp;
                                <mat-icon svgIcon="warn-red"></mat-icon>&nbsp;
                                <span>Thresholds: {{intervalsArray[currentSlide].thresholdCount}}</span>
                            </div>
                        </div>
                </div>
            </div>
            <button *ngIf="intervalsArray && intervalsArray.length && !showInterval" mat-mini-fab color="primary" (click)="onNextClick()" class="arrow right">
                <mat-icon svgIcon="arrow-right-white"></mat-icon>
            </button>
        </div>
        <ng-template #fullimgtemplate>
            <div>
                <button mat-icon-button class="close-button" (click)="closeFullImg()">
                    <mat-icon>close</mat-icon>
                </button>
                <img [src]="imgsrc" class="full-height full-width" />
            </div>
        </ng-template>
        <img *ngIf="imgSrc !== ''" class="imgmargin" mat-card-xl-image [src]="imgSrc" />
    </div>
        <div class="button-container download-button" *ngIf="intervalsArray.length > 0">
            <button mat-flat-button color="primary" class="button" (click)="downloadImage()"
                [disabled]="this.imageLoadError">
                <mat-icon svgIcon="download-white"></mat-icon>
                <span>Download Image</span>
            </button>
        </div>
</div>