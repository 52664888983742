import { Component } from '@angular/core';
import { User$ } from '@injectables';

@Component({
  selector: 'auth',
  templateUrl: './auth.component.html',
  styleUrls:['./auth.component.scss']
})
export class AuthComponent {
  constructor(public readonly user$:User$){}
}