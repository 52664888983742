<div class="input-wrapper">
	<input type="text" class="mat-mdc-input-element mat-mdc-form-field-input-control mdc-text-field__input" [disabled]="true" [(ngModel)]="valueStr" />
	<div class="input-sizer">
		{{maxLengthStr}}
	</div>
</div>
<button mat-icon-button class="toggle" (click)="open()">
	<mat-icon matDatepickerToggleIcon>arrow_drop_down</mat-icon>
</button>
<ng-template #popup>
	<div class="popup">
		<mat-calendar [selected]="value" (selectedChange)="onDateChange($event)" [dateFilter]="dateFilter">
		</mat-calendar>
	</div>
</ng-template>