import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DialogService } from '@app/old-ui/dialog/dialog.service';
import { WebClient } from '@injectables';
import { Device } from '@models';
import { FragQueryService } from 'src/injectables/frag-query.service';

@Component({
    selector: 'target-interval-settings',
    templateUrl: './target-intervals.component.html',
    styleUrls: ['./target-intervals.component.scss']
})
export class TargetIntervalsComponent implements OnInit {
    device: Device;

    constructor(
        private readonly webClient: WebClient,
        private fragQuery: FragQueryService,
        private readonly dialog: DialogService,
    ) { }
    public readonly devices$ = this.fragQuery.devices$;
    public readonly device$ = this.fragQuery.device$;
    public readonly particleSizeUnitSystem$ = this.fragQuery.particleSizeUnitSystem$;
    public formGroup: FormGroup;
    public readonly restClient = this.webClient.model.device;
    public minSize: number = null;
    public maxSize: number = null;
    public intervals: number = null;
    public newMinSize: number = null;
    public newMaxSize: number = null;
    public newTargetInterval: number = null;
    public preferCustomSizes:boolean =false;
    public sizeOptions: Array<{ minSize: number, maxSize: number, intervals: number }> = [];
    public showAddSizeRange: boolean = false;

    ngOnInit(): void {

        this.device$.subscribe((device: Device) => {
            this.device = device;
            this.minSize = device.minTargetSize;
            this.maxSize = device.maxTargetSize;
            this.intervals = device.targetIntervals;
            this.preferCustomSizes= device.preferCustomSizes;
   
            if(this.device.customSizeRanges){
                let customSizeOptions = JSON.parse(this.device.customSizeRanges);
                if(customSizeOptions[0].minSize!=null && customSizeOptions[0].maxSize!=null){
                    this.sizeOptions = customSizeOptions;
                }else{
                    this.sizeOptions = [];
                }
            }else{
                this.sizeOptions = [];
            }
        });

    }

    async saveSettings() {
        try {
            this.device.minTargetSize = this.minSize;
            this.device.maxTargetSize = this.maxSize;
            this.device.targetIntervals = this.intervals;
            this.device.customSizeRanges = JSON.stringify(this.sizeOptions);
            this.device.preferCustomSizes = this.preferCustomSizes;
            console.log(this.preferCustomSizes)
            await this.dialog.await(this.restClient.save(this.device));
            location.reload();
        } catch (error) {
            console.error('Error saving settings:', error);
        }
    }

    addCustomSizeRange() {
        if (this.newMinSize!=null && this.newMaxSize && (this.newMinSize < this.newMaxSize)) {
            const newRange = { minSize: this.newMinSize, maxSize: this.newMaxSize, intervals: this.newTargetInterval };
            this.sizeOptions = [...this.sizeOptions, newRange];
            this.newMinSize=null;
            this.newMaxSize=null;
            this.newTargetInterval=null;
        } else {
            alert('Invalid size range');
        }
    }
    removeSizeRange(rangeToRemove: { minSize: number; maxSize: number; intervals: number }) {
        this.sizeOptions = this.sizeOptions.filter(range => range !== rangeToRemove);
    }
    toggleAddSizeRange() {
        this.showAddSizeRange = !this.showAddSizeRange;
    }
}
