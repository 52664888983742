<div class="data-table-caption">
    <div *ngIf="title">{{ title }}</div>
    <div class="flex-row justify">
        <div>
            <ng-content selector=".settings"> </ng-content>
        </div>
        <div>
            <div class="flex-row end gap-small" *ngIf="addable()">
                <button mat-button color="primary" class="button" [disabled]="selected.size === 0" (click)="_delete()">
                    <mat-icon svgIcon="trash"> </mat-icon>
                    Remove
                </button>
                <button mat-flat-button color="primary" (click)="add()" class="button">
                    Add
                </button>
            </div>
        </div>
    </div>
</div>
<div>
    <table class="data full-width">
        <thead>
            <tr>
                <th>
                    <mat-checkbox [ngModel]="selected.size === (pageRecords$ | async)?.length"
                        (ngModelChange)="selectAll($event)">
                    </mat-checkbox>
                </th>
                <ng-container *ngIf="headerContent" [ngTemplateOutlet]="headerContent.templateRef"></ng-container>
                <th></th>
            </tr>
        </thead>
        <tbody>
            <tr *ngIf="!(pageRecords$ | async)">
                <td colspan="1000">
                    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                </td>
            </tr>
            <tr *ngFor="let record of pageRecords$ | async">
                <td>
                    <mat-checkbox [ngModel]="selected.has(record)" (ngModelChange)="$event ? selected.add(record) : selected.delete(record)"></mat-checkbox>
                </td>
                <ng-container *ngTemplateOutlet="rowContent.templateRef; context: { record }"></ng-container>
                <td>
                    <div class="flex-row end gap-small">
                        <ng-container *ngIf="actionsContent">
                            <ng-container *ngTemplateOutlet="actionsContent.templateRef;context: { record }"></ng-container>
                        </ng-container>
                        <button mat-stroked-button class="action-button" (click)="edit(record)">
                            <mat-icon svgIcon="edit" class="mat-icon-svg"></mat-icon>
                        </button>
                        <button *ngIf="deletable(record)" button mat-stroked-button class="action-button" [disabled]="!deletable(record)"
                            (click)="_delete(record)">
                            <mat-icon svgIcon="trash" class="mat-icon-svg"></mat-icon>
                        </button>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
</div>
<mat-paginator [length]="recordCount$ | async" [pageSize]="pageSize$ | async" [pageSizeOptions]="[25, 50, 100]" (page)="onPage($event)">
</mat-paginator>