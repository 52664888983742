import {math} from "../math";
import {Color} from "./color";
import {isFiniteNumber, isValidNumber} from "../number";
import {NumberToColorMap} from "./number-to-color-map";

const tmpV2A=new math.Vec2();

export class NumberToColorMapBlended extends NumberToColorMap{
	public constructor(
		positions:number[],
		colors:Color[],
	){
		super(positions,colors);
		const length=Math.min(this.positions.length,this.colors.length);
		this.positions.length=length;
		this.colors.length=length;
	}

	public clone(){
		return <this>new NumberToColorMapBlended(this.positions.slice(),this.colors.map(c=>c.clone()));
	}

	public isValid(){
		if(!Array.isArray(this.positions))
			return false;
		if(!Array.isArray(this.colors))
			return false;
		if(this.positions.length!==this.colors.length)
			return false;
		for(const p of this.positions){
			if(!isValidNumber(p))
				return false;
		}
		for(const c of this.colors){
			if(!(c instanceof Color))
				return false;
		}
		return true;
	}
	
	public clean(){
		const indices=this.positions.map((c,i)=>i)
			.filter(i=>isValidNumber(this.positions[i]))
			.sort((a,b)=>this.positions[a]-this.positions[b]);
		const positions=indices.map(i=>this.positions[i]);
		const colors=indices.map(i=>this.colors[i] ?? Color.transparent());
		return new NumberToColorMapBlended(positions,colors);
	}

	public get length(){
		return this.positions.length;
	}

	public set length(v:number){
		while(this.positions.length<v)
			this.positions.push(this.positions.at(-1)!);
		while(this.colors.length<v)
			this.colors.push(this.colors.at(-1)!);
		this.positions.length=v;
		this.colors.length=v;
	}

	public *entries(){
		for(let i=0;i<this.positions.length;++i)
			yield <[number,Color]>[this.positions[i],this.colors[i]];
	}

	public [Symbol.iterator](){
		return this.entries();
	}

	// public set(index:number, v:number|Color){
	// 	if(0<=index && index<this.positions.length){
	// 		if(typeof(v)==='number'){
	// 			if(this.positions[index]!==v){
	// 				this.positions[index]=v;
	// 				return true;
	// 			}
	// 		}else if(v instanceof Color){
	// 			if(this.colors[index]!==v){
	// 				this.colors[index]=v;
	// 				return true;
	// 			}
	// 		}
	// 	}
	// 	return false;
	// }

	public delete(index:number){
		if(0<=index && index<this.positions.length){
			this.positions.splice(index,1);
			this.colors.splice(index,1);
			return true;
		}
		return false;
	}

	private positionToIndex(v:number){
		if(v<this.positions[0])
			return null;
		for(let i=1;i<this.positions.length;++i){
			if(v<this.positions[i])
				return i-1;
		}
		return null;
	}

	public atPosition(v:number){
		const ia=this.positionToIndex(v);
		if(ia===null)
			return null;
		const ib=ia+1;
		const weight=this.positions[ib]-v;
		const c=this.colors[ia];
		const weightSum=weight;
		const hueVector=math.azimuth.toVector(c.hue()*math.D2R,tmpV2A).multiplyScalar(weight);
		const satSum=c.saturation()*weight;
		const valSum=c.value()*weight;
		const opacSum=c.alpha()*weight;
		return Color.hsv(math.azimuth.from(hueVector)*math.R2D,satSum/weightSum,valSum/weightSum,opacSum/weightSum);
	}

	public atPositionRange(v:number, u:number){
		return this.atPosition((v+u)/2);
	}

	public slice(begin:number, end:number){
		// let bi=this.positions.findIndex(p=>begin<p);
		// let ei=this.positions.findIndex(p=>end<p);
		// if(bi<0)
		// 	bi=this.positions.length;
		// else
		// 	bi-=1;
		// if(ei<0)
		// 	ei=this.positions.length;
		// else
		// 	ei+=1;

		const positions=this.positions.filter(p=>begin<p && p<end);
		positions.unshift(begin);
		positions.push(end);
		return new NumberToColorMapBlended(
			positions,
			positions.map(p=>this.atPosition(p)!)
		);
	}

	public toCssGradient(sideOrCorner:string):string{
		if(this.colors.length===0)
			return 'none';
		const steps:string[]=[];
		steps.push(this.colors.at(0)!.toString());
		for(let [p,color] of this)
			steps.push(`${color.toString()} ${p*100}%`);
		steps.push(this.colors.at(-1)!.toString());

		return `linear-gradient(${sideOrCorner},${steps.join(',')})`;
	}

	public toJson(){
		return {
			positions:this.positions.slice(),
			colors:this.colors.map(c=>c.html())
		}
	}

}

export namespace NumberToColorMapBlended{
	export function fromJson(json:NumberToColorMap.JSON):NumberToColorMapBlended|null{
		if(
			json.positions.length===json.colors.length
			&& json.positions.every(isFiniteNumber)
		)
			return new NumberToColorMapBlended(<number[]>json.positions,json.colors.map(c=>Color.html(c)));
		return null;
	}
	NumberToColorMap.fromJSONExtension.push(fromJson);
}
