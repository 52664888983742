import { Component,EventEmitter,Input,Output } from '@angular/core';
import { iterate } from '@lib/iterate';
import { percentageNewColors } from 'src/colors';
@Component({
    selector: 'percent-selector',
    templateUrl: './percent-selector.component.html',
    styleUrls: ['./percent-selector.component.scss']
})
export class PercentSelectorComponent {
    constructor(
    ) {}

    @Input() get value() {
        return this._value;
    }
    set value(v) {
        this._value = v;
    }
    @Input() diameters: Map<number, number>;
    @Input() small: boolean = false;

    @Output() valueChanged = new EventEmitter<Map<number, string>>();

    public readonly percents = [...iterate.numbers(10, 100, 10, true)].reverse();
    public readonly colors = new Map(percentageNewColors);

    private _value = new Map(this.colors);

    private emitValue() {
        this._value = new Map([...this._value.keys()]
            .sort((a, b) => a - b)
            .map((p) => <[number, string]>[p, this.colors.get(p)]));
        this.valueChanged.emit(this._value);
    }

    isSmallScreen(): boolean {
        return window.innerWidth <= 780;
    }

    public togglePercent(percent: number) {
        this._value = new Map(this._value);
        if (this._value.has(percent)) {
            this._value.delete(percent);
        } else {
            this._value.set(percent, this.colors.get(percent));
        }
        this.emitValue();
    }
}
