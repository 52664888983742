import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, op } from '@lib/rxjs';
import { DataIntervalTag } from '@models';
import { FragQueryService } from 'src/injectables/frag-query.service';

@Component({
    selector: 'live-tag-details',
    templateUrl: './live-tag-details.component.html',
    styleUrls: ['./live-tag-details.component.scss']
})
export class LiveTagDetailsComponent {

    constructor(
        private readonly router: Router,
        private readonly fragQuery: FragQueryService
    ) { }

    @Input()
    get tags() {
        return this.tags$.value;
    }
    set tags(v) {
        this.tags$.next(v ?? []);
    }

    private readonly tags$ = new BehaviorSubject<DataIntervalTag[]>([]);
    public readonly particleSizeUnitSystem$ = this.fragQuery.particleSizeUnitSystem$;

    isSmallScreen(): boolean {
        return window.innerWidth <= 780;
    }

    switchPage() {
        this.router.navigateByUrl(`/details`);
    }

    public readonly tag$ = {
        toleranceSpecSize:  this.tags$.pipe(
            op.map(tags => {
                let i=tags.find(tag => tag.toleranceSpecSize!==null) || null;
                if(i){
                    return i.toleranceSpecSize;
                }else{
                    return 0;
                }
            }),
        ),
        benchSeamSize: 
        this.tags$.pipe(
            op.map(tags => {
                let i=tags.find(tag => tag.benchSeamSize!==null) || null;
                if(i){
                    return i.benchSeamSize;
                }else{
                    return 0;
                }
            }),
        ),
        materialType: this.tags$.pipe(op.map(tags => tags.map(tag => tag.materialType ?? '---').unique().join(', ') || '---')),
        truckLoadCount: this.tags$.pipe(op.map(tags => tags.map(tag => tag.truckLoadCount ?? '---').unique().join(', ') || '---')),
        comments: this.tags$.pipe(op.map(tags => tags.map(tag => tag.comments ?? '---').unique().join(', ') || '---')),
    };
}
