import {AfterViewInit,Component,ElementRef,EventEmitter,Input,Output,ViewChild} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {MatSliderModule} from '@angular/material/slider';

@Component({
	standalone: true,
	selector: 'slider',
	templateUrl: './slider.component.html',
	styleUrls: ['./slider.component.scss'],
	imports: [
		FormsModule,
		MatSliderModule,
	],
})
export class SliderComponent implements AfterViewInit{
	constructor(
		private host:ElementRef<HTMLElement>
	){
	}

	@Input() min:number|string;
	@Input() max:number|string;
	@Input() step:number|string;
	@Input() get thumbColor(){
		return this._thumbColor;
	}
	set thumbColor(v){
		this._thumbColor=v;
		this.setCssVar('--thumb-color',this._thumbColor);
	}
	@Input() get trackBackground(){
		return this._trackBackground;
	}
	set trackBackground(v){
		this._trackBackground=v;
		this.setCssVar('--track-background',this._trackBackground);
	}

	@Input() value:number;
	@Output() valueChange=new EventEmitter<number>();

	private _thumbColor:string;
	private _trackBackground:string;

	private setCssVar(name:string, v:string){
		if(v!==undefined)
			this.host?.nativeElement?.style.setProperty(name,v);
		else
			this.host?.nativeElement?.style.removeProperty(name);
	}

	public ngAfterViewInit():void{
		this.setCssVar('--thumb-color',this._thumbColor);
		this.setCssVar('--track-background',this._trackBackground);
	}

	public emitValue(v:number){
		this.value=v;
		this.valueChange.emit(v);
	}
}
