import * as pdfkit from './pdf';
import { getDOMRectStr, PageInfo } from './shared';

export function renderPageCanvas(page:PageInfo, doc:pdfkit.PDFDocument, ele:HTMLCanvasElement, rect:DOMRect){
	const meta=page.meta.get(ele);
	if(!meta) return;
	const rectStr=getDOMRectStr(rect);
	if(page.canvasList.has(rectStr)) {
		const canvasList=page.canvasList.get(rectStr);
		if(canvasList.length > 1) {
			canvasList.forEach((element,index)=>{
				const transparency=index>0;
				renderCanvas(doc,element,rect,transparency);
				page.meta.delete(element);
			});
		}
		else{
			renderCanvas(doc,ele,rect,false);
		}
	}
	else {
		renderCanvas(doc,ele,rect,false);
	}
}

export function renderCanvas(doc:pdfkit.PDFDocument, ele:HTMLCanvasElement, rect:DOMRect, transparentBackground:boolean){
	if(transparentBackground) {
		const dataUrl=ele.toDataURL('image/png');
		doc.image(dataUrl,rect.x,rect.y,{width: rect.width, height: rect.height});
		return;
	}
	//console.time('canvas');
	// creating a white background canvas to put the map on since the jpeg is doesn't support transparent background.
	const whiteBackgroundContainer=document.createElement("canvas");
	whiteBackgroundContainer.height=ele.height;
	whiteBackgroundContainer.width=ele.width;

	let context=whiteBackgroundContainer.getContext('2d');
	context.fillStyle='white';
	context.fillRect(0,0,whiteBackgroundContainer.width,whiteBackgroundContainer.height);
	context.drawImage(ele, 0, 0);

	//dataUrl is way faster than using the blob and converting to an array buffer, I checked
	//const dataUrl=ele.toDataURL('image/png');
	const dataUrl=whiteBackgroundContainer.toDataURL('image/jpeg');
	doc.image(dataUrl,rect.x,rect.y,{width: rect.width, height: rect.height});
	//console.timeEnd('canvas');
}
