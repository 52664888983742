import {Directive,TemplateRef} from '@angular/core';

@Directive({
	selector: 'ng-template[model-table-row]',
})
export class ModelTableRowDirective<
	Record extends {
		id:number;
		name?:string;
	}
>{
	public constructor(
		public templateRef:TemplateRef<{record:Record}>,
	){
	}
}
