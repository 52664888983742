import { Component, OnInit } from '@angular/core';
import { DateTime } from '@lib/date-time';
import { ActivatedRoute, Router } from '@angular/router';
import { FragQueryService } from 'src/injectables/frag-query.service';
import { BehaviorSubject, Subject, Observable, combineLatest, firstValueFrom, observable, lastValueFrom } from 'rxjs';
import { NavigationRoute$, User$ } from '@injectables';
import { op } from '@lib/rxjs';
import { combineInput } from '@app/new-ui/analysis/analysis.component';
import { generateTimelineChartLines } from '@app/new-ui/chart-transforms';

@Component({
    selector: 'filters',
    templateUrl: './filters.component.html',
    styleUrls: ['./filters.component.scss']
})
export class FiltersComponent implements OnInit {

    constructor(
        public readonly fragQuery: FragQueryService,
        private readonly route: ActivatedRoute,
        private readonly route$: NavigationRoute$,
        public readonly user$: User$,
    ) { }

    public ngOnInit(): void {
        const fragment = JSON.parse(this.route.snapshot.fragment);
        if (fragment?.custom) {
            this.fragQuery.duration$.next('custom');
            if (Array.isArray(fragment.custom)) {
                this.fragQuery.begin$.next(
                    new DateTime(fragment.custom[0] ?? DateTime.now())
                );
                this.fragQuery.end$.next(
                    new DateTime(fragment.custom[1] ?? DateTime.now())
                );
            }
        }
        if (fragment?.device) {
            this.fragQuery.devices$.subscribe(devices => [
                devices.forEach(device => {
                    if (device.id === fragment.device) {
                        this.fragQuery.device$.next(device)
                    }
                })
            ])
        }
    }

    public xHighlight: DateTime.Range = null;
    public readonly durationOptions = this.fragQuery.durationOptions;
    public readonly duration$ = this.fragQuery.duration$;
    public readonly newPercents$ = this.fragQuery.newPercents$;
    public readonly devices$ = this.fragQuery.devices$;
    public readonly device$ = this.fragQuery.device$;
    public readonly shifts$ = this.fragQuery.shifts$;
    public readonly shift$ = this.fragQuery.shift$;
    public readonly begin$ = this.fragQuery.begin$;
    public readonly end$ = this.fragQuery.end$;
    public readonly customShiftDate$ = this.fragQuery.customShiftDate$;
    public readonly currentInterval = new BehaviorSubject(null);
    public readonly chartType$ = this.fragQuery.chartType$;
    public readonly weightType$ = this.fragQuery.weightType$;
    public readonly particleSizeUnit$ = this.fragQuery.particleSizeUnit$;

    public readonly chartLines$: Observable<Highcharts.Options> = combineLatest([
        this.fragQuery.newPercents$,
        this.fragQuery.timelineSieveSizesAtPercents$,
        this.fragQuery.addOnTimelineSieveSizesAtPercents$,
        this.fragQuery.particleSizeUnit$,
    ]).pipe(
        op.tap(([percents, input, addOn]) => combineInput(input, addOn, this.fragQuery.addDataAt.value)),
        op.tap(input => {
            if (input[1] && input[1].intervals) {
                this.fragQuery.totalIntervalLength.next((input[1].end.valueOf() - input[1].begin.valueOf()))
                this.fragQuery.intervalsLength.next(input[1].intervals.length)
            }
        }),
        op.map(([percents, input, input2, unit]) => {
            return generateTimelineChartLines('line', percents, input, unit, this.addData.bind(this), this.onPlotClick.bind(this));
        })
    )

    addData(type: string) {
        this.fragQuery.addDataAt.next(type);
    }

    async onPlotClick(time: number) {
        const input = await firstValueFrom(
            this.fragQuery.timelineSieveSizesAtPercents$
        );
        let dataPoint;
        for (let index = 0; index < input.intervals.length; index++) {
            const interval = input.intervals[index];
            if (interval.begin.valueOf() <= time) {
                dataPoint = interval;
            } else {
                break;
            }
        }
        if (dataPoint) { this.currentInterval.next(dataPoint) };
    }

    isSmallScreen(): boolean {
        return window.innerWidth <= 1000;
    }

    public readonly type$ = this.route$
        .pipe(op.map(route => route[0]));

}
