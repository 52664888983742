<mat-drawer-container>
    <mat-drawer #drawer mode="side" disableClose="true" opened="true">
        <sidebar></sidebar>
    </mat-drawer>
    <mat-card>
        <navbar></navbar>
    </mat-card>
    <mat-toolbar>
        <mat-toolbar-row>
            <filters style="width: 75vw;"></filters>
            <div class="flex-row">
                <mat-icon *ngIf="!((allDeviceSeries$ | async) !== undefined && (allDeviceSeries$ | async)!==null && (devices$|async)!==null && (allDeviceSeries$ | async).length<(devices$|async).length) && (type$ | async) === 'home'" mat-fab color="primary"
                (click)="refresh()" color="primary" class="icon-button" matTooltip="Refresh" [matTooltipPosition]="'below'">refresh-1</mat-icon>
            
                <mat-icon class="example-icon" aria-hidden="false" aria-label="Example heart icon"  svgIcon="file-primary" matTooltip="Reports" [matTooltipPosition]="'below'" *ngIf="(type$ | async) === 'analytics' || (type$ | async) === 'details' || (type$ | async) === 'shift-analytics'" mat-icon-button
                (click)="openReportProperties()" class="icon-button"></mat-icon>
            
                <div class="theme-toggle-container" *ngIf="showDarkMode">
                    <mat-icon *ngIf="className === lightClass" (click)="toggleTheme()">dark_mode</mat-icon>
                    <mat-icon *ngIf="className === darkClass" (click)="toggleTheme()">light_mode</mat-icon>
                </div>
        
                <mat-icon  class="example-icon" aria-hidden="false" aria-label="Example heart icon"  svgIcon="arrow-down-accent" [matMenuTriggerFor]="menu" mat-icon-button class="icon-button"></mat-icon>
                <mat-card-header *ngIf="(type$ | async) === 'home'">
                    <img class="profile-pic" mat-card-avatar src="https://www.hotelbooqi.com/wp-content/uploads/2021/12/128-1280406_view-user-icon-png-user-circle-icon-png.png">
                    <mat-card-title>{{ userName$ | async }}</mat-card-title>
                    <mat-card-subtitle>{{ email$ | async }}</mat-card-subtitle>
                </mat-card-header>
            </div>
      
        </mat-toolbar-row>
      </mat-toolbar>

        <div>
            <div *ngIf="(deviceUnregistered$|async)">
                <button mat-button (click)="registerDevice()">Unregistered Device</button>
            </div>
        </div>

    <mat-menu #menu="matMenu">
        <div class="user-menu">
            <div mat-menu-item>
                <button mat-button [ngClass]="activeUser ? 'activebutton' : 'inactivebutton'"
                [color]="activeUser ? 'primary' : ''" [disabled]="!activeUser" routerLink="user/preferences">
                <mat-icon [svgIcon]="
                activeUser
                ? 'user-preference-primary'
                : 'user-preference-accent'
                "></mat-icon>
                User Preference
                </button>
                </div>
            <div mat-menu-item class="flex-row center">
                <button mat-flat-button color="primary" (click)="logout()" class="button">
                    LOG OUT
                </button>
            </div>
        </div>
    </mat-menu>
    <div class="content">
        <router-outlet *ngIf="user$ | async"></router-outlet>
    </div>
</mat-drawer-container>