
<live-tag-details [tags]="tags$ | async"> </live-tag-details>

<div class="section flex-percent flex-wrapper">
    <div class="container-67 flex-column">

        <percent-selector [value]="newPercents$ | async" (valueChanged)="newPercents$.next($event)"></percent-selector>
        <mat-card>
            <mat-tab-group>
                <mat-tab>
                    <ng-template mat-tab-label>
                        PSD Timeline
                    </ng-template>
                    <ng-container>
                        <div *ngIf="(chartLines$ | async) === undefined" class="container centered-content">
                            <mat-spinner strokeWidth="3" [diameter]="50"></mat-spinner>
                        </div>
                        <div *ngIf="(this.fragQuery.addOnTimelineSieveSizesAtPercents$|async)===undefined" class="container centered-content add-on-loader">
                            <mat-spinner strokeWidth="3" [diameter]="50"></mat-spinner>
                        </div>
                        <div *ngIf="(chartLines$ | async) === null" class="container centered-content">
                            <div class="container centered-content">
                                <div>
                                    <mat-icon svgIcon="empty-data" class="not-found-svg"></mat-icon>
                                </div>
                                <div>No Data Found</div>
                            </div>
                        </div>
                        <div *ngIf="(chartType$ | async) === 'line'">
                            <figure *ngIf="(chartLines$ | async)" class="highcharts-figure full-width">
                                <highcharts-chart class="highcharts-figure" [Highcharts]="Highcharts" [constructorType]="'stockChart'" [options]="chartLines$|async">
                                </highcharts-chart>
                            </figure>
                        </div>
                        <div *ngIf="(chartType$ | async) === 'series'">
                            <figure *ngIf="(chartLines$ | async)" class="highcharts-figure full-width">
                                <highcharts-chart class="highcharts-figure" [Highcharts]="Highcharts" [constructorType]="'stockChart'" [options]="chartSeries$|async">
                                </highcharts-chart>
                            </figure>
                        </div>
                    </ng-container>
                </mat-tab>
                <mat-tab>
                    <ng-template mat-tab-label>
                        Quantity Timeline
                    </ng-template>
                    <ng-container>
                        <div *ngIf="(chartLines$ | async) === undefined" class="container centered-content">
                            <mat-spinner strokeWidth="3" [diameter]="50"></mat-spinner>
                        </div>
                        <div *ngIf="(this.fragQuery.addOnTimelineSieveSizesAtPercents$|async)===undefined" class="container centered-content add-on-loader">
                            <mat-spinner strokeWidth="3" [diameter]="50"></mat-spinner>
                        </div>
                        <div *ngIf="(chartLines$ | async) === null" class="container centered-content">
                            <div class="container centered-content">
                                <div>
                                    <mat-icon svgIcon="empty-data" class="not-found-svg"></mat-icon>
                                </div>
                                <div>No Data Found</div>
                            </div>
                        </div>
                        <div *ngIf="(chartType$ | async) === 'line'">
                            <figure *ngIf="(chartLines$ | async)" class="highcharts-figure full-width">
                                <highcharts-chart class="highcharts-figure" [Highcharts]="Highcharts" [constructorType]="'stockChart'" [options]="quantityChartLines$|async">
                                </highcharts-chart>
                            </figure>
                        </div>
                        <div *ngIf="(chartType$ | async) === 'series'">
                            <figure *ngIf="(chartLines$ | async)" class="highcharts-figure full-width">
                                <highcharts-chart class="highcharts-figure" [Highcharts]="Highcharts" [constructorType]="'stockChart'" [options]="quantityChartSeries$|async">
                                </highcharts-chart>
                            </figure>
                        </div>
                    </ng-container>
                </mat-tab>
            </mat-tab-group>
        </mat-card>
    </div>
    <div class="container container-33">
        <snapshot-card-analysis [series]="chartInputs$ | async" [currInterval]="currentInterval | async">
        </snapshot-card-analysis>
    </div>
</div>