
export function isValidNumber(v:number|unknown):v is number{
	return typeof(v)==='number' && !isNaN(v);
}

export function canBeValidNumber(v:any){
	return !isNaN(+v);
}

export function isFiniteNumber(v:number|unknown):v is number{
	return typeof(v)==='number' && !isNaN(v) && isFinite(v);
}

export function isInteger(v:number|unknown):v is number{
	return typeof(v)==='number' && !isNaN(v) && Math.floor(v)===v;

}