import { Color } from '../../../color';
import { DateTime } from '../../../date-time';
import { math } from '../../../math';
import { MultiMap } from '../../../multi-map';
import { toNum } from '../helpers';
import { AxisDataType } from '../types';
import { PlotEvent,Series,SeriesLine,SeriesOccurances } from './chart-line.namespace';

export interface ProcessedLine{
	series:SeriesLine;
	seriesIndex:number;
	points:number[][];
	fillColor:string;
	strokeColor:string;
	width:number;
	dash:string|null;
	strokePath:string;
	fillPath:string;
}

export interface ProcessedOccurance{
	series:SeriesOccurances;
	seriesIndex:number;
	dataIndex:number;
	top:number;
	bottom?:number;
	fillColor:string;
	strokeColor:string;
	strokeWidth:number;
}

interface ProcessedOccuranceSet{
	left:number;
	right?:number;
	width?:number;
	occurances:ProcessedOccurance[];
}

export class Plot{
	public constructor(
		public readonly dataTypes:[AxisDataType,AxisDataType],
		series:Series[]
	){
		this.init(series ?? []);
	}

	public dataRanges:[math.Range|DateTime.Range,math.Range|DateTime.Range]=[
		this.dataTypes[0]==='time'?new DateTime.Range():new math.Range(),
		this.dataTypes[1]==='time'?new DateTime.Range():new math.Range(),
	];
	public toData:[((x:number)=>(number|DateTime)),((x:number)=>(number|DateTime))]=[
		v=>v,
		v=>v,
	];
	public lines:ProcessedLine[]=[];
	public occuranceSets:ProcessedOccuranceSet[]=[];

	private init(
		seriesArray:Series[]
	){
		const occuranceSetMap=new MultiMap<number,ProcessedOccurance>();
		let dataCount=0;
		for(const [seriesIndex,series] of seriesArray.entries()){
			if(series.type==='line'){
				dataCount+=series.data.length;
				const line:ProcessedLine={
					series,
					seriesIndex,
					fillColor: series.color.toString(),
					strokeColor: series.color.toString(),
					points: [],
					width: series.width ?? 4,
					dash: series.dash?series.dash.join(' '):'',
					strokePath: '',
					fillPath: series.fill?'':null,
				};
				for(const datum of series.data){
					const p=[toNum(datum[0]),toNum(datum[1])];
					line.points.push(p);
					for(let i=0;i<2;++i){
						if(series.includeInRange?.[i] ?? true)
							this.dataRanges[i].expandByPoint(p[i]);
					}
				}
				this.lines.push(line);
			}else if(series.type==='occurances'){
				dataCount+=series.data.length;
				this.dataRanges[1].expandByPoint(0);
				for(const [dataIndex,datum] of series.data.entries()){
					const p=[toNum(datum[0]),toNum(datum[1])];
					const strokeColor=Color.html(series.color).clone();
					strokeColor.rgb(strokeColor.value(),strokeColor.value(),strokeColor.value());
					strokeColor.negate();
					occuranceSetMap.add(p[0],{
						series,
						seriesIndex,
						dataIndex,
						top: p[1],
						fillColor: series.color.toString(),
						strokeColor: '#00000080',
						strokeWidth: 1,
					});
					for(let i=0;i<2;++i){
						if(series.includeInRange?.[i] ?? true)
							this.dataRanges[i].expandByPoint(p[i]);
					}
				}
			}
		}


		if(dataCount===0){
			this.dataRanges=[null,null];
			return;
		}

		this.finishLines();
		this.finishOccurances(occuranceSetMap);
	}

	private finishLines(){
		for(let i=0;i<2;++i){
			const translation=this.dataRanges[i].min;
			const scale=(this.dataRanges[i].max-translation);
			for(const outLine of this.lines){
				for(const p of outLine.points)
					p[i]=(p[i]-translation)/scale;
			}
			const dataType=this.dataTypes[i];
			this.toData[i]=(v=>{
				v=v*scale+translation;
				if(dataType==='time')
					return new DateTime(v);
				return v;
			});
		}

		for(const [i,outLine] of this.lines.entries()){
			outLine.strokePath=outLine.points.map((p,i)=>(i===0?'M ':'L ')+p.join(',')).join('\n');
			const begin=outLine.points.at(0);
			const end=outLine.points.at(-1);
			if(outLine.fillPath!==null){
				outLine.fillPath=[
					outLine.strokePath,
					`L${end[0]} -1`,
					`L${begin[0]} -1`,
					'Z',
				].join('\n');
			}
		}
	}

	private finishOccurances(occuranceSetMap:MultiMap<number,ProcessedOccurance>){
		if(occuranceSetMap.size===0){
			this.occuranceSets=[];
			return;
		}

		const occuranceSets=[...occuranceSetMap].map(([left,occurances]):ProcessedOccuranceSet=>({left,occurances}));
		occuranceSets.sort((a,b)=>a.left-b.left);

		const beginMargin=this.dataRanges[0].fromParameter(-1/32);
		const endMargin=this.dataRanges[0].fromParameter(33/32);
		this.dataRanges[0].expandByPoint(<any>beginMargin);
		this.dataRanges[0].expandByPoint(<any>endMargin);

		for(const set of occuranceSets){
			set.left=this.dataRanges[0].getParameter(set.left);
			for(const occurance of set.occurances){
				occurance.top=this.dataRanges[1].getParameter(occurance.top);
			}
		}

		for(const [i,set] of occuranceSets.entries()){
			set.right=math.lerp(set.left,(occuranceSets[i+1]?.left ?? 1),0.75);
			set.width=set.right-set.left;
			set.width=math.min(set.width,1/32);

			set.occurances.sort((a,b)=>a.top-b.top);
			let bottom=0;
			for(const occurance of set.occurances){
				occurance.bottom=bottom;
				bottom=occurance.top;
			}
		}

		let width=math.median(occuranceSets.map(v=>v.width));
		for(const set of occuranceSets){
			set.width=width;
			set.right=set.left+width;
		}
		occuranceSets.reverse();

		this.occuranceSets=occuranceSets;
	}

	private dataIndexFromX(series:Series, x:number){
		let i=0;
		for(;i<series.data.length;++i){
			const dx=toNum(series.data[i][0]);
			if(x<dx)
				break;
		}
		if(i===0){
			return -1;
		}
		if(i>=series.data.length){
			return series.data.length;
		}
		const a=toNum(series.data[i-1][0]);
		const b=toNum(series.data[i][0]);
		return (i-1)+(x-a)/(b-a);
	}

	public mouseEventToPlotEvent(p:DOMPoint, target:ProcessedLine|ProcessedOccurance){
		if(this.dataRanges[0] && this.dataRanges[1]){
			let x=this.dataRanges[0].fromParameter(p.x);
			let y=this.dataRanges[1].fromParameter(p.y);
			const out:PlotEvent={
				dataPoint: [x,y],
				series: null,
				seriesIndex: null,
				dataIndex: null,
				atX: [],
			};
			if(target){
				out.series=target.series;
				out.seriesIndex=target.seriesIndex;

				if('dataIndex' in target){
					out.dataIndex=target.dataIndex;
				}else if(target.series.type==='line'){
					const {series}=target;
					x=toNum(x);
					out.dataIndex=this.dataIndexFromX(series,x);
				}
			}
			return out;
		}
		return null;
	}
}

