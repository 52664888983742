import { math } from "../math";
import * as three from 'three';
import { Delaunator } from "./delaunator";
import { TriangulatedContours } from "./triangulated-contours";
import { TriangulatedPoints } from "./triangulated-points";

export {
	TriangulatedPoints
}

interface TriangulatePointsArgsArray{
	points:([number,number,...any][])|(math.Vec2Like[]);
}

interface TriangulatePointsArgsFlat{
	points:ArrayLike<number>;
	stride:number;
}

interface TriangulatePointsArgsCallback{
	count:number;
	x:(vi:number)=>number;
	y:(vi:number)=>number;
}

type TriangulatePointsArgs=TriangulatePointsArgsArray|TriangulatePointsArgsFlat|TriangulatePointsArgsCallback;

function _triangulatePoints(args:TriangulatePointsArgsCallback):[Uint16Array|Uint32Array,Int32Array]{
	if(args.count<3)
		return [new Uint16Array(),new Int32Array()];
	const delaunator=Delaunator.from(
		args.count,
		args.x,
		args.y,
	);
	return [
		delaunator.triangles,
		delaunator.halfedges,
	];
}

export function triangulatePoints(args:TriangulatePointsArgs|TriangulatePointsArgsArray['points']):TriangulatedPoints{
	if(Array.isArray(args)){
		args={points:args};
	}
	
	if('stride' in args){
		const {points,stride}=args;
		args={
			count: math.floor(points.length/stride),
			x: (vi=>points[vi*stride+0]),
			y: (vi=>points[vi*stride+1]),
		};
	}else if('points' in args){
		let sample=args.points[0];
		if(sample && ('x' in sample)){
			const points=<math.Vec2Like[]>args.points;
			args={
				count: points.length,
				x: (vi=>points[vi].x),
				y: (vi=>points[vi].y),
			};
		}else{
			const points=<[number,number][]>args.points;
			args={
				count: points.length,
				x: (vi=>points[vi][0]),
				y: (vi=>points[vi][0]),
			};
		}
	}


	const [indices,edge2edge]=_triangulatePoints(args);
	return new TriangulatedPoints(args.count,indices,edge2edge);
}

export function triangulateContours<Point extends math.Vec2Like>(
	features:Iterable<Point[]>
){
	const vertices=TriangulatedContours.contoursToVertices(features);
	const [indices,edge2edge]=_triangulatePoints({
		count: vertices.length,
		x: vi=>vertices[vi].coord.x,
		y: vi=>vertices[vi].coord.y,
	});

	return new TriangulatedContours(vertices,indices,edge2edge);
}

export function triangulatePolygon(points:math.Vec2[]):math.Triangle2[];
export function triangulatePolygon(points:math.Vec3[]):math.Triangle3[];
export function triangulatePolygon(points:(math.Vec2[]|math.Vec3[])){
	const vertices=points.map((p:math.Vec2|math.Vec3)=>p.clone());
	const faces=three.ShapeUtils.triangulateShape(vertices,[]);

	if(vertices[0] instanceof math.Vec3){
		const v3Vertices=<math.Vec3[]>vertices;
		const triangles=faces.map(face=>new math.Triangle3(v3Vertices[face[0]],v3Vertices[face[1]],v3Vertices[face[2]]));
		return triangles;
	}

	const v2Vertices=<math.Vec2[]>vertices;
	const triangles=faces.map(face=>new math.Triangle2(v2Vertices[face[0]],v2Vertices[face[1]],v2Vertices[face[2]]));
	return triangles;
}

export function triangulatePolygonToIndices(points:(math.Vec2[]|math.Vec3[])){
	const vertices=points.map(p=>p.clone());
	const faces=<[number,number,number][]>three.ShapeUtils.triangulateShape(vertices,[]);
	return faces;
}
