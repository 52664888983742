<div class="section">
    <div [ngClass]="{ 'flex-row': !isSmallScreen(), 'grid-column': isSmallScreen(),'full-width': isSmallScreen() }">
        <mat-card class="card-normal">
            <mat-card-header class="small-card-content-livetag">Tolerance Spec</mat-card-header>
            <measurement class="small-card-content primary" type="particleSize" [unitSystem]="(particleSizeUnitSystem$|async)" [value]="(tag$.toleranceSpecSize|async)"></measurement>
        </mat-card>
        <mat-card class="card-normal">
            <mat-card-header class="small-card-content-livetag">Bench Seam</mat-card-header>
            <measurement class="small-card-content primary" type="distance" [unitSystem]="(particleSizeUnitSystem$|async)" [value]="(tag$.benchSeamSize|async)"></measurement>
        </mat-card>
        <mat-card class="card-normal">
            <mat-card-header class="small-card-content-livetag">Material Type</mat-card-header>
            <span class="small-card-content primary">{{(tag$.materialType|async)}} </span>
        </mat-card>
        <mat-card class="card-normal">
            <mat-card-header class="small-card-content-livetag">Truck Load Count</mat-card-header>
            <span class="small-card-content primary">{{(tag$.truckLoadCount|async)}} </span>
        </mat-card>
        <mat-card class="card-normal">
            <mat-card-header class="small-card-content-livetag">Comments</mat-card-header>
            <span class="small-card-content primary">{{(tag$.comments|async)}} </span>
        </mat-card>
        <button (click)="switchPage()" mat-mini-fab color="primary" class="arrow right" matTooltip="Go to PSD Details"
            [matTooltipPosition]="'below'">
            <mat-icon svgIcon="arrow-right-white"></mat-icon>
        </button>
    </div>
</div>