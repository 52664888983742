import { Color } from "@lib/color";
import { math } from "@lib/math";

export const percentageDefaultColors=new Map([
	[10,Color.hsv(240,1,1)],
	[20,Color.hsv(120,1,1)],
	[30,Color.hsv(math.lerp(80,120,2/3),1,1)],
	[40,Color.hsv(math.lerp(80,120,1/3),1,1)],
	[50,Color.hsv(80,1,1)],
	[60,Color.hsv(math.lerp(0,80,2/3),1,1)],
	[70,Color.hsv(math.lerp(0,80,1/3),1,1)],
	[80,Color.hsv(0,1,1)],
	[90,Color.hsv(0,1,1)],
	[100,Color.hsv(0,1,1)],
]);

export const percentageNewColors=new Map([
	[10,'#DF0C0C'],
	[20,'#980040'],
	[30,'#98007F'],
	[40,'#3A0098'],
	[50,'#003D98'],
	[60,'#00986A'],
	[70,'#009818'],
	[80,'#4C9800'],
	[90,'#987F00'],
	[100,'#980000'],
]);
