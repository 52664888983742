import {Component,Inject} from '@angular/core';
import {MAT_DIALOG_DATA,MatDialogConfig,MatDialogRef} from '@angular/material/dialog';
import {Client,User} from '@models';

interface Input{
	record:Client;
}

@Component({
	selector: 'client-edit-dialog',
	templateUrl: './client-edit-dialog.component.html',
	styleUrls: [
		'./client-edit-dialog.component.scss'
	]
})
export class ClientEditDialog{
	static readonly config:MatDialogConfig<Input>={
		autoFocus: false,
		disableClose: true,
	};
	static readonly output:boolean;

	constructor(
		public readonly user:User,
		private host:MatDialogRef<boolean>,
		@Inject(MAT_DIALOG_DATA) private readonly input:Input,
	){
	}

	public record=this.input.record;
	public name=this.record.name ?? '';
	public enabled=this.record.enabled;

	public clientDisplayWith(client:Client){
		return client?.name ?? '';
	}

	public valid(){
		if(!this.name)
			return false;
		return true;
	}

	public cancel(){
		this.host.close(false);
	}

	public save(){
		this.record.name=this.name;
		this.record.enabled=this.enabled;
		this.host.close(true);
	}
}
