<div mat-dialog-content>
	<div class="flex-column">
		<mat-form-field>
			<mat-label>Name</mat-label>
			<input matInput type="text" [(ngModel)]="name"/>
			<mat-hint>Enter a name for this device</mat-hint>
		</mat-form-field>
		<mat-form-field>
			<mat-label>Type</mat-label>
			<mat-select [(ngModel)]="type">
				<mat-option *ngFor="let item of typeOptions" [value]="item[0]">
					{{item[1]}}
				</mat-option>
			</mat-select>
		</mat-form-field>
	</div>
</div>
<div mat-dialog-actions>
	<button mat-button [disabled]="!valid()" (click)="finish()">Register</button>
</div>
