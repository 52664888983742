import {math} from '../math';
import * as three from 'three';

function toHexComponent(v:number){
	return v.toString(16).padStart(2,'0');
}

export class Color{
	public constructor(
		isRGB:boolean,
		x:number,
		y:number,
		z:number,
		a:number
	){
		this._a=a;
		if(isRGB){
			this._r=x;
			this._g=y;
			this._b=z;
		}else{
			this._hue=x;
			this._sat=y;
			this._val=z;
			this.updateRGB();
		}
	}

	private _r:number;
	private _g:number;
	private _b:number;
	private _a:number;
	private _hue?:number;
	private _sat?:number;
	private _val?:number;

	private clearHSV(){
		delete this._hue;
		delete this._sat;
		delete this._val;
	}
	
	private updateHSV(){
		if(this._hue===undefined){
			const r=this._r,g=this._g,b=this._b;
			const min=Math.min(r,g,b);
			const max=Math.max(r,g,b);

			let h=0,s=0,v=0;
			v=max;
			const delta=max-min;
			if(delta<0.00001 || max<=0){
				//the color is a shade of gray
				h=0;
				s=0;
			}else{
				s=(delta/max);
				if(r==max)
					h=(g-b)/delta;// between yellow & magenta
				else if(g==max)
					h=2.0+(b-r)/delta;// between cyan & yellow
				else
					h=4.0+(r-g)/delta;// between magenta & cyan

				h*=60.0;// degrees

				if(h<0)
					h+=360;

			}
			this._hue=h;
			this._sat=s;
			this._val=v;
		}
	}

	private updateRGB(){
		let h=this._hue ?? 0,s=this._sat ?? 0,v=this._val ?? 0;
		if(s<=0){
			this._r=this._g=this._b=v;
		}else{
			let r=0,g=0,b=0;
			let hh=h;
			if(hh>=360.0)
				hh=0;
			hh/=60.0;
			let i=Math.floor(hh);
			let ff=hh-i;
			let p=v*(1.0-s);
			let q=v*(1.0-(s*ff));
			let t=v*(1.0-(s*(1.0-ff)));

			switch(i){
			case 0:
				r=v;
				g=t;
				b=p;
				break;
			case 1:
				r=q;
				g=v;
				b=p;
				break;
			case 2:
				r=p;
				g=v;
				b=t;
				break;

			case 3:
				r=p;
				g=q;
				b=v;
				break;
			case 4:
				r=t;
				g=p;
				b=v;
				break;
			case 5:
			default:
				r=v;
				g=p;
				b=q;
				break;
			}
			this._r=r;
			this._g=g;
			this._b=b;
		}
	}

	public JSONParams(){
		return [true,this._r,this._g,this._b,this._a];
	}

	public clone(){
		return new Color(true,this._r,this._g,this._b,this._a);
	}

	public copy(that:Color){
		this._r=that._r;
		this._b=that._b;
		this._g=that._g;
		this._a=that._a;
		this.clearHSV();
		return this;
	}

	public equals(that:Color|Color.HtmlName){
		if(typeof(that)==='string')
			that=Color.htmlNames[that];
		return that && this._r===that._r && this._g===that._g && this._b===that._b && this._a===that._a;
	}

	private rgbComponent(key:'_r'|'_g'|'_b', v?:number){
		if(typeof(v)==='number'){
			v=Math.min(Math.max(v,0),1);
			if(this[key]!==v){
				this[key]=v;
				this.clearHSV();
			}
			return this;
		}
		return this[key];
	}

	public red():number;
	public red(v:number):this;
	public red(v?:number){
		return this.rgbComponent('_r',v);
	}

	public green():number;
	public green(v:number):this;
	public green(v?:number){
		return this.rgbComponent('_g',v);
	}

	public blue():number;
	public blue(v:number):this;
	public blue(v?:number){
		return this.rgbComponent('_b',v);
	}

	public hue():number;
	public hue(v:number):this;
	public hue(v?:number){
		this.updateHSV();
		if(typeof(v)==='number'){
			v=(v%360+360)%360;
			if(this._hue!==v){
				this._hue=v;
				this.updateRGB();
			}
			return this;
		}
		return this._hue;
	}

	public saturation():number;
	public saturation(v:number):this;
	public saturation(v?:number){
		this.updateHSV();
		if(typeof(v)==='number'){
			v=Math.min(Math.max(v,0),1);
			if(this._sat!==v){
				this._sat=v;
				this.updateRGB();
			}
			return this;
		}
		return this._sat;
	}

	public value():number;
	public value(v:number):this;
	public value(v?:number){
		this.updateHSV();
		if(typeof(v)==='number'){
			v=Math.min(Math.max(v,0),1);
			if(this._val!==v){
				this._val=v;
				this.updateRGB();
			}
			return this;
		}
		return this._val;
	}

	public alpha():number;
	public alpha(v:number):this;
	public alpha(v?:number){
		if(v!==undefined){
			this._a=Math.min(Math.max(v,0),1);
			return this;
		}
		return this._a;
	}

	// public opacity():number;
	// public opacity(v:number):this;
	// public opacity(v?:number):this|number{
	// 	return this.alpha(v);
	// }

	public rgb(r:number, g:number, b:number, a?:number):this;
	public rgb(array:number[]):this;
	public rgb(r:number|number[],g?:number,b?:number,a?:number){
		if(Array.isArray(r)){
			this._r=r[0] ?? 0;
			this._g=r[1] ?? 0;
			this._b=r[2] ?? 0;
			this._a=r[3] ?? 1;
		}else{
			this._r=r;
			this._g=g!;
			this._b=b!;
			this._a=a ?? 1;
		}
		this.clearHSV();
		return this;
	}

	public hsv(h:number, s:number, v:number, a?:number):this;
	public hsv(array:number[]):this;
	public hsv(h:number|number[],s?:number,v?:number,a?:number){
		if(Array.isArray(h)){
			this._hue=h[0] ?? 0;
			this._sat=h[1] ?? 0;
			this._val=h[2] ?? 0;
			this._a=h[3] ?? 1;
		}else{
			this._hue=h;
			this._sat=s!;
			this._val=v!;
			this._a=a!;
		}
		this.updateRGB();
		return this;
	}

	public rgbDistance(that:Color){
		return Math.sqrt((this._r-that._r)**2+(this._g-that._g)**2+(this._b-that._b)**2);
	}

	public hsvPosition(out=new math.Vec3()){
		this.updateHSV();
		const hue=this._hue!*math.D2R;
		out.x=math.cos(hue);
		out.y=math.sin(hue);
		
		const sat=this._sat!*math.PIHALF;
		out.z=math.cos(sat);
		const sinSat=math.sin(sat);
		out.x*=sinSat;
		out.y*=sinSat;

		const val=this._val!;
		out.x*=val;
		out.y*=val;
		out.z*=val;
		return out;
	}

	public html(includeAlpha=true){
		let r=Math.min(Math.max(Math.round(this._r*255),0),255);
		let g=Math.min(Math.max(Math.round(this._g*255),0),255);
		let b=Math.min(Math.max(Math.round(this._b*255),0),255);
		let a=Math.min(Math.max(Math.round(this._a*255),0),255);

		let s=`#${toHexComponent(r)}${toHexComponent(g)}${toHexComponent(b)}`;
		if(includeAlpha && a!=255)
			s+=toHexComponent(a);
		return s;
	}

	public htmlRgb(){
		if(this._a!=1)
			return `rgba(${Math.round(this._r*255).toString()},${Math.round(this._g*255).toString()},${Math.round(this._b*255).toString()},${Math.round(this._a).toFixed(2)})`;
		return `rgb(${Math.round(this._r*255).toString()},${Math.round(this._g*255).toString()},${Math.round(this._b*255).toString()})`;
	}

	public toString(){
		return this.html();
	}

	public toArray():[number,number,number,number];
	public toArray<T extends number[]>(array:T, i?:number):T;
	public toArray(array:number[]=new Array(4), i=0):number[]{
		array[i+0]=this._r;
		array[i+1]=this._g;
		array[i+2]=this._b;
		array[i+3]=this._a;
		return array;
	}

	public toVec4(out?:three.Vector4):three.Vector4{
		if(!out)
			return new three.Vector4(this._r,this._g,this._b,this._a);
		out.x=this._r;
		out.y=this._g;
		out.z=this._b;
		out.w=this._a;
		return out;
	}

	// public threeColor():three.Color{
	// 	return new three.Color(this._r,this._g,this._b);
	// }

	public scale(f:number){
		this.clearHSV();
		this._r*=f;
		this._g*=f;
		this._b*=f;
		this._a*=f;
		return this;
	}

	public add(that:Color){
		this.clearHSV();
		this._r+=that._r;
		this._g+=that._g;
		this._b+=that._b;
		this._a+=that._a;
		return this;
	}

	public addScaled(that:Color, f:number){
		this.clearHSV();
		this._r+=that._r*f;
		this._g+=that._g*f;
		this._b+=that._b*f;
		this._a+=that._a*f;
		return this;
	}

	public lerp(that:Color, f:number){
		this.clearHSV();
		this._r=math.lerp(this._r,that._r,f);
		this._g=math.lerp(this._g,that._g,f);
		this._b=math.lerp(this._b,that._b,f);
		this._a=math.lerp(this._a,that._a,f);
		return this;
	}

	public negate(){
		this.clearHSV();
		this._r=1-this._r;
		this._g=1-this._g;
		this._b=1-this._b;
		return this;
	}


}

export class ReadOnlyColor extends Color{
	public constructor(
		isRGB:boolean,
		x:number,
		y:number,
		z:number,
		a:number
	){
		super(isRGB,x,y,z,a);
	}

	public copy(that:Color){
		return this;
	}

	public red():number;
	public red(v:number):this;
	public red(v?:number){
		if(typeof(v)==='number')
			return this;
		return super.red();
	}

	public green():number;
	public green(v:number):this;
	public green(v?:number){
		if(typeof(v)==='number')
			return this;
		return super.green();
	}

	public blue():number;
	public blue(v:number):this;
	public blue(v?:number){
		if(typeof(v)==='number')
			return this;
		return super.blue();

	}

	public hue():number;
	public hue(v:number):this;
	public hue(v?:number){
		if(typeof(v)==='number')
			return this;
		return super.hue();
	}

	public saturation():number;
	public saturation(v:number):this;
	public saturation(v?:number){
		if(typeof(v)==='number')
			return this;
		return super.saturation();
	}

	public value():number;
	public value(v:number):this;
	public value(v?:number){
		if(typeof(v)==='number')
			return this;
		return super.value();
	}

	public alpha():number;
	public alpha(v:number):this;
	public alpha(v?:number){
		if(typeof(v)==='number')
			return this;
		return super.alpha();
	}

	public rgb(r:number, g:number, b:number, a?:number):this;
	public rgb(array:number[]):this;
	public rgb(r:number|number[],g?:number,b?:number,a?:number){
		return this;
	}

	public hsv(h:number, s:number, v:number, a?:number):this;
	public hsv(array:number[]):this;
	public hsv(h?:number|number[],s?:number,v?:number,a?:number){
		return this;
	}

	public scale(f:number){
		return this;
	}

	public add(that:Color){
		return this;
	}

	public addScaled(that:Color, f:number){
		return this;
	}

	public lerp(that:Color, f:number){
		return this;
	}

	public negate(){
		return this;
	}
}

export namespace Color{
	export function fromArray(a:number[]){
		return rgb(
			a[0] ?? 0,
			a[1] ?? 0,
			a[2] ?? 0,
			a[3] ?? 1,
		);
	}

	export function uniqueHue(index:number){
		if(index<3)
			return index*120;
		//this formula is infinit subdivision
		const level=3/2*Math.pow(2,Math.ceil(Math.log2((index+1)/3)));
		return 360*(0.5+index-level)/level;
	}

	export function random(){
		const red=Math.random();
		const green=Math.random();
		const blue=Math.random();
		return new Color(true,red,green,blue,1);
	}

	export function rgb(r:number,g:number,b:number,a:number=1){
		r=Math.min(Math.max(r,0),1);
		g=Math.min(Math.max(g,0),1);
		b=Math.min(Math.max(b,0),1);
		a=Math.min(Math.max(a,0),1);
		return new Color(true,r,g,b,a);
	}

	export function hsv(h:number,s:number,v:number,a:number=1){
		h=h%360;
		s=Math.min(Math.max(s,0),1);
		v=Math.min(Math.max(v,0),1);
		a=Math.min(Math.max(a,0),1);
		return new Color(false,h,s,v,a);
	}

	// export function named(name:HtmlName|Color){
	// 	if(name instanceof Color)
	// 		return name;
	// 	else
	// 		return htmlNames[name];
	// }

	export function html(color:string|Color):Color;
	export function html(color:string|Color,Type:typeof ReadOnlyColor):ReadOnlyColor;
	export function html(color:string|Color,Type=Color):Color|null{
		if(color instanceof Color)
			return color;
		if(!color)
			return null;
		let matches:RegExpMatchArray|null;
		if(matches=color.match(/^#([0-9A-F]{1})([0-9A-F]{1})([0-9A-F]{1})$/i))
			return new Type(true,parseInt(matches[1],16)/15,parseInt(matches[2],16)/15,parseInt(matches[3],16)/15,1);

		if(matches=color.match(/^#([0-9A-F]{2})([0-9A-F]{2})([0-9A-F]{2})$/i))
			return new Type(true,parseInt(matches[1],16)/255,parseInt(matches[2],16)/255,parseInt(matches[3],16)/255,1);

		if(matches=color.match(/^#([0-9A-F]{2})([0-9A-F]{2})([0-9A-F]{2})([0-9A-F]{2})$/i))
			return new Type(true,parseInt(matches[1],16)/255,parseInt(matches[2],16)/255,parseInt(matches[3],16)/255,parseInt(matches[4],16)/255);

		if(matches=color.match(/^rgb\(\s*([0-9]+)\s*,\s*([0-9]+)\s*,\s*([0-9]+)\s*\)$/i))
			return new Type(true,parseInt(matches[1])/255,parseInt(matches[2])/255,parseInt(matches[3])/255,1);

		if(matches=color.match(/^rgba?\(\s*([0-9]+)\s*,\s*([0-9]+)\s*,\s*([0-9]+)\s*,\s*(\d*\.?\d+)\s*\)$/i))
			return new Type(true,parseInt(matches[1])/255,parseInt(matches[2])/255,parseInt(matches[3])/255,parseFloat(matches[4]));

		if(htmlNames[<HtmlName>color]!==undefined)
			return htmlNames[<HtmlName>color];

		// console.error(`bad color format "${color}"`);
		return null;
	}

	export function white(){
		return new Color(true,1,1,1,1);
	}

	export function black(){
		return new Color(true,0,0,0,1);
	}	

	export function transparent(){
		return new Color(true,0,0,0,0);
	}	

	// These colors are standard html colors. DO NOT CHANGE THE VALUES HERE, PLEASE.
	export const htmlNames={
		transparent: html('#00000000',ReadOnlyColor),
		black: html('#000000',ReadOnlyColor),
		silver: html('#c0c0c0',ReadOnlyColor),
		gray: html('#808080',ReadOnlyColor),
		white: html('#ffffff',ReadOnlyColor),
		maroon: html('#800000',ReadOnlyColor),
		red: html('#ff0000',ReadOnlyColor),
		purple: html('#800080',ReadOnlyColor),
		fuchsia: html('#ff00ff',ReadOnlyColor),
		green: html('#008000',ReadOnlyColor),
		lime: html('#00ff00',ReadOnlyColor),
		olive: html('#808000',ReadOnlyColor),
		yellow: html('#ffff00',ReadOnlyColor),
		navy: html('#000080',ReadOnlyColor),
		blue: html('#0000ff',ReadOnlyColor),
		teal: html('#008080',ReadOnlyColor),
		aqua: html('#00ffff',ReadOnlyColor),
		orange: html('#ffa500',ReadOnlyColor),
		aliceblue: html('#f0f8ff',ReadOnlyColor),
		antiquewhite: html('#faebd7',ReadOnlyColor),
		aquamarine: html('#7fffd4',ReadOnlyColor),
		azure: html('#f0ffff',ReadOnlyColor),
		beige: html('#f5f5dc',ReadOnlyColor),
		bisque: html('#ffe4c4',ReadOnlyColor),
		blanchedalmond: html('#ffebcd',ReadOnlyColor),
		blueviolet: html('#8a2be2',ReadOnlyColor),
		brown: html('#a52a2a',ReadOnlyColor),
		burlywood: html('#deb887',ReadOnlyColor),
		cadetblue: html('#5f9ea0',ReadOnlyColor),
		chartreuse: html('#7fff00',ReadOnlyColor),
		chocolate: html('#d2691e',ReadOnlyColor),
		coral: html('#ff7f50',ReadOnlyColor),
		cornflowerblue: html('#6495ed',ReadOnlyColor),
		cornsilk: html('#fff8dc',ReadOnlyColor),
		crimson: html('#dc143c',ReadOnlyColor),
		cyan: html('#00ffff',ReadOnlyColor),
		darkblue: html('#00008b',ReadOnlyColor),
		darkcyan: html('#008b8b',ReadOnlyColor),
		darkgoldenrod: html('#b8860b',ReadOnlyColor),
		darkgray: html('#a9a9a9',ReadOnlyColor),
		darkgreen: html('#006400',ReadOnlyColor),
		darkgrey: html('#a9a9a9',ReadOnlyColor),
		darkkhaki: html('#bdb76b',ReadOnlyColor),
		darkmagenta: html('#8b008b',ReadOnlyColor),
		darkolivegreen: html('#556b2f',ReadOnlyColor),
		darkorange: html('#ff8c00',ReadOnlyColor),
		darkorchid: html('#9932cc',ReadOnlyColor),
		darkred: html('#8b0000',ReadOnlyColor),
		darksalmon: html('#e9967a',ReadOnlyColor),
		darkseagreen: html('#8fbc8f',ReadOnlyColor),
		darkslateblue: html('#483d8b',ReadOnlyColor),
		darkslategray: html('#2f4f4f',ReadOnlyColor),
		darkslategrey: html('#2f4f4f',ReadOnlyColor),
		darkturquoise: html('#00ced1',ReadOnlyColor),
		darkviolet: html('#9400d3',ReadOnlyColor),
		deeppink: html('#ff1493',ReadOnlyColor),
		deepskyblue: html('#00bfff',ReadOnlyColor),
		dimgray: html('#696969',ReadOnlyColor),
		dimgrey: html('#696969',ReadOnlyColor),
		dodgerblue: html('#1e90ff',ReadOnlyColor),
		firebrick: html('#b22222',ReadOnlyColor),
		floralwhite: html('#fffaf0',ReadOnlyColor),
		forestgreen: html('#228b22',ReadOnlyColor),
		gainsboro: html('#dcdcdc',ReadOnlyColor),
		ghostwhite: html('#f8f8ff',ReadOnlyColor),
		gold: html('#ffd700',ReadOnlyColor),
		goldenrod: html('#daa520',ReadOnlyColor),
		greenyellow: html('#adff2f',ReadOnlyColor),
		grey: html('#808080',ReadOnlyColor),
		honeydew: html('#f0fff0',ReadOnlyColor),
		hotpink: html('#ff69b4',ReadOnlyColor),
		indianred: html('#cd5c5c',ReadOnlyColor),
		indigo: html('#4b0082',ReadOnlyColor),
		ivory: html('#fffff0',ReadOnlyColor),
		khaki: html('#f0e68c',ReadOnlyColor),
		lavender: html('#e6e6fa',ReadOnlyColor),
		lavenderblush: html('#fff0f5',ReadOnlyColor),
		lawngreen: html('#7cfc00',ReadOnlyColor),
		lemonchiffon: html('#fffacd',ReadOnlyColor),
		lightblue: html('#add8e6',ReadOnlyColor),
		lightcoral: html('#f08080',ReadOnlyColor),
		lightcyan: html('#e0ffff',ReadOnlyColor),
		lightgoldenrodyellow: html('#fafad2',ReadOnlyColor),
		lightgray: html('#d3d3d3',ReadOnlyColor),
		lightgreen: html('#90ee90',ReadOnlyColor),
		lightgrey: html('#d3d3d3',ReadOnlyColor),
		lightpink: html('#ffb6c1',ReadOnlyColor),
		lightsalmon: html('#ffa07a',ReadOnlyColor),
		lightseagreen: html('#20b2aa',ReadOnlyColor),
		lightskyblue: html('#87cefa',ReadOnlyColor),
		lightslategray: html('#778899',ReadOnlyColor),
		lightslategrey: html('#778899',ReadOnlyColor),
		lightsteelblue: html('#b0c4de',ReadOnlyColor),
		lightyellow: html('#ffffe0',ReadOnlyColor),
		limegreen: html('#32cd32',ReadOnlyColor),
		linen: html('#faf0e6',ReadOnlyColor),
		magenta: html('#ff00ff',ReadOnlyColor),
		mediumaquamarine: html('#66cdaa',ReadOnlyColor),
		mediumblue: html('#0000cd',ReadOnlyColor),
		mediumorchid: html('#ba55d3',ReadOnlyColor),
		mediumpurple: html('#9370db',ReadOnlyColor),
		mediumseagreen: html('#3cb371',ReadOnlyColor),
		mediumslateblue: html('#7b68ee',ReadOnlyColor),
		mediumspringgreen: html('#00fa9a',ReadOnlyColor),
		mediumturquoise: html('#48d1cc',ReadOnlyColor),
		mediumvioletred: html('#c71585',ReadOnlyColor),
		midnightblue: html('#191970',ReadOnlyColor),
		mintcream: html('#f5fffa',ReadOnlyColor),
		mistyrose: html('#ffe4e1',ReadOnlyColor),
		moccasin: html('#ffe4b5',ReadOnlyColor),
		navajowhite: html('#ffdead',ReadOnlyColor),
		oldlace: html('#fdf5e6',ReadOnlyColor),
		olivedrab: html('#6b8e23',ReadOnlyColor),
		orangered: html('#ff4500',ReadOnlyColor),
		orchid: html('#da70d6',ReadOnlyColor),
		palegoldenrod: html('#eee8aa',ReadOnlyColor),
		palegreen: html('#98fb98',ReadOnlyColor),
		paleturquoise: html('#afeeee',ReadOnlyColor),
		palevioletred: html('#db7093',ReadOnlyColor),
		papayawhip: html('#ffefd5',ReadOnlyColor),
		peachpuff: html('#ffdab9',ReadOnlyColor),
		peru: html('#cd853f',ReadOnlyColor),
		pink: html('#ffc0cb',ReadOnlyColor),
		plum: html('#dda0dd',ReadOnlyColor),
		powderblue: html('#b0e0e6',ReadOnlyColor),
		rosybrown: html('#bc8f8f',ReadOnlyColor),
		royalblue: html('#4169e1',ReadOnlyColor),
		saddlebrown: html('#8b4513',ReadOnlyColor),
		salmon: html('#fa8072',ReadOnlyColor),
		sandybrown: html('#f4a460',ReadOnlyColor),
		seagreen: html('#2e8b57',ReadOnlyColor),
		seashell: html('#fff5ee',ReadOnlyColor),
		sienna: html('#a0522d',ReadOnlyColor),
		skyblue: html('#87ceeb',ReadOnlyColor),
		slateblue: html('#6a5acd',ReadOnlyColor),
		slategray: html('#708090',ReadOnlyColor),
		slategrey: html('#708090',ReadOnlyColor),
		snow: html('#fffafa',ReadOnlyColor),
		springgreen: html('#00ff7f',ReadOnlyColor),
		steelblue: html('#4682b4',ReadOnlyColor),
		tan: html('#d2b48c',ReadOnlyColor),
		thistle: html('#d8bfd8',ReadOnlyColor),
		tomato: html('#ff6347',ReadOnlyColor),
		turquoise: html('#40e0d0',ReadOnlyColor),
		violet: html('#ee82ee',ReadOnlyColor),
		wheat: html('#f5deb3',ReadOnlyColor),
		whitesmoke: html('#f5f5f5',ReadOnlyColor),
		yellowgreen: html('#9acd32',ReadOnlyColor),
		rebeccapurple: html('#663399',ReadOnlyColor),
	};
	export type HtmlName=keyof typeof htmlNames;

	const friendlyNames={
		'black': Color.hsv(0,0,0),
		'dark gray': Color.hsv(0,0,0.25),
		'gray': Color.hsv(0,0,0.5),
		'light-gray': Color.hsv(0,0,0.75),
		'white': Color.hsv(0,0,1),
		'red': Color.hsv(0,1,1),
		'dark red': Color.hsv(0,1,0.5),
		'orange': Color.hsv(40,1,1),
		'dark orange': Color.hsv(40,1,0.5),
		'yellow': Color.hsv(60,1,1),
		'dark yellow': Color.hsv(40,1,0.5),
		'bright green': Color.hsv(120,1,1),
		'green': Color.hsv(120,1,0.5),
		'dark green': Color.hsv(120,1,0.25),
		'cyan': Color.hsv(180,1,1),
		'dark cyan': Color.hsv(180,1,0.5),
		'blue': Color.hsv(240,1,1),
		'dark blue': Color.hsv(240,1,0.5),
		'bluish purple': Color.hsv(270,1,1),
		'magenta': Color.hsv(300,1,1),
		'purple': Color.hsv(300,1,0.5),
	};

	const friendlyPositions=new Map(Object.entries(friendlyNames).map(([name,color])=>[name,color.hsvPosition()]));

	export function friendlyName(color:Color){
		let closestName='unknown';
		let closestDist=Infinity;

		const wantedPosition=color.hsvPosition();
		for(const [name,position] of friendlyPositions){
			const dist=wantedPosition.distTo(position);		
			if(closestDist>dist){
				closestDist=dist;
				closestName=name;
			}
		}
		return closestName;
	}

	export function bestFitPalette(maxCount:number, colors:Color[]){

	}
}
