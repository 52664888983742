import {PDFDocument} from "./pdf";
import {fromPx} from "./shared";

const usableFonts:Record<string,boolean>={
	Courier: true,
	Helvetica: true,
	Symbol: true,
	'Times-Roman': true,
	ZapfDingbats: true,
}

export const fontAscents:Record<string,number>={
	'Courier': 718/1000,
	'Courier-Bold': 718/1000,
	'Courier-Oblique': 718/1000,
	'Courier-Bold-Oblique': 718/1000,
	'Helvetica': 718/1000,
	'Helvetica-Bold': 718/1000,
	'Helvetica-Oblique': 718/1000,
	'Helvetica-Bold-Oblique': 718/1000,
	Symbol: 500/1000,
	'Times-Roman': 683/1000,
	'Times-Roman-Bold': 683/1000,
	'Times-Roman-Italic': 683/1000,
	'Times-Roman-Bold-Italic': 683/1000,
	ZapfDingbats: 500/1000,
}

export async function loadFonts(doc:PDFDocument){
	const fontfaces=[
		{
			fontFamily: 'Lato',
			fontStyle: 'normal',
			fontWeight: 400,
			src: './assets/fonts/Lato-Regular.ttf',
		},
		{
			fontFamily: 'Lato',
			fontStyle: 'normal',
			fontWeight: 700,
			src: './assets/fonts/Lato-Bold.ttf',
		},
	];

	for(const font of fontfaces){
		let name=font.fontFamily;
		if(font.fontWeight===700)
			name=`${name}-Bold`;
		const response=await fetch(font.src);
		const buffer=await response.arrayBuffer();
		doc.registerFont(name,buffer);
		usableFonts[name]=true;
	}	
}

export function getFont(styles:CSSStyleDeclaration){
	let family=styles.fontFamily;
	if(!usableFonts[family])
		family='Courier';
	if(+styles.fontWeight>=700)
		family+='-Bold';
	else if(styles.fontStyle==='italic'){
		if(family==='Times')
			family+='-Italic';
		else
			family+='-Oblique';
	}
	const size=fromPx(styles.fontSize);
	return {family,size};
}

function getFontSize(styles:CSSStyleDeclaration){
	return fromPx(styles.fontSize);
}
