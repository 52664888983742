
class MasterResizeObserver{
	private readonly callbacks=new WeakMap<Element,Set<(e:ResizeObserverEntry)=>void>>();
	private readonly ro=new ResizeObserver(entries=>{
		for(const e of entries){
			const callbacks=this.callbacks.get(e.target);
			if(callbacks){
				for(const callback of callbacks)
					callback(e);
			}
		}
	});

	public add(target:Element, callback:(e:ResizeObserverEntry)=>void, options?:ResizeObserverOptions){
		let callbacks=this.callbacks.get(target);
		if(!callbacks)
			this.callbacks.set(target,callbacks=new Set());
		callbacks.add(callback);
		this.ro.observe(target,options);
		return callback;
	}

	public delete(target:Element, callback:(e:ResizeObserverEntry)=>void){
		let callbacks=this.callbacks.get(target);
		if(callbacks){
			callbacks.delete(callback);
			if(callbacks.size===0){
				this.callbacks.delete(target);
				this.ro.unobserve(target);
			}
		}
	}
}

let _resizeObserver:MasterResizeObserver;

export function resizeObserver(){
	_resizeObserver??=new MasterResizeObserver();
	return _resizeObserver;
}
