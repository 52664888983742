export function _keysEqual(
	this:{
		keys():Iterable<any>;
		has(v:any):boolean,
	},
	that:{
		keys():Iterable<any>;
		has(v:any):boolean,
	}
){
	if(this===that)
		return true;
	for(const v of that.keys()){
		if(!this.has(v))
			return false;
	}
	for(const v of this.keys()){
		if(!that.has(v))
			return false;
	}
	return true;
}