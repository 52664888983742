import { MultiMap } from "../multi-map";
import { math } from "../math";
import { iterate } from "../iterate";
import { TriangulatedPoints } from "./triangulated-points";

interface Edge{
	ti:number;
	side:number;
}

function advanceEdge(edge:Edge, delta:number){
	return {ti:edge.ti,side:(edge.side+delta)%3};
}

function distanceXYSq(a:math.Vec2Like, b:math.Vec2Like){
	return (a.x-b.x)**2+(a.y-b.y)**2;
}

export class TriangulatedContours<Point extends math.Vec2Like> extends TriangulatedPoints{
	public constructor(
		public vertices:TriangulatedContours.Vertex<Point>[],
		indices:Uint16Array|Uint32Array,
		edge2edge:Int32Array,
	){
		super(vertices.length,indices,edge2edge);
	}

	public verticesAt(ti:number){
		ti*=3;
		return <[TriangulatedContours.Vertex<Point>,TriangulatedContours.Vertex<Point>,TriangulatedContours.Vertex<Point>]>[
			this.vertices[this.indices[ti+0]],
			this.vertices[this.indices[ti+1]],
			this.vertices[this.indices[ti+2]],
		];
	}

	public jumpEdge(edge:Edge){
		let side=this.edge2edge[edge.ti*3+edge.side];
		if(side<0)
			return null;//no neighbor
		let ti=side;
		side%=3;
		ti=(ti-side)/3;
		return {ti,side};
	}

	private isContourConnection(
		a:number,
		b:number,
	){
		if((math.abs(a-b)==1 && this.vertices[a].contourIndex===this.vertices[b].contourIndex))
			return true;//would cross contour
		return false;
	}
	
	private isWindingCorrect(a:number, b:number, c:number){
		return math.Triangle2.areaSigned(this.vertices[a].coord,this.vertices[b].coord,this.vertices[c].coord)<0;
	}

	public alignTriangles(
		canTriangleTake:(ti:number)=>boolean,
		canTriangleBeTaken:(ti:number)=>boolean,
		triangleTaken:(ti:number)=>void,
	){
		const {indices,vertices}=this;
		const triangleCount=indices.length/3;
		const trianglesClosed=new Int8Array(triangleCount).fill(0);

		function getSideLengthsSq(t:[number,number,number]){
			return [
				distanceXYSq(vertices[t[0]].coord,vertices[t[1]].coord),
				distanceXYSq(vertices[t[1]].coord,vertices[t[2]].coord),
				distanceXYSq(vertices[t[2]].coord,vertices[t[0]].coord),
			];
		}
		const takeNeighbor=(
			recursionDepth:number,
			focalVi:number,
			_edge:Edge,
			takenTriangles:number[],
			takenVertices:number[],
		):void=>{
			if(recursionDepth>=64)
				return;

			const edge=this.jumpEdge(_edge);
			if(!edge)
				return;
			if(!canTriangleBeTaken(edge.ti))
				return;

			const t=this.atRotated(edge.ti,edge.side);
			const lengths=getSideLengthsSq(t);
			if(!(lengths[0]>lengths[1] && lengths[0]>lengths[2]))
				return;

			if(trianglesClosed[edge.ti])
				return;//neighbor is already taken
			trianglesClosed[edge.ti]=1;

			if(this.isWindingCorrect(focalVi,t[1],t[2]) && this.isWindingCorrect(focalVi,t[2],t[0])){
				if(!this.isContourConnection(t[1],t[2])){
					takeNeighbor(recursionDepth+1,focalVi,advanceEdge(edge,1),takenTriangles,takenVertices);
				}
				takenTriangles.push(edge.ti);
				takenVertices.push(t[2]);
				if(!this.isContourConnection(t[2],t[0])){
					takeNeighbor(recursionDepth+1,focalVi,advanceEdge(edge,2),takenTriangles,takenVertices);
				}
			}
		}

		function alignNeighbors(
			focalVi:number,
			takenTriangles:number[],
			takenVertices:number[],
		){
			if(takenVertices.length===takenTriangles.length+1){
				for(const [a,b] of takenVertices.pairs()){
					const ti=takenTriangles.pop()!;
					triangleTaken(ti);
					const ti3=ti*3;
					indices[ti3+0]=focalVi;
					indices[ti3+1]=a;
					indices[ti3+2]=b;
				}
				for(const ti of takenTriangles){
					//make any unused indices dead (there's a possiblity these 0 size triangles could cause issues though)
					const ti3=ti*3;
					indices[ti3+0]=focalVi;
					indices[ti3+1]=focalVi;
					indices[ti3+2]=focalVi;
				}
			}
		}

		for(let ti=0;ti<triangleCount;++ti){
			// let t=this.at(ti);
			// const debug=(
			// 	t.some(i=>vertices[i].feature.id===204 && vertices[i].feature.geometry.coordinates[245]===vertices[i].coord)
			// 	&& t.some(i=>vertices[i].feature.id===204 && vertices[i].feature.geometry.coordinates[261]===vertices[i].coord)
			// 	&& t.some(i=>vertices[i].feature.id===24 && vertices[i].feature.geometry.coordinates[233]===vertices[i].coord)
			// );
			// if(debug){
			// 	debugger;
			// }else{
			// 	continue;
			// }
			if(trianglesClosed[ti])
				continue;
			if(!canTriangleTake(ti))
				continue;

			const t=this.at(ti);
			const lengths=getSideLengthsSq(t);
			const sides=[0,1,2].sort((a,b)=>lengths[a]-lengths[b]);
			for(const side of sides){
				const t=this.atRotated(ti,side);
				if(!this.isContourConnection(t[0],t[1])){
					const takenTriangles=[ti];
					const takenVertices=[t[0]];
					takeNeighbor(0,t[2],{ti,side},takenTriangles,takenVertices);
					if(takenTriangles.length>1){
						takenVertices.push(t[1]);
						alignNeighbors(t[2],takenTriangles,takenVertices);
						trianglesClosed[ti]=1;
						break;
					}
				}
			}
		}
	}
}

export namespace TriangulatedContours{
	export interface Vertex<Point extends math.Vec2Like>{
		coord:Point;
		contourIndex:number;
		coordIndex:number;
	}

	export function contoursToVertices<Point extends math.Vec2Like>(features:Iterable<Point[]>){
		let vertices:Vertex<Point>[]=[];
		let featureIndex=0;
		for(const feature of features){
			vertices.push(...feature.map((coord,coordIndex):Vertex<Point>=>({
				coord,
				contourIndex: featureIndex,
				coordIndex,
			})));
			featureIndex+=1;
		}

		const dupMap=new MultiMap<string,number>();
		for(const [vi,end] of vertices.entries()){
			const key=`${end.coord.x.toFixed(2)},${end.coord.y.toFixed(2)}`;
			dupMap.add(key,vi);
		}
		const dups=new Set<number>();
		for(const [k,v] of dupMap){
			// if((new Set(v.map(vi=>vertices[vi].type))).size>1){
			if(v.length>1){
				for(const vi of v)
					dups.add(vi);
			}
		}

		vertices=vertices.filter((e,i)=>!dups.has(i));
		return vertices;
	}
}