import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from "@sentry/angular-ivy";
import { CaptureConsole } from '@sentry/integrations';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import '@lib/stdext';

if (environment.production) {
  enableProdMode();
}
if(environment.sentryUrl) {
  Sentry.init({
    dsn: "https://d4f10a1170dd47d0bc2ccd688ebe879f@sentry2.strayos.com//15",
    integrations: [
      <any>new CaptureConsole({
        levels: ['error']
      })
    ],
    release: `netra-frontend-${environment.name}-release`,
    attachStacktrace: true,
    environment: environment.name,
    tracesSampleRate: 1.0,
  });
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
