import {Component} from '@angular/core';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer, Title} from '@angular/platform-browser';
import {environment} from '@environment';
import {NavigationRoute$, User$,WebClient} from '@injectables';
import { op } from '@lib/rxjs';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent{
	constructor(
		iconRegistry:MatIconRegistry,
		sanitizer:DomSanitizer,
		title:Title,
		private readonly webClient:WebClient,
		public readonly user$:User$,
		private readonly route$: NavigationRoute$,
	){
		title.setTitle(environment.appTitle);		
		iconRegistry.setDefaultFontSetClass("material-icons-outlined");
		iconRegistry.addSvgIcon('strayos',sanitizer.bypassSecurityTrustResourceUrl('assets/mat-icons/strayos.svg'));
		iconRegistry.addSvgIcon('strayos_blue',sanitizer.bypassSecurityTrustResourceUrl('assets/mat-icons/strayos_blue.svg'));
		iconRegistry.addSvgIcon('strayos_white',sanitizer.bypassSecurityTrustResourceUrl('assets/mat-icons/strayos_white.svg'));
		iconRegistry.addSvgIcon('home',sanitizer.bypassSecurityTrustResourceUrl('assets/mat-icons/home.svg'));
		iconRegistry.addSvgIcon('analysis',sanitizer.bypassSecurityTrustResourceUrl('assets/mat-icons/analysis.svg'));
		iconRegistry.addSvgIcon('settings',sanitizer.bypassSecurityTrustResourceUrl('assets/mat-icons/settings.svg'));
		iconRegistry.addSvgIcon('checked-blue',sanitizer.bypassSecurityTrustResourceUrl('assets/mat-icons/checked-blue.svg'));
		iconRegistry.addSvgIcon('warn-red',sanitizer.bypassSecurityTrustResourceUrl('assets/mat-icons/warn-red.svg'));
		iconRegistry.addSvgIcon('edit',sanitizer.bypassSecurityTrustResourceUrl('assets/mat-icons/edit.svg'));
		iconRegistry.addSvgIcon('edit-accent',sanitizer.bypassSecurityTrustResourceUrl('assets/mat-icons/edit-accent.svg'));
		iconRegistry.addSvgIcon('empty-data',sanitizer.bypassSecurityTrustResourceUrl('assets/mat-icons/empty-data.svg'));
		iconRegistry.addSvgIcon('trash',sanitizer.bypassSecurityTrustResourceUrl('assets/mat-icons/trash.svg'));
		iconRegistry.addSvgIcon('back-primary',sanitizer.bypassSecurityTrustResourceUrl('assets/mat-icons/back-primary.svg'));
		iconRegistry.addSvgIcon('file-primary',sanitizer.bypassSecurityTrustResourceUrl('assets/mat-icons/file-primary.svg'));
		iconRegistry.addSvgIcon('file',sanitizer.bypassSecurityTrustResourceUrl('assets/mat-icons/file.svg'));
		iconRegistry.addSvgIcon('arrow-down-accent',sanitizer.bypassSecurityTrustResourceUrl('assets/mat-icons/arrow-down-accent.svg'));
		iconRegistry.addSvgIcon('user-preference-accent',sanitizer.bypassSecurityTrustResourceUrl('assets/mat-icons/user-preference-accent.svg'));
		iconRegistry.addSvgIcon('user-preference-primary',sanitizer.bypassSecurityTrustResourceUrl('assets/mat-icons/user-preference-primary.svg'));
		iconRegistry.addSvgIcon('chat-white',sanitizer.bypassSecurityTrustResourceUrl('assets/mat-icons/chat-white.svg'));
		iconRegistry.addSvgIcon('download-white',sanitizer.bypassSecurityTrustResourceUrl('assets/mat-icons/download-white.svg'));
		iconRegistry.addSvgIcon('arrow-left-white',sanitizer.bypassSecurityTrustResourceUrl('assets/mat-icons/arrow-left-white.svg'));
		iconRegistry.addSvgIcon('arrow-right-white',sanitizer.bypassSecurityTrustResourceUrl('assets/mat-icons/arrow-right-white.svg'));
		iconRegistry.addSvgIcon('checked-white',sanitizer.bypassSecurityTrustResourceUrl('assets/mat-icons/checked-white.svg'));
		iconRegistry.addSvgIcon('csv',sanitizer.bypassSecurityTrustResourceUrl('assets/mat-icons/csv.svg'));
		iconRegistry.addSvgIcon('pdf',sanitizer.bypassSecurityTrustResourceUrl('assets/mat-icons/pdf.svg'));
		iconRegistry.addSvgIcon('mail',sanitizer.bypassSecurityTrustResourceUrl('assets/mat-icons/mail.svg'));
		iconRegistry.addSvgIcon('reset-successful',sanitizer.bypassSecurityTrustResourceUrl('assets/mat-icons/reset-successful.svg'));
		iconRegistry.addSvgIcon('refresh-white',sanitizer.bypassSecurityTrustResourceUrl('assets/mat-icons/refresh-white.svg'));
		iconRegistry.addSvgIcon('db',sanitizer.bypassSecurityTrustResourceUrl('assets/mat-icons/db.svg'));
		iconRegistry.addSvgIcon('db-accent',sanitizer.bypassSecurityTrustResourceUrl('assets/mat-icons/db-accent.svg'));
		iconRegistry.addSvgIcon('api',sanitizer.bypassSecurityTrustResourceUrl('assets/mat-icons/api.svg'));
		iconRegistry.addSvgIcon('psd',sanitizer.bypassSecurityTrustResourceUrl('assets/mat-icons/psd.svg'));
		iconRegistry.addSvgIcon('shift',sanitizer.bypassSecurityTrustResourceUrl('assets/mat-icons/shift.svg'));
		iconRegistry.addSvgIcon('area',sanitizer.bypassSecurityTrustResourceUrl('assets/mat-icons/area.svg'));
		iconRegistry.addSvgIcon('cube',sanitizer.bypassSecurityTrustResourceUrl('assets/mat-icons/cube.svg'));
		iconRegistry.addSvgIcon('play',sanitizer.bypassSecurityTrustResourceUrl('assets/mat-icons/play.svg'));
		iconRegistry.addSvgIcon('warn-yellow',sanitizer.bypassSecurityTrustResourceUrl('assets/mat-icons/warn-yellow.svg'));
	}

	public readonly serverReachable$=this.webClient.serverReachable$;
	public readonly type$ = this.route$.pipe(
		op.map(route => route[0]),
	);
}
