import {math} from "../math";
import {Observable,Subscriber} from "rxjs";
import {resizeObserver} from './resize-observer';

export class SizeObservable extends Observable<math.Vec2>{
	private target:Element;
	private callback:any;
	private subscribers:Subscriber<math.Vec2>[]=[];
	private size:math.Vec2;
	private resizeOnly:boolean;

	public readonly completeThis=true;

	public constructor(options:{
		resizeOnly?:boolean
	}={}){
		super(subscriber=>{
			this.subscribers.push(subscriber);
			if(!this.resizeOnly && this.size)
				subscriber.next(this.size);
		});
		if(options.resizeOnly)
			this.resizeOnly=true;
	}

	private nextSize(size:math.Vec2){
		if(!this.size?.equals(size)){
			this.size=size;
			for(const sub of this.subscribers)
				sub.next(size);
		}
	}

	public observe(target:Element){
		this.target=target;
		if(!this.resizeOnly){
			this.nextSize(new math.Vec2(
				target.clientWidth,
				target.clientHeight
			));
		}
		this.callback=resizeObserver().add(target,e=>{
			this.nextSize(new math.Vec2(
				e.contentRect.width,
				e.contentRect.height,
			));
		});
	}

	public complete(){
		resizeObserver().delete(this.target,this.callback);
		delete this.target;
		delete this.callback;
		for(const sub of this.subscribers)
			sub.complete();
		this.subscribers=[];
		delete this.size;
	}
}
