<div class="x-axis" #xAxis>
	<div class="labels">
		<div
			*ngFor="let label of (xAxis$|async).labels"
			class="label"
		>
			<div class="text">{{label.value}}</div>
		</div>
	</div>
	<div
		*ngIf="(xAxis$|async).name"
		class="title"
	>
		<div>
			{{(xAxis$|async).name}}
		</div>
	</div>
</div>

<div class="y-axis" #yAxis>
	<div
		*ngIf="(yAxis$|async).name"
		class="title"
	>
		<div>
			{{(yAxis$|async).name}}
		</div>
	</div>
	<div class="labels">
		<div
			*ngFor="let label of (yAxis$|async).labels"
			class="label"
			[style.height]="label.positionLength*100+'%'"
		>
			<div class="text">{{label.value}}</div>
		</div>
	</div>
	<svg
		class="lines"
		viewBox="0 0 1 1"
		preserveAspectRatio="none"
	>
		<g>
			<line
				*ngFor="let label of (yAxis$|async).labels"
				[attr.y1]="label.position"
				x1="0"
				[attr.y2]="label.position"
				x2="1"
				stroke-width="1"
				vector-effect="non-scaling-stroke"
			>
			</line>
		</g>
	</svg>
</div>

<div
	class="plot"
	(click)="onPlotClick($event)"
	(mouseover)="onPlotMouseOver($event)"
>
	<div class="guide-lines">
		<div
			*ngFor="let line of (yAxis$|async).labels"
			class="line"
			[style.height]="line.positionLength*100+'%'"
		></div>
	</div>

	<div
		class="bar-sets"
		[style.height]="(yAxis$|async).plotLength*100+'%'"
	>
		<ng-container
			*ngFor="let barSet of (plot$|async).barSets;let index=index"
		>
			<div class="spacer"></div>
			<div class="bar-set">
				<div
					*ngFor="let bar of barSet.bars"
					class="bar"
					[style.height]="bar.height*100+'%'"
					[style.backgroundColor]="bar.fillColor"
					[style.borderWidth]="bar.width+'px'"
					[style.bottom]="bar.bottom*100+'%'"
				>
				</div>
			</div>
		</ng-container>
	</div>
</div>

<div
	*ngIf="legend && (legend$|async)"
	class="legend"
>
	<div
		*ngFor="let item of (legend$|async)"
		class="item"
	>
		<div class="text">{{item.name}}</div>
		<div class="color" [style.color]="item.color"></div>
	</div>
</div>
