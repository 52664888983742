<div class="table-container" [ngClass]="{'flex-column': direction==='vertical'}">
    <mat-card *ngIf="showDxxTable" [ngClass]="{'horizontal':direction==='horizontal','vertical':direction==='vertical','small':size==='small','normal':size==='normal'}" class="table start">
        <div class="table-title">D Values</div>
        <mat-table [dataSource]="dxxDataTable" [ngClass]="{'small-height': height === 'small', 'normal-height': height === 'normal'}">
            <ng-container matColumnDef="percentPassing">
                <mat-header-cell *matHeaderCellDef> Percent Passing </mat-header-cell>
                <mat-cell *matCellDef="let row"> {{ row.percentPassing }} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="value">
                <mat-header-cell *matHeaderCellDef> Value ({{particleSizeUnit$|async}}) </mat-header-cell>
                <mat-cell *matCellDef="let row"> {{ row.value }} </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="['percentPassing', 'value']"></mat-header-row>
            <mat-row [ngClass]="{'row-small': size==='small'}" *matRowDef="let row; columns: ['percentPassing', 'value']"></mat-row>
        </mat-table>
    </mat-card>
    <mat-card [ngClass]="{'horizontal':direction==='horizontal','vertical':direction==='vertical','small':size==='small','normal':size==='normal'}" class="table">
        <div class="table-title">Particle Size Distribution</div>
        <mat-table [dataSource]="primarySieveSizeDataTable" [ngClass]="{'small-height': height === 'small', 'normal-height': height === 'normal'}">
            <ng-container matColumnDef="targetSize">
                <mat-header-cell *matHeaderCellDef> Target Size ({{particleSizeUnit$|async}}) </mat-header-cell>
                <mat-cell *matCellDef="let row"> {{ row.targetSize }} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="targetRange">
                <mat-header-cell *matHeaderCellDef> Target Range (%) </mat-header-cell>
                <mat-cell *matCellDef="let row"> {{ row.targetRange }} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="cumulative">
                <mat-header-cell *matHeaderCellDef> Cumulative (%) </mat-header-cell>
                <mat-cell *matCellDef="let row"> {{ row.cumulative }} </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="['targetSize', 'targetRange', 'cumulative']"></mat-header-row>
            <mat-row [ngClass]="{'row-small': size==='small'}" *matRowDef="let row; columns: ['targetSize', 'targetRange', 'cumulative']"></mat-row>
        </mat-table>
    </mat-card>
    <div *ngFor="let table of secondarySieveSizeDataTables" >
        <ng-template #cardTemplate>
            <mat-card class="vertical small table">
                <div class="table-title">Particle Size Distribution</div>
                <mat-table [dataSource]="table">
                    <ng-container matColumnDef="targetSize">
                        <mat-header-cell *matHeaderCellDef> Target Size ({{particleSizeUnit$|async}}) </mat-header-cell>
                        <mat-cell *matCellDef="let row"> {{ row.targetSize }} </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="targetRange">
                        <mat-header-cell *matHeaderCellDef> Target Range (%) </mat-header-cell>
                        <mat-cell *matCellDef="let row"> {{ row.targetRange }} </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="cumulative">
                        <mat-header-cell *matHeaderCellDef> Cumulative (%) </mat-header-cell>
                        <mat-cell *matCellDef="let row"> {{ row.cumulative }} </mat-cell>
                    </ng-container>
                    <mat-header-row *matHeaderRowDef="['targetSize', 'targetRange', 'cumulative']"></mat-header-row>
                    <mat-row class="row-small"  *matRowDef="let row; columns: ['targetSize', 'targetRange', 'cumulative']"></mat-row>
                </mat-table>
            </mat-card>
        </ng-template>
    </div>
</div>