
export function islands<T>(
	items:Iterable<T>,
	iterateConnections:(item:T)=>Iterable<T>,
){
	const open=new Set<T>(items);
	const islands:Set<T>[]=[];

	for(let cur of open){
		open.delete(cur);
		const island=new Set<T>();
		island.add(cur);
		let working=new Set<T>();
		working.add(cur);

		while(working.size>0){
			const next=new Set<T>();
			for(const a of working){
				for(const b of iterateConnections(a)){
					if(open.has(b)){
						open.delete(b);
						island.add(b);
						next.add(b);
					}
				} 
			}
			working=next;
		}
		islands.push(island);
	}
	return islands;
}
