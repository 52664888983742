<div mat-dialog-content>
	<div class="flex-column">
		<mat-form-field>
			<mat-label>Client</mat-label>
			<input
				type="text"
				matInput
				[matAutocomplete]="auto"
				[disabled]="(clients$|async).length===0"
				[value]="(initialClient$|async)?.name"
			/>
			<mat-autocomplete
				#auto="matAutocomplete"
				[autoActiveFirstOption]="true"
				[autoSelectActiveOption]="true"
				[displayWith]="clientDisplayWith"
				(optionActivated)="client=$event?.option?.value"
				(optionSelected)="client=$event?.option?.value"
			>
				<mat-option
					*ngFor="let client of (clients$|async)"
					[value]="client"
				>
					{{client.name}}
				</mat-option>
			</mat-autocomplete>			
		</mat-form-field>
		<mat-form-field>
			<mat-label>Email</mat-label>
			<input matInput type="text" [(ngModel)]="email"/>
		</mat-form-field>
		<mat-form-field>
			<mat-label>Permissions</mat-label>
			<mat-select [(ngModel)]="permissions">
				<mat-option [value]="0">
					Standard
				</mat-option>
				<mat-option *ngIf="user.permissions>=1"  [value]="1">
					Client Admin
				</mat-option>
				<mat-option *ngIf="user.permissions>=2" [value]="2">
					Super Admin
				</mat-option>
			</mat-select>
		</mat-form-field>
		<mat-form-field>
			<mat-label>First Name</mat-label>
			<input matInput type="text" [(ngModel)]="firstName"/>
		</mat-form-field>
		<mat-form-field>
			<mat-label>Last Name</mat-label>
			<input matInput type="text" [(ngModel)]="lastName"/>
		</mat-form-field>
		<mat-form-field>
			<mat-label>Password</mat-label>
			<input matInput type="text" [(ngModel)]="password"/>
		</mat-form-field>
	</div>
</div>
<div mat-dialog-actions>
	<button mat-button (click)="cancel()">Cancel</button>
	<button mat-button [disabled]="!valid()" (click)="save()">Save</button>
</div>
