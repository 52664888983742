import { Stream } from 'stream'
import { dynamicScriptLoad } from "./dynamic-script-load";
export interface BlobStream extends Stream{
	length:number;
	writable:boolean;

	toBlob(contentType:string):Blob;
}

export var blobStream:{():BlobStream};

export async function load(){
	const src='/assets/blob-stream.js';
	await dynamicScriptLoad(src);
	blobStream=(<any>window)['blobStream'];
}
