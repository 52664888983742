import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BehaviorSubject, Subject, takeUntil, tap } from 'rxjs';

@Component({
    selector: 'app-dialog-progress',
    templateUrl: './dialog-progress.component.html',
    styleUrls: ['./dialog-progress.component.scss'],
})
export class DialogProgressComponent {
    public value: number = 0;
    public message: string = '';
    private destroy$ = new Subject<void>();

    constructor(
        @Inject(MAT_DIALOG_DATA)
        public data: {
            progress$: BehaviorSubject<number>;
            message$: BehaviorSubject<string>;
        }
    ) { }

    ngOnInit() {
        if (this.data.progress$ instanceof BehaviorSubject) {
            this.data.progress$.pipe(takeUntil(this.destroy$)).subscribe((value) => {
                this.value = value;
            });
            this.data.message$.pipe(takeUntil(this.destroy$)).subscribe((value) => {
                this.message = value;
            });
        } else {
            console.error('Invalid data type provided to MAT_DIALOG_DATA');
        }
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }
}