import {math} from "@lib/math";

export interface ElementMeta{
	styles:CSSStyleDeclaration&{zoom?:string};
	zIndex:number,
	originalTransform?:string;
	rect?:DOMRect;
}

export interface PageInfo{
	element:HTMLElement;
	rect:DOMRect;
	size:string;
	layout:'landscape'|'portrait';
	dimensions:math.Vec2; //dimensions in printerPoints
	ptsPerPx:number;// printerPoints/px
	meta:Map<HTMLElement|SVGSVGElement,ElementMeta>;
	canvasList:Map<string,Array<HTMLCanvasElement>>;
}

export const sides=[
	'top',
	'right',
	'bottom',
	'left',
];

export interface Sides{
	top:number;
	right:number;
	bottom:number;
	left:number;
};

export function fromColor(string:string){
	if(string==='none')
		return {rgb: (<[number,number,number]>[0,0,0]), opacity: 0};
	else if(string.startsWith('rgb('))
		string=string.substr(4,string.length-5);
	else if(string.startsWith('rgba('))
		string=string.substr(5,string.length-6);
	else
		return {rgb: (<[number,number,number]>[0,0,0]), opacity: 0};
	let color=string.split(',').map(v=>+v.trim());
	let opacity=1;
	if(color.length===4){
		opacity=color[3];
		color.length=3;
	}
	return {rgb:<[number,number,number]>color,opacity};
}

export function fromPx(string:string){
	if(string.endsWith('px'))
		return +string.substr(0,string.length-2);
	if(string.endsWith('px,'))
		return +string.substr(0,string.length-3);
	if(string==='')
		return 0;
	console.warn('returning 0',typeof(string),string);
	return 0;
}

export function fromPxOrPercent(string:string, total:number){
	if(string.endsWith('%'))
		return total*(+string.substr(0,string.length-1));
	if(string.endsWith('px'))
		return +string.substr(0,string.length-2);
	if(string.endsWith('px,'))
		return +string.substr(0,string.length-3);
	if(string==='')
		return 0;
	console.warn('returning 0',typeof(string),string);
	return 0;
}

export function getDOMRectStr(rect:DOMRect) {
	return JSON.stringify({
		x: rect?.x,
		y: rect?.y,
	});
}
