import {DateTime} from "../../date-time";

export function toNum(v:number|DateTime){
	if(v instanceof DateTime)
		return v.getTime();
	return v;
}

export function toDateTime(v:number|DateTime){
	if(v instanceof DateTime)
		return v;
	return new DateTime(v);
}

// export function timeToPlotNum(v:Date){
// 	return v.getTime()/(60*1000);
// }

// export function plotNumToTime(v:number){
// 	return new DateTime((v)*(60*1000));
// }
