import { Component } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'password-recovery',
  templateUrl: './password-recovery.component.html',
})
export class PasswordRecoveryComponent {
  constructor(
		private readonly router:Router,
	){}

  emailSent:boolean=false;
  sendMail(){
    this.emailSent=true;
  }
  newPassword(){
    this.router.navigateByUrl('/new-password')
  }
}
