import { _keysEqual } from "./is-key-diff";

Map.prototype.array=function(){
	return Array.from(this.values());
}

Map.prototype.deleteIf=function(condition){
	for(const [k,v] of this){
		if(condition(v,k))
			this.delete(k);
	}
}

Map.prototype.equals=function(that){
	if(this===that)
		return true;

	if(this.size!=that.size)
		return false;

	for(const [k,v] of this){
		if(v!==that.get(k))
			return false;
	}
	return true;
}

Map.prototype.filter=function(passes:(v:any,k:any)=>boolean){
	let filtered=new Map<any,any>();
	this.forEach((v,k)=>{
		if(passes(v,k))
			filtered.set(k,v);
	});
	return filtered;
}

Map.prototype.getOrAdd=function(k, valueMaker){
	let v=this.get(k);
	if(v!==undefined)
		return v;
	v=valueMaker();
	this.set(k,v);
	return v;
}

Map.prototype.keysEqual=_keysEqual;

function keyDiff(this:Map<any,any>, that:Map<any,any>):{added:Map<any,any>,deleted:Map<any,any>};
function keyDiff(this:Map<any,any>, that:Set<any>):{added:Set<any>,deleted:Map<any,any>};
function keyDiff(this:Map<any,any>, that:Map<any,any>|Set<any>){
	if(that instanceof Set){
		const added=new Set<any>();
		for(const k of that){
			if(!this.has(k)){
				added.add(k);
			}
		}
		const deleted=new Map<any,any>();
		for(const [k,v] of this){
			if(!that.has(k)){
				deleted.set(k,v);
			}
		}
		return {added,deleted};
	}
	const added=new Map<any,any>();
	for(const [k,v] of that){
		if(!this.has(k)){
			added.set(k,v);
		}
	}
	const deleted=new Map<any,any>();
	for(const [k,v] of this){
		if(!that.has(k)){
			deleted.set(k,v);
		}
	}
	return {added,deleted};
}

Map.prototype.keyDiff=keyDiff;

Map.prototype.map=function(change){
	const that=new Map<any,any>();
	for(const [k,v] of this)
		that.set(k,change(v,k));
	return that;
}
