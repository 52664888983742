<div class="input-wrapper">
	<input
		type="text"
		class="mat-mdc-input-element mat-mdc-form-field-input-control mdc-text-field__input"
		[disabled]="true"
		[(ngModel)]="valueStr"
	/>
	<div class="input-sizer">
		{{valueStr}}
	</div>
</div>
<button mat-icon-button class="toggle" (click)="open()">
	<mat-icon>arrow_drop_down</mat-icon>
</button>
<ng-template #popup>
	<div class="popup">
		<div class="flex-row gap-medium">
			<div *ngIf="hourStep<24" class="time-component hour">
				<button mat-icon-button [disabled]="!value" (click)="stepHour(+1)"><mat-icon>expand_less</mat-icon></button>
				<input type="text" [disabled]="!value" [(ngModel)]="hour"/>
				<button mat-icon-button [disabled]="!value" (click)="stepHour(-1)"><mat-icon>expand_more</mat-icon></button>
			</div>
			<div *ngIf="minuteStep<60" class="time-component minute">
				<button mat-icon-button [disabled]="!value" (click)="stepMinute(+1)"><mat-icon>expand_less</mat-icon></button>
				<input type="text" [disabled]="!value" [(ngModel)]="minute"/>
				<button mat-icon-button [disabled]="!value" (click)="stepMinute(-1)"><mat-icon>expand_more</mat-icon></button>
			</div>
			<div *ngIf="secondStep<60" class="time-component second">
				<button mat-icon-button [disabled]="!value" (click)="stepSecond(+1)"><mat-icon>expand_less</mat-icon></button>
				<input type="text" [disabled]="!value" [(ngModel)]="second"/>
				<button mat-icon-button [disabled]="!value" (click)="stepSecond(-1)"><mat-icon>expand_more</mat-icon></button>
			</div>
			<div *ngIf="millisecondStep<1000" class="time-component millisecondStep">
				<button mat-icon-button [disabled]="!value" (click)="stepMillisecond(+1)"><mat-icon>expand_less</mat-icon></button>
				<input type="text" [disabled]="!value" [(ngModel)]="millisecond"/>
				<button mat-icon-button [disabled]="!value" (click)="stepMillisecond(-1)"><mat-icon>expand_more</mat-icon></button>
			</div>
		</div>
		<div class="flex-row end align-center">
			<button mat-button color="warn" (click)="Close(false)">Cancel</button>
			<button mat-button color="primary" (click)="Close(true)">OK</button>
		</div>
	</div>
</ng-template>