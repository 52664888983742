<model-table
	[model]="model"
	[restClient]="restClient"
	[editDialog]="editDialog"
	[deletable]="deletable"
>
	<ng-template model-table-header>
		<th>
			Name
		</th>
		<th>
			Enabled
		</th>
	</ng-template>

	<ng-template
		model-table-row
		[type]="model"
		let-record="record"
	>
		<td>
			{{record.name}}
		</td>
		<td>
			{{record.enabled}}
		</td>
	</ng-template>
</model-table>
