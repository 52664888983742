import { environment } from '@environment';

class StorageLocal{
	private readonly keyPrefix=environment.appName+'-';

	public get<T=any>(key:string):T{
		key=this.keyPrefix+key;
		let value:any=localStorage.getItem(key);
		if(value===null)
			return null;
		value=JSON.parse(value);
		return value;
	}

	public set(key:string,value:any){
		key=this.keyPrefix+key;
		value=JSON.stringify(value);
		localStorage.setItem(key,value);
	}

	public delete(key:string){
		key=this.keyPrefix+key;
		localStorage.removeItem(key);
	}

	public clear(){
		for(let i=0;i<localStorage.length;){
			const key=localStorage.key(i);
			if(key.startsWith(this.keyPrefix))
				localStorage.removeItem(key);
			else
				++i;
		}
	}
}

export const storageLocal=new StorageLocal();
