import { Unit } from "./units";

export type Type =
	'distance'
	|'smallDistance'
	|'particleSize'
	|'elevation' //in this library just a synonym for distance, but other places can use it to tag different behavior
	|'area'
	|'largeArea'
	|'volume'
	|'angle'
	|'count'
	|'percent'
	|'lonLat'
	|'density'
	|'smallDensity'
	|'largeDensity'
	|'pressure'
	|'specificEnergy'
	|'time'
	|'smallTime'
	|'rockPressure'
	|'weight'
	|'largeWeight'
	|'weightPerDistance'
	|'velocity'
	|'smallVelocity'
	|'largeVelocity'
	|'timePerDistance'
	|'smallTimePerDistance'
	|'sound'
	|'largeSound'
	|'vibrationScaledDistance'
	|'airblastScaledDistance'
	|'temperature'
	|'frequency'
	|'smallDistancePerPixel';

export type UnitSystem = keyof ExtendedTypeMeta;

export type TypeToUnit={[t in Type]:Unit};

interface TypeMeta{
	valueUnit:Unit;
	metricUnit:Unit;
	imperialUnit:Unit;
};

export interface ExtendedTypeMeta extends TypeMeta{
	valueUnit:Unit;
	smallMetricUnit:Unit;
	mediumMetricUnit:Unit;
	largeMetricUnit:Unit;
	smallImperialUnit:Unit;
	mediumImperialUnit:Unit;
	largeImperialUnit:Unit;
}

/*
	This table must be updated for each MeasurementType added
	valueUnit is the type unit the data is represented internally. This should always be its metric system base unit. For example 'distance', 'smallDistance', 'largeDistance' would all be valueUnit: meters
	metricUnit is the default unit for this type in the metric system
	imperialUnit is the default unit for this type in the imperial system
*/
export const typeMeta:{[type in Type]: TypeMeta | ExtendedTypeMeta }={
	angle: {
		valueUnit: 'radians',
		metricUnit: 'degrees',
		imperialUnit: 'degrees',
	},
	count: {
		valueUnit: 'none',
		metricUnit: 'none',
		imperialUnit: 'none',
	},
	percent: {
		valueUnit: 'none',
		metricUnit: 'percent',
		imperialUnit: 'percent',
	},
	sound: {
		valueUnit: 'deciBel',
		metricUnit: 'deciBel',
		imperialUnit: 'deciBel',
	},
	largeSound: {
		valueUnit: 'deciBel',
		metricUnit: 'megaDeciBel',
		imperialUnit: 'megaDeciBel',
	},
	time: {
		valueUnit: 'second',
		metricUnit: 'second',
		imperialUnit: 'second',
	},
	smallTime: {
		valueUnit: 'second',
		metricUnit: 'millisecond',
		imperialUnit: 'millisecond',
	},
	lonLat: {
		valueUnit: 'lonLatDegree',
		metricUnit: 'lonLatDegree',
		imperialUnit: 'lonLatDegree',
	},
	distance: {
		valueUnit: 'meter',
		metricUnit: 'meter',
		imperialUnit: 'feet',
	},
	elevation: {
		valueUnit: 'meter',
		metricUnit: 'meter',
		imperialUnit: 'feet',
	},
	smallDistance: {
		valueUnit: 'meter',
		metricUnit: 'millimeter',
		imperialUnit: 'inch',
	},
	particleSize: {
		valueUnit: 'meter',
		metricUnit: 'millimeter',
		smallMetricUnit:'millimeter',
		mediumMetricUnit:'centimeter',
		largeMetricUnit:'meter',
		imperialUnit: 'inch',
	},
	area: {
		valueUnit: 'meter2',
		metricUnit: 'meter2',
		imperialUnit: 'yard2',
	},
	largeArea: {
		valueUnit: 'meter2',
		metricUnit: 'kilometer2',
		imperialUnit: 'acre',
	},
	volume: {
		valueUnit: 'meter3',
		metricUnit: 'meter3',
		imperialUnit: 'yard3',
	},
	density: {
		valueUnit: 'kilogramPerMeter3',
		metricUnit: 'kilogramPerMeter3',
		imperialUnit: 'poundsPerYard3',
	},
	smallDensity: {
		valueUnit: 'kilogramPerMeter3',
		metricUnit: 'gramPerCentimeter3',
		imperialUnit: 'gramPerCentimeter3',//???
	},
	largeDensity: {
		valueUnit: 'kilogramPerMeter3',
		metricUnit: 'metrictonPerMeter3',
		imperialUnit: 'shorttonPerYard3',
	},
	pressure: {
		valueUnit: 'bar',
		metricUnit: 'bar',
		imperialUnit: 'poundsPerInch2',
	},
	rockPressure: {
		valueUnit: 'megapascal',
		metricUnit: 'megapascal',
		imperialUnit: 'poundsPerInch2',
	},
	specificEnergy: {
		valueUnit: 'megaJoulePerKilogram',
		metricUnit: 'megaJoulePerKilogram',
		imperialUnit: 'kilocaloriePerPound',
	},
	weight: {
		valueUnit: 'kilogram',
		metricUnit: 'kilogram',
		imperialUnit: 'pounds',
	},
	largeWeight: {
		valueUnit: 'kilogram',
		metricUnit: 'metricton',
		imperialUnit: 'shortton',
	},
	weightPerDistance: {
		valueUnit: 'kilogramPerMeter',
		metricUnit: 'kilogramPerMeter',
		imperialUnit: 'poundsPerYard',
	},
	velocity: {
		valueUnit: 'meterPerSecond',
		metricUnit: 'meterPerSecond',
		imperialUnit: 'feetPerSecond',
	},
	smallVelocity: {
		valueUnit: 'meterPerSecond',
		metricUnit: 'millimeterPerSecond',
		imperialUnit: 'inchPerSecond',
	},
	largeVelocity: {
		valueUnit: 'meterPerSecond',
		metricUnit: 'kilometerPerHour',
		imperialUnit: 'milePerHour',
	},
	timePerDistance: {
		valueUnit: 'secondPerMeter',
		metricUnit: 'secondPerMeter',
		imperialUnit: 'secondPerFeet',
	},
	smallTimePerDistance: {
		valueUnit: 'secondPerMeter',
		metricUnit: 'millisecondPerMeter',
		imperialUnit: 'millisecondPerFeet',
	},
	vibrationScaledDistance: {
		valueUnit: 'meterPerSquareRootKg',
		metricUnit: 'meterPerSquareRootKg',
		imperialUnit: 'feetPerSquareRootPound',
	},
	airblastScaledDistance: {
		valueUnit: 'meterPerCubeRootKg',
		metricUnit: 'meterPerCubeRootKg',
		imperialUnit: 'feetPerCubeRootPound',
	},
	temperature: {
		valueUnit: 'kelvin',
		metricUnit: 'celsius',
		imperialUnit: 'fahrenheit',
	},
	frequency: {
		valueUnit: 'hertz',
		metricUnit: 'hertz',
		imperialUnit: 'hertz',
	},
	smallDistancePerPixel: {
		valueUnit: 'meterPerPixel',
		metricUnit: 'milliMeterPerPixel',
		imperialUnit: 'inchPerPixel',
	},
};