import { math } from '@lib/math';
import * as pdfkit from './pdf';
import { renderPageCanvas } from './render-canvas';
import { renderImage } from './render-image';
import { renderSVG } from './render-svg';
import { renderText } from './render-text';
import { fromColor,fromPx,fromPxOrPercent,PageInfo } from './shared';

export function renderElement(
	doc:pdfkit.PDFDocument,
	page:PageInfo,
	containerRect:DOMRect|null,
	ele:HTMLElement|SVGSVGElement,
	depth:number
):boolean{
	const meta=page.meta.get(ele);
	if(!meta)
		return false;

	const {styles,rect: borderRect}=meta;
	if(
		containerRect
		&& (
			borderRect.bottom<containerRect.top
			|| borderRect.left>containerRect.right
			|| borderRect.top>containerRect.bottom
			|| borderRect.right<containerRect.left
		)
	){
		return false;
	}

	doc.save();

	const matches=styles.transform.match(/^matrix\(([^\(]*)\)$/);
	if(matches){
		const matrix=matches[1].split(',').map(v=>+v);
		const transformOrigin=styles.transformOrigin.split(' ').map(fromPx);
		transformOrigin[0]+=borderRect.x;
		transformOrigin[1]+=borderRect.y;
		doc.transform(
			matrix[0],
			matrix[1],
			matrix[2],
			matrix[3],
			matrix[4]-(matrix[0]*transformOrigin[0]+matrix[2]*transformOrigin[1])+transformOrigin[0],
			matrix[5]-(matrix[1]*transformOrigin[0]+matrix[3]*transformOrigin[1])+transformOrigin[1],
		);
	}

	containerRect=containerRect || borderRect;

	const borders=calcBorderWidth(ele,styles);
	const clientRect=new DOMRect(borderRect.x+borders[3],borderRect.y+borders[0],borderRect.width-borders[1]-borders[3],borderRect.height-borders[0]-borders[2]);

	const borderRadii=[
		fromPxOrPercent(styles.borderTopLeftRadius,borderRect.width),
		fromPxOrPercent(styles.borderTopRightRadius,borderRect.width),
		fromPxOrPercent(styles.borderTopRightRadius,borderRect.height),
		fromPxOrPercent(styles.borderBottomRightRadius,borderRect.height),
		fromPxOrPercent(styles.borderBottomRightRadius,borderRect.width),
		fromPxOrPercent(styles.borderBottomLeftRadius,borderRect.width),
		fromPxOrPercent(styles.borderBottomLeftRadius,borderRect.height),
		fromPxOrPercent(styles.borderTopLeftRadius,borderRect.height),
	];
	const borderPoints=roundedRectPoints(borderRect,borderRadii);
	borderRadii[0]=math.max(0,borderRadii[0]-(clientRect.top-borderRect.top));
	borderRadii[1]=math.max(0,borderRadii[1]-(borderRect.right-clientRect.right));
	borderRadii[2]=math.max(0,borderRadii[2]-(clientRect.top-borderRect.top));
	borderRadii[3]=math.max(0,borderRadii[3]-(borderRect.bottom-clientRect.bottom));
	const clientPoints=roundedRectPoints(clientRect,borderRadii);

	const padding={
		top: fromPx(styles.paddingTop),
		right: fromPx(styles.paddingRight),
		bottom: fromPx(styles.paddingBottom),
		left: fromPx(styles.paddingLeft),
	};
	const paddingRect=new DOMRect(
		clientRect.x+padding.left,
		clientRect.y+padding.top,
		clientRect.width-padding.left-padding.right,
		clientRect.height-padding.top-padding.bottom,
	);

	if(displayRenderable(styles.display)){
		renderBox(doc,ele,styles,borderPoints,clientPoints);
		if(ele instanceof HTMLElement)
			renderText(doc,page,ele,styles,paddingRect);
	}

	if(ele instanceof SVGSVGElement){
		renderSVG(doc,page,ele,styles);
	}else if(ele instanceof HTMLCanvasElement){
		renderPageCanvas(page,doc,ele,paddingRect);
	}else if(ele instanceof HTMLImageElement){
		if(!renderImage(doc,ele,clientRect)){
			return false;
		};
	}else{
		if(styles.overflow==='hidden'){
			const points=clientPoints;
			doc.moveTo(points[0].x,points[0].y);
			for(let i=1;i<points.length;++i)
				doc.lineTo(points[i].x,points[i].y);
			doc.closePath();
			doc.fillOpacity(1);
			doc.clip();
			containerRect=clientRect;
		}
		for(let i=0;i<ele.children.length;++i){
			const child=ele.children.item(i);
			if(child instanceof HTMLElement || child instanceof SVGSVGElement){
				renderElement(doc,page,containerRect,child,depth+1);
			}
		}
	}

	doc.restore();
	return true;
}

function calcBorderWidth(
	ele:HTMLElement|HTMLTableCellElement|SVGSVGElement,
	styles:CSSStyleDeclaration
){
	const borderWidth=[
		fromPx(styles.borderTopWidth),
		fromPx(styles.borderRightWidth),
		fromPx(styles.borderBottomWidth),
		fromPx(styles.borderLeftWidth),
	];

	if(ele instanceof HTMLTableCellElement && styles.borderCollapse==='collapse'){
		const cell=ele;
		if(cell.nextElementSibling instanceof HTMLTableCellElement){
			const cellNext=cell.nextElementSibling;
			if(getComputedStyle(cellNext).borderLeftWidth!=='0px')
				borderWidth[1]=0;
		}

		let tr=<HTMLTableRowElement>cell.parentElement;
		if(tr instanceof HTMLTableRowElement){
			if(tr.nextElementSibling instanceof HTMLTableRowElement){
				tr=tr.nextElementSibling;
				const cellBelow=tr.cells.item(cell.cellIndex);
				if(cellBelow && styles.borderTopWidth!=='0px')
					borderWidth[2]=0;
			}else{
				let tbody=tr.parentElement;
				if((tbody.tagName==='THEAD' || tbody.tagName==='TBODY') && (tbody.nextElementSibling?.tagName==='THEAD' || tbody.nextElementSibling?.tagName==='TBODY'))
					borderWidth[2]=0;
			}
		}
	}
	return borderWidth;
}

function displayRenderable(display:string){
	return display==='block'
	|| display==='inline-block'
	|| display==='flex'
	|| display==='grid'
	|| display==='table'
	|| display==='table-column-group'
	|| display==='table-header-group'
	|| display==='table-footer-group'
	|| display==='table-row'
	|| display==='table-column'
	|| display==='table-cell';
}

function roundedRectPoints(rect:DOMRect, _radii:number[]){
	const corners=[
		new math.Vec2(rect.left,rect.top),
		new math.Vec2(rect.right,rect.top),
		new math.Vec2(rect.right,rect.bottom),
		new math.Vec2(rect.left,rect.bottom),
	];

	const radii:number[]=[];
	for(let i=0;i<4;++i){
		const ra=_radii[i*2];
		const rb=_radii[i*2+1];
		const sideRadii=ra+rb;
		const sideLength=Math.max(0,i%2===0?rect.width:rect.height);
		if(sideRadii>sideLength){
			radii.push(ra*sideLength/sideRadii);
			radii.push(rb*sideLength/sideRadii);
		}else{
			radii.push(ra);
			radii.push(rb);
		}
	}

	const points:math.Vec2[]=[];

	for(let i=0;i<4;++i){
		const a=corners[i];
		const b=corners[(i+1)%4];
		const v=b.clone().sub(a).normalize();
		const u=new math.Vec2(-v.y,v.x);
		const r=[
			radii[(i*2+7)%8],
			radii[i*2],
			radii[i*2+1],
			radii[(i*2+2)%8],
		];

		let q=a.clone();
		q.addScaledVector(v,r[1]*(1-math.SQRT1_2));
		q.addScaledVector(u,r[0]*(1-math.SQRT1_2));
		points.push(q);

		q=a.clone();
		q.addScaledVector(v,r[1]);
		points.push(q);

		q=b.clone();
		q.addScaledVector(v,-r[2]);
		points.push(q);

		q=b.clone();
		q.addScaledVector(u,r[3]*(1-math.SQRT1_2));
		q.addScaledVector(v,r[2]*-(1-math.SQRT1_2));
		points.push(q);
	}
	return points;
}

function renderBox(doc:pdfkit.PDFDocument, ele:HTMLElement|SVGSVGElement, styles:CSSStyleDeclaration, borderPoints:math.Vec2[], clientPoints:math.Vec2[]){
	const backgroundColor=fromColor(styles.backgroundColor);
	const borderColor=[
		fromColor(styles.borderTopColor),
		fromColor(styles.borderRightColor),
		fromColor(styles.borderBottomColor),
		fromColor(styles.borderLeftColor),
	];

	if(backgroundColor.opacity>0){
		const points=borderPoints;
		doc.moveTo(points[0].x,points[0].y);
		for(let i=1;i<points.length;++i)
			doc.lineTo(points[i].x,points[i].y);
		doc.closePath();
		doc.fillColor(backgroundColor.rgb);
		doc.fillOpacity(backgroundColor.opacity);
		doc.fill();
	}

	for(let i=0;i<4;++i){
		if(!borderPoints[i*4+1].equals(clientPoints[i*4+1])){
			const points=[...borderPoints.slice(i*4,i*4+4),...clientPoints.slice(i*4,i*4+4).reverse()];
			doc.moveTo(points[0].x,points[0].y);
			for(let i=1;i<points.length;++i)
				doc.lineTo(points[i].x,points[i].y);
			doc.closePath();
			doc.fillColor(borderColor[i].rgb);
			doc.fillOpacity(borderColor[i].opacity);
			doc.fill();
		}
	}
}
