<div class="snapshot-container full-height">
    <mat-card-content class="full-height">
        <div class="flex-row justify height-10">
            <div class="full-width flex-row center middle">
                <p class="font-medium"><strong>Begin: </strong>{{beginDate|date:'medium'}}</p>
            </div>
            <div class="full-width flex-row center middle">
                <p class="font-medium"><strong>End: </strong>{{endDate|date:'medium'}}</p>
            </div>
        </div>
        <div class="flex-column center height-90">
            <img onerror="this.onerror=null; this.src='../../../assets/image_not_found.jpg'" class="slide" height="100%"
                [src]="imageSrc" />
        </div>
    </mat-card-content>
</div>