<div class="title">
	<mat-icon svgIcon="strayos_blue"></mat-icon>

	<span class="report-name">{{title}}
	</span>

	<span *ngIf="includeProjectName" class="project-name">
		( {{projectName}} )
	</span>
</div>

<ng-content></ng-content>

<div *ngIf="subTitle" class="sub-title">
	<span>{{subTitle}}</span>
</div>
