import {RestTypes as _RestTypes} from "@lib/model-meta";
import {storageLocal} from "@lib/storage-local";
import {WebClient as _WebClient} from "@lib/web-client";

export class RestModel<
	Instance extends {id:number},	
	RestTypes extends _RestTypes,
> extends _WebClient.RestModel<
	Instance,
	RestTypes
>{
	public auth():
	{
		email:string;
		authToken:string;
	}{
		return storageLocal.get('auth');
	}		
}