import {DateTime} from '@lib/date-time';
import { Shift as _Shift } from '../../../shared-models/public/shift';

export class Shift extends _Shift {
    public get beginDateTime(){
		if(typeof(this.begin)==='number')
			return new DateTime(this.begin*1000);
		return null;
	}

	public set beginDateTime(v){
		if(v instanceof DateTime)
			this.begin=Math.floor(v.getTime()/1000);
		else
			this.begin=null;
	}

	public get endDateTime(){
		if(typeof(this.end)==='number')
			return new DateTime(this.end*1000);
		return null;
	}

	public set endDateTime(v){
		if(v instanceof DateTime)
			this.end=Math.floor(v.getTime()/1000);
		else
			this.end=null;
	}
}

export namespace Shift {
	export import RestTypes=_Shift.RestTypes;
}