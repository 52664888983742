//model-meta generated file
import {DateTime,ModelMeta,uuid} from '../../lib/model-meta';
import {WhereTable,RestTypes as _RestTypes} from '../../lib/model-meta';

export class RockCount{
	public id:number;
	public dataIntervalId:number;
	public localId:number;
	public diameter:number;
	public count:number;
	public totalArea:number;
	public totalVolume:number;
}

export namespace RockCount{
	export const meta=new ModelMeta<keyof Props>(
		"RockCount",
		[],
		[
			{
				name: "id",
				type: "serial",
				decorators: {},
				uniqueKey: true,
				notNull: true
			},{
				name: "dataIntervalId",
				type: "int",
				decorators: {
					index: true
				},
				index: true,
				notNull: true
			},{
				name: "localId",
				type: "int",
				decorators: {},
				notNull: true
			},{
				name: "diameter",
				type: "int",
				decorators: {},
				notNull: true
			},{
				name: "count",
				type: "int",
				decorators: {},
				notNull: true
			},{
				name: "totalArea",
				type: "number",
				decorators: {},
				notNull: true
			},{
				name: "totalVolume",
				type: "number",
				decorators: {},
				notNull: true
			}
		]
	);

	interface Props{
		id:number;
		dataIntervalId:number;
		localId:number;
		diameter:number;
		count:number;
		totalArea:number;
		totalVolume:number;
	}


	meta.generateRest("rock-count",[
		{
			name: "email",
			type: "string",
			decorators: {}
		},{
			name: "authToken",
			type: "string",
			decorators: {}
		}
	]);

	export interface ReadableProps{
		id:number;
		dataIntervalId:number;
		localId:number;
		diameter:number;
		count:number;
		totalArea:number;
		totalVolume:number;
	}

	export interface ReadablePrimitiveProps{
		id:number;
		dataIntervalId:number;
		localId:number;
		diameter:number;
		count:number;
		totalArea:number;
		totalVolume:number;
	}

	namespace body{

		interface WritableProps{
			dataIntervalId:number;
			localId:number;
			diameter:number;
			count:number;
			totalArea:number;
			totalVolume:number;
		}

		export interface Auth{
			email?:string;
			authToken?:string;
		}

		export interface Get extends Auth{
			where:number;
			fields?:(keyof ReadableProps)[];
		}

		export interface Put extends Auth{
			where:number;
			fields:Partial<WritableProps>;
		}

		export interface Post extends Auth{
			fields:Partial<WritableProps>;
		}

		export interface Delete extends Auth{
			where:number;
		}

		export interface Count extends Auth{
			where?:WhereTable<keyof ReadablePrimitiveProps>;
		}

		export interface Search extends Auth{
			fields?:(keyof ReadablePrimitiveProps)[];
			where?:WhereTable<keyof ReadablePrimitiveProps>;
			range?:[number,number];
			sort?:Partial<Record<keyof ReadablePrimitiveProps,'+'|'-'>>;
		}
	}

	namespace _response{
		export type Get=Partial<ReadableProps>;
		export type Search=Partial<ReadablePrimitiveProps>[];
	}

	export interface RestTypes extends _RestTypes{
		body:{
			Auth:body.Auth;
			Get:body.Get;
			Put:body.Put;
			Post:body.Post;
			Delete:body.Delete;
			Count:body.Count;
			Search:body.Search;
		}

		response: {
			Get:_response.Get;
			Search:_response.Search;
		}
	}

}
