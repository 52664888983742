import { PairSet } from "../pair-set";

export type PathCostFunc<T>=(path:T[], to:T)=>number;

export function paths<T>(
	items:Iterable<T>,	
	iterateConnections:(item:T)=>Iterable<T>,
	costFunc?:PathCostFunc<T>,
){
	const closed=new PairSet<T,T>();

	let nextNode=function(cur:T, path:T[]){
		for(const node of iterateConnections(cur)){
			if(!closed.has(cur,node))
				return node;
		}
		return undefined;
	}

	if(costFunc){
		nextNode=function(cur:T, path:T[]){
			let next:T|undefined=undefined;
			let nextCost=Infinity;
			for(const node of iterateConnections(cur)){
				if(!closed.has(cur,node)){
					const cost=costFunc(path,node);
					if(nextCost>cost){
						nextCost=cost;
						next=node;
					}
				}
			}
			return next;
		}
	}


	const paths:T[][]=[];
	for(let cur of items){
		const path:T[]=[cur];
		for(let i=0;i<2;++i){
			while(true){
				const next=nextNode(cur,path);
				if(next!==undefined){
					closed.add(cur,next);
					closed.add(next,cur);
					path.push(next);
					cur=next;
				}else
					break;
			}
			cur=path[0];
			path.reverse();
		}
		if(path.length>1)
			paths.push(path);
	}
	return paths;
}


export function pathsBetweenNodes<T>(
	nodes:Set<T>,
	iterateConnections:(item:T)=>Iterable<T>,
){
	const closed=new PairSet<T,T>();
	const paths:T[][]=[];
	for(const seed of nodes){
		const prev=new Map<T,T|null>();
		prev.set(seed,null);

		let working=new Set<T>([seed]);
		while(working.size>0){
			const next=new Set<T>();
			for(const a of working){
				for(const b of iterateConnections(a)){
					if(!closed.has(a,b)){
						closed.add(a,b);
						closed.add(b,a);
						prev.set(b,a);
						if(nodes.has(b)){
							const path:T[]=[];
							for(let cur:T|null|undefined=b;!!cur;cur=prev.get(cur))
								path.push(cur);
							paths.push(path);
						}else{
							next.add(b);
						}
					}
				} 
			}
			working=next;
		}
	}

	return paths;
}
