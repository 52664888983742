import { Component, ElementRef, OnInit, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { FragQueryService } from 'src/injectables/frag-query.service';
import { WebrtcService } from './webrtc.service';
import { WebsocketService } from './websocket.service';
import { LoginService } from 'src/injectables/login.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-video-streaming',
  templateUrl: './video-streaming.component.html',
  styleUrls: ['./video-streaming.component.scss']
})
export class VideoStreamingComponent implements OnInit, AfterViewInit, OnDestroy {
  public readonly devices$ = this.fragQuery.devices$;
  public readonly device$ = this.fragQuery.device$;
  public errorMessage: string | null = null;
  public deviceStatus: string;
  private errorMessageSubscription: Subscription | undefined;
  private deviceStatusSubscription: Subscription | undefined;
  private deviceSubscription: Subscription | undefined;
  public isStreaming: boolean = false;

  @ViewChild('imageEle') imageElement!: ElementRef<HTMLImageElement>;
  @ViewChild('preloadImageEle') preloadImageElement!: ElementRef<HTMLImageElement>;

  constructor(
    public readonly fragQuery: FragQueryService,
    private webrtcService: WebrtcService,
    private websocketService: WebsocketService,
    private loginservice: LoginService,
  ) { }

  ngOnInit() {
    const user = this.loginservice.user$.getValue();
    console.log('user.userId', user.id);
    this.websocketService.connect(user.id.toString());

    this.device$.subscribe(device => {
      this.webrtcService.onlineDevice(device.uuid);
    })

    this.errorMessageSubscription = this.webrtcService.errorMessage$.subscribe(errorMessage => {
      this.errorMessage = errorMessage;
    });
    this.deviceStatusSubscription = this.webrtcService.deviceStatus$.subscribe(deviceStatus => {
      this.deviceStatus = deviceStatus;
      console.log('this.deviceStatus', this.deviceStatusSubscription);
    });
  }

  ngAfterViewInit(): void {
    this.webrtcService.initializeImageElement(this.imageElement.nativeElement, this.preloadImageElement.nativeElement);
  }

  ngOnDestroy() {
    this.stopStreaming();
    if (this.errorMessageSubscription) {
      this.errorMessageSubscription.unsubscribe();
    }
    if (this.deviceSubscription) {
      this.deviceSubscription.unsubscribe();
    }
  }

  stopStreaming() {
    console.log('Stopping stream');
    this.webrtcService.stopStream();
    this.isStreaming = false;
  }

  startStreaming() {
    if (this.deviceSubscription) {
      this.deviceSubscription.unsubscribe();
    }

    this.deviceSubscription = this.device$.subscribe(device => {
      if (device) {
        console.log('Starting stream for device:', device);
        this.webrtcService.startStream(device.uuid.toString())
          .then(() => {
            this.isStreaming = true;
          })
          .catch(error => {
            console.error('Error starting stream:', error);
          });
      } else {
        console.error('No device found when starting stream');
      }
    });
  }

  preloadAndSwapImage(newImageUrl: string) {
    const preloadImage = this.preloadImageElement.nativeElement;
    preloadImage.src = newImageUrl;
    preloadImage.onload = () => {
      this.imageElement.nativeElement.src = newImageUrl;
    };
  }

}