
export class MultiMap<K,V> extends Map<K,V[]>{
	public constructor(entries?:Iterable<[K,V]>){
		super();

		if(entries){
			for(const [k,v] of entries){
				let array=this.get(k);
				if(!array)
					this.set(k,array=[]);
				array.push(v);
			}
		}
	}

	public add(k:K, ...v:[V,...V[]]){
		let array=this.get(k);
		if(!array)
			this.set(k,array=[]);
		array.push(...v);
	}

	public override getOrAdd(k:K, valueMaker:()=>V[]=()=>[]):V[]{
		return super.getOrAdd(k,valueMaker);
	}

	public merge(that:MultiMap<K,V>){
		for(let [key,values] of that){
			values=this.get(key)?.concat(values) ?? values;
			this.set(key,values);
		}
		return this;
	}
}
